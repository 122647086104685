import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { X } from 'lucide-react';
import Popup from 'reactjs-popup';
import * as FaIcons from 'react-icons/fa';
import "./styles/resume_display.css"
import Tooltip from '@mui/material/Tooltip';

export const ShowCandidateResume = ({candidateInc}) => {

    const [fileName, setFileName] = useState(null);
    const [candidate, setCandidate] = useState(candidateInc);


    const [pdfUrl, setPdfUrl] = useState('');

  
      
    const fetchPDF = async () => {
        try {
        const response = await fetch('https://api.skillpool.tech/get_file', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            fileName: candidate?.fileName, // Specify your file name here
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();

        // Create a URL for the blob
        const pdfBlobUrl = URL.createObjectURL(blob);
        setPdfUrl(pdfBlobUrl);
        } catch (error) {
        console.error('Error fetching the PDF', error);
        }
    };



    useEffect(() => {

        if (candidate) {
            if (candidate?.notes) {
                setNotes(candidate?.notes);
            }
        }
        
    }, [candidate])

    //Notes

    const [popupIsOpen, setPopupOpen] = useState(false);


    const handlePopupOpen = () => {
        fetchPDF();
        setPopupOpen(true);
    }

    const [notes, setNotes] = useState(''); // Current notes in the textarea
    const [loadedNotes, setLoadedNotes] = useState('');

    const saveNote = async () => {

        try {
            const response = await fetch('https://api.skillpool.tech/saveNotes', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    candidateId: candidate.id,
                    note: notes
                }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            } catch (error) {
            console.error('Error fetching the PDF', error);
            }
    }

    const closePopup = () => {
        setPopupOpen(false);
        saveNote();
    }

    const openingPopup = () => {}

    return (

        <Popup
            trigger={
                <Tooltip title = "Show Resume">
                    <div className="best_fit_candidate_action candidate_view_resume">
                        <FaIcons.FaRegFilePdf />
                    </div>
                </Tooltip>
            }   
            open={popupIsOpen}
            onOpen={handlePopupOpen}
            modal
            nested
            position="center center" 
        >
            <div className="popup_holder" style={{backdropFilter:"none"}}>
                <div className="popup" style={{boxShadow:"none"}}>
                    <h1 className="popup_heading">Resume</h1>
                    <div className="popup_close" onClick={closePopup}>
                        <X />
                    </div>


                    <div className="resume_content_holder">
                        <div className="resume_holder">
                            {pdfUrl && <iframe src={pdfUrl} toolbar="0" height="100%" width="100%" style={{ border: 'none' }} title="PDF Document"></iframe>}
                        </div>

                        <div className="notes_holder">
                            <div className='notes'>

                                <textarea className='notes_writing_area' placeholder='Write notes about the applicant here!' value={notes} onChange={(e) => setNotes(e.target.value)}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
        
    );
};