import React from 'react';

export const TimeToHireChart = ({ jobs }) => {
  const filledJobs = jobs.filter(job => job.filled && job.filledTime && job.createdTime);
  const totalDays = filledJobs.reduce((total, job) => {
    const startDate = new Date(job.createdTime);
    const endDate = new Date(job.filledTime.seconds * 1000); //this is in Firestore timestamp format
    return (total + (endDate - startDate) / (1000 * 3600 * 24));
  }, 0);
  const averageDays = parseInt(totalDays / filledJobs.length);

  return (
    <div className='company_report_chart_holder time_to_hire_chart'>
      <p className='company_report_chart_heading'>Time-To-Hire: </p>
      <div style={{display:"flex", justifyContent:"center", alignItems:"center", fontWeight:600, color:"var(--primary)"}}>{isNaN(averageDays) ? 'N/A' : `${averageDays} days`}</div>
    </div>
  );
};
