import { Tooltip } from "@mui/material"
import "./styles/schedulingInterview.css"
import { X } from "lucide-react"
import { useEffect, useState } from "react"
import * as FaIcons from "react-icons/fa"
import ReactQuill from "react-quill"
import Popup from "reactjs-popup"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { sendNotification } from "./notification"
import { addDoc, collection, doc, Timestamp, updateDoc } from "firebase/firestore"
import { db } from "../firebase"

export const ScheduleInterviewPopup = ({candidate, job, employerData, shiftedStage, closePopup, closeGlobalPopup}) => {


    const [schedulingType, setSchedulingType] = useState(job?.pipeline[candidate.stage]?.interviewers?.hasEmails ? "availability" : "direct")

    const [emailSubject, setEmailSubject] = useState("")
    const [emailBody, setEmailBody] = useState("")

    const handleEmailBodyChange = (content, delta, source, editor) => {
        setEmailBody(editor.getHTML());
    };

    useEffect(() => {
        if (emailSubject && emailBody) {
            var subject = emailSubject;
            var body = emailBody;
            subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
            subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
            subject = subject.replace(/{{employer_name}}/g, employerData.name);

            body = body.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
            body = body.replace(/{{job_title}}/g, job?.jobTitle);
            body = body.replace(/{{employer_name}}/g, employerData.name);

            setEmailBody(body);
            setEmailSubject(subject);
        }
    }, [emailSubject, emailBody])

    const setEmailDetails = ({subject, body}) => {
        var subject = subject;
        var body = body;
        subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
        subject = subject.replace(/{{employer_name}}/g, employerData.name);

        body = body.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        body = body.replace(/{{job_title}}/g, job?.jobTitle);
        body = body.replace(/{{employer_name}}/g, employerData.name);

        setEmailBody(body);
        setEmailSubject(subject);
    }

    const [scheduleLaterPopup, setScheduleLaterPopup] = useState(false)

    const handleClosePopup = () => {
        closePopup(false)
        // if (shiftedStage) {
        closeGlobalPopup(false)
        // }s
    }

    useEffect(() => {
        if (employerData) {

            let interviewEmails = {
                directBooking: {
                    body: `<p>Hi {{candidate_name}},</p><p>Congratulations on making it to the next round! We're excited to learn more about you and discuss how your background might be a fit for the {{job_title}} at {{employer_name}}.</p><p>Please schedule a time for our in-depth discussion at your earliest convenience by clicking the following link:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Schedule Interview</a></p><p>We can't wait to meet you virtually and explore the possibilities of working together!</p><p>All the best,</p><p>The {{employer_name}} Recruitment Team</p>`,
                    subject: "Congratulations, {{candidate_name}}! Next Steps in Your Application for {{job_title}}",
                },
                availability: {
                    body: `<p>Hi {{candidate_name}},</p><p>Congratulations on making it to the next round! We're excited to learn more about you and discuss how your background might be a fit for the {{job_title}} at {{employer_name}}.</p><p>Please indicate your availability for our in-depth discussion at your earliest convenience:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Give Availability</a></p><p>We can't wait to meet you virtually and explore the possibilities of working together!</p><p>All the best,</p><p>The {{employer_name}} Recruitment Team</p>`,
                    subject: "Congratulations, {{candidate_name}}! Next Steps in Your Application for {{job_title}}",
                },
                reminder: {
                    body: `<p>Hi {{candidate_name}},</p><p>We're sending over this email to remind you of your interview in {{time}}.</p><p>Here's the link for the interview:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Meeting Link</a></p><p>Looking forward to getting in touch with you!</p><p>All the best,</p><p>The {{employer_name}} Recruitment Team</p>`,
                    subject: "Reminder for your Interview in {{time}}!",
                }
            }

            if (!employerData.settings.email_templates.interviews) {
                updateDoc(doc(db, "teams", employerData.id), {
                    settings: {
                        ...employerData.settings,
                        email_templates: {
                            ...employerData.settings.email_templates,
                            interviews: interviewEmails
                        }
                    }
                })


            } else {
                interviewEmails = employerData.settings.email_templates.interviews;
            }
            console.log();
            if (schedulingType === "direct") {
                setEmailDetails({subject: interviewEmails.directBooking.subject, body: interviewEmails.directBooking.body})
            } else {
                setEmailDetails({subject: interviewEmails.availability.subject, body: interviewEmails.availability.body})
            }
        }
        
    }, [employerData, schedulingType])


    // handlign input for scheduling later

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(() => {
        const now = new Date();
        const minutes = Math.ceil(now.getMinutes() / 30) * 30;
        now.setMinutes(minutes);
        now.setSeconds(0);
        return now.toTimeString().slice(0, 5);
    });

    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                options.push(<option key={time} value={time}>{formatTime(time)}</option>);
            }
        }
        return options;
    };

    const convertToFirebaseTimestamp = () => {
        const dateTime = new Date(selectedDate);
        const [hours, minutes] = selectedTime.split(':');
        dateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        return Timestamp.fromDate(dateTime);
    };

    const formatTime = (time) => {
        const [hour, minute] = time.split(':');
        const hourInt = parseInt(hour, 10);
        const ampm = hourInt >= 12 ? 'PM' : 'AM';
        const hour12 = hourInt % 12 || 12;
        return `${hour12}:${minute} ${ampm}`;
    };

    const [showDatePicker, setShowDatePicker] = useState(false);

    const handleTimeChange = (e) => {
        const newTime = e.target.value;
        const currentDate = new Date();
        const selectedDateTime = new Date(selectedDate.toDateString() + ' ' + newTime);

        if (selectedDate.toDateString() === currentDate.toDateString() && selectedDateTime <= currentDate) {
            sendNotification("Please select a time after now")
            return; // Don't update if the selected time is before or equal to the current time
        }

        setSelectedTime(newTime);
    };


    const [sendingConfirmation, setSendingConfirmation] = useState(false)
    const [schedulingConfirmation, setSchedulingConfirmation] = useState(false)


    const sendEmail = async (link = null) => {
        let emailData;

        if (link) {
            emailData = {
                senderEmail: candidate.employerEmail,
                receiverEmail: candidate.email,
                subject: emailSubject,
                body: emailBody,
                candidateID: candidate.id,
                link: link,
            }
        } else {
            emailData = {
                senderEmail: candidate.employerEmail,
                receiverEmail: candidate.email,
                subject: emailSubject,
                body: emailBody,
                candidateID: candidate.id,
            }
        }

        fetch('https://api.skillpool.tech/send_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
            // Include headers if your server requires them
        });

        setSendingConfirmation(true)

        setTimeout(() => {
            setSendingConfirmation(false)
            handleClosePopup();
        }, 2000)

       
    }


    const scheduleEmail = async (link = null) => {
        const timestamp = convertToFirebaseTimestamp();
        let emailData;
        console.log(candidate);

       

        if (link) {
            emailData = {
                senderEmail: candidate.employerEmail,
                receiverEmail: candidate.email,
                subject: emailSubject,
                body: emailBody,
                candidateID: candidate.id,
                time: timestamp,
                link: link,
            }
        } else {
            emailData = {
                senderEmail: candidate.employerEmail,
                receiverEmail: candidate.email,
                subject: emailSubject,
                body: emailBody,
                candidateID: candidate.id,
                time: timestamp,
            }
        }

        fetch('https://api.skillpool.tech/schedule_send_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
            // Include headers if your server requires them
        });

        setSchedulingConfirmation(true)

        setTimeout(() => {
            setSchedulingConfirmation(false)
            handleClosePopup();
        }, 2000)
        
    }


    const handleSendingEmail = async (schedule = false) => {
        const meetingRef = collection(db, "meeting_invites");
        const meetingDoc = await addDoc(meetingRef, {
            candidateID: candidate.id,
            jobID: candidate.job_id,
            stage: candidate.stage,
            duration: job.pipeline[candidate.stage].duration || 30,
            type: schedulingType,
        });

        let meetingLink = `https://jobs.skillpool.tech/book-meeting/${meetingDoc.id}`;
    
        // update candidate data to make scheduled true
        candidate.scheduled = true;

        try {
            const candidateRef = doc(db, "candidates", candidate.id);
            await updateDoc(candidateRef, {
                scheduled: true,
            });
        } catch (error) {
            console.error("Error updating candidate:", error);
        }

        if (schedule) {
            scheduleEmail(meetingLink);
        } else {
            sendEmail(meetingLink);
        }
    }


    const handleSchedulingTypeChange = (type) => {

        if (type === "direct") {
            if (job.pipeline[candidate.stage].interviewers.hasEmails) {
                sendNotification("Cannot use direct availability when using external interviewers")
                return;
            }
        }


        setSchedulingType(type)
    }


    return (
        <div className="popup_holder">
            <div className="popup">
                <div className="popup_close" onClick={handleClosePopup}>
                    <X />
                </div>

                <h1 className="popup_heading" style={{marginBottom:"1rem"}}>
                    Schedule Interview
                </h1>

                {
                    sendingConfirmation ? (
                        <div className="scheduling_confirmation">
                            <p>Sent the candidate a scheduling email!</p>
                        </div>
                    ) : (
                        <>
                            <div className="popup_body">
                                {shiftedStage ?  
                                    <p>
                                        {candidate.name} has been moved to the interview stage! Would you like to schedule an interview now?
                                    </p>
                                    : 
                                    <p></p>
                                }

                                <div className="scheduling_type">
                                    <p><strong>Type of Scheduling: </strong></p>

                                    <div className="scheduling_type_options">
                                        <div 
                                            className={`scheduling_type_option ${schedulingType === "direct" ? "selected" : ""}`}
                                            onClick={() => handleSchedulingTypeChange("direct")}
                                        >
                                            <p>Candidate Direct Booking</p>
                                        </div>

                                        <div 
                                            className={`scheduling_type_option ${schedulingType === "availability" ? "selected" : ""}`}
                                            onClick={() => handleSchedulingTypeChange("availability")}
                                        >
                                            <p>Candidate Submit Availability</p>
                                        </div>
                                    </div>
                                </div> 


                                <div className="scheduling_email">
                                    <p><strong>Email: </strong></p>

                                    <input 
                                        type="text" 
                                        placeholder="Please enter the subject here..." 
                                        value={emailSubject}
                                        onChange={(e) => setEmailSubject(e.target.value)}
                                        className="scheduling_email_subject"
                                    />

                                    <div className="scheduling_email_body">
                                        <ReactQuill theme="snow" value={emailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="scheduling_email_body"/>
                                    </div>
                                </div>
                            </div>

                            <div className="scheduling_bottom_actions">
                                <div className="scheduling_action later" onClick={handleClosePopup}>
                                    Later
                                </div>

                                <div className="send_email_actions">

                                    <Popup 
                                    trigger={
                                        <Tooltip title="Schedule Email">
                                            <div className="schedule_later">
                                                <FaIcons.FaRegClock />
                                            </div>
                                        </Tooltip>
                                    }
                                    position="center center"
                                    open={scheduleLaterPopup}
                                    onOpen={() => setScheduleLaterPopup(true)}
                                    modal
                                    nested
                                    >
                                        <div className="popup_holder second">
                                            <div className="popup" style={{width: "40%", height:"50%"}}>
                                                <div className="popup_close" onClick={() => setScheduleLaterPopup(false)}>
                                                    <X />
                                                </div>

                                                <h1 className="popup_heading">
                                                    Schedule email
                                                </h1>

                                                {schedulingConfirmation ? (
                                                    <div className="scheduling_confirmation">
                                                        <p>Scheduled the email successfully!</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                    <div className="popup_body">
                                                        <p className="time-info">
                                                            {new Date(selectedDate.toDateString() + ' ' + selectedTime).toLocaleString('en-US', { 
                                                            weekday: 'short', 
                                                            month: 'short', 
                                                            day: 'numeric', 
                                                            year: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            timeZoneName: 'short'
                                                            })}
                                                            , based on your location
                                                        </p>
                                                        
                                                        <div className="input-group">
                                                            <label>Date</label>
                                                            <DatePicker
                                                            selected={selectedDate}
                                                            onChange={(date) => setSelectedDate(date)}
                                                            dateFormat="MM/dd/yyyy"
                                                            className="date-input"
                                                            minDate={new Date()}
                                                            popperPlacement="bottom-start"
                                                            open={showDatePicker}
                                                            onInputClick={() => {
                                                                setShowDatePicker(true)
                                                            }}
                                                            onSelect={() => {
                                                                setShowDatePicker(false)
                                                            }}
                                                            onClickOutside={() => setShowDatePicker(false)}
                                                            />
                                                        </div>
                                                        <div className="input-group">
                                                            <label>Time</label>
                                                            <select
                                                            value={selectedTime}
                                                            onChange={handleTimeChange}
                                                            className="time-input"
                                                            >
                                                            {generateTimeOptions()}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="popup_bottom_actions" style={{padding: "0rem 1rem", boxSizing:"border-box"}}>
                                                        <div></div>
                                                        <div className="schedule_email" onClick={() => handleSendingEmail(true)}>
                                                            Schedule
                                                        </div>
                                                    </div>
                                                    </>
                                                )
                                                }

                                                
                                            </div>
                                        </div>
                                    </Popup>
                                    
                                    <div className="scheduling_action send" onClick={() => handleSendingEmail(false)}>
                                        Send
                                    </div>
                                </div>
                                
                            </div>
                        </>
                    )
                }
                
            </div>
        </div>
    ) 
}