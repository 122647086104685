import { useEffect, useState } from "react";
import "./styles/debrief.css"
import ToggleButton from "../../toggleButton";
import * as FaIcons from "react-icons/fa"
import { nodesOrder, pipeline } from "../../../pages/createJob";
import { Tooltip } from "react-tooltip";

export const Debrief = ({
    enabled, 
    setEnabled,
    debriefDuration,
    setDebriefDuration,
    scheduleAutomatically,
    setScheduleAutomatically,
    sendEmailReminders,
    setSendEmailReminders,
    emailRemindersTime,
    setEmailRemindersTime,
    debriefMembers,
    setDebriefMembers,
    complete,
    setComplete,
    index
}) => {


    // handling the completeness

    useEffect(() => {
        if (enabled && debriefMembers.length === 0) {
            setComplete(false)
        } else {
            setComplete(true)
        }
    }, [enabled, debriefMembers])



    const addPastInterviewers = () => {
        // add all past interviewers to the debrief
    
        // first look through all past interviews to get all the interviews so far.

        // then add them all to the debrief members

        const updatedPipeline = pipeline.filter(node => !node.deleted);

        const orderedPipeline = nodesOrder.map(index => updatedPipeline[index])

        const orderedIndex = nodesOrder[index]

        const currentNode = orderedPipeline[orderedIndex];

        // Get all the interviewers until and including the current node
        const updatedDebriefMembers = orderedPipeline
            .slice(0, orderedIndex + 1)
            .filter(node => node.label === 'interview')
            .map(node => ({
                name: node.details.name,
                interviewers: node.interviewers.interviewers
            }));

        setDebriefMembers(updatedDebriefMembers);

        console.log(updatedDebriefMembers);

        
    }


    const deleteDebriefMember = (index) => {
        const updatedDebriefMembers = debriefMembers.filter((_, i) => i !== index);
        setDebriefMembers(updatedDebriefMembers);
    }


    return (
        <div className="interview_section_holder">
            <div className="feedback_category interview_feedback_category">
                <p className="interview_section_text">
                    Include a debrief with others?
                </p>

                <ToggleButton isOn={enabled} setIsOn={setEnabled} />
            </div>  

            {
                enabled && (

                    <>

                    <div className="debrief_section attendees">

                        <div className="debrief_section_top">
                            <p className="interview_section_text">
                                Attendees
                            </p>

                            <div className="interview_section_action add_past_interviewers" onClick={addPastInterviewers}>
                                Add All Past Interviewers
                            </div>
                        </div>
                        
                        
        
                        <div className="past_interviewers_area">
                            {
                                debriefMembers.map((member, index) => (
                                    <div className="past_stage" key={index}>

                                        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                            <p className="past_stage_name">{member.name}</p>
                                            <div style={{display:"flex", alignItems:"center"}} onClick={() => deleteDebriefMember(index)}>
                                                <FaIcons.FaTrashAlt className="delete_interviewer"/>
                                            </div>
                                        </div>
                                        
                                        <div className="past_interviewer_interviewers">
                                            {member.interviewers.map((interviewer, index) => (    
                                                (interviewer.type === "group") ? (
                                                    <div key={index} className="past_interviewer_group_item">

                                                        {/* add an info icon here that says "This will select the person who actually conducted the interview" */}

                                                        <div style={{display:"flex", alignItems:"center"}}>
                                                           
                                                            <span>Select <strong>{interviewer.count}</strong> from: </span>

                                                            <FaIcons.FaInfoCircle className="tooltip_info_icon" style={{marginLeft:"0.5rem", opacity:0.7}} />

                                                            <Tooltip anchorSelect=".tooltip_info_icon" id="tooltip-info" place="top" effect="solid">
                                                                <span>This will select the person who actually conducted the interview</span>
                                                            </Tooltip>

                                                            {/* make this info icon clickable and show a tooltip that says "This will select the person who actually conducted the interview" */}
                                                        </div>

                                                        <div className="interviewers_sublist">
                                                            {
                                                                interviewer.interviewers.map((specificInterviewer, index) => (
                                                                    <div key={index} className="interviewer_sub_item">
                                                                        <span>{specificInterviewer.name}</span>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        
                                                    </div>
                                                ) : (
                                                    <div key={index} className="past_interviewer_item">
                                                        <span>{interviewer.name}</span>

                                                    </div>
                                                )
                                                
                                                
                                            ))}   
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    </div>
        
        
                    <div className="debrief_section scheduling">

                        <div className="debrief_section_top">
                            <p className="interview_section_text">
                                Scheduling
                            </p>

                            <div className="debrief_duration_holder">
                                <FaIcons.FaRegClock style={{marginRight:"0.5rem"}} />

                                <input 
                                    type="number" 
                                    className="interview_section_number_input debrief" 
                                    value={debriefDuration} 
                                    onChange={(e) => setDebriefDuration(e.target.value)} 
                                />

                                minutes
                            </div>
                        </div>
                        
        
                        <div className="debrief_scheduling_section_row">
                            <p>Schedule Automatically</p>

                            <ToggleButton isOn={scheduleAutomatically} setIsOn={setScheduleAutomatically} />
                        </div>

                        <div className="debrief_scheduling_section_row">

                            <div className="scheduling_section_row_first">
                                <p>Send Email Reminders</p>

                                    {
                                        sendEmailReminders && (
                                            <>
                                            <select className="debrief_scheduling_section_select" value={emailRemindersTime} onChange={(e) => setEmailRemindersTime(parseInt(e.target.value))}>
                                                <option value="12">12 hours</option>
                                                <option value="24" selected>24 hours</option>
                                                <option value="36">36 hours</option>
                                                <option value="48">2 days</option>                             
                                            </select> 

                                            <p>before the interview</p>
                                            </>
                                        )
                                    }
                            </div>

                            <ToggleButton isOn={sendEmailReminders} setIsOn={setSendEmailReminders} />
                        </div>
                    </div>
                    </>

                )
            }
        </div>
    )
}
