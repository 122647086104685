import { Handle } from "reactflow";

import "./styles/node.css"
import "./styles/prescreeningNode.css"
import Popup from "reactjs-popup";
import { useState } from "react";
import { X } from "lucide-react";


export const MadeOfferNode = ({ data }) => {
    
    const [popupIsOpen, setPopupOpen] = useState(false);
    const [complete, setComplete] = useState(true);

    const deleteNode = () => {
        data.deleteNode(data.id, data.pipelineIndex);
        setPopupOpen(false);
    }

    return (<Popup trigger={

        <div className='pipeline_node_display'>
            Made Offer
            <Handle type="target" position="left" id="5"  style={{pointerEvents:"none"}}/>
            {/* <Handle type="target" position="top" id="6"  style={{pointerEvents:"none"}}/> */}
            <Handle type="source" position="right" id="4"  style={{pointerEvents:"none"}}/>
            {/* <Handle type="source" position="bottom" id="3"  style={{pointerEvents:"none"}}/> */}
            
            


            {!complete && (
                    <div className="node_incomplete"> 
                        ! 
                        {/* Note that this will actually be an orange circle / symbol.  */}
                    </div>
            )}

        </div>

    }

    open={popupIsOpen}
    onOpen={() => setPopupOpen(true)}
    modal
    nested
    position="center center"
    >
    
    <div className="create_stage_holder">
        <div className="create_stage">
            <h1 className="create_stage_heading"> Made Offer </h1>

            <div className="popup_close" onClick={() => setPopupOpen(false)}>
                <X />
            </div>

            <div className="stage_bottom_actions">
                <div className="stage_delete" onClick={deleteNode}>
                    Delete
                </div>

                <div className="stage_save"  onClick={(e) => setPopupOpen(false)}>
                    Save
                </div>
            </div>
        </div>
    </div>

    </Popup>
)};