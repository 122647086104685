import React from "react";

export const WeightageSlider = ({ value, onChange, max = 100, min = 0 }) => {
  return (
    <div
      className="number_slider skillscore_weightage_slider"
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="range"
        min="0" // Minimum value
        max={max} // Maximum value
        value={value} // Controlled by the parent component
        onChange={onChange} // Handled by the parent component
      />
    </div>
  );
};

function interpolateColor(lowColor, highColor, weightage) {
  const parseRGB = (rgb) => {
    const [r, g, b] = rgb.match(/\d+/g).map(Number);
    return { r, g, b };
  };

  const low = parseRGB(lowColor);
  const high = parseRGB(highColor);

  const interpolate = (lowVal, highVal, factor) =>
    lowVal + factor * (highVal - lowVal);

  const factor = weightage / 10;
  const r = interpolate(low.r, high.r, factor);
  const g = interpolate(low.g, high.g, factor);
  const b = interpolate(low.b, high.b, factor);
  const opacity = "0.3";

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const getBackgroundColor = (weightage) => {
  const lowColor = "rgb(249, 246, 249)"; // Low weightage
  const highColor = "rgb(200, 153, 214)"; // High weightage with 20% opacity

  return interpolateColor(lowColor, highColor, weightage);
};
