import { Link } from "react-router-dom";
import "./styles/all_jobs.css"
import * as FaIcons from "react-icons/fa"
import { useEffect, useState } from "react";
import { initialCandidates } from "./job_subPages/pipeline";
import { auth, db } from "../firebase";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";

export const AllJobs = () => {

    const [canRun, setCanRun] = useState(false);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);


    useEffect( () => {



        const fetchJobs = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }

                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }

                const memberData = memberSnapshot.docs[0].data();
                const { team_id } = memberData;
                try {
                    const response = await fetch('https://api.skillpool.tech/getJobs', {
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ employer_id: team_id }),
                    });
                    const data = await response.json();
                    setJobs(data);
                    setFinishedLoading(true);
                } catch (error) {
                console.error("Error fetching jobs:", error);
                }


            } catch (error) {
                console.error("Failed to fetch jobs:", error);
            }
        };

        if (canRun) {
            fetchJobs();
        }


    }, [canRun]);

    const [jobs, setJobs] = useState([])


    const [searchTerm, setSearchTerm] = useState('');

    // Filter jobs based on search term
    const filteredJobs = jobs
    .filter(job =>
        job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(job => job.deleted !== true
    )
    .filter(job => job.filled !== true)

    const filledJobs = jobs.filter(job => job.filled === true);

    const [finishedLoading, setFinishedLoading] = useState(false);


    return (

        <>
            <div className = "jobs_page_content">

            <div className="jobs_page_header">
                <h1>
                    Jobs Page
                </h1>
            </div>

            <div className="all_job_postings">

                <div style={{justifyContent: "space-between", display: "flex", alignItems:"center", marginBottom:"2rem"}}>

                    <div style={{display:"flex", alignItems: "center"}}>
                        <FaIcons.FaSearch style={{opacity:0.5}} />
                        <input
                            className="searchBar"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            placeholder={(
                                "Search!"
                            )}/>

                    </div>


                    <div className="create_new_job"><Link to="new">

                        <FaIcons.FaPlus style={{marginRight:"1rem"}}/>

                        <p> Add New </p>

                    </Link></div>
                </div>


                {
                    finishedLoading && (
                        <>
                            { !((filteredJobs.length === 0) && (filledJobs.length === 0)) && (
                                <div className="all_jobs_area">

                                    <div className="unfilled_jobs_area">
                                        {filteredJobs.map((job) => (
                                            <div className="job_posting_holder">
                                                <Link className = "job_posting_link" to={`/job/${job.id}`}>
                                                    <div className="job_posting">
                                                        <div class = "job_posting_info">
                                                            <h2> {job.jobTitle} </h2>
                                                            <span style={{fontWeight: "bold"}}> Location: </span>
                                                            <span>  {job?.remote ? 'Remote' : job?.location?.label}  </span>
                                                        </div>


                                                        <div className="end_of_posting">
                                                            <FaIcons.FaArrowRight />
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                        ))}

                                        {
                                            filteredJobs.length === 0 && (
                                              <div className="no_jobs_yet">
                                                  <p> No active jobs yet!</p>
                                              </div>
                                            )
                                        }
                                    </div>

                                    {
                                      filledJobs.length !== 0 && (
                                        <div className="filled_jobs_area">

                                            <h1 style={{fontSize:"1.75rem"}}>Filled Jobs: </h1>
                                            {filledJobs.map((job) => (
                                                <div className="job_posting_holder">
                                                    <Link className = "job_posting_link" to={`/job/${job.id}`}>
                                                        <div className="job_posting">
                                                            <div class = "job_posting_info">
                                                                <h2> {job.jobTitle} </h2>
                                                                <span style={{fontWeight: "bold"}}> Location: </span>
                                                                <span>  {job?.remote ? 'Remote' : job?.location?.label}  </span>
                                                            </div>


                                                            <div className="end_of_posting">
                                                                <FaIcons.FaArrowRight />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            ))}
                                        </div>
                                    )}



                                </div>
                            )}



                            {
                                ((filteredJobs.length === 0) && (filledJobs.length === 0)) && (
                                    <div className="no_jobs_yet">
                                        <p> No jobs yet!</p>
                                    </div>
                                )
                            }
                        </>
                    )
                }









            </div>

            </div>


        </>

    );
};
