
import "./styles/candidate.css"
import "./styles/candidate_details.css"
// import "./styles/candidate_resume.css"
import * as FaIcons from "react-icons/fa"
import { ImCross } from "react-icons/im";
import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { DonutChart } from "../components/donutChart";
import { updateCandidate } from "./candidate_subPages/details";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { candidatesList, curr_index, setCandidateListIndex } from "./job_subPages/pipeline";
import { globalCandidates, lastPage, setGlobalCandidates, stagesNames } from "./job";
import Popup from "reactjs-popup";
import { X } from "lucide-react";
import { CandidateEmailHistory } from "../components/candidateEmailHistory";
import { CandidateNotes } from "../components/candidate_subcomponents/candidateNotes/candidateNotes";
import { ShiftCandidateStage } from "../components/shiftCandidateStage";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../components/shadcn/collapsible";
import { CandidateDelete } from "../components/candidate_subcomponents/deleteCandidate/candidateDelete";
import { CandidateResume } from "../components/candidate_subcomponents/candidateResume/candidateResume";
import { CandidateScreeningInterviewTranscript } from "../components/candidate_subcomponents/candidateScreeningTranscript/candidateScreeningTranscript";
import { ScheduleInterviewPopup } from "../components/scheduleInterview";
import { sendNotification } from "../components/notification";



export const Candidate = () => {

    const [candidate, setCandidate] = useState(null);
    const [job, setJob] = useState(null);
    const [employer, setEmployer] = useState(null);
    const [employerId, setEmployerId] = useState(null);

    var candidateId = window.location.href.split('/')[6]
    var jobId = window.location.href.split('/')[4]


    const [selectedStage, setSelectedStage] = useState(null); //Start this with the candidate's current stage

    const handleStageSelect = (stage) => {
        setSelectedStage(stage);
    };


    const [pipeline, setPipeline] = useState([]); // Change this later

    useEffect(() => {

        const getJob = async () => {
            try {

                const docRef = doc(db, 'jobs', jobId); // Create a reference to the candidate document
                const docSnap = await getDoc(docRef); // Get the document snapshot
                if (docSnap.exists()) {
                    setJob(docSnap.data()); // Set the candidate state with the data from Firestore
                    setPipeline(docSnap.data().pipeline); // Set the candidate state with the data from Firestore
                    setEmployerId(docSnap.data().employer_id)

                }

            } catch (error) {
                console.error("Error fetching job:", error);
            }
        };

        const getCandidate = async () => {
            try {

                const docRef = doc(db, 'candidates', candidateId); // Create a reference to the candidate document
                const docSnap = await getDoc(docRef); // Get the document snapshot
                var candidateData = null;
                if (docSnap.exists()) {
                    candidateData = docSnap.data();
                    setCandidate({...docSnap.data(), id: docSnap.id}); // Set the candidate state with the data from Firestore
                    setSelectedStage(candidateData.stage)
                    setInterviews(candidateData.interviews);
                }

                // Get the job id and then the pipeline here


                if (!candidateData.viewed) {
                    const candidateRef = doc(db, "candidates", candidateId);
                    await updateDoc(candidateRef, {
                        viewed: true
                    });
                }

            } catch (error) {
                console.error("Error fetching candidates:", error);
            }
        };



        if (candidateId) {
            getCandidate();
        }

        if (jobId) {
            getJob();
        }

        if (globalCandidates.length === 0) {

            console.log(localStorage.getItem('globalCandidates'))
            const globalCandidates = JSON.parse(localStorage.getItem('globalCandidates'));
            setGlobalCandidates(globalCandidates);
        }

    }, []);


    useEffect(() => {

        const getEmployer = async () => {
            try {

                const docRef = doc(db, 'teams', employerId); // Create a reference to the candidate document
                const docSnap = await getDoc(docRef); // Get the document snapshot
                if (docSnap.exists()) {
                    setEmployer({
                        ...docSnap.data(),
                        id: docSnap.id
                    }); // Set the candidate state with the data from Firestore
                }

            } catch (error) {
                console.error("Error fetching employer:", error);
            }
        };

        if (employerId) {
            getEmployer();
        }

    }, [employerId])

    const [interviews, setInterviews] = useState([]); //array to handle feedback from as many interviewing rounds as necessary.

    const interviewRefs = useRef([]);

    const [currInterviewIndex, setCurrInterviewIndex] = useState(0);

    useEffect(() => {
        interviewRefs.current = interviewRefs.current.slice(0, interviews?.length);
    }, [interviews]);

    const showInterview = (index) => {
        if (index >= interviews.length) {
            setCurrInterviewIndex(0);
        } else if (index < 0) {
            setCurrInterviewIndex(interviews.length - 1);
        } else {
            setCurrInterviewIndex(index);
        }
    };

    const nextInterview = () => {
        showInterview(currInterviewIndex + 1);
    };

    const prevInterview = () => {
        showInterview(currInterviewIndex - 1);
    };






    // Handling the technical skill, soft skill, personality fit switching

    const candidateInfoContainers = [ //obviously only the sliding ones
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const [currentInfoVisibleIndex, setCurrentInfoVisibleIndex] = useState(0);

    const showSlide = (index) => {
        if (index >= candidateInfoContainers.length) {
            setCurrentInfoVisibleIndex(0);
        } else if (index < 0) {
            setCurrentInfoVisibleIndex(candidateInfoContainers.length - 1);
        } else {
            setCurrentInfoVisibleIndex(index);
        }
    };

    const nextSlide = () => {
        showSlide(currentInfoVisibleIndex + 1);
    };

    const prevSlide = () => {
        showSlide(currentInfoVisibleIndex - 1);
    };


// handing the passwork auth seitch across brosers
    const [candidateWorkSelectedSection, setCandidateWorkSelectedSection] = useState('Jobs');


    const evaluateScore = score => score <= 20 ? "Poor" : score <= 40 ? "Low" : score <= 60 ? "Moderate" : score <= 80 ? "Good" : "High";

    const formatDuration = months => `${months >= 12 ? Math.floor(months / 12) + ' years and ' : ''}${months % 12} months`;

    const getUniversityRanking = rank =>
    rank == 0 ? "Top 1000+":
    rank <= 5 ? "Top 5" :
    rank <= 20 ? "Top 20" :
    rank <= 50 ? "Top 50" :
    rank <= 100 ? "Top 100" :
    rank <= 200 ? "Top 200" :
    rank <= 500 ? "Top 500" :
    rank <= 1000 ? "Top 1000" :
    "Top 1000+";

    // The above evaluate score is just to convert the score to some text


    // To handle showing each skill with it's popups

    const SoftSkillShowcase = ({ skill }) => {

        const [popupIsOpen, setPopupOpen] = useState(false);

        const sentences = skill.sentences || [];

        const sortedSentences = sentences.filter(sentence => sentence.sentence !== "None")
        .sort((a, b) => b.score - a.score)
        .slice(0, 5);

        const showcaseNames = sortedSentences.map(sentence => '"' + sentence.sentence + '"').join(', ');

        return (

            <Popup trigger={

            <div className="candidate_skill">

                <div className="candidate_skill_top">

                    <div style={{display:"flex", alignItems:"center"}}>
                        <DonutChart percentage={skill.score} color="darkgreen" multiplier={0.05} fontSize={12} />
                        <div className="candidate_skill_name">
                            {skill.name}
                        </div>
                    </div>

                    <FaIcons.FaExpandAlt />
                </div>

                <div className="skill_showcase">
                        <p className="skill_showcase_head">Seen in: </p>
                        <div className="skill_showcase_content">{showcaseNames || 'Not seen anywhere!'}</div>
                    </div>

            </div>

            }

            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            >

            <div className="skill_popup_holder">
                <div className="skill_popup">

                    <div className="skill_popup_header">
                        <div className="skill_popup_header_first">
                            <DonutChart percentage={skill.score} color="darkgreen" multiplier={0.075} fontSize={15} />
                            <h3>{skill.name}</h3>
                        </div>

                        <div className="skill_popup_close" onClick={() => setPopupOpen(false)}>
                            <X />
                        </div>
                    </div>

                    <p style={{marginLeft: "2rem"}}>{candidate?.name} displayed {skill.name} in their interview in these sentences: </p>

                    <div className="skill_popup_showcases_holder skill_sentences_holder">
                        {sortedSentences.map((sentence, index) => (
                            <div key={index} className="skill_popup_sentence">

                                <p>"{sentence.sentence}"</p>

                            </div>
                        ))}
                    </div>

                </div>
            </div>

            </Popup>
        );
    };

    const PersonalityFitShowcase = ({ personality }) => {

        const [popupIsOpen, setPopupOpen] = useState(false);

        const sentences = personality.sentences || [];

        const sortedSentences = sentences.filter(sentence => sentence.sentence !== "None")
        .sort((a, b) => b.score - a.score)
        .slice(0, 5);




        const showcaseNames = sortedSentences.map(sentence => '"' + sentence.sentence + '"').join(', ');


        return (
            <Popup trigger={

                <div className="candidate_skill">

                    <div className="candidate_skill_top">

                        <div style={{display:"flex", alignItems:"center"}}>
                            <DonutChart percentage={personality.score} color="darkgreen" multiplier={0.05} fontSize={12} />
                            <div className="candidate_skill_name">
                                {personality.name}
                            </div>
                        </div>


                        <FaIcons.FaExpandAlt />
                    </div>

                    <div className="skill_showcase">
                        <p className="skill_showcase_head">Seen in: </p>
                        <div className="skill_showcase_content">{showcaseNames || 'Not seen anywhere!'}</div>
                    </div>

                </div>

                }

                open={popupIsOpen}
                onOpen={() => setPopupOpen(true)}
                modal
                nested
                position="center center"
                >

                <div className="skill_popup_holder">
                    <div className="skill_popup">

                        <div className="skill_popup_header">
                            <div className="skill_popup_header_first">
                                <DonutChart percentage={personality.score} color="darkgreen" multiplier={0.075} fontSize={15} />
                                <h3>{personality.name}</h3>
                            </div>


                            <div className="skill_popup_close" onClick={() => setPopupOpen(false)}>
                                <X />
                            </div>
                        </div>

                        <p style={{marginLeft: "2rem"}}>{candidate?.name} displayed {personality.name} in their interview in these sentences: </p>

                        <div className="skill_popup_showcases_holder skill_sentences_holder">
                            {sortedSentences.map((sentence, index) => (
                                <div key={index} className="skill_popup_sentence">

                                    <p>"{sentence.sentence}"</p>

                                </div>
                            ))}
                        </div>

                    </div>
                </div>

            </Popup>
        );
    };

    const TechnicalSkill = ({ skill }) => {

        const [popupIsOpen, setPopupOpen] = useState(false);

        const showcases = skill.showcases || [];

        // Sort showcases based on the score
        const sortedShowcases = showcases.sort((a, b) => b.score - a.score);

        // Extract the names
        const showcaseNames = sortedShowcases.map(showcase => showcase.title).join(', ');

        return (

            <Popup trigger={

                <div className="candidate_skill">

                    <div className="candidate_skill_top">

                        <div style={{display:"flex", alignItems:"center"}}>
                            <div className="candidate_skill_score">
                                <DonutChart percentage={skill.score} color="darkgreen" multiplier={0.05} fontSize={12} />
                            </div>
                            <div className="candidate_skill_name">
                                {skill.name}
                            </div>
                        </div>


                        <FaIcons.FaExpandAlt />
                    </div>

                    <div className="skill_showcase">
                        <p className="skill_showcase_head">Seen in: </p>
                        <div className="skill_showcase_content">{showcaseNames || 'Not seen anywhere!'}</div>
                    </div>


                </div>

            }

            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            >

            <div className="skill_popup_holder">
                <div className="skill_popup">

                    <div className="skill_popup_header">
                        <div className="skill_popup_header_first">
                            <div className="candidate_skill_score">
                                <DonutChart percentage={skill.score} color="darkgreen" multiplier={0.075} fontSize={15} />
                            </div>
                            <h3>{skill.name}</h3>
                        </div>

                        <div className="skill_popup_close" onClick={() => setPopupOpen(false)}>
                            <X />
                        </div>
                    </div>

                    <p style={{marginLeft: "2rem"}}>{candidate?.name} displays {skill.name} in the following experiences: </p>

                    <div className="skill_popup_showcases_holder">
                        {sortedShowcases.map((showcase, index) => (
                            <div key={index} className="skill_popup_showcase">

                                <div className="skill_popup_showcase_info">
                                    <h4 className="skill_popup_showcase_title">{showcase.title}</h4>

                                    <div className="skill_popup_showcase_score">
                                        <p>Level of Usage: </p>
                                        <DonutChart percentage={showcase.score * 10} color="darkgreen" multiplier={0.05} fontSize={12} />
                                    </div>

                                </div>

                                <div className="skill_popup_showcase_reason">
                                    <p>{showcase.reason}</p>
                                </div>

                            </div>
                        ))}
                    </div>

                </div>
            </div>

            </Popup>
        )

    }


    const JobDisplay = ({job}) => {

        const [popupIsOpen, setPopupOpen] = useState(false);


        // Handling teh collapsibles for each section


        const [companyDetailOpen, setCompanyDetailOpen] = useState(false)
        const [detailDetailOpen, setDetailDetailOpen] = useState(false);
        const [durationDetailOpem, setDurationDetailOpen] = useState(false);
        const [levelDetailOpen, setLevelDetailOpen] = useState(false);

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return (

            <Popup
            trigger={
                <div className="candidate_work_history_job">


                <div className="candidate_work_history_job_top">


                <div style={{display:"flex", alignItems:"center"}}>

                    <div className="candidate_work_history_job_score">
                        <DonutChart percentage={job.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                    </div>

                    <div className="candidate_work_history_job_info">

                    <div className="candidate_work_history_job_title">
                        {job.title}
                    </div>
                    <div className="candidate_work_history_job_company">
                        {job.company.name}
                    </div>

                    </div>

                </div>

                <div className="candidate_work_history_job_duration">
                    <p>{months[job.duration.duration.startMonth]} {job.duration.duration.startYear} </p>
                </div>

                </div>

                <div className="candidate_work_history_job_description">
                <ul>
                    {job.details.details.map((detail, index) => (
                    <li key={index} className="candidate_work_history_job_list_item">{detail}</li>
                    ))}
                </ul>
                </div>
                </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            >
            <div className="popup_holder">
                <div className="popup">

                    <div className="score_popup_heading">
                        <DonutChart percentage={job.score} color="darkgreen" multiplier={0.075} fontSize={15}   />
                        <h1 className="popup_heading"> {job.title} </h1>

                    </div>

                    <div className="popup_body">
                        <p>The position {job.title} has received a score of {job.score} because:</p>


                        <div className="job_score_details">

                        {/* The factors to display are: Company, Details, Duration, Job Level */}


                            <Collapsible
                                open={detailDetailOpen}
                                onOpenChange={setDetailDetailOpen}
                                className="job_score_detail_collapsible"
                                >

                                <CollapsibleTrigger
                                className="job_score_detail_holder work_detail"
                                >
                                <div className="job_score_detail">


                                    <div className="job_score_detail_first">
                                        <DonutChart percentage={job.details.score.totalScore} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                        <p className="job_score_detail_head">
                                            Work Done
                                        </p>
                                    </div>

                                    <div className='role_arrow_showcase_holder'>
                                    {detailDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                    </div>

                                </div>

                                </CollapsibleTrigger>

                                <CollapsibleContent className="job_score_detail_reason">
                                <div className="job_score_detail_body">

                                    <div className="job_score_information">
                                        <p><strong>Details: </strong></p>
                                        <ul>
                                            {job.details.details.map((detail, index) => (
                                                <li key={index} className="candidate_work_history_job_list_item">{detail}</li>
                                            ))}
                                        </ul>
                                    </div>

                                    <p style={{marginBottom:"1rem"}}><strong>Score Justification: </strong></p>

                                    <div className="job_score_breakdown">

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.impact}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.details.score.breakdown.impact)} Impact</p>
                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.innovation}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.details.score.breakdown.innovation)} Innovation</p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.responsibility}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p> {evaluateScore(job.details.score.breakdown.responsibility)} Responsibility</p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.technical_complexity}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.details.score.breakdown.technical_complexity)} Complexity </p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.uniqueness}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.details.score.breakdown.uniqueness)} Uniqueness</p>

                                        </div>


                                    </div>
                                </div>
                                </CollapsibleContent>
                            </Collapsible>

                            <Collapsible
                                open={companyDetailOpen}
                                onOpenChange={setCompanyDetailOpen}
                                className="job_score_detail_collapsible"
                                >

                                <CollapsibleTrigger
                                className="job_score_detail_holder company_detail"
                                >
                                <div className="job_score_detail">


                                    <div className="job_score_detail_first">
                                        <DonutChart percentage={job.company.score.totalScore} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                        <p className="job_score_detail_head">
                                            Company Level
                                        </p>
                                    </div>

                                    <div className='role_arrow_showcase_holder'>
                                    {companyDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                    </div>

                                </div>

                                </CollapsibleTrigger>

                                <CollapsibleContent>
                                <div className="job_score_detail_body">

                                    <div className="job_score_information">
                                    <p> <strong>Company: </strong> {job.company.name}</p>
                                    </div>

                                    <p style={{marginBottom:"1rem"}}><strong>Score Justification: </strong></p>

                                    <div className="job_score_breakdown">

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.company_recognition}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.company.score.breakdown.company_recognition)} Recognition</p>
                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.company_size}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.company.score.breakdown.company_size)} Size</p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.funding}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p> {evaluateScore(job.company.score.breakdown.funding)} Funding</p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.growth}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.company.score.breakdown.growth)} Growth </p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.industry_relevance}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.company.score.breakdown.industry_relevance)} Relevance</p>

                                        </div>


                                    </div>
                                </div>
                                </CollapsibleContent>
                            </Collapsible>



                            <Collapsible
                                open={durationDetailOpem}
                                onOpenChange={setDurationDetailOpen}
                                className="job_score_detail_collapsible"
                                >

                                <CollapsibleTrigger
                                className="job_score_detail_holder"
                                >
                                <div className="job_score_detail">


                                    <div className="job_score_detail_first">
                                        <DonutChart percentage={job.duration.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                        <p className="job_score_detail_head">
                                            Duration
                                        </p>
                                    </div>

                                    <div className='role_arrow_showcase_holder'>
                                    {durationDetailOpem ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                    </div>

                                </div>

                                </CollapsibleTrigger>

                                <CollapsibleContent>
                                <div className="job_score_detail_body">
                                    <div className="job_score_information">
                                        <p><strong>Duration:</strong> {formatDuration(job.duration.duration.duration)}</p>
                                    </div>
                                </div>
                                </CollapsibleContent>
                            </Collapsible>


                            <Collapsible
                                open={levelDetailOpen}
                                onOpenChange={setLevelDetailOpen}
                                className="job_score_detail_collapsible"
                                >

                                <CollapsibleTrigger
                                className="job_score_detail_holder"
                                >
                                <div className="job_score_detail">


                                    <div className="job_score_detail_first">
                                        <DonutChart percentage={job.jobLevel.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                        <p className="job_score_detail_head">
                                            Job Level
                                        </p>
                                    </div>

                                    <div className='role_arrow_showcase_holder'>
                                    {levelDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                    </div>

                                </div>

                                </CollapsibleTrigger>

                                <CollapsibleContent>
                                <div className="job_score_detail_body">
                                    <div className="job_score_information">
                                        <p><strong>Job Level:</strong> {job.jobLevel.level}</p>
                                    </div>

                                    {/* <div className="job_score_breakdown">
                                        <p></p>
                                    </div> */}
                                </div>
                                </CollapsibleContent>
                            </Collapsible>

                        </div>

                    </div>



                    <div className="popup_close" onClick={() => setPopupOpen(false)}> <X /> </div>
                </div>
            </div>
            </Popup>
        )
    }


    const ProjectDisplay = ({project}) => {
        const [popupIsOpen, setPopupOpen] = useState(false);

        return (

            <Popup
                trigger={
                    <div className="candidate_work_history_job">

                        <div className="candidate_work_history_job_top">


                            <div style={{display:"flex", alignItems:"center"}}>

                                <div className="candidate_work_history_job_score">
                                    <DonutChart percentage={project.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                </div>

                                <div className="candidate_work_history_job_info">

                                    <div className="candidate_work_history_job_title">
                                        {project.name}
                                    </div>

                                </div>

                            </div>

                            </div>

                            <div className="candidate_work_history_job_description">
                            <ul>
                                {project.details.details.map((detail, index) => (
                                    <li key={index} className="candidate_work_history_job_list_item">{detail}</li>
                                ))}
                            </ul>
                            </div>
                    </div>
                }
                open={popupIsOpen}
                onOpen={() => setPopupOpen(true)}
                modal
                nested
                position="center center"
                >
                <div className="popup_holder">
                    <div className="popup">

                        <div className="score_popup_heading">
                            <div className="score_popup_score">
                                <DonutChart percentage={project.score} color="darkgreen" multiplier={0.075} fontSize={15}   />
                            </div>
                            <h1 className="popup_heading"> {project.name} </h1>
                        </div>

                        <div className="popup_body">

                            <div className="project_information">
                                <p><strong>Details: </strong></p>
                                <ul>
                                    {project.details.details.map((detail, index) => (
                                        <li key={index} className="candidate_work_history_job_list_item">{detail}</li>
                                    ))}
                                </ul>
                            </div>

                            <div className="project_score_breakdown">
                                <p><strong>Score Justification:</strong></p>
                                <div className="project_score_breakdown_item">
                                    <DonutChart percentage={project.details.scores.impact * 10} color="darkgreen" multiplier={0.05} fontSize={12}  />
                                    <p>{evaluateScore(project.details.scores.impact * 10)} Impact</p>
                                </div>

                                <div className="project_score_breakdown_item">
                                    <DonutChart percentage={project.details.scores.initiative * 10} color="darkgreen" multiplier={0.05} fontSize={12}  />
                                    <p>{evaluateScore(project.details.scores.initiative * 10)} Initiative</p>
                                </div>

                                <div className="project_score_breakdown_item">
                                    <DonutChart percentage={project.details.scores.innovation * 10} color="darkgreen" multiplier={0.05} fontSize={12}  />
                                    <p>{evaluateScore(project.details.scores.innovation * 10)} Innovation</p>
                                </div>

                                <div className="project_score_breakdown_item">
                                    <DonutChart percentage={project.details.scores.technical_complexity * 10} color="darkgreen" multiplier={0.05} fontSize={12}  />
                                    <p>{evaluateScore(project.details.scores.technical_complexity * 10)} Complexity</p>
                                </div>

                            </div>

                        </div>

                        <div className="popup_close" onClick={() => setPopupOpen(false)}> <X /> </div>
                    </div>
                </div>
            </Popup>
        )
    }


    const EducationDisplay = () => {
        const [popupIsOpen, setPopupOpen] = useState(false);


        const [gpaDetailOpen, setGpaDetailOpen] = useState(false);
        const [universityDetailOpen, setUniversityDetailOpen] = useState(false);

        return (

            <Popup
                trigger={
                    <div className="candidate_info_container candidate_education" style={{overflow:"hidden", overflowY:"hidden"}}>

                        <div className="candidate_info_container_top"
                        >
                            <h3>Education</h3>

                            <DonutChart percentage={candidate?.education.score} color="darkgreen" multiplier={0.05} fontSize={12} />

                            {/* scoring here if skillscore is enabled */}

                        </div>


                        <div className="candidate_info_container_info">
                            <div className="candidate_education_level">
                                <FaIcons.FaUserGraduate className="candidate_education_level_icon" />
                                <p className="candidate_education_level_text">{candidate?.education.highestLevel}</p>
                            </div>

                            <div className="candidate_education_info">

                                <div className="candidate_education_major">
                                    <h4 className="candidate_education_major_category_heading">
                                        Major:
                                    </h4>
                                    <p>
                                        {candidate?.education.major}
                                    </p>
                                </div>

                                <div className="candidate_education_university">

                                    <h4 className="candidate_education_major_category_heading">
                                        School:
                                    </h4>
                                    <p>
                                        {candidate?.education.university.name}
                                    </p>
                                </div>

                                <div className="candidate_education_gpa">
                                    <h4 className="candidate_education_major_category_heading">
                                        GPA:
                                    </h4>
                                    <p>
                                        {candidate?.education.gpa.value}
                                    </p>
                                </div>


                            </div>
                        </div>


                    </div>
                }
                open={popupIsOpen}
                onOpen={() => setPopupOpen(true)}
                modal
                nested
                position="center center"
                >
                <div className="popup_holder">
                    <div className="popup">
                        <div className="score_popup_heading">
                            <DonutChart percentage={candidate?.education.score} color="darkgreen" multiplier={0.075} fontSize={15}   />
                            <h1 className="popup_heading"> Education </h1>

                        </div>


                        <div className="popup_close" onClick={() => setPopupOpen(false)}> <X /> </div>


                        <div className="popup_body">
                            <div className="education_information">

                                <p><strong>Details: </strong></p>

                                <div className="education_information_item">
                                    <div className="education_information_item_first">
                                        <p style={{fontWeight:500, margin: 0}}>Highest Education Level: </p>
                                    </div>

                                    {candidate?.education.highestLevel}

                                </div>

                                <div className="education_information_item">
                                    <div className="education_information_item_first">
                                        <p style={{fontWeight:500, margin: 0}}>Field of Study: </p>
                                    </div>

                                    {candidate?.education.major}
                                </div>

                                <div className="education_information_item">
                                    <div className="education_information_item_first">
                                        <p style={{fontWeight:500, margin: 0}}>Time: </p>
                                    </div>

                                    <p style={{margin: 0}}>{candidate?.education.startYear} - {candidate?.education.graduationYear} </p>
                                </div>


                                <div className="education_information_item">
                                    <div className="education_information_item_first">
                                        <p style={{fontWeight:500, margin: 0}}>GPA: </p>
                                    </div>

                                    <p style={{margin: 0}}>{candidate?.education.gpa.value} </p>
                                </div>

                                <div className="education_information_item">
                                    <div className="education_information_item_first">
                                        <p style={{fontWeight:500, margin: 0}}>University: </p>
                                    </div>

                                    <p style={{margin: 0}}>{candidate?.education.university.name} </p>
                                </div>

                            </div>


                            <div className="education_score_breakdown">

                                <p><strong>Score Breakdown: </strong></p>

                                <div className="education_score_collapsibles_holder">


                                    <Collapsible
                                        open={gpaDetailOpen}
                                        onOpenChange={setGpaDetailOpen}
                                        className="job_score_detail_collapsible"
                                        >

                                        <CollapsibleTrigger
                                        className="job_score_detail_holder"
                                        >
                                        <div className="job_score_detail education_score_detail">


                                            <div className="job_score_detail_first">
                                                <DonutChart percentage={candidate?.education.gpa.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                                <p className="job_score_detail_head">
                                                    GPA
                                                </p>
                                            </div>

                                            <div className='role_arrow_showcase_holder'>
                                            {gpaDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                            </div>

                                        </div>

                                        </CollapsibleTrigger>

                                        <CollapsibleContent>
                                        <div className="job_score_detail_body">
                                            <div className="job_score_information">
                                                <p>A GPA of {candidate?.education.gpa.value} receives a score of {candidate?.education.gpa.score}</p>
                                            </div>
                                        </div>
                                        </CollapsibleContent>
                                    </Collapsible>

                                    <Collapsible
                                        open={universityDetailOpen}
                                        onOpenChange={setUniversityDetailOpen}
                                        className="job_score_detail_collapsible"
                                        >

                                        <CollapsibleTrigger
                                        className="job_score_detail_holder"
                                        >
                                        <div className="job_score_detail education_score_detail">


                                            <div className="job_score_detail_first">
                                                <DonutChart percentage={candidate?.education.university.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                                <p className="job_score_detail_head">
                                                    University
                                                </p>
                                            </div>

                                            <div className='role_arrow_showcase_holder'>
                                            {gpaDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                            </div>

                                        </div>

                                        </CollapsibleTrigger>

                                        <CollapsibleContent>
                                        <div className="job_score_detail_body">
                                            <div className="job_score_information">
                                                <p><strong>University: </strong> {candidate?.education.university.name}</p>
                                            </div>

                                            <div>
                                                <p>This university is ranked in the {getUniversityRanking(candidate?.education.university.ranking)} universities,
                                                and gets a score of {candidate?.education.university.score}.</p>
                                            </div>
                                        </div>
                                        </CollapsibleContent>
                                    </Collapsible>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Popup>
        )
    }


    const InterviewDisplay = ({index, interview}) => {
        const [popupIsOpen, setPopupOpen] = useState(false);
        const [currentInterviewIndex, setCurrentInterviewIndex] = useState(0);
            //   const feedbackForm = interviews[interviews.length - 1].feedbackForm;
            //   const interviewSummary = interviews[interviews.length - 1].summary;

        console.log(interview.responses);

        const [interviewData, setInterviewData] = useState(null);
        const [questions, setQuestions] = useState(null);

        useEffect(() => {
            setInterviewData(job?.pipeline[interview.stage]);
            setQuestions(job?.pipeline[interview.stage].feedback?.selectedQuestions);
        }, [job]);

        const interviewers = interviews[0].interviewers;

       


        const [responseIndex, setResponseIndex] = useState(0);

         const handlePrevResponse = () => {
            setResponseIndex((prevIndex) => 
            prevIndex > 0 ? prevIndex - 1 : interview.responses.length - 1
            );
        };

        const handleNextResponse = () => {
            setResponseIndex((prevIndex) => 
            prevIndex < interview.responses.length - 1 ? prevIndex + 1 : 0
            );
        };


        console.log("CHECK THE DATA: ", interviewData);

        return (
            <Popup
                trigger={
                    <div
                        key={index}
                        ref={(el) => (interviewRefs.current[index] = el)}
                        className={`candidate_info_container candidate_carousel_info_container interview_feedback_container ${currInterviewIndex === index ? 'active' : ''}`}
                    >
                        <div className="interview_heading_holder">
                            <h3>{interviewData?.details?.name}</h3>
                        </div>

                        <div className="interview_display_info">
                            <div className="interview_display_item">
                                <p className="interview_display_item_head">Type: </p>
                                <p>{interviewData?.details?.interviewType} Interview</p>
                            </div>

                            <div className="interview_display_item">
                                <p className="interview_display_item_head">Interviewers: </p>
                                <div className="interview_display_item_interviewers">
                                    {
                                        interview?.responses?.map((response, index) => (
                                            <>
                                                <p key={index}>{response.interviewer.name}</p>
                                            </>   
                                        ))
                                    }   
                                </div>
                            </div>
                        </div>

                        {
                            interviewData?.feedback?.enabled && (
                                <div className="interview_click">
                                    <p>View Feedback</p>
                                    <FaIcons.FaExternalLinkAlt />
                                </div>
                        )}
                        
                    </div>
                }
                open={popupIsOpen}
                onOpen={() => setPopupOpen(true)}
                modal
                nested
                position="center center"
            >
                <div className="popup_holder">
                    <div className="popup">
                        <div className="score_popup_heading">
                            <h1 className="popup_heading"> {interviewData?.details?.name} </h1>
                        </div>

                        <div className="popup_body" style={{marginTop:"1.5rem", height: "85%"}}>

                            <div className="interview_feedback_carousel">

                                <FaIcons.FaChevronLeft onClick={handlePrevResponse} className="interview_feedback_nav" />

                                <div className="interview_feedback_direct">

                                    <div className="interview_feedback_direct_interviewer">
                                        <p className="interview_feedback_direct_interviewer_head">
                                            Interviewer #{responseIndex + 1}: 
                                        </p>

                                        <div>
                                            {interview?.responses?.[responseIndex]?.interviewer?.name}
                                        </div>
                                    </div>
                                    
                                    <div className="interview_feedback_direct_holder">

                                        {
                                            questions?.map((question, index) => (

                                                <div className="interview_feedback_question">

                                                    <p className="interview_feedback_question_name">{question.questionText} {question.questionRequired ? '*' : ''} <span className="interview_feedback_question_type">{question.questionType}</span></p>

                                                    {question.questionType === 'Single Line' && (
                                                    <input 
                                                        type="text" 
                                                        placeholder="Enter response" 
                                                        className="question_input" 
                                                        style={{ width: "100%" }} 
                                                        value={interview?.responses?.[responseIndex]?.responses?.[index]}
                                                        readOnly
                                                    />
                                                    )}
                        
                                                    {question.questionType === 'Select' && (
                                                    <>
                                                    <select 
                                                        className="question_select" 
                                                        style={{ width: "70%" }}
                                                        value={interview?.responses?.[responseIndex]?.responses?.[index]}
                                                        disabled
                                                    >
                                                        <option value="">Select Response</option>
                                                        {question.options.map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option}>
                                                            {option}
                                                        </option>
                                                        ))}
                                                    </select>

                        
                                                    </>
                                                    )}
                        
                                                    {question.questionType === 'Range' && (
                                                        <div className="range_slider_preview">
                                                            <div className="range_slider_value">
                                                            <span>{interview?.responses?.[responseIndex]?.responses?.[index]}</span>
                                                            </div>
                                                            <div className="range_slider_slider">
                                                            <p className="range_slider_desc">{question.rangeDescription.min}</p>
                                                            <div className="question_preview_range">
                                                                <p>1</p>
                                                                <div className='number_slider question_preview_slider'>
                                                                <input
                                                                    type="range"
                                                                    min={1}
                                                                    max={4}
                                                                    value={interview?.responses?.[responseIndex]?.responses?.[index]}
                                                                    readOnly
                                                                />
                                                                </div>
                                                                <p>4</p>
                                                            </div>
                                                            <p className="range_slider_desc">{question.rangeDescription.max}</p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {question.questionType === 'Yes/No' && (
                                                        <div className="yes_no_preview">
                                                            <div 
                                                                className={`yes_no_option ${interview?.responses?.[responseIndex]?.responses?.[index] === 'Yes' ? 'selected' : ''}`} 
                                                                style={{marginRight:"1rem"}}
                                                            >
                                                                Yes
                                                            </div>
                                                            <div 
                                                                className={`yes_no_option ${interview?.responses?.[responseIndex]?.responses?.[index] === 'No' ? 'selected' : ''}`} 
                                                            >
                                                                No
                                                            </div>
                                                        </div>
                                                    )}

                                                    {question.questionType === 'Score' && (
                                                    
                                                        <div className="score_preview">
                                                            <div className="score_slider_preview" style={{display:"flex", alignItems:"center"}}>
                                                                <p style={{marginRight:"1rem", width:"10%"}}>{interview?.responses?.[responseIndex]?.responses?.[index]}</p>
                                                                <div className='number_slider question_preview_slider' style={{width:"90%"}}>
                                                                    <input
                                                                        type="range"
                                                                        min={0}
                                                                        max={100}
                                                                        value={interview?.responses?.[responseIndex]?.responses?.[index]}
                                                                        readOnly
                                                                    />
                                                                </div>

                                                                    
                                                            </div>
                                                        </div>
                                                    )}

                                                    {question.questionType === 'Checkbox' && (
                                                        <div className="checkbox_preview">
                                                            <div className="checkbox_preview_options">
                                                                {question.options.map((option, optionIndex) => (
                                                                    <div key={optionIndex} className="checkbox_option" style={{display:"flex", alignItems:"center", marginBottom:"0.5rem"}}>
                                                                        <div
                                                                            className="checkbox"
                                                                            style={{
                                                                                border: "1px solid var(--primary)",
                                                                                width: ".7rem",
                                                                                height: ".7rem",
                                                                                borderRadius: "20%",
                                                                                backgroundColor: interview?.responses?.[responseIndex]?.responses?.[index]?.[optionIndex] === 'Y' ? "var(--primary)" : "transparent",
                                                                                marginRight:"0.5rem"
                                                                            }}
                                                                        />
                                                                        <p style={{margin:"0"}}>{option}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {
                                                        question.questionType === 'Long Answer' && (
                                                            <textarea 
                                                                placeholder="Enter response" 
                                                                className="feedback_long_input" 
                                                                style={{width: "100%"}} 
                                                                value={interview?.responses?.[responseIndex]?.responses?.[index]}
                                                                readOnly
                                                            />
                                                        )
                                                    }
                                                </div>   
                                                
                                            ))

                                        }

                                    </div>
                                    
                                </div>

                                <FaIcons.FaChevronRight onClick={handleNextResponse} className="interview_feedback_nav" />

                            </div>


                            <div className="interview_feedback_indicators">
                                {interviews.map((_, i) => (
                                    <span
                                        key={i}
                                        className={`dot ${currentInterviewIndex === i ? 'active' : ''}`}
                                        onClick={() => currentInterviewIndex(i)}
                                    ></span>
                                ))}
                            </div>

                        </div>

                        <div className="popup_close" onClick={() => setPopupOpen(false)}> <X /> </div>
                    </div>
                </div>
            </Popup>
        )
    }


    // Handling candidate actions

    const [candidateActionsPopupOpen, setCandidateActionsPopupOpen] = useState(false);


    // handling prescreening rejected candidates

    const [viewAnyway, setViewAnyway] = useState(false);


    // handling schedulign itnerview

    const [schedulingInterviewPopup, setSchedulingInterviewPopup] = useState(false);


    // handling candidate navigation

    let navigate = useNavigate();

    const navigateCandidate = (direction) => {
        console.log(globalCandidates);

        // find the current index of the candidate in the globalCandidates array
        const currentIndex = globalCandidates.findIndex(candidate => candidate === candidateId);

        let newIndex;
        if (direction === 'left') {
            newIndex = currentIndex - 1;
            if (newIndex < 0) {
                newIndex = globalCandidates.length - 1;
            } 
        } else {
            newIndex = currentIndex + 1;
            if (newIndex >= globalCandidates.length) {
                newIndex = 0;
            }
        }

        console.log(globalCandidates, newIndex);

        navigate(`../job/${jobId}/candidate/${globalCandidates[newIndex]}`);


        // store globalCandidates in local storage
        localStorage.setItem('globalCandidates', JSON.stringify(globalCandidates));

        // reload the page
        window.location.reload();


    }


    return (


        
        <div className="candidate_total_holder">

            <div className="candidate_navigation left" onClick={() => navigateCandidate('left')}>
                <FaIcons.FaChevronLeft className="candidate_navigation_icon" />
            </div>

            <div className="candidate_holder">

                <div className="candidate_top">

                    <div className='candidateInfo'>

                        <div className="back_button">
                            <Link to={`../job/${jobId}/${lastPage}`} style={{color: "black"}}>
                                <FaIcons.FaChevronLeft/>
                            </Link>
                        </div>

                        {/* Only when SkillScore is enabled */}

                        { (candidate?.score !== undefined) && (
                            <div className="candidate_score">
                                <DonutChart percentage={candidate?.score} color="darkgreen" multiplier={0.075} fontSize={16} />
                            </div>
                        )}
 
                        <h1 className="candidate_name">
                            {candidate?.name}
                        </h1>



                    </div>



                    <div className="candidate_top_end">
                        {(candidate?.stage !== null) && (pipeline.length > 0) && job && (
                            <div className="candidate_info_item" style={{marginRight:"2.5rem"}}>
                                <ShiftCandidateStage candidate={{ ...candidate, id: candidateId }} job={job} employerData={employer} />
                            </div>
                        )} 

                        <Popup trigger={
                            <div className="open_candidate_options">
                                <FaIcons.FaBars />
                                {/* this should have candidate notes, resume, screening interview transcript, and delete candidate */}
                            </div>
                        }

                        open={candidateActionsPopupOpen}
                        onOpen={() => setCandidateActionsPopupOpen(true)}
                        // modal
                        contentStyle={{ padding: '0px', border: 'none' }}
                        nested
                        position="left top"
                        >
                            <div className="candidate_actions_holder">

                            {/* View resume if available */}
                            { (pipeline[candidate?.stage]?.label === "interview") && (!candidate.scheduled) && (
                                <Popup trigger={
                                    <div className="candidate_action">
                                        Schedule Interview
                                    </div>
                                }
                                modal
                                nested
                                position="center center"
                                open={schedulingInterviewPopup}
                                onOpen={() => setSchedulingInterviewPopup(true)}
                                >
                                    <ScheduleInterviewPopup 
                                    candidate={candidate} 
                                    job={job}
                                    employerData={employer} 
                                    shiftedStage={false} 
                                    closePopup={() => setSchedulingInterviewPopup(false)}
                                    closeGlobalPopup={() => setCandidateActionsPopupOpen(false)}
                                    />
                                </Popup>
                            )}

                            {candidate?.fileName && (
                                <CandidateResume candidate={candidate} setActionsPopupOpen={setCandidateActionsPopupOpen} />
                            )}


                            {/* View Candidate Notes */}

                            {(candidate?.notes !== null) && (
                                <CandidateNotes candidate={candidate} candidateId={candidateId} setActionsPopupOpen={setCandidateActionsPopupOpen} />
                            )}

                            {/* View screening interview transcript if availbale */}
                            {candidate?.screening_interview_transcript && (
                                <CandidateScreeningInterviewTranscript candidate={candidate} setActionsPopupOpen={setCandidateActionsPopupOpen} />
                            )}

                            {/* Delete Candidate */}
                            { candidate && (
                                <CandidateDelete candidate={candidate} candidateId={candidateId} setActionsPopupOpen={setCandidateActionsPopupOpen} />
                            )}

                            </div>
                        </Popup>

                    </div>
                   



                </div>
             
                <div className="candidate_second_row">

                   

                    {candidate?.email && candidate?.emailHistory && employer && (
                        <div className="candidate_info_item">
                            <CandidateEmailHistory candidate={candidate}  candidateId={candidateId} emailHistory={candidate?.emailHistory} employer={employer} job={job} page="candidate" />
                        </div>
                    )}

                    {candidate?.phone && employer && (
                        <div className="candidate_info_item">
                            <div 
                                className="candidate_phone candidate_email"
                                onClick={() => {
                                    const originalText = candidate.phone;
                                    navigator.clipboard.writeText(originalText)
                                        .then(() => {
                                            const phoneElement = document.querySelector('.candidate_phone span');
                                            phoneElement.textContent = "Copied!";
                                            setTimeout(() => {
                                                phoneElement.textContent = originalText;
                                            }, 1500);
                                        })
                                        .catch(err => {
                                            console.error('Failed to copy: ', err);
                                        });
                                }}
                                style={{ cursor: 'pointer', height: "100%", width: "100%" }}
                            >
                                <FaIcons.FaPhoneAlt style={{marginRight:"0.5rem"}} />
                                <span style={{display: "inline-block", minWidth: "100px"}}>{candidate?.phone}</span>
                            </div>
                        </div>
                    )}

                    {candidate?.location && employer && (
                        <div className="candidate_info_item">
                            <div 
                                className="candidate_location candidate_email"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <FaIcons.FaMapMarkerAlt style={{marginRight:"0.5rem"}} />
                                <span style={{display: "inline-block", minWidth: "100px"}}>{candidate?.location.label}</span>
                            </div>
                        </div>
                    )}

                   
                </div>

                {candidate && (
                    (candidate?.prescreeningRejection && !viewAnyway)? (
                        <>
                            <p>{(() => {
                                switch (candidate.prescreeningRejection) {
                                    case 'location':
                                        return `${candidate.name} has been rejected because they could not be present at the job location.`;
                                    case 'authorization':
                                        return `${candidate.name} has been rejected because they do not have the required authorization for the job.`;
                                    case 'sponsorship':
                                        return `${candidate.name} has been rejected because they required a sponsorship for the job.`;
                                }
                            })()}</p>

                            <div className="prescreening_rejected_view_anyway" onClick={() => setViewAnyway(true)}>
                                View Anyway
                            </div>
                        </>
                        ) : (candidate?.score !== undefined) ? (

                            <>
                        
                            <div className="candidate_info_display">
        
                                <div className="candidate_info_left">
        
                                    <EducationDisplay />
        
        
                                    <div className="candidate_info_container candidate_work_history">
        
                                        <div className="candidate_info_container_top">
        
                                            <h3>Work Experience</h3>
        
                                            <DonutChart percentage={candidate?.work.score} color="darkgreen" multiplier={0.05} fontSize={12} />
        
                                        </div>
        
        
        
        
                                        <div className="candidate_work_history_choice">
                                            <div
                                                className={`candidate_work_history_choice_selection ${candidateWorkSelectedSection === 'Jobs' ? 'selected' : ''}`}
                                                onClick={() => setCandidateWorkSelectedSection('Jobs')}
                                            >
                                                Jobs
                                            </div>
        
                                            <div
                                                className={`candidate_work_history_choice_selection ${candidateWorkSelectedSection === 'Projects' ? 'selected' : ''}`}
                                                onClick={() => setCandidateWorkSelectedSection('Projects')}
                                            >
                                                Projects
                                            </div>
                                        </div>
        
                                        {candidateWorkSelectedSection === 'Jobs' && (
                                            <div className="candidate_work_history_jobs candidate_work_history_area">
                                                {/* {candidate?.jobs.map((job, index) => ( */}
        
                                                {/* The above code is only when skillscore is not enabled. Same thing for the projects */}
        
                                                {candidate?.work.jobs.map((job, index) => (
        
                                                    <JobDisplay job={job} />
                                                ))}
        
                                                {candidate?.work.jobs.length === 0 && (
                                                    <p style={{opacity:"0.7", marginLeft:"2rem"}}>No jobs to display!</p>
                                                )}
                                            </div>
                                        )}
        
                                        {candidateWorkSelectedSection === 'Projects' && (
                                            <div className="candidate_work_history_projects candidate_work_history_area">
                                                {/* {candidate?.projects.map((project, index) => (
        
                                                Above code only when skillscore is not enabled  */}
                                                {candidate?.work.projects.map((project, index) => (
                                                    <ProjectDisplay project={project} />
                                                ))}
        
                                                {candidate?.work.projects.length === 0 && (
                                                    <p style={{opacity:"0.7", marginLeft:"2rem"}}>No projects to display!</p>
                                                )}
                                            </div>
                                        )}
        
                                    </div>
        
                                </div>
        
                                <div className="candidate_info_right">
        
                                    <div className="candidate_info_container_holder candidate_info_right_top">
        
                                        <div className="candidate_info_container_holder_top">
                                            {/* reason for such weird naming is cus this div will hold many containers, and arrows to switch between them, and an indicator of which one is on */}
                                            <div class="arrow left" onClick={prevSlide}>
                                                <FaIcons.FaChevronLeft />
                                            </div>
        
                                            <div
                                                ref={candidateInfoContainers[0]}
                                                className={`candidate_info_container candidate_carousel_info_container candidate_technical_skills ${currentInfoVisibleIndex === 0 ? 'active' : ''}`}
                                            >
                                                <div className="candidate_info_container_top">
                                                    <h3>Technical Skills</h3>
        
                                                    <DonutChart percentage={candidate?.technical_skills?.score || 0} color="darkgreen" multiplier={0.05} fontSize={12} />
        
                                                    {/* scoring here if skillscore is enabled */}
        
                                                </div>
        
                                                <div className="candidate_skills_display">
        
                                                    {candidate?.technical_skills?.skills.map((skill, index) => (
        
                                                        <TechnicalSkill skill={skill} key={index} />
                                                    ))}
        
                                                    {
                                                        candidate?.technical_skills?.skills.length === 0 && (
                                                            <p>No technical skills selected for this job!</p>
                                                        )
                                                    }
        
                                                </div>
                                            </div>
                                            <div
                                                ref={candidateInfoContainers[1]}
                                                className={`candidate_info_container candidate_carousel_info_container candidate_soft_skills ${currentInfoVisibleIndex === 1 ? 'active' : ''}`}
                                            >
                                                <div className="candidate_info_container_top">
                                                    <h3>Soft Skills</h3>
        
                                                    <DonutChart percentage={candidate?.soft_skills?.score || 0} color="darkgreen" multiplier={0.05} fontSize={12} />
        
                                                    {/* scoring here if skillscore is enabled */}
        
                                                </div>
        
                                                <div className="candidate_skills_display">
        
                                                    {candidate?.soft_skills?.skills.map((skill, index) => (
        
                                                        <SoftSkillShowcase skill={skill} />
        
                                                    ))}
        
                                                    {
                                                      (((job?.pipeline.find(stage => stage.label === "skillscore").softSkills.rating === 0) && (job?.pipeline.find(stage => stage.label === "skillscore").personalityFit.rating === 0)) ? (
                                                        <p>The screening interview was not enabled for this job!</p>
                                                        ) : (
                                                          <>
        
                                                          {
                                                              candidate?.soft_skills?.skills.length === 0 && (
                                                                  <p>No soft skills selected for this job!</p>
                                                              )
                                                          }
        
                                                          {
                                                              !candidate?.soft_skills && (
                                                                  <p>{candidate.name} has not given the screening interview yet!</p>
                                                              )
                                                          }
        
                                                          </>
        
                                                        ))
                                                    }
        
        
        
                                                </div>
                                            </div>
                                            <div
                                                ref={candidateInfoContainers[2]}
                                                className={`candidate_info_container candidate_carousel_info_container candidate_personality_fit ${currentInfoVisibleIndex === 2 ? 'active' : ''}`}
                                            >
                                                <div className="candidate_info_container_top">
                                                    <h3>Personality Fit</h3>
        
                                                    <DonutChart percentage={candidate?.personality_traits?.score || 0} color="darkgreen" multiplier={0.05} fontSize={12} />
        
                                                    {/* scoring here if skillscore is enabled */}
        
                                                </div>
        
                                                <div className="candidate_skills_display">
        
                                                    {candidate?.personality_traits?.traits.map((personality, index) => (
                                                        <PersonalityFitShowcase personality={personality} />
                                                    ))}
        
                                                    {
                                                      (((job?.pipeline.find(stage => stage.label === "skillscore").softSkills.rating === 0) && (job?.pipeline.find(stage => stage.label === "skillscore").personalityFit.rating === 0)) ? (
                                                        <p>The screening interview was not enabled for this job!</p>
                                                        ) : (
                                                          <>
        
                                                          {
                                                              candidate?.personality_traits?.traits.length === 0 && (
                                                                  <p>No personality traits selected for this job!</p>
                                                              )
                                                          }
        
                                                          {
                                                              !candidate?.personality_traits && (
                                                                  <p>{candidate.name} has not given the screening interview yet!</p>
                                                              )
                                                          }
        
                                                          </>
        
                                                        ))
                                                    }
        
        
        
        
                                                </div>
                                            </div>
        
        
                                            <div class="arrow right" onClick={nextSlide}>
                                                <FaIcons.FaChevronRight />
                                            </div>
        
                                        </div>
        
                                        <div className="candidate_info_container_holder_bottom indicator">
                                            {candidateInfoContainers.map((slide, index) => (
                                                <span
                                                    key={index}
                                                    className={`dot ${currentInfoVisibleIndex === index ? 'active' : ''}`}
                                                    onClick={() => setCurrentInfoVisibleIndex(index)}
                                                ></span>
                                            ))}
                                        </div>
        
        
                                    </div>
        
        
                                    <div className="candidate_info_container_holder candidate_info_right_bottom">
        
                                        {/* reason for such weird naming is cus this div will hold many containers, and arrows to switch between them, and an indicator of which one is on */}
        
        
                                        <div className="candidate_info_container_holder_top">
                                            <div className="arrow left" onClick={prevInterview}>
                                                <FaIcons.FaChevronLeft />
                                            </div>
        
                                            {interviews?.length > 0 ? (
                                                interviews.map((interview, index) => (
                                                    <InterviewDisplay key={index} index={index} interview={interview} />
                                                ))
                                            ) : (
                                                <div className="candidate_info_container candidate_carousel_info_container active"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "1.5rem",
                                                        color: "gray"
                                                    }}
                                                >
                                                    No interviews yet!
                                                </div>
                                            )}
        
                                            <div className="arrow right" onClick={nextInterview}>
                                                <FaIcons.FaChevronRight />
                                            </div>
                                        </div>
        
                                        <div className="candidate_info_container_holder_bottom indicator">
                                            {interviews?.map((interview, index) => (
                                                <span
                                                    key={index}
                                                    className={`dot ${currInterviewIndex === index ? 'active' : ''}`}
                                                    onClick={() => showInterview(index)}
                                                ></span>
                                            ))}
                                        </div>
        
                                        {/* there should be one of these for every interview the candidate has given */}
        
        
        
                                    </div>
        
                                </div>
        
                            </div>
                                            
                            </>

                        ) : (
                            <p>Currently processing the candidate, give it a few minutes :)</p>
                        )
                    
                )}
                
            </div>

            <div className="candidate_navigation right" onClick={() => navigateCandidate('right')}>
                <FaIcons.FaChevronRight className="candidate_navigation_icon" />   
            </div>
        </div>
    )
}
