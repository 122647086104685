import React from 'react';
import './styles/slider.css';

function NumberSlider({ value, onChange, max = 100, min = 0 }) {
  return (
    <div className='number_slider'>
      <input
        type="range"
        min="0" // Minimum value
        max={max} // Maximum value
        value={value} // Controlled by the parent component
        onChange={onChange} // Handled by the parent component
      />
    </div>
  );
}

export default NumberSlider;