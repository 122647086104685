import { X } from "lucide-react";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "./styles/skillscore_category.css";
import {
  WeightageSlider,
  getBackgroundColor,
  interpolateColor,
} from "./components";
import * as FaIcons from "react-icons/fa";
import { SoftSkillsInfo } from "./skillscore_info/softSkillInfo";
import { sendNotification } from "../../notification";

export const SoftSkillsCategory = ({
  weightage,
  setWeightage,
  complete,
  setComplete,
  data,
  setData,
  editing,
}) => {
  const [popupIsOpen, setPopupOpen] = useState(false);

  const handleWeightageChange = (event) => {

    if (editing) {
      if (skills.length === 0) {
        sendNotification("Cannot change rating when no skills are added!");
        return;
      }
    }
    
    event.preventDefault();
    event.stopPropagation();
    setWeightage(event.target.value);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const [skills, setSkills] = useState(data.skills || []);
  const [newSkill, setNewSkill] = useState("");

  const handleAddSkill = () => {

    if (editing) {
      sendNotification("Cannot change skills when editing a job!");
      return;
    }

    if (newSkill.trim() !== "") {
      setSkills([...skills, { name: newSkill, rating: 10 }]);
      setNewSkill("");
    }
  };

  const handleRatingChange = (index, rating) => {
    const updatedSkills = [...skills];
    updatedSkills[index].rating = rating;
    setSkills(updatedSkills);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddSkill();
    }
  };

  const handleDeleteSkill = (index) => {

    if (editing) {
      sendNotification("Cannot change skills when editing a job!");
      return;
    }

    const updatedSkills = skills.filter((_, i) => i !== index);
    setSkills(updatedSkills);
  };

  // Handling completeness

  useEffect(() => {
    if (parseInt(weightage) === 0) {
      setComplete(true);
    } else {
      if (skills.length > 0) {
        // if atleast one skill has a rating above 0
        if (skills.some((skill) => skill.rating > 0)) {
          setComplete(true);
        } else {
          setComplete(false);
        }
      } else {
        setComplete(false);
      }
    }
  }, [skills, weightage]);

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      if (dataLoaded) {
        return;
      }    setSkills(data.skills);
      setDataLoaded(true);
    }
  }, [data])

  // handling saving the data

  useEffect(() => {
      const data = {
        rating: parseInt(weightage),
        skills: skills.map(skill => ({name: skill.name, rating: parseInt(skill.rating)}))
      }

    setData(data);
  }, [skills, weightage])

  // handling the example popup

  const [examplePopupIsOpen, setExamplePopupIsOpen] = useState(false);

  return (
    <>
      <div
        className="skillscore_category"
        onClick={openPopup}
        style={{ backgroundColor: getBackgroundColor(weightage) }}
      >
        <p className="skillscore_category_heading">Soft Skills</p>

        {!complete && (
          <div className="node_incomplete">
            !{/* Note that this will actually be an orange circle / symbol.  */}
          </div>
        )}

        <div className="skillscore_category_expand">
          <p style={{ margin: 0, marginRight: "1rem" }}>Click to customize</p>
          <FaIcons.FaExpandAlt />
        </div>

        <div className="weightage_section">
          <div className="weightage_top_row">
            <p>Weightage</p>
            <p>{weightage}</p>
          </div>

          <WeightageSlider
            value={weightage}
            onChange={handleWeightageChange}
            max={10}
          />
        </div>
      </div>

      <Popup
        open={popupIsOpen}
        onOpen={() => setPopupOpen(true)}
        modal
        nested
        position="center center"
      >
        <div className="popup_holder">
          <div className="popup" style={{ width: "50%", height: "50%" }}>
            <h1 className="popup_heading" style={{margin:0, marginBottom:"1rem"}}>Soft Skills</h1>

            <p className="skillscore_category_instructions">Add any soft skills you're looking for one by one!</p>

            <SoftSkillsInfo />

            <div className="skillscore_category_input_holder">
              <div className="skillscore_category_input">
                <input
                  type="text"
                  placeholder="Eg. Problem Solving, Critical Thinking, Communication, etc."
                  value={newSkill}
                  onChange={(e) => setNewSkill(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>

            <div className="skillscore_category_list">
              {skills.map((skill, index) => (
                <div
                  key={index}
                  className="skillscore_category_list_item"
                  style={{ backgroundColor: getBackgroundColor(skill.rating) }}
                >
                  <p className="skillscore_category_list_item_name">
                    {skill.name}
                  </p>

                  <div className="skillscore_category_list_item_weightage_holder">
                    <div className="weightage_top_row">
                      <p>Weightage</p>
                      <p>{skill.rating}</p>
                    </div>

                    <WeightageSlider
                      value={skill.rating}
                      onChange={(e) =>
                        handleRatingChange(index, e.target.value)
                      }
                      max={10}
                    />
                  </div>

                  <div className="delete_icon_holder">
                    <FaIcons.FaTrash
                      className="delete_icon"
                      onClick={() => handleDeleteSkill(index)}
                    />
                  </div>
                </div>
              ))}

              {skills.length === 0 && (
                <p
                  style={{
                    padding: "0rem 1rem",
                    opacity: "0.5",
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  No skills added yet!
                </p>
              )}
            </div>

            <div className="popup_close" onClick={() => setPopupOpen(false)}>
              <X />
            </div>

            
          </div>
        </div>
      </Popup>
    </>
  );
};
