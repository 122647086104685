import { useEffect, useState } from "react";
import "./styles/admin.css"
import { collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { NotFound } from "./NotFound";
import { auth, db } from "../firebase";
import * as FaIcons from "react-icons/fa"

export const ConfirmLinks = () => {
    const [canRun, setCanRun] = useState(false);
    const [god, setGod] = useState(false);
    const [confirmationLinks, setConfirmationLinks] = useState([]);
    const [selectedLinks, setSelectedLinks] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setCanRun(true);
            } else {
                setCanRun(false);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const getLinks = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('No authenticated user found');
                }

                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                    throw new Error('Member not found');
                }

                const memberData = memberSnapshot.docs[0].data();
                if (memberData.god === true) {
                    setGod(true);
                    
                    // Fetch confirmation links
                    const linksRef = collection(db, "confirmation_links");
                    const linksSnapshot = await getDocs(linksRef);
                    const links = linksSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setConfirmationLinks(links);
                }
            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        };

        if (canRun) {
            getLinks();
        }
    }, [canRun]);

    const handleCheckboxChange = (id) => {
        setSelectedLinks(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const newSelectedLinks = {};
        confirmationLinks.forEach(link => {
            newSelectedLinks[link.id] = newSelectAll;
        });
        setSelectedLinks(newSelectedLinks);
    };

    const handleDeleteSelected = async () => {
        const selectedIds = Object.keys(selectedLinks).filter(id => selectedLinks[id]);
        if (selectedIds.length === 0) return;

        try {
            for (let id of selectedIds) {
                const linkRef = doc(db, "confirmation_links", id);
                deleteDoc(linkRef);
            }
            
            setConfirmationLinks(prevLinks => 
                prevLinks.filter(link => !selectedIds.includes(link.id))
            );
            setSelectedLinks({});
            setSelectAll(false);
        } catch (error) {
            console.error("Failed to delete selected links", error);
        }
    };

    return (
        <>
            {god ? (
                <div className="admin_stuff">
                    <h2>Confirmation Links</h2>
                    <div className="controls">
                        <label>
                            <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                            Select All
                        </label>
                        <button onClick={handleDeleteSelected} disabled={!Object.values(selectedLinks).some(v => v)}>
                            <FaIcons.FaTrash /> Delete Selected
                        </button>
                    </div>
                    <table className="links-table">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {confirmationLinks.map((link) => (
                                <tr key={link.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedLinks[link.id] || false}
                                            onChange={() => handleCheckboxChange(link.id)}
                                        />
                                    </td>
                                    <td>
                                        <a href={link.link} target="_blank" rel="noopener noreferrer">
                                            {link.link}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <NotFound />
            )}
        </>
    )
}