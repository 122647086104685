import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "../styles/job_settings.css"
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import * as FaIcons from "react-icons/fa"
import Popup from 'reactjs-popup';

export var currentJob = null;
export const changeJob = (newJob) => {
    currentJob = newJob;
}


export const JobSettings = () => {

    const [stages, setStages] = useState([]);
    const [newStage, setNewStage] = useState('');
    const jobId = window.location.href.split('/')[4];
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const docRef = doc(db, 'jobs', jobId); // Create a reference to the document
                const docSnap = await getDoc(docRef); // Get the document

                if (docSnap.exists()) {
                    setStages(docSnap.data().pipeline); // Set the state with the data from Firestore
                } else {
                }
            } catch (err) {
                console.error('Error: ' + err.message);
            }
        };

        fetchJob();
  }, [jobId]); 

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // // Dropped outside the list or in restricted areas
    if (!destination || destination.index < 1 ) {
      return;
    }

    const newStages = Array.from(stages);
    const [removed] = newStages.splice(source.index, 1);
    newStages.splice(destination.index, 0, removed);
    setStages(newStages);
  };

  const addStage = () => {

    // const new_stage_object = {
    //   name: newStage,
    //   type: "lol"
    // }
    // if (new_stage_object && !stages.includes(new_stage_object)) {
    //   const newStages = [...stages];
    //   newStages.splice(stages.length - 1, 0, new_stage_object);
    //   setStages(newStages);
    //   setNewStage('');
    // }

    setPopupOpen(true);
    
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  }

  const [popupIsOpen, setPopupOpen] = useState(false);

    return (
      <>
        <div className="job_settings_holder">
          <h3>Pipeline:</h3>

          <Popup trigger={

            <div className='job_settings_add_stage_button'>
              <FaIcons.FaPlus />
              Add Stage
            </div>

            }

            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            >
            <div className="add_new_stage_area">

                <div className='add_new_stage_popup'>

                  <div className='stage_popup_top_area'>
                    <div className='stage_popup_heading'>
                          <h3>Create a New Stage</h3>
                      </div>

                      <div className='stage_popup_input'>
                        <p>What's the stage name?</p>
                        <input type="text" id="stage_name" placeholder="Stage Name here..." className="stage_input_info" />

                        <p>Pick the type of stage this is...</p>
                        <select className='job_pipeline_stage_type'>
                          <option value="" selected disabled>Select</option>
                          <option value="interview">Interview</option>
                          <option value="technical_interview">Technical Interview</option>
                          <option value="take_home">Take Home Assignment</option>
                          <option value="other">Other</option>
                          
                        </select>

               
                      </div>
                  </div>
                    
                   
                  
                  <div className='actions_bottom'>
                      <div className='close_popup' onClick={() => setPopupOpen(false)}>Close</div>

                      <div className='create_member'>
                          Add
                      </div>
                  </div>

                </div>

              </div>

            </Popup>


          <DragDropContext onDragEnd={onDragEnd}>
            <div className="job_settings_pipeline_container">
              {/* Render the first stage outside of Droppable context */}
              <div className="job_settings_pipeline_stage">
                <div className='job_settings_pipeline_reorder_icon reorder_icon_disabled'>
                    <FaIcons.FaGripVertical />
                </div>
                <div className="pipeline_stage_left" style={{display: "flex", alignItems: "center"}}>
                  <h4>{stages[0]?.name}</h4>
                </div>
                <div className="sub-stage"> 
                            
                  [Type] 
                  <div className='job_settings_pipeline_info_icon'>
                    <FaIcons.FaPen />
                  </div>
                </div>
              </div>

              {/* Droppable starts from the second element and ends before the last element */}
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={{width:"100%"}}>
                    {stages.slice(1, -1).map((stage, index) => (
                      <Draggable key={stage.name} draggableId={stage.name} index={index + 1}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="job_settings_pipeline_stage"
                          >
                            <div className='job_settings_pipeline_reorder_icon'>
                                <FaIcons.FaGripVertical />
                            </div>
                            <div className="pipeline_stage_left" style={{display: "flex", alignItems: "center"}}>
                              <h4>{stage?.name}</h4>
                            </div>
                            
                            <div className="sub-stage"> 
                            
                              [Type] 
                              <div className='job_settings_pipeline_info_icon'>
                                <FaIcons.FaPen />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {/* Render the last stage outside of Droppable context */}
              <div className="job_settings_pipeline_stage">
                <div className='job_settings_pipeline_reorder_icon reorder_icon_disabled'>
                    <FaIcons.FaGripVertical />
                </div>
                <div className="pipeline_stage_left" style={{display: "flex", alignItems: "center"}}>
                  <h4>{stages[stages.length - 1]?.name}</h4>
                </div>
                
                <div className="sub-stage"> 
                            
                  [Type] 
                  <div className='job_settings_pipeline_info_icon'>
                    <FaIcons.FaPen />
                  </div>
                </div>
              </div>
            </div>
          </DragDropContext>

        </div>


        
      </>
    )
}