import { useEffect, useState } from 'react';
import * as FaIcons from "react-icons/fa"
import { HiArrowUpRight } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import './styles/alerts.css';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';


export const Alerts = ({isOpen, toggleContainer, alerts, setAlerts, member}) => {

    const navigate = useNavigate();
    
    useEffect(() => {
        if (member) {
            setAlerts(member.alerts);
        }
    }, [member]);

    const getTimeSince = (timestamp) => {
        const now = new Date();
        const taskTime = new Date(timestamp * 1000);
        const diffInMinutes = Math.floor((now - taskTime) / (1000 * 60));
        
        if (diffInMinutes < 60) {
            return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
        } else if (diffInMinutes < 1440) { // Less than 24 hours
            const diffInHours = Math.floor(diffInMinutes / 60);
            return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
        } else {
            const diffInDays = Math.floor(diffInMinutes / 1440);
            return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
        }
    }

    const handleAlertClick = (alert, index) => {
        if (alert.type === 'cancelled') {
            navigate(`/job/${alert.job.id}/candidate/${alert.candidate.id}`);
        }

        if (alert.type === 'rescheduled') {
            if (alert.schedulingType === "direct") {
                navigate(`/calendar`);
            } else {
                navigate("/schedule/" + alert.task_id);
            }
        }

        toggleContainer();

        // delete the alert
        const newAlerts = alerts.filter((_, i) => i !== index);
        setAlerts(newAlerts);

        // remove this alert from the member
        const memberRef = doc(db, 'members', member.id);
        updateDoc(memberRef, {alerts: newAlerts});
    }


	return (
		<div className={`tasks_container ${isOpen ? "open" : ""}`}>
			<div className="tasks_heading" onClick={toggleContainer}>
				<p className="tasks_heading_text">Alerts</p>
				{isOpen && 
					<FaIcons.FaChevronRight className="tasks_heading_icon" />
				}
			</div>  

			<div className="tasks_body">
				{alerts?.length > 0 ? (     
					<>          
						<div className="tasks_tab_content">
                            {alerts?.map((alert, index) => (

                                <>

                               { alert.type === 'cancelled' && (
                                    <div key={index} className="alert_item" onClick={() => handleAlertClick(alert, index)}>
                                        <div className="alert_item_info">
                                            <p style={{marginBottom: '0.5rem'}}>
                                                <strong>Cancelled</strong>: {alert.name} | {alert.candidate?.name}
                                            </p>
                                            <div className='alert_item_info_date'>
                                                <FaIcons.FaRegClock />
                                                <p>{getTimeSince(alert?.time?.seconds)}</p>
                                            </div>
                                        </div>
                                        <div className="task_item_actions">
                                            <strong><HiArrowUpRight className='task_item_actions_icon' /></strong>
                                        </div>
                                    </div>
                                )}


                                {alert.type === "rescheduled" && (
                                    
                                    <div key={index} className="alert_item" onClick={() => handleAlertClick(alert, index)}>
                                            <div className="alert_item_info"
                                                style={{
                                                    width: '100%',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                <p style={{
                                                    marginBottom: '0.5rem',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    <strong>{alert.schedulingType === "direct" ? "Rescheduled" : "Reschedule"}</strong>: {alert.name} | {alert.candidate?.name}
                                                </p>
                                                <div className='alert_item_info_date'>
                                                    <FaIcons.FaRegClock />
                                                    <p>{getTimeSince(alert?.time?.seconds)}</p>
                                                </div>
                                            </div>
                                            <div className="task_item_actions">
                                                <strong><HiArrowUpRight className='task_item_actions_icon' /></strong>
                                            </div>
                                        </div>


                                    
                                )}

                                </>
                            ))}
						</div>
					</>
				) : (
					<p className="no_tasks">No alerts yet!</p>
				)}
			</div>
		</div>  
	)
}