import {useState, useRef, useEffect} from "react"


export const DonutChart = ({ percentage, color, multiplier = 0.01, fontSize = 16 }) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          width: window.innerWidth * multiplier, // Adjust the factor as needed
          height: window.innerHeight * multiplier, // Adjust the factor as needed
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial dimensions
      handleResize();
  
      // Cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const radius = Math.min(dimensions.width, dimensions.height) * 0.4;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (circumference * percentage) / 100;
  
    return (
      <svg width={dimensions.width} height={dimensions.height}>
        {/* Draw the background circle */}
        <circle cx={dimensions.width / 2} cy={dimensions.height / 2} r={radius} fill="none" stroke="#f3f3f3" strokeWidth={radius * 0.1} />
  
        {/* Draw the filled arc representing the percentage */}
        <circle
          cx={dimensions.width / 2}
          cy={dimensions.height / 2}
          r={radius}
          fill="none"
          stroke={"var(--primary)"}
          strokeWidth={radius * 0.3}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          transform={`rotate(-90 ${dimensions.width / 2} ${dimensions.height / 2})`}
        />
        <text x={dimensions.width / 2} y= {(dimensions.height / 2) + 5} textAnchor="middle" fontSize={fontSize} fill="#000" fontWeight={400}>
            {percentage}
        </text>

      </svg>
    );
  };