import React from 'react';
import { Pie } from 'react-chartjs-2';
import { brandColors, chartOptions } from '../../pages/job_subPages/job_dashboard';

export const JobsFilledChart = ({ jobs }) => {
  const filled = jobs.filter(job => job.filled).length;
  const unfilled = jobs.length - filled;

  const options = {
    ...chartOptions,
    scales: {},
    plugins: {
      legend: {
        // display: false,
        position: "bottom", // This hides the legend
        align: "start",
        fullSize: true,
        align: "center", // Aligns the legend entries in the center
        labels: {
          boxWidth: 15, // Adjusts the size of the color box
          padding: 15 // Adds padding between legend items
        }

      }
    },
  };

  const data = {
    labels: ['Filled', 'Unfilled'],
    datasets: [{
      data: [filled, unfilled],
      backgroundColor: [brandColors.primary40, brandColors.secondary],
      borderColor: [brandColors.primary, brandColors.primary],
      borderWidth: 1,
    }]
  };

  return (
    <div className='company_report_chart_holder jobs_filled_chart'>
      <p className='company_report_chart_heading'>Jobs Filled </p>
      <div className='company_report_chart'>
        <Pie data={data} options={options} />
      </div>
      
    </div>
  );
};
