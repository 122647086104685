import { Handle } from "reactflow";

import "./styles/node.css";
import "./styles/skillscoreNode.css";
import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import ToggleButton from "../toggleButton";
import * as FaIcons from "react-icons/fa";
import StarRating from "../starRating";
import { Tooltip } from "react-tooltip";
import { updateNodeData } from "../../pages/createJob";
import { EducationCategory } from "./skillscore_categories/education";
import { WorkCategory } from "./skillscore_categories/workHistory";
import { TechnicalSkillsCategory } from "./skillscore_categories/technicalSkills";
import { SoftSkillsCategory } from "./skillscore_categories/softSkills";
import { PersonalityTraitsCategory } from "./skillscore_categories/personalityTraits";
import { X } from "lucide-react";
import { SkillScoreInfo } from "./skillscore_categories/skillscore_info/skillscoreInfo";

export const SkillScoreNode = ({ data }) => {
  const [complete, setComplete] = useState(false); //Marks whether the user still has to fill out something or not.

  const [popupIsOpen, setPopupOpen] = useState(false);

  // handling the ratings
  const [educationHistoryRating, setEducationHistoryRating] = useState(10);
  const [workHistoryRating, setWorkHistoryRating] = useState(10);
  const [technicalSkillsRating, setTechnicalSkillsRating] = useState(10);
  const [softSkillsRating, setSoftSkillsRating] = useState(10);
  const [personalityFitRating, setPersonalityFitRating] = useState(10);

  // handling the completion state of the categories

  const [educationHistoryComplete, setEducationHistoryComplete] =
    useState(true);
  const [workHistoryComplete, setWorkHistoryComplete] = useState(true);
  const [technicalSkillsComplete, setTechnicalSkillsComplete] = useState(false);
  const [softSkillsComplete, setSoftSkillsComplete] = useState(false);
  const [personalityFitComplete, setPersonalityFitComplete] = useState(false);

  useEffect(() => {
    // if all are complete, set complete to true
    if (
      educationHistoryComplete &&
      workHistoryComplete &&
      technicalSkillsComplete &&
      softSkillsComplete &&
      personalityFitComplete
    ) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  }, [
    educationHistoryComplete,
    workHistoryComplete,
    technicalSkillsComplete,
    softSkillsComplete,
    personalityFitComplete,
  ]);

  const [screeningInterviewEnabled, setScreeningInterviewEnabled] =
    useState(true);

  useEffect(() => {
    if (!screeningInterviewEnabled) {
      setPersonalityFitRating(0);
      setSoftSkillsRating(0);
    }
  }, [screeningInterviewEnabled]);

  useEffect(() => {
    if (softSkillsRating > 0 || personalityFitRating > 0) {
      setScreeningInterviewEnabled(true);
    }
  }, [softSkillsRating, personalityFitRating]);


  // NOW HANDLING THE SUBCATEGORY DATA FOR EACH
  // not going to list it all, just the container object and then make each file return the object

  const [educationHistoryData, setEducationHistoryData] = useState({})
  const [workHistoryData, setWorkHistoryData] = useState({})
  const [technicalSkillsData, setTechnicalSkillsData] = useState({})
  const [softSkillsData, setSoftSkillsData] = useState({})
  const [personalityFitData, setPersonalityFitData] = useState({})

  useEffect(() => {

    const nodeData = {
        complete: complete,
        educationHistory: educationHistoryData,
        workHistory: workHistoryData,
        technicalSkills: technicalSkillsData,
        softSkills: softSkillsData,
        personalityFit: personalityFitData,
    }
    //
    updateNodeData(data.index, nodeData);

  }, [educationHistoryData, workHistoryData, technicalSkillsData, softSkillsData, personalityFitData, complete])

  return (
    <Popup
      trigger={
        <div className="pipeline_node_display">
          
          <div className="pipeline_node_actual_display">
              <p> SkillScore </p>
              <FaIcons.FaExpandAlt />
          </div>
          <Handle
            type="target"
            position="left"
            id="5"
            style={{ pointerEvents: "none" }}
          />
          {/* <Handle type="target" position="top" id="6"  style={{pointerEvents:"none"}}/> */}
          <Handle
            type="source"
            position="right"
            id="4"
            style={{ pointerEvents: "none" }}
          />
          {/* <Handle type="source" position="bottom" id="3"  style={{pointerEvents:"none"}}/> */}
          {!complete && (
            <div className="node_incomplete">
              !
              {/* Note that this will actually be an orange circle / symbol.  */}
            </div>
          )}
        </div>
      }
      open={popupIsOpen}
      onOpen={() => setPopupOpen(true)}
      modal
      nested
      position="center center"
    >
      <div className="create_stage_holder">
        <div className="create_stage">
          <div className="top_section" style={{ height: "85%" }}>
            <h1 className="create_stage_heading"> SkillScore </h1>

            <SkillScoreInfo />

            <div className="popup_close" onClick={(e) => setPopupOpen(false)}>
              <X />
            </div>

            

            <div className="skillscore_heading">
              <div className="skillscore_info_column">
                <p className="skillscore_heading_text">Measured Using</p>
              </div>

              <div className="skillscore_content_column">
                <p className="skillscore_heading_text">Measuring</p>
              </div>
            </div>

            <div className="skillscore_row">
              <div className="skillscore_info_column">
                <p className="skillscore_row_text">Resume Screening</p>
              </div>

              <div className="skillscore_content_column skillscore_row_content">
                <EducationCategory
                  weightage={educationHistoryRating}
                  setWeightage={setEducationHistoryRating}
                  complete={educationHistoryComplete}
                  setComplete={setEducationHistoryComplete}
                  data={educationHistoryData}
                  setData={setEducationHistoryData}
                />

                <WorkCategory
                  weightage={workHistoryRating}
                  setWeightage={setWorkHistoryRating}
                  complete={workHistoryComplete}
                  setComplete={setWorkHistoryComplete}
                  data={workHistoryData}
                  setData={setWorkHistoryData}
                />

                <TechnicalSkillsCategory
                  weightage={technicalSkillsRating}
                  setWeightage={setTechnicalSkillsRating}
                  complete={technicalSkillsComplete}
                  setComplete={setTechnicalSkillsComplete}
                  data={technicalSkillsData}
                  setData={setTechnicalSkillsData}
                />
              </div>
            </div>

            <div className="skillscore_row">
              <div className="skillscore_info_column">
                <ToggleButton
                  isOn={screeningInterviewEnabled}
                  setIsOn={setScreeningInterviewEnabled}
                />
                <p className="skillscore_row_text">Screening Interview</p>
              </div>

              <div className="skillscore_content_column skillscore_row_content">
                <SoftSkillsCategory
                  weightage={softSkillsRating}
                  setWeightage={setSoftSkillsRating}
                  complete={softSkillsComplete}
                  setComplete={setSoftSkillsComplete}
                  data={softSkillsData}
                  setData={setSoftSkillsData}
                />

                <PersonalityTraitsCategory
                  weightage={personalityFitRating}
                  setWeightage={setPersonalityFitRating}
                  complete={personalityFitComplete}
                  setComplete={setPersonalityFitComplete}
                  data={personalityFitData}
                  setData={setPersonalityFitData}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </Popup>
  );
};
