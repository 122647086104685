import { useNavigate } from "react-router-dom";
import { candidatesList, curr_index } from "./job_subPages/pipeline";
import { useEffect } from "react";

export const CandidateSwitch = () => {
    // var candidateId = window.location.href.split('/')[6]

    let navigate = useNavigate();
    const jobId = window.location.pathname.split('/')[2];
    const newPath = `/job/${jobId}/candidate/${candidatesList[curr_index]}`;

    useEffect(() => {
        //The most stupid and ingenious workaround i've had
        navigate(newPath);
    })
    
}