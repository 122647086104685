import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
   useNodesState,
  useEdgesState,
  Controls,
  Handle,
  MiniMap,
} from 'reactflow';
import 'reactflow/dist/style.css';
import "./styles/pipelineEditor.css";
import { useViewport } from 'reactflow';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import { AppliedNode } from './pipeline_nodes/appliedNode';
import { PreScreening, PreScreeningNode } from './pipeline_nodes/prescreeningNode';
import { SkillScoreNode } from './pipeline_nodes/skillscoreNode';
import { TechnicalInterviewNode } from './pipeline_nodes/technicalInterview';
import { InterviewNode } from './pipeline_nodes/interviewingNode';
import { HiredNode } from './pipeline_nodes/hiredNode';
import { CustomNode } from './pipeline_nodes/customNode';
import { MadeOfferNode } from './pipeline_nodes/madeOfferNode';
import { deleteNode, nodesOrder, pipeline, updateNodeData, updateNodesOrder, updatePipeline } from '../pages/createJob';
import { sendNotification } from './notification';


export var globalNodes = null;
export var globalEdges = null;


const getDistance = (point, lineStart, lineEnd) => {


    // TODO: The edges can sometimes be curved. Identify how to model that.
    const A = point.x - lineStart.x;
    const B = point.y - lineStart.y;
    const C = lineEnd.x - lineStart.x;
    const D = lineEnd.y - lineStart.y;
  
    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    const param = len_sq !== 0 ? dot / len_sq : -1;


  
    let xx, yy;
  
    if (param < 0) {
      xx = lineStart.x;
      yy = lineStart.y;
    } else if (param > 1) {
      xx = lineEnd.x;
      yy = lineEnd.y;
    } else {
      xx = lineStart.x + param * C;
      yy = lineStart.y + param * D;
    }
  
    const dx = point.x - xx;
    const dy = point.y - yy;
    return Math.sqrt(dx * dx + dy * dy);
};


const getMidpoint = (point1, point2) => ({
    x: (point1.x + point2.x) / 2,
    y: (point1.y + point2.y) / 2,
});


const nodeTypes = {
    applied: AppliedNode,
    prescreening: PreScreeningNode,
    skillscore: SkillScoreNode,
    technical_interviwe: TechnicalInterviewNode,
    interview: InterviewNode,
    made_offer: MadeOfferNode,
    hired: HiredNode,
    custom: CustomNode
};


let id = 0;
const getId = () => `dndnode_${id++}`;

var currentDraggedType = null;


export const PipelineSidebar = () => {
    const { x, y, zoom } = useViewport();


 

    const onDragStart = (event, nodeType) => {
      event.dataTransfer.setData('application/reactflow', nodeType);
      currentDraggedType = nodeType;
      event.dataTransfer.effectAllowed = 'move';

    };
  
    return (
      <aside>

        {/* <p>{x}, {y}, {zoom}</p> */}
        {/* <div className="description">You can drag these nodes to the pane on the right.</div> */}
        <h3 style={{marginBottom:"1rem"}}> Drag & Drop Stages</h3>
        {/* <div className="pipeline_node input" onDragStart={(event) => onDragStart(event, 'input')} draggable>
          Input Node
        </div> */}

        <div className='pipeline_editor_stages_section'>
            <p className='pipeline_editor_stages_section_head'>Pre-Screening</p>

            <div className="pipeline_node" onDragStart={(event) => onDragStart(event, 'prescreening')} draggable>
                Pre-Screening
            </div>
        </div>

        {/* <div className='pipeline_editor_stages_section'>
            <p className='pipeline_editor_stages_section_head'>Screening</p>

            <div className="pipeline_node" onDragStart={(event) => onDragStart(event, 'skillscore')} draggable>
                SkillScore
            </div>
        </div> */}

        <div className='pipeline_editor_stages_section'>
            <p className='pipeline_editor_stages_section_head'>Interviewing</p>

            <div className="pipeline_node" onDragStart={(event) => onDragStart(event, 'interview')} draggable>
                Interviewing
            </div>
        </div>

        {/* <div className='pipeline_editor_stages_section'>
            <p className='pipeline_editor_stages_section_head'>Made Offer</p>

            <div className="pipeline_node" onDragStart={(event) => onDragStart(event, 'made_offer')} draggable>
                Made Offer
            </div>
        </div> */}
        

      </aside>

      
    );
};
  

export const PipelineEditor = () => {
  

    const remToPixels = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);


    const initialNodes = [
        {
            id: '1',
            type: 'applied',
            data: { label: 'applied node' },
            position: { x: remToPixels(50), y: remToPixels(-15) },
        },
        {
          id: '2',
          type: 'skillscore',
          data: { 
            label: 'skillscore node',
            index: 1,
        },
          position: { x: remToPixels(90), y: remToPixels(5) },
        },
        {
            id: '3',
            type: 'hired',
            data: { label: 'hired node' },
            position: { x: remToPixels(120), y: remToPixels(25) },
        },
    ];

    const initialEdges = [
        {
            id: 'e1',
            source: '1',
            target: '2',
            animated: true,
        },
        {
          id: 'e2',
          source: '2',
          target: '3',
          animated: true,
        },
    ];

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [nearestEdge, setNearestEdge] = useState(null);


  useEffect(() => {
    globalNodes = nodes;
    globalEdges = edges;
  }, [nodes, edges])

  useEffect(() => {
        const order = [];
        const nodeIndexMap = nodes.reduce((acc, node, index) => {
            acc[node.id] = index;
            return acc;
        }, {});

        let currentNodeId = "1";

        while (currentNodeId && currentNodeId !== '3') { // '2' is the "hired" node
            const currentIndex = nodeIndexMap[currentNodeId];
            order.push(currentIndex);

            const nextEdge = edges.find(edge => edge.source === currentNodeId);
            if (nextEdge) {
            currentNodeId = nextEdge.target;
            } else {
            break;
            }
        }

        const hiredIndex = nodeIndexMap['3'];
        if (hiredIndex !== undefined) {
            order.push(hiredIndex); // Add the "hired" node at the end
        }

        updateNodesOrder(order);
  }, [nodes])


  const onNodeDrag = (event, node) => {
    setCurrentNode({ ...node });
  };


  const PROXIMITY_THRESHOLD = 500;

  const deleteThisNode = (id, pipelineIndex) => {
    
    const node = globalNodes.find(n => n.id === id);

    let prevNode, nextNode;
    let edgeTo, edgeFrom;

    globalEdges.forEach(edge => {

        if (edge.source === node.id) {
            edgeFrom = edge;
            nextNode = edge.target;
        } else if (edge.target === node.id) {
            edgeTo = edge;
            prevNode = edge.source;
        }
    });

    const newEdge = { id: `e-${prevNode}-${nextNode}`, source: prevNode, target: nextNode, animated: true };

    setEdges(eds => [
        ...eds.
        filter(edge => edge !== edgeFrom).
        filter(edge => edge !== edgeTo)
        ,
        newEdge,
    ]);

    setNodes(nds => nds.filter(n => n !== node));
    deleteNode(pipelineIndex);

  }  

  const onDrop = useCallback((event) => {
    event.preventDefault();
    const reactFlowBounds = event.target.getBoundingClientRect();
    var type = event.dataTransfer.getData('application/reactflow-type');

    if (type === "") {
        type = currentDraggedType;
    }

    
    const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
    });

    

    const nodeId = getId();
    const newNode = {
      id: nodeId,
      type,
      position,
      data: { 
        id: nodeId,
        index: nodes.length,
        pipelineIndex: pipeline.length,
        label: `${type} node`,
        updateNodeData: updateNodeData,
        deleteNode: deleteThisNode,
    },
    };
    const newNodes = nodes.concat(newNode);

    


    let nearestEdge = null;
    let minDistance = Infinity;

    let prevNode = null;
    let nextNode = null;
      
    edges.forEach(edge => {
        const sourceNode = newNodes.find(n => n.id === edge.source);
        const targetNode = newNodes.find(n => n.id === edge.target);
        if (sourceNode && targetNode) {
          const midpoint = getMidpoint(sourceNode.position, targetNode.position);
          const distance = Math.sqrt(
            Math.pow(midpoint.x - newNode.position.x, 2) +
            Math.pow(midpoint.y - newNode.position.y, 2)
          );

          if (distance < minDistance && distance <= PROXIMITY_THRESHOLD) {
            minDistance = distance;
            nearestEdge = edge;
            prevNode = sourceNode;
            nextNode = targetNode;
          }
        }
    });

    switch (type) {
      case "prescreening":
        if (!((prevNode.type === "applied") && (nextNode.type === "skillscore"))) {
          sendNotification("Pre-Screening can only be placed after Applied!")
          return;
        }
        break;
      case "interview":
        if (!((prevNode.type === "interview") || (prevNode.type === "skillscore"))) {
          sendNotification("A manual interview should take place after SkillScore screening!")
          return;
        } 
        break;
      case "made_offer":
        if (!(nextNode.type === "hired")) {
          sendNotification("An offer can only be made before hiring!")
          return;
        } else if (prevNode.type === "made_offer") {
          sendNotification("You can only make an offer once!")
          return;
        }
        break;
    }

    pipeline.push({ label: type })
    // updatePipeline((pipeline) => [...prevPipeline, { label: type }]);
    // setNodes(newNodes);pro
    setCurrentNode(newNode);  // Set the newly created node as current

    if (nearestEdge && !edges.some(edge => edge.source === newNode.id || edge.target === newNode.id)) {
        const pos = reactFlowInstance.screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
        });
        const sourceNode = newNodes.find(n => n.id === nearestEdge.source);
        const targetNode = newNodes.find(n => n.id === nearestEdge.target);
        
        // Update the current node's position to the midpoint
        const updatedNodes = newNodes.map(node => {
            if (node.id === newNode.id) {
            return { ...node, position: pos };
            }
            return node;
        });
        setNodes(updatedNodes);

        // Create two new edges
        const newEdge1 = { id: `e-${sourceNode.id}-${newNode.id}`, source: sourceNode.id, target: newNode.id, animated: true };
        const newEdge2 = { id: `e-${newNode.id}-${targetNode.id}`, source: newNode.id, target: targetNode.id, animated: true };

        setEdges(eds => [
            ...eds.filter(edge => edge.id !== nearestEdge.id),
            newEdge1,
            newEdge2
        ]);

    } 

  }, [reactFlowInstance, nodes, edges, setNodes, setEdges]);

  const onNodeDragStop = useCallback((event) => {


    if (currentNode) {
      let nearestEdge = null;
      let minDistance = Infinity;
      
      edges.forEach(edge => {
        const sourceNode = nodes.find(n => n.id === edge.source);
        const targetNode = nodes.find(n => n.id === edge.target);
        if (sourceNode && targetNode) {
          const midpoint = getMidpoint(sourceNode.position, targetNode.position);
          const distance = Math.sqrt(
            Math.pow(midpoint.x - currentNode.position.x, 2) +
            Math.pow(midpoint.y - currentNode.position.y, 2)
          );

          if (distance < minDistance && distance <= PROXIMITY_THRESHOLD) {
            minDistance = distance;
            nearestEdge = edge;
          }
        }
      });

      if (nearestEdge && !edges.some(edge => edge.source === currentNode.id || edge.target === currentNode.id)) {
        const sourceNode = nodes.find(n => n.id === nearestEdge.source);
        const targetNode = nodes.find(n => n.id === nearestEdge.target);
        const midpoint = getMidpoint(sourceNode.position, targetNode.position);
        
        // Update the current node's position to the midpoint
        const updatedNodes = nodes.map(node => {
          if (node.id === currentNode.id) {
            return { ...node, position: midpoint };
          }
          return node;
        });
        setNodes(updatedNodes);

        // Create two new edges
        const newEdge1 = { id: `e-${sourceNode.id}-${currentNode.id}`, source: sourceNode.id, target: currentNode.id, animated: true };
        const newEdge2 = { id: `e-${currentNode.id}-${targetNode.id}`, source: currentNode.id, target: targetNode.id, animated: true };

        setEdges(eds => [
          ...eds.filter(edge => edge.id !== nearestEdge.id),
          newEdge1,
          newEdge2
        ]);
      }
    }

    setCurrentNode(null);  // Reset current node after processing
  }, [currentNode, nodes, edges, setNodes, setEdges]);

  useEffect(() => {
    if (currentNode) {
      let minDistance = Infinity;
      let closestEdge = null;

      edges.forEach(edge => {
        const sourceNode = nodes.find(n => n.id === edge.source);
        const targetNode = nodes.find(n => n.id === edge.target);
        if (sourceNode && targetNode) {
          const distance = getDistance(
            { x: currentNode.position.x, y: currentNode.position.y },
            { x: sourceNode.position.x, y: sourceNode.position.y },
            { x: targetNode.position.x, y: targetNode.position.y }
          );

          if (distance < minDistance) {

            minDistance = distance;
            closestEdge = edge;
          }
        }
      });

      setNearestEdge(closestEdge);
    }
  }, [currentNode, nodes, edges]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [],
  );

  

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onEdgeMouseEnter = useCallback((event, edge) => {
   }, []);


  

  const nodeExtent = [
    [remToPixels(60), remToPixels(-15)], // Top-left corner
    [remToPixels(135), remToPixels(30)] // Bottom-right corner
  ];

  const translateExtent = [
    [0, 0], // Top-left corner
    [remToPixels(63.5), remToPixels(33.5)] // Bottom-right corner
  ];


  return (

    <div className='pipeline_editor_page_holder'>
        
        <div className="pipeline_editor_holder">


              <ReactFlowProvider>
                  <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                      <ReactFlow
                          id='pipeline_editor'
                          className='pipeline_editor'
                          nodes={nodes}
                          edges={edges}
                          onNodesChange={onNodesChange}
                          onEdgesChange={onEdgesChange}
                          onDrop={onDrop}
                          onNodeDragStop={onNodeDragStop}
                          onDragOver={onDragOver}
                          onConnect={onConnect}
                          onInit={setReactFlowInstance}
                          // translateExtent={translateExtent}
                          nodeExtent={nodeExtent}
                          autoPanOnNodeDrag={false} // Disable panning the canvas on drag
                          panOnDrag={false} // Disable panning the canvas on drag  
                          zoomOnPinch={false} // Disable canvas zoom on pinch
                          zoomOnScroll={false} // Disable canvas zoom on scroll
                          zoomOnDoubleClick={false} // Disable canvas zoom on double click
                          nodeTypes={nodeTypes} // Define custom nodes here if needed
                          onEdgeMouseMove={onEdgeMouseEnter}
                          elementsSelectable={false}
                          defaultViewport={{x: -523.5, y: 186.5, zoom: .575}}
                          // fitView
                      />
                  </div>
                 
                  <PipelineSidebar />
              </ReactFlowProvider>


            </div>
        </div>
  );
};
