import React, { useState } from 'react';
import Select from 'react-select';
import { registerBlockType } from '@quillforms/blocks';
import {countries, usStates} from "./constantArrays"
// Assuming these are your predefined lists for countries and US states


const LocationBlockDisplay = ({ setIsValid, setIsAnswered, setVal }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [city, setCity] = useState('');

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedState(''); // Reset state selection when country changes
    setCity(''); // Reset city input
    setIsValid(false);
    setIsAnswered(false);
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setCity(''); // Reset city input
    setIsValid(false);
    setIsAnswered(false);
  };

  const handleCityInput = (event) => {
    const newCity = event.target.value;
    setCity(newCity);
    if (newCity.trim() !== '') {
      setIsValid(true);
      setIsAnswered(true);
      setVal({ country: selectedCountry.value, state: selectedState.value, city: newCity });
    } else {
      setIsValid(false);
      setIsAnswered(false);
    }
  };

  return (
    <div>
      <Select
        options={countries}
        onChange={(option) => handleCountryChange(option)}
        placeholder="Select a country"
        value={selectedCountry}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
      />
      {selectedCountry.value === 'United States' && (
        <Select
          options={usStates}
          onChange={(option) => handleStateChange(option)}
          placeholder="Select a state"
          value={selectedState}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
        />
      )}
      {(selectedCountry && selectedCountry.value !== 'USA') || selectedState ? (
        <input
          type="text"
          placeholder="Enter city"
          value={city}
          onChange={handleCityInput}
        />
      ) : null}
    </div>
  );
};

// Registering the custom block type with QuillForms
registerBlockType('location-block', {
  supports: {
    editable: true,
  },
  attributes: {
    country: {
      type: 'string',
      default: '',
      value: "",
    },
    state: {
      type: 'string',
      default: '',
      value: "",
    },
    city: {
      type: 'string',
      default: '',
      value: "",
    },
  },
  display: LocationBlockDisplay,
});
