import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { subDays, format } from 'date-fns';
import { brandColors, chartOptions } from '../../pages/job_subPages/job_dashboard';

export const CandidatesByTimeChart = ({ candidates, employer }) => {
  const [timeFrame, setTimeFrame] = useState('Past Week');
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: 'Candidates',
      data: [],
      fill: true,
      pointRadius: 1.5,
      backgroundColor: brandColors.primary40,
      borderColor: brandColors.primary,
      tension: 0.1,
    }]
  });

  useEffect(() => {
    const getLimitDate = () => {
      switch (timeFrame) {
        case 'Past Day':
          return new Date(new Date().setDate(new Date().getDate() - 1));
        case 'Past Week':
          return new Date(new Date().setDate(new Date().getDate() - 7));
        case 'Past Month':
          return new Date(new Date().setMonth(new Date().getMonth() - 1));
        case 'Past Year':
          return new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        default:
          return new Date(employer.createdTime.seconds * 1000); // Basically just get the time the account was created
      }
    };



    const limitDate = getLimitDate();

    const dateFormat = { month: '2-digit', day: '2-digit' };

    function formatDateForCounting(date) {
      return format(date, 'yyyy-MM-dd'); // Use this for keys in counts
    }

    function formatDateForDisplay(date) {
      const day = date.getDate();
      const monthShort = date.toLocaleString('en-US', { month: 'short' });
      const ordinal = (day) => ['th', 'st', 'nd', 'rd'][(day % 10 > 3) ? 0 : ((day % 100 - day % 10 != 10) * day % 10)];
      return `${day}${ordinal(day)} ${monthShort}`; // Display without year
    }


    let counts = {};
    let displayLabels = {};

    for(let d = new Date(limitDate); d <= new Date(); d.setDate(d.getDate() + 1)){
      const countingStr = formatDateForCounting(d);
      counts[countingStr] = 0; // Initialize count
      displayLabels[countingStr] = formatDateForDisplay(d); // Map for labels
    }

    candidates.forEach(candidate => {

      const applicationTime = new Date(candidate.application_time.seconds * 1000);
      applicationTime.setHours(0, 0, 0, 0);
      const countingStr = formatDateForCounting(applicationTime);
      if (counts.hasOwnProperty(countingStr)) {
        counts[countingStr]++;
      }
    });

    const chartLabels = Object.keys(counts).map(key => displayLabels[key]);
    const chartDataset = Object.values(counts);

    setChartData({
      labels: chartLabels,
      datasets: [{
        
        ...chartData.datasets[0],
        data: chartDataset,
        
        // borderWidth: 1,
      }]
    });
  }, [candidates, timeFrame]);

  const handleTimeFrameChange = (e) => {
    setTimeFrame(e.target.value);
  };


  const options = {
    ...chartOptions,
  }

  return (
    <div className='company_report_chart_holder candidates_by_time'>

        <div className='company_report_chart_heading_holder'>

          <p className='company_report_chart_heading'>Candidates by Date</p>
          <select className='company_report_timeframe_select' onChange={handleTimeFrameChange} value={timeFrame}>
              <option value="Past Day">Past 24 Hours</option>
              <option value="Past Week">Past Week</option>
              <option value="Past Month">Past Month</option>
              <option value="Past Year">Past Year</option>
              <option value="All-Time">All-Time</option>
          </select>

        </div>

        { chartData && (chartData !== undefined) && (
            <div className='company_report_chart'>
              <Line data={chartData} options={options} />
            </div>
          )}
      
    </div>
  );
};
