// File will contain the routing logic for the app
import { BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import { NotFound } from "./pages/NotFound"; 
import { Job } from "./pages/job";
import { Dashboard } from "./pages/dashboard";
import { AllJobs } from "./pages/all_jobs";
import { Candidate } from "./pages/candidate";
import Sidebar from "./components/Sidebar";
import { Candidates } from "./pages/job_subPages/candidates";
import { Pipeline } from "./pages/job_subPages/pipeline";
import { Details } from "./pages/candidate_subPages/details";
import { Responses } from "./pages/candidate_subPages/responses";
import { Settings } from "./pages/settings";
import { AllCandidates } from "./pages/AllCandidates";
import { CreateJob } from "./pages/createJob";
import { Notification } from "./components/notification";
import PrivateRoute from "./components/privateRoute";
import { Team } from "./pages/team";
import { Members } from "./pages/team_subpages/members";
import { Roles } from "./pages/team_subpages/roles";
import { CalendarShowcase } from "./pages/calendar";
import { CalSettings } from "./pages/calendar_settings";
import { CandidateSwitch } from "./pages/candidate_switch";
import { JobSettings } from "./pages/job_subPages/job_settings";
import { JobDashboard } from "./pages/job_subPages/job_dashboard";
import { PipelineEditor } from "./components/pipelineEditor";
import { SettingsAccount } from "./pages/settings_subPages/account";
import { SettingsEmails } from "./pages/settings_subPages/emails";
import { SettingsJobsPage } from "./pages/settings_subPages/jobs_page";
import { Admin } from "./pages/admin";
import { ConfirmLinks } from "./pages/confirmLinks";
import { ScheduleMeeting } from "./pages/scheduleMeeting";
import { Automation } from "./components/automation";
import { SettingsScheduling } from "./pages/settings_subPages/scheduling";

export const Routing = () => {
    return (
        <div className="website-container">
        <Router>
            <Sidebar />

            <div className="main_content">
                <Routes>
                    {/* Define routes with Sidebar */}
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/login" element={<Dashboard />} />

                    <Route path="/team" element={<Team />} >
                        <Route path="members" element={<Members />} />
                        <Route path="roles" element={<Roles />} />
                    </Route>
                        
                    <Route path="job/:jobId" element={<Job />}>
                        <Route path="" element={<JobDashboard />} />
                        <Route path="candidates" element={<Candidates />} />
                        <Route path="pipeline" element={<Pipeline />} />
                        <Route path="settings" element={<JobSettings />} />
                    </Route>
                    
                    <Route path="jobs" element={<AllJobs />} />

                    <Route path="jobs/new" element={<CreateJob />} />
                    
                    <Route path="job/:jobId/candidate/:candidateId" element={<Candidate />} />       
                    
                    <Route path="job/:jobId/candidate/:candidateId/score" element={<Details />} />
                    {/* <Route path="responses" element={<Responses />} /> */}              
                    {/* <Route path="job/:jobId/candidate/:candidateId/resume" element={<Resume />} />                  */}
                    

                    <Route path="job/:jobId/candidate/:candidateId/switch" element={<CandidateSwitch />} />

                    <Route path="schedule/:id" element={<ScheduleMeeting />} />

                    <Route path="settings" element={<Settings />} >
                        <Route path="" element={<SettingsAccount />} />
                        <Route path="emails" element={<SettingsEmails />} />
                        <Route path="scheduling" element={<SettingsScheduling />} />
                        <Route path="jobs_page" element={<SettingsJobsPage />} />
                    </Route>

                    <Route path="candidates" element={<AllCandidates />} />

                    <Route path="calendar" element={<CalendarShowcase />} />

                    <Route path="calendar/settings" element={<CalSettings />} />

                    <Route path="pipelinetest" element={<PipelineEditor />} />

                    <Route path="oauth2callback" element={<CalSettings />}/>

                    <Route path="admin" element={<Admin />} />

                    <Route path="confirmLinks" element={<ConfirmLinks />} />

                    <Route path="*" element={<NotFound />} />

                {/* Define routes without Sidebar */}
                </Routes>
            </div>
        </Router>
        <Notification />
        <Automation />
        </div>
    )
}




