import { Handle } from "reactflow";

import "./styles/node.css"
import "./styles/prescreeningNode.css"
import Popup from "reactjs-popup";
import { useState } from "react";


export const HiredNode = ({ data }) => {
    
    const [popupIsOpen, setPopupOpen] = useState(false);

    return (
    

    <div className='pipeline_node_display'>
        Hired
        <Handle type="target" position="left" id="5"  style={{pointerEvents:"none"}}/>
        {/* <Handle type="target" position="top" id="6"  style={{pointerEvents:"none"}}/>
        <Handle type="source" position="right" id="4"  style={{pointerEvents:"none"}}/>
        <Handle type="source" position="bottom" id="3"  style={{pointerEvents:"none"}}/>
        
         */}

        {/* <div className="node_incomplete"> 
            ! 
        </div> */}
    </div>

    
    
    // <Popup trigger={

    //     <div className='pipeline_node_display'>
    //         Hired
    //         <Handle type="target" position="left" id="5"  style={{pointerEvents:"none"}}/>
    //         {/* <Handle type="target" position="top" id="6"  style={{pointerEvents:"none"}}/>
    //         <Handle type="source" position="right" id="4"  style={{pointerEvents:"none"}}/>
    //         <Handle type="source" position="bottom" id="3"  style={{pointerEvents:"none"}}/>
            
    //          */}

    //         <div className="node_incomplete"> 
    //             ! 
    //             {/* Note that this will actually be an orange circle / symbol.  */}
    //         </div>
    //     </div>

    // }

    // open={popupIsOpen}
    // onOpen={() => setPopupOpen(true)}
    // modal
    // nested
    // position="center center"
    // >
    
    // <div className="create_stage_holder">
    //     <div className="create_stage">
    //         <h1> Hired </h1>

    //         <div className="stage_bottom_actions">
    //             <div className="stage_cancel" onClick={(e) => setPopupOpen(false)}>
    //                 Cancel
    //             </div>

    //             <div className="stage_save">
    //                 Save
    //             </div>
    //         </div>
    //     </div>
    // </div>

    // </Popup>
)};