import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import "./styles/scheduleMeeting.css";
import { addDoc, arrayRemove, arrayUnion, collection, connectFirestoreEmulator, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as FaIcons from "react-icons/fa";
import { CiVideoOn } from "react-icons/ci";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import { useNavigate } from "react-router-dom";


// Setup the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

export const ScheduleMeeting = () => {
    const scheduleMeetingID = window.location.href.split("/")[4];
    const navigate = useNavigate();

    const [meeting, setMeeting] = useState(null);
    const [job, setJob] = useState(null);
    const [employer, setEmployer] = useState(null);
    const [interviewers, setInterviewers] = useState([]);
    const [candidate, setCandidate] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [duration, setDuration] = useState(30);
    const [fullyLoaded, setFullyLoaded] = useState(false);
    const [canRun, setCanRun] = useState(false);
    const [memberID, setMemberID] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });
  
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);
  
  
  
    useEffect(() => {
  
        const getTeam = async () => {
            try {
              const user = auth.currentUser;
              if (!user) {
              throw new Error('No authenticated user found');
              }
  
              // Step 1: Get the user's member document to find their team_id
              const membersRef = collection(db, "members");
              const qMember = query(membersRef, where("email", "==", user.email));
              const memberSnapshot = await getDocs(qMember);
              if (memberSnapshot.empty) {
              throw new Error('Member not found');
              }
  
              const memberData = memberSnapshot.docs[0].data();
              setMemberID(memberSnapshot.docs[0].id);
  
            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        };
  
        if (canRun) {
          getTeam();
        }
  
      }, [canRun]);

    useEffect(() => {
        const fetchMeeting = async () => {



            const meetingRef = doc(db, "to_schedule", scheduleMeetingID);
            const meeting = await getDoc(meetingRef);

            setMeeting({
                ...meeting.data(),
                id: scheduleMeetingID
            });

            const jobRef = doc(db, "jobs", meeting.data().job);
            const job = await getDoc(jobRef);

            setJob(job.data());

            const employerRef = doc(db, "teams", job.data().employer_id);
            const employer = await getDoc(employerRef);
            setEmployer(employer.data());

            const candidateRef = doc(db, "candidates", meeting.data().candidate);
            const candidate = await getDoc(candidateRef);

            setCandidate(candidate.data());

            const members = job.data().pipeline[meeting.data().stage].interviewers.interviewers;

            setDuration(job.data().pipeline[meeting.data().stage].details.duration);

            setInterviewers(members);

            const membersMap = {};

            console.log("got here");

            for (const member of members) {
                console.log("member", member);
                if (member.type === "group") {
                    for (const memberID of member.interviewers) {
                        const memberRef = doc(db, "members", memberID.id);
                        const memberSnap = await getDoc(memberRef);

                        let events = [];

                        if (memberSnap.data().events && memberSnap.data().events.length > 0) {
                            events = await Promise.all(
                                memberSnap.data().events.map(async (eventId) => {
                                    const eventRef = doc(db, "events", eventId);
                                    const eventSnap = await getDoc(eventRef);
                                    return eventSnap.exists() ? { id: eventId, ...eventSnap.data() } : null;
                                })
                            );
                        }

                        membersMap[memberID.id] = {
                            ...memberSnap.data(),
                            events: events.filter(event => event !== null)
                        };
                    }
                } else {
                    if (!member.id) {
                        continue;
                    }
                    const memberRef = doc(db, "members", member.id);
                    const memberSnap = await getDoc(memberRef);
                    let events = [];
                    if (memberSnap.data().events && memberSnap.data().events.length > 0) {
                        events = await Promise.all(
                            memberSnap.data().events.map(async (eventId) => {
                                const eventRef = doc(db, "events", eventId);
                                const eventSnap = await getDoc(eventRef);
                                return eventSnap.exists() ? { id: eventId, ...eventSnap.data() } : null;
                            })
                        );
                    }

                    membersMap[member.id] = {
                        ...memberSnap.data(),
                        events: events.filter(event => event !== null)
                    };
                }
            }

            console.log("got here 2");
            setTeamMembers(membersMap);
            setFullyLoaded(true);
        }

        if (scheduleMeetingID) {
            fetchMeeting();
        }
    }, [scheduleMeetingID]);

    const [calendarRange, setCalendarRange] = useState({ start: null, end: null });

    useEffect(() => {
        if (meeting && candidate) {
            const days = meeting.availability.days.map(day => new Date(day));
            const startDate = new Date(Math.min(...days));
            const endDate = new Date(Math.max(...days));

            endDate.setDate(endDate.getDate() + 4);
            
            setCalendarRange({
                start: startDate,
                end: endDate
            });

            const newEvents = generateEvents();
            setEvents(newEvents);
        }
    }, [meeting, candidate, teamMembers, interviewers]);

    // handling hovering over an interviewer

    const [hoveredInterviewer, setHoveredInterviewer] = useState(null);

    const handleInterviewerHover = useCallback((interviewerId) => {
        setHoveredInterviewer(interviewerId);
    }, []);

    const handleInterviewerLeave = useCallback(() => {
        setHoveredInterviewer(null);
    }, []);

    const generateEvents = () => {
        const events = [];

        if (!meeting || !meeting.availability || !meeting.availability.days) {
            return events;
        }

        const availableDays = meeting.availability.days.map(day => {
            const [, month, date, year] = day.split(' ');
            return new Date(Date.parse(`${month} ${date} ${year}`)).toDateString();
        });

        // Add interviewer events
        interviewers.forEach(interviewer => {
            if (interviewer.type === "group") {
                interviewer.interviewers.forEach(subInterviewer => {
                    if (teamMembers[subInterviewer.id]) {
                        teamMembers[subInterviewer.id].events.forEach(event => {
                            const eventDate = new Date(event.startTime.seconds * 1000).toDateString();
                            if (availableDays.includes(eventDate)) {
                                events.push({
                                    interviewerId: subInterviewer.id,
                                    start: new Date(event.startTime.seconds * 1000),
                                    end: new Date(event.endTime.seconds * 1000),
                                    title: `${subInterviewer.name}: ${event.title || "Busy"}`,
                                    resource: 'interviewer'
                                });
                            }
                        });
                    }
                });
            } else if (teamMembers[interviewer.id]) {
                teamMembers[interviewer.id].events.forEach(event => {
                    const eventDate = new Date(event.startTime.seconds * 1000).toDateString();
                    if (availableDays.includes(eventDate)) {
                        events.push({
                            interviewerId: interviewer.id,
                            start: new Date(event.startTime.seconds * 1000),
                            end: new Date(event.endTime.seconds * 1000),
                            title: `${interviewer.name}: ${event.title || "Busy"}`,
                            resource: 'interviewer'
                        });
                    }
                });
            }
        });

        return events;
    };

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [tempEvent, setTempEvent] = useState(null);

    const handleSelectSlot = ({ start }) => {
        const dateStr = moment(start).format('ddd MMM DD YYYY');
        const startTimeStr = moment(start).format('HH:mm');
        const end = moment(start).add(duration, 'minutes').toDate();
        const endTimeStr = moment(end).format('HH:mm');
        const availabilityForDay = meeting?.availability?.times[dateStr];

        if (availabilityForDay) {
            const isAvailable = availabilityForDay.some(slot => 
                startTimeStr >= slot.start && endTimeStr <= slot.end
            );

            if (isAvailable) {
                setTempEvent({
                    start,
                    end,
                    title: 'Interview Slot',
                    resource: 'temp'
                });
            }
        }
    };

    const handleSelectEvent = (event) => {
        if (event.resource === 'temp') {
            setTempEvent(null);
        }
    };

    const allEvents = useMemo(() => {
        return tempEvent ? [...events, tempEvent] : events;
    }, [events, tempEvent]);

    const eventStyleGetter = useCallback((event, start, end, isSelected) => {
        let style = {
            backgroundColor: event.resource === 'temp' ? 'var(--secondary80)' : 'var(--accent40)',
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '1px ridge var(--secondary40)',
            display: 'block',
            width: "100%",
            transition: "all 0.3s ease",
        };

        if (hoveredInterviewer && event.interviewerId === hoveredInterviewer) {
            style.border = '1px solid var(--secondary80)';
            style.backgroundColor = 'var(--secondary)';
            style.boxShadow = '0 2px 5px var(--secondary40)';
        }

        return {
            style,
            className: event.resource !== 'temp' ? 'rbc-event-non-selectable' : '',
        };
    }, [hoveredInterviewer]);

    const onEventClick = (event) => {
        console.log("event clicked", event);
    }

    const handleTimeRangeSelected = (args) => {
        const isAvailable = calendarConfig.events.some(event => 
            event.text === "Available" && 
            args.start >= event.start && 
            args.end <= event.end
        );

        if (isAvailable) {
            setSelectedEvent({
                start: args.start.toString(),
                end: args.end.toString(),
            });
        }
    };

    const [calendarConfig, setCalendarConfig] = useState({
        viewType: "Week",
        durationBarVisible: false,
        timeRangeSelectedHandling: "Enabled",
        onTimeRangeSelected: handleTimeRangeSelected,
        onEventClick: onEventClick,
    });

    const calendarRef = useRef();

    const dayPropGetter = (date) => {
        if (!meeting || !meeting.availability || !meeting.availability.times) return {};
    
        const dateStr = moment(date).format('ddd MMM DD YYYY');
        const availabilityForDay = meeting.availability.times[dateStr];
    
        if (!availabilityForDay) {
            return {
                className: 'rbc-day-unavailable',
                style: {
                    backgroundColor: 'var(--bg)',
                }
            };
        }
    
        return {
            className: 'rbc-day-available',
            style: {
                backgroundColor: 'var(--bg)',
            }
        };
    };

    const slotPropGetter = (date) => {
        if (!meeting || !meeting.availability || !meeting.availability.times) return {};
    
        const dateStr = moment(date).format('ddd MMM DD YYYY');
        const timeStr = date.toTimeString().slice(0, 5);
        const availabilityForDay = meeting.availability.times[dateStr];
    
        if (availabilityForDay) {
            const isAvailable = availabilityForDay.some(slot => 
                timeStr >= slot.start && timeStr < slot.end
            );
    
            if (isAvailable) {
                return {
                    className: 'rbc-slot-available',
                    style: {
                        backgroundColor: 'var(--bg)',
                        border: "none",
                    }
                };
            }
        }
    
        return {
            className: 'rbc-slot-unavailable',
            style: {
                backgroundColor: 'var(--secondary10)',
                border: "none",
            }
        };
    };

    const CustomToolbar = (toolbar) => {
        const goToToday = () => {
          toolbar.onNavigate('TODAY');
        };
      
        const goToPrev = () => {
          toolbar.onNavigate('PREV');
        };
      
        const goToNext = () => {
          toolbar.onNavigate('NEXT');
        };

      
        return (
          <div className="rbc-toolbar" style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginRight:"2rem"}}>
            <h3>Calendar</h3>
            <span className="rbc-btn-group" style={{display:"flex", alignItems:"center", justifyContent:"space-between", width: "30%"}}>

              <div style={{display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer"}} onClick={goToPrev}>
                <FaIcons.FaAngleDoubleLeft />
              </div>

              <div style={{display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer"}} onClick={goToToday}>
                Today
              </div>

              <div style={{display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer"}} onClick={goToNext}>
                <FaIcons.FaAngleDoubleRight />
              </div>

            </span>
          </div>
        );
    };

    const CustomEvent = ({ event }) => {

        return (
            <div style={{
                opacity: 0.8,
                color: 'var(--text)',
                textShadow: "0px 2px 5px var(--secondary)",
                alignItems: "center",
                width: "100%",
                fontSize: "0.7rem",
                height: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitBoxPack: "center",
                WebkitLineClamp: 2,
                lineHeight: "1.2em",
                maxHeight: "2.75em",
                paddingTop: "0.2rem",
            }}>
                {event.title}
            </div>
        );
    };

    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        setSelectedDate(moment(calendarRange.start).startOf('day').add(9, 'hours').toDate());
    }, [calendarRange.start]);


    // handling the suggested times

    const [suggestedTimes, setSuggestedTimes] = useState([]);

    const getSuggestedTimes = () => {
        console.log("getting suggested times 1")
        if (!fullyLoaded) return [];

        console.log("getting suggested times")
    
        const candidateTimezone = meeting.availability.timezone;
        const allOpenTimes = [];
        const daysToCheck = 7; // Check for the next 7 days
        const minGapBetweenEvents = 60 * 60 * 1000; // 1 hour gap between events
    
        for (const day of meeting.availability.days) {
            const date = moment(day, 'ddd MMM DD YYYY').toDate();
            const dateStr = moment(date).format('ddd MMM DD YYYY');
            console.log(dateStr);
            const availabilityForDay = meeting.availability.times[dateStr];
    
            if (availabilityForDay) {
                const dayOpenTimes = [];
                for (const slot of availabilityForDay) {
                    const slotStart = moment(date).set({
                        hour: parseInt(slot.start.slice(0, 2)),
                        minute: parseInt(slot.start.slice(3, 5)),
                        second: 0,
                        millisecond: 0
                    });
                    const slotEnd = moment(date).set({
                        hour: parseInt(slot.end.slice(0, 2)),
                        minute: parseInt(slot.end.slice(3, 5)),
                        second: 0,
                        millisecond: 0
                    });
    
                    while (slotStart.add(duration, 'minutes').isSameOrBefore(slotEnd)) {
                        const start = slotStart.toDate();
                        const end = moment(start).add(duration, 'minutes').toDate();
    
                        // Check if the end time is also within the availability slot
                        if (moment(end).isAfter(slotEnd)) {
                            break;
                        }
    
                        const isFarEnough = interviewers.every(interviewer => {
                            if (interviewer.type === "group") {
                                return interviewer.interviewers.every(subInterviewer => {
                                    const member = teamMembers[subInterviewer.id];
                                    return !member || !member.events ? true : 
                                        isTimeFarFromEvents(member.events, start, end, minGapBetweenEvents);
                                });
                            } else {
                                const member = teamMembers[interviewer.id];
                                return !member || !member.events ? true : 
                                    isTimeFarFromEvents(member.events, start, end, minGapBetweenEvents);
                            }
                        });
                        if (isFarEnough) {
                            dayOpenTimes.push({
                                start: adjustTimeForTimezone(start, candidateTimezone),
                                end: adjustTimeForTimezone(end, candidateTimezone),
                                distanceFromEvents: getMinDistanceFromEvents(start, end, date)
                            });
                        }
                    }
                }
                
                // Sort day's times by distance from events (descending) and add to allOpenTimes
                dayOpenTimes.sort((a, b) => b.distanceFromEvents - a.distanceFromEvents);
                allOpenTimes.push(...dayOpenTimes);
            }
        }

        console.log(allOpenTimes);
    
        // Randomize times with equal distance from events
        const randomizedTimes = allOpenTimes.reduce((acc, time) => {
            if (!acc[time.distanceFromEvents]) {
                acc[time.distanceFromEvents] = [];
            }
            acc[time.distanceFromEvents].push(time);
            return acc;
        }, {});

        Object.values(randomizedTimes).forEach(group => {
            for (let i = group.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [group[i], group[j]] = [group[j], group[i]];
            }
        });

        console.log(randomizedTimes);

        const sortedRandomizedTimes = Object.entries(randomizedTimes)
            .sort(([a], [b]) => Number(b) - Number(a))
            .flatMap(([, group]) => group);
    
        // Select up to 4 times
        return sortedRandomizedTimes.slice(0, 4);
    };

    const getMinDistanceFromEvents = (start, end) => {
        let minDistance = Infinity;
        const startDate = start.toDateString();
        interviewers.forEach(interviewer => {
            let events = [];
            if (interviewer.type === "group") {
                events = interviewer.interviewers.flatMap(subInterviewer => {
                    const member = teamMembers[subInterviewer.id];
                    return member && member.events ? member.events : [];
                });
            } else {
                const member = teamMembers[interviewer.id];
                events = member && member.events ? member.events : [];
            }
            
            events.forEach(event => {
                const eventStart = new Date(event.startTime.seconds * 1000);
                const eventEnd = new Date(event.endTime.seconds * 1000);
                
                // Only consider events on the same day as the timeslot
                if (eventStart.toDateString() === startDate) {
                    const distanceStart = Math.abs(start.getTime() - eventEnd.getTime());
                    const distanceEnd = Math.abs(eventStart.getTime() - end.getTime());
                    minDistance = Math.min(minDistance, distanceStart, distanceEnd);
                }
            });
        });
        return minDistance;
    };

    const regenerateSuggestedTimes = () => {
        console.log("regenerating suggested times")
        console.log(meeting, candidate, teamMembers, Object.keys(teamMembers).length, fullyLoaded)
        if (fullyLoaded) {
            const newSuggestedTimes = getSuggestedTimes();
            setSuggestedTimes(newSuggestedTimes);
        }
    };

    useEffect(() => {
        regenerateSuggestedTimes();
    }, [meeting, candidate, teamMembers]);

    const isTimeFarFromEvents = (events, start, end, minGap) => {
        const startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
        const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
        
        const sameDay = (date1, date2) => {
            return date1.getFullYear() === date2.getFullYear() &&
                   date1.getMonth() === date2.getMonth() &&
                   date1.getDate() === date2.getDate();
        };

        return events.every(event => {
            const eventStart = new Date(event.startTime.seconds * 1000);
            const eventEnd = new Date(event.endTime.seconds * 1000);

            // Only consider events on the same day as start or end
            if (!sameDay(eventStart, startDate) && !sameDay(eventStart, endDate)) {
                return true;
            }

            return (start.getTime() - eventEnd.getTime() >= minGap) || 
                   (eventStart.getTime() - end.getTime() >= minGap);
        });
    };
    const adjustTimeForTimezone = (time, timezone) => {
        const offsetDiff = timezone.offset - (new Date().getTimezoneOffset() / -60);
        return new Date(time.getTime() + offsetDiff * 60 * 60 * 1000);
    };


    const meetingInTimeslot = (timeslot, intervalStart, intervalEnd) => {
        if (timeslot) {



            // Create a new Date object for timeslotStart using the same day as intervalStart
            const timeslotStartDate = new Date(intervalStart); // Copy date to ensure it's the same day

            const [timeslotStartHour, timeslotStartMinute] = timeslot.start.split(':').map(Number);
            timeslotStartDate.setHours(timeslotStartHour, timeslotStartMinute, 0, 0); // Set hours and minutes, seconds and milliseconds to 0

            const timeslotEndDate = new Date(intervalStart); // Copy date to ensure it's the same day

            const [timeslotEndHour, timeslotEndMinute] = timeslot.end.split(':').map(Number);
            timeslotEndDate.setHours(timeslotEndHour, timeslotEndMinute, 0, 0); // Set hours and minutes, seconds and milliseconds to 0


            if ((intervalStart >= timeslotStartDate) && (intervalEnd <= timeslotEndDate)) {
                return true;
            }


        }

    }

    const isMemberAvailable = (availability, events, member_timezone, current_timezone, intervalStart, intervalEnd) => {


        var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        const day = days[intervalStart.getDay()];
        var isAvailable = false;
        var noOverlap = true;
        const daily_availability = availability[day];
        if (daily_availability) {

            daily_availability.forEach(timeslot => {
                if(meetingInTimeslot(timeslot, intervalStart, intervalEnd)) {
                    isAvailable = true;
                };
            })

            if (isAvailable) {
                if (events) {
                    events.forEach(event => {
                        
                        const eventStart = new Date((event.startTime.seconds * 1000) + (member_timezone.offset * 3600 * 1000) - (current_timezone.offset * 3600 * 1000));
                        const eventEnd = new Date((event.endTime.seconds * 1000) + (member_timezone.offset * 3600 * 1000) - (current_timezone.offset * 3600 * 1000));
    
    
    
                        if (intervalStart < eventEnd && intervalEnd > eventStart) {
                            noOverlap = false;
                        }
    
                    })
                }
            }

        }
        return (isAvailable && (noOverlap));

        
    };

     // Handle the timezone part

    const labelStyle = "original"
    const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
    }


    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })

    const [selectedTimezone, setSelectedTimezone] = useState(parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone));

    const [bookingConfirmed, setBookingConfirmed] = useState(false);

    const setupReminder = (reminderTime, meetingLink, interviewers, emailSubject, emailBody) => {
        const reminderDate = new Date(tempEvent.start);
        reminderDate.setHours(reminderDate.getHours() - parseInt(reminderTime));

        const meetingAttendees = [
            {
                email: candidate.email,
                name: candidate.name
            },
            ...interviewers.map(interviewer => {
                const actualInterviewer = teamMembers[interviewer.id];
                if (actualInterviewer) {
                    return {
                        email: actualInterviewer.email,
                        name: `${actualInterviewer.first_name} ${actualInterviewer.last_name}`
                    };
                } else {
                    return {
                        email: interviewer.name,
                        name: interviewer.name
                    };
                }
            })
        ]

        const reminderData = {
            reminder_time: reminderDate,
            interview_time: tempEvent.start,
            meetingLink,
            attendees: meetingAttendees,
            employer_name: employer.name,
            job_title: job.jobTitle,
            employerEmail: candidate.employerEmail,
            emailSubject, 
            emailBody
        }

        addDoc(collection(db, "interview_reminders"), reminderData);
    }

    const sendCalendarInvite = (link, cancelLink, rescheduleLink, interviewers) => {

        let eventDescription = "";

        const interviewData = job.pipeline[meeting.stage];

        if (interviewData.details.location === "Virtual") {
            eventDescription = 
        `Event Type: ${interviewData.details.name}\n\nJob: ${job.jobTitle}\n\nLocation: This is a virtual interview. You can join this meeting at the following link: ${link}\n\n\nNeed to make any changes?\nCancel: ${cancelLink}\nReschedule: ${rescheduleLink}`;
        } else {
            eventDescription = 
            `Event Type: ${interviewData.details.name}\n\nJob: ${job.jobTitle}\n\nLocation: ${interviewData.details.inPersonLocation.label}\n\n\nNeed to make any changes?\nCancel: ${cancelLink}\nReschedule: ${rescheduleLink}`;
        }

        const startTime = new Date(tempEvent.start);
        const endTime = new Date(tempEvent.start);
        endTime.setMinutes(endTime.getMinutes() + duration);

        const meetingTitle = `${interviewData.details.name} | ${candidate.name}`;

        const emailSubject = "Invite: " + meetingTitle;

        const meetingAttendees = [
            {
                email: candidate.email,
                name: candidate.name
            },
            ...interviewers.map(interviewer => {
                const actualInterviewer = teamMembers[interviewer.id];
                if (actualInterviewer) {
                    return {
                        email: actualInterviewer.email,
                        name: `${actualInterviewer.first_name} ${actualInterviewer.last_name}`
                    };
                } else {
                    return {
                        email: interviewer.name,
                        name: interviewer.name
                    };
                }
            })
        ]
        
        let location = "Virtual Meeting (check description)";
        if (interviewData.details.location !== "Virtual") {
            location = interviewData.details.inPersonLocation.label;
        }


        fetch('https://api.skillpool.tech/send_interview_invite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                employerEmail: job.employerEmail,
                recipientEmail: candidate.email,
                startTime,
                endTime,
                emailSubject,
                eventTitle: meetingTitle,
                eventDescription,
                location: location,
                employerName: employer.name,
                attendees: meetingAttendees
            }),
        })


    }

    const sendInterviewerInvite = (interviewer, link, cancelLink, rescheduleLink, interviewers) => {


        let eventDescription = "";

        const interviewData = job.pipeline[meeting.stage];

        if (interviewData.details.location === "Virtual") {
            eventDescription = 
            `Event Type: ${interviewData.details.name}\n\nJob: ${job.jobTitle}\n\nLocation: This is a virtual interview. You can join this meeting at the following link: ${link}`;
        } else {
            eventDescription = 
            `Event Type: ${interviewData.details.name}\n\nJob: ${job.jobTitle}\n\nLocation: ${interviewData.details.inPersonLocation.label}`;
        }

        const startTime = new Date(tempEvent.start);
        const endTime = new Date(tempEvent.start);
        endTime.setMinutes(endTime.getMinutes() + duration);

        const meetingTitle = `${interviewData.details.name} | ${candidate.name}`;

        const emailSubject = "Invite: " + meetingTitle;

        const meetingAttendees = [
            {
                email: candidate.email,
                name: candidate.name
            },
            ...interviewers.map(interviewer => {
                const actualInterviewer = teamMembers[interviewer.id];
                if (actualInterviewer) {
                    return {
                        email: actualInterviewer.email,
                        name: `${actualInterviewer.first_name} ${actualInterviewer.last_name}`
                    };
                } else {
                    return {
                        email: interviewer.name,
                        name: interviewer.name
                    };
                }
            })
        ]

        let location = "Virtual Meeting (check description)";
        if (interviewData.details.location !== "Virtual") {
            location = interviewData.details.inPersonLocation.label;
        }

        let interviewerEmail = "";

        if (teamMembers[interviewer.id]) {
            interviewerEmail = teamMembers[interviewer.id].email;
        } else {
            interviewerEmail = interviewer.name;
        }

        console.log(interviewerEmail);


        fetch('https://api.skillpool.tech/send_interview_invite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                employerEmail: job.employerEmail,
                recipientEmail: interviewerEmail,
                startTime,
                endTime,
                emailSubject,
                eventTitle: meetingTitle,
                eventDescription,
                location: location,
                employerName: employer.name,
                attendees: meetingAttendees
            }),
        })


    }


     const setupFeedback = async (interviewID, interviewers, endTime) => {

        // first you'll have to get the feedback questions
        const interviewData = job.pipeline[meeting.stage];

        const feedbackData = {
            interviewers: interviewers.map(interviewer => {
                const actualInterviewer = teamMembers[interviewer.id];
                if (actualInterviewer) {
                    return {
                        id: interviewer.id,
                        email: actualInterviewer.email,
                        name: `${actualInterviewer.first_name} ${actualInterviewer.last_name}`
                    };
                } else {
                    return {
                        email: interviewer.name,
                        name: interviewer.name
                    };
                }
            }),
            feedbackQuestions: interviewData.feedback.selectedQuestions,
            reminder: interviewData.feedback.feedbackRemindersIn,
            candidate: {
                name: candidate.name,
                id: meeting.data().candidate, 
            },
            job: {
                title: job.jobTitle,
                id: meeting.data().job,
            },
            interview: {
                name: interviewData.details.name,
                id: interviewID,
            },
            employerEmail: employer.settings.employerEmail,
            employerName: employer.name,
            endTime,
        }

        console.log(feedbackData);

        const feedbackRef = await addDoc(collection(db, "feedback"), feedbackData);

        return feedbackRef.id;
    }



    const scheduleMeeting = async () => {
        console.log("scheduling meeting at ", tempEvent)

         // get basic info
        let endTime = new Date(tempEvent.start);
        endTime.setMinutes(endTime.getMinutes() + duration);

        // identify everyone that will be a part of this interview
        const interviewData = job.pipeline[meeting.stage];
        const setsOfInterviewers = interviewData.interviewers.interviewers;
        let interviewers = [];
        
        const optimizeInterviewerSelection = (availableGroupMembers, count) => {
            console.log("Available Group Members: ", availableGroupMembers[0]);
            let selectedInterviewers = [];
            
            // First, try to select interviewers who are already in the list
            for (let i = 0; i < count && availableGroupMembers.length > 0; i++) {
                const existingInterviewer = availableGroupMembers.find(interviewer => 
                    interviewers.some(selected => selected.id === interviewer.id)
                );
                
                if (existingInterviewer) {
                    selectedInterviewers.push(existingInterviewer);
                    availableGroupMembers = availableGroupMembers.filter(interviewer => interviewer.id !== existingInterviewer.id);
                } else {
                    break;
                }
            }
            
            // If we still need more interviewers, select randomly
            while (selectedInterviewers.length < count && availableGroupMembers.length > 0) {
                const randomIndex = Math.floor(Math.random() * availableGroupMembers.length);
                selectedInterviewers.push(availableGroupMembers[randomIndex]);
                availableGroupMembers.splice(randomIndex, 1);
            }
            
            return selectedInterviewers;
        };

        // First, handle individual interviewers
        setsOfInterviewers.forEach(set => {
            if (set.type !== "group") {
                if (!interviewers.some(interviewer => interviewer.id === set.id)) {
                    interviewers.push(set);
                }
            }
        });

        // Then, handle group selections
        setsOfInterviewers.forEach(set => {
            if (set.type === "group") {
                const count = set.count || 1; // Default to 1 if count is not specified
                console.log(set.interviewers);
                const availableGroupMembers = set.interviewers.filter(interviewer => {
                    if (teamMembers[interviewer.id]) {
                        return isMemberAvailable(teamMembers[interviewer.id].availability, 
                                                 teamMembers[interviewer.id].events, 
                                                 teamMembers[interviewer.id].timezone, 
                                                 selectedTimezone, 
                                                 new Date(tempEvent.start), 
                                                 endTime);
                    }
                    return false; // If the interviewer is not in teamMembers, consider them unavailable
                });

                console.log("Available Group Members: ", availableGroupMembers);
                
                const selectedInterviewers = optimizeInterviewerSelection(availableGroupMembers, count);

                console.log("Selected Interviewers: ", selectedInterviewers);
                
                if (selectedInterviewers.length < count) {
                    // If we couldn't select enough interviewers from available ones,
                    // include external interviewers to meet the count
                    const externalInterviewers = set.interviewers.filter(interviewer => !teamMembers[interviewer.id]);
                    for (let i = 0; i < count - selectedInterviewers.length && i < externalInterviewers.length; i++) {
                        selectedInterviewers.push(externalInterviewers[i]);
                    }
                }

                selectedInterviewers.forEach(interviewer => {
                    if (!interviewers.some(selected => selected.id === interviewer.id)) {
                        interviewers.push(interviewer);
                    }
                });
            }
        });

        // Remove any duplicates that might have slipped through
        interviewers = interviewers.filter((interviewer, index, self) =>
            index === self.findIndex((t) => t.id === interviewer.id)
        );

        console.log(interviewers);

        const event = {
            title: interviewData.details.name,
            interviewers: interviewers.map(interviewer => {
                const actualInterviewer = teamMembers[interviewer.id];
                if (actualInterviewer) {
                    return {
                        id: interviewer.id,
                        name: actualInterviewer.first_name + " " + actualInterviewer.last_name,
                        email: actualInterviewer.email,
                        timezone: actualInterviewer.timezone
                    };
                } else {
                    return {
                        id: interviewer.id,
                        name: interviewer.name,
                        email: interviewer.name,
                        timezone: selectedTimezone
                    };
                }
            }),
            job: meeting.job,
            jobTitle: job.jobTitle,
            interviewerNotes: interviewData.details.interviewNotes,
            candidate: meeting.candidate,
            startTime: tempEvent.start,
            endTime: endTime,
            stage: meeting.stage,
            timezone: selectedTimezone,
            employer: employer.name,
            schedulingType: "availability",
            availability: meeting.availability,
        }

        // TODO: Figure out exactly what data the event will need

        console.log(event);

        try {
            // Step 1: Create a new document in the 'events' collection with the event data
            const eventsRef = collection(db, "events");
            const eventRef = await addDoc(eventsRef, event);
            const newEventID = eventRef.id; // Store new event ID to use in the candidate's document
            const updatePromises = interviewers.map(interviewer => {
                if (interviewer.id) {
                    const memberRef = doc(db, "members", interviewer.id);
                    return updateDoc(memberRef, {
                        events: arrayUnion(newEventID) // This will create the array if it doesn't exist and append the ID if it does
                    });
                } else {
                    // If the interviewer doesn't have an id, we skip updating their document
                    return Promise.resolve();
                }
            });
            await Promise.all(updatePromises);


            const eventLink = "https://jobs.skillpool.tech/interview/" + newEventID;
            const cancelLink = "https://jobs.skillpool.tech/book-meeting/" + newEventID + '/cancel';
            const rescheduleLink = "https://jobs.skillpool.tech/book-meeting/" + newEventID + '/reschedule';

            let eventDescription = "";

            const interviewData = job.pipeline[meeting.stage];

            if (interviewData.details.location === "Virtual") {
                eventDescription = 
                `Event Type: ${interviewData.details.name}\n\nJob: ${job.jobTitle}\n\nLocation: This is a virtual interview. You can join this meeting at the following link: ${eventLink}\n\n\nNeed to make any changes?\nCancel: ${cancelLink}\nReschedule: ${rescheduleLink}`;
            } else {
                eventDescription = 
                `Event Type: ${interviewData.details.name}\n\nJob: ${job.jobTitle}\n\nLocation: ${interviewData.details.inPersonLocation.label}\n\n\nNeed to make any changes?\nCancel: ${cancelLink}\nReschedule: ${rescheduleLink}`;
            }

            let feedbackID = null;
            if (interviewData.feedback.enabled) {
                feedbackID = await setupFeedback(newEventID, interviewers, endTime);
            }

            if (feedbackID) {
                updateDoc(eventRef, {link: eventLink, feedback: feedbackID, description: eventDescription})
            } else {
                updateDoc(eventRef, {link: eventLink, description: eventDescription})
            }

            // now we have to send the calendar invite to the candidate as well as to all of the interviewers

            sendCalendarInvite(eventLink, cancelLink, rescheduleLink, interviewers) //let this be the candidate calendar invite.
            // Now we have to send the calendar invite to all of the interviewers

            interviewers.forEach(async interviewer => {
                sendInterviewerInvite(interviewer, eventLink, cancelLink, rescheduleLink, interviewers);
                 
            })

            if (interviewData.scheduling.enableInterviewReminder) {
                let emailSubject = "";
                let emailBody = "";
                if (!employer.settings.email_templates.interviews) {
                    emailBody = `<p>Hi {{candidate_name}},</p><p>We're sending over this email to remind you of your interview in {{time}}.</p><p>Here's the link for the interview:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Meeting Link</a></p><p>Looking forward to getting in touch with you!</p><p>All the best,</p><p>The {{employer_name}} Recruitment Team</p>`;
                    emailSubject = "Reminder for your Interview in {{time}}!";    
                } else {
                    emailSubject = employer.settings.email_templates.interviews.reminder.subject;
                    emailBody = employer.settings.email_templates.interviews.reminder.body;
                }
                    
                setupReminder(interviewData.scheduling.reminderTime, eventLink, interviewers, emailSubject, emailBody);
            }

            setBookingConfirmed(true);

            
            // Navigate to the root page and refresh
            setTimeout( async () => {

                const taskToRemove = {
                    id: scheduleMeetingID,
                    type: 'schedule'
                };
                
                const memberRef = doc(db, "members", memberID);
                const memberDoc = await getDoc(memberRef);
                const currentTasks = memberDoc.data().tasks || [];
                
                const updatedTasks = currentTasks.filter(task => 
                    task.id !== taskToRemove.id
                );
                
                await updateDoc(memberRef, { tasks: updatedTasks });


                const meetingInviteRef = doc(db, "to_schedule", scheduleMeetingID); 
                deleteDoc(meetingInviteRef);

                navigateToRoot();

            }, 1500);

        } catch (error) {
            console.error("Error in creating interview: ", error);
        }

        console.log(event);
    }

    const navigateToRoot = () => {
        console.log("navigating to root");
        navigate('/', { replace: true });
        window.location.reload();
    }




    return (
        <div className="schedule_meeting_holder">
            <h1 style={{marginBottom:"1.5rem"}} onClick={navigateToRoot}>Schedule Meeting</h1>

            <div className="schedule_meeting_area">
                <div className="schedule_meeting_calendar">
                    {meeting && candidate && calendarRange.start && calendarRange.end && (
                        <Calendar
                            localizer={localizer}
                            events={allEvents}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: "100%" }}
                            min={new Date(2020, 1, 0, 9, 0, 0)} 
                            max={new Date(2020, 1, 0, 17, 0, 0)}
                            date={selectedDate}
                            onNavigate={date => {
                                setSelectedDate(date);
                            }}
                            defaultView='week'
                            views={['week']}
                            eventPropGetter={eventStyleGetter}
                            dayPropGetter={dayPropGetter}
                            slotPropGetter={slotPropGetter}
                            components={{ 
                                toolbar: CustomToolbar, 
                                event: CustomEvent 
                            }}
                            selectable={true}
                            onSelectSlot={handleSelectSlot}
                            onSelectEvent={handleSelectEvent}
                        />
                    )}
                </div>

                <div className="schedule_meeting_attendees_info">
                    <div className="schedule_meeting_candidate_availability schedule_meeting_info_section">
                        <h3>Candidate Availability</h3>
                        <div className="schedule_meeting_candidate_availability_days">
                            {meeting?.availability?.days?.sort((a, b) => new Date(a) - new Date(b)).map(day => (
                                <div key={day} className="schedule_meeting_candidate_availability_day" onClick={() => setSelectedDate(new Date(day))}>
                                    <p className="candidate_availability_day_title">{new Date(day).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' }).replace(/(\d+)/, (match) => {
                                        const suffix = ['th', 'st', 'nd', 'rd'][match > 3 && match < 21 ? 0 : match % 10 < 4 ? match % 10 : 0];
                                        return `${match}${suffix} `;
                                    })}: </p>
                                    <div className="candidate_availability_timeslots">
                                        {meeting?.availability?.times?.[day]?.sort((a, b) => a.start.localeCompare(b.start)).map(slot => (
                                            <div key={slot.start} className="candidate_availability_timeslot">
                                                <div className="timeslot_start" style={{width: '45%', textAlign: 'left'}}>
                                                    {`${parseInt(slot.start.slice(0, 2)) % 12 || 12}${slot.start.slice(2, 5) !== ':00' ? ':' + slot.start.slice(3, 5) : ''}${parseInt(slot.start.slice(0, 2)) >= 12 ? 'PM' : 'AM'}`}
                                                </div>
                                                <div className="timeslot_dash" style={{width: '5%', textAlign: 'center'}}>
                                                    –
                                                </div>
                                                <div className="timeslot_end" style={{width: '45%', textAlign: 'right'}}>
                                                    {`${parseInt(slot.end.slice(0, 2)) % 12 || 12}${slot.end.slice(2, 5) !== ':00' ? ':' + slot.end.slice(3, 5) : ''}${parseInt(slot.end.slice(0, 2)) >= 12 ? 'PM' : 'AM'}`}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="schedule_meeting_interviewers schedule_meeting_info_section">
                        <h3 >Interviewers</h3>
                        <div className="schedule_meeting_interviewers_list">
                        {interviewers.map((interviewer, index) => (
                        interviewer.type === "group" ? (
                            <div key={index} className="interviewer_group_item">
                                {/* ... existing group interviewer JSX ... */}
                                <div className="interviewers_sublist">
                                    {interviewer.interviewers.map((specificInterviewer, subIndex) => (
                                        <div 
                                            key={subIndex} 
                                            className="interviewer_sub_item schedule_meeting_interviewer_item"
                                            onMouseEnter={() => handleInterviewerHover(specificInterviewer.id)}
                                            onMouseLeave={handleInterviewerLeave}
                                        >
                                            <span>{specificInterviewer.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div 
                                key={index} 
                                className="interviewer_item schedule_meeting_interviewer_item"
                                onMouseEnter={() => handleInterviewerHover(interviewer.id)}
                                onMouseLeave={handleInterviewerLeave}
                            >
                                <span>{interviewer.name}</span>
                            </div>
                            )
                        ))}   
                        </div>
                    </div>
                </div>
            </div>

            <div className="schedule_meeting_bottom_row">
                <div className="schedule_meeting_suggested_times">
                    

                    <div className="suggested_times_header">
                        <h3>Suggested Times</h3>

                        <div className="suggested_times_refresh" style={{cursor:"pointer", display:"flex", alignItems:"center"}} onClick={regenerateSuggestedTimes}>
                            <FaIcons.FaSync style={{marginRight:"0.5rem"}} />
                            <span>Refresh</span>
                        </div>
                    </div>

                    <div className="suggested_times_area">
                        <div className="suggested_times_grid">
                            {suggestedTimes
                                .sort((a, b) => a.start.getTime() - b.start.getTime())
                                .map((time, index) => (
                                    <div 
                                        key={index} 
                                        className={`suggested_time_slot ${tempEvent && tempEvent.start.getTime() === time.start.getTime() ? 'active' : ''}`} 
                                        onClick={() => setTempEvent({...time, title: 'Interview Slot', resource: 'temp'})}
                                    >
                                        <span>{moment(time.start).format('ddd, MMM D')}</span>
                                        <span>{moment(time.start).format('h:mm A')} - {moment(time.end).format('h:mm A')}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="schedule_meeting_confirm">
                    <h3>Confirm</h3>

                   
                    <div className="confirm_area">

                        {tempEvent && (
                            bookingConfirmed ? (
                                <div style={{display:"flex",  alignItems:"center"}}>
                                    <div className="confirm_area_icon">
                                        <FaIcons.FaCheckCircle style={{color: "var(--primary)", opacity:0.5}} />
                                    </div>

                                    <p style={{fontSize:"1.2rem", opacity:"0.5"}}>This interview has been scheduled!</p>
                                </div>
                            ) : (
                                <>
                                <div className="confirm_area_icon">
                                    <FaIcons.FaVideo style={{opacity:0.5, color: "var(--primary)"}} />
                                </div>

                                <div className="confirm_area_info">
                                    <div className="confirm_area_info_section">
                                        <div className="confirm_area_info_section_title">
                                            <FaIcons.FaRegClock />
                                        </div>

                                        <div className="confirm_area_info_section_content">
                                            {moment(tempEvent?.start).format('hh:mm a')} – {moment(tempEvent?.end).format('hh:mm a')}
                                        </div>
                                    </div>

                                    <div className="confirm_area_info_section">
                                        <div className="confirm_area_info_section_title">
                                            <FaIcons.FaCalendarAlt />
                                        </div>

                                        <div className="confirm_area_info_section_content">
                                            {moment(tempEvent.start).format('Do MMM')}
                                        </div>
                                    </div>
                                </div>

                                <button className="confirm_area_button" onClick={scheduleMeeting}>
                                    Confirm
                                </button>
                                </>
                            )
                        )} 

                        {!tempEvent && (
                            <div className="confirm_area_no_event">
                                <p>No slot selected</p>
                            </div>
                        )}
                    </div>

                   

                    
                </div>
            </div>

            
        </div>
    )
}