import React, { useEffect, useRef, useState } from 'react';
import "./styles/calendar.css"
import * as FaIcons from 'react-icons/fa';
import { DayPilot, DayPilotCalendar, DayPilotNavigator } from "@daypilot/daypilot-lite-react";
import { Link } from 'react-router-dom';
import { arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../firebase';
import Calendar from 'react-calendar';
import { X } from 'lucide-react';
import Popup from 'reactjs-popup';
import { sendNotification } from '../components/notification';



export const CalendarShowcase = () => {

    const [canRun, setCanRun] = useState(false);
    const [events, setEvents] = useState([]);

    const [selectedEvent, setSelectedEvent] = useState(null);

    const [memberData, setMemberData] = useState(null);

    const onEventClick = async (args) => {

        const eventData = args.e.data;

        if (eventData.external) {
            return;
        }

        setEventInfoPopupOpen(true);
        const candidateID = eventData.candidate;
        const jobID = eventData.job;

        const candidateRef = doc(db, "candidates", candidateID);
        const jobRef = doc(db, "jobs", jobID);

        try {
            // Fetch both documents concurrently
            const [candidateSnapshot, jobSnapshot] = await Promise.all([
                getDoc(candidateRef),
                getDoc(jobRef)
            ]);

            // Check if the documents exist and extract data
            if (!candidateSnapshot.exists()) {
                console.error('Candidate not found!');
                return;
            }
            if (!jobSnapshot.exists()) {
                console.error('Job not found!');
                return;
            }

            const candidate = candidateSnapshot.data();
            const job = jobSnapshot.data();
            // store the candidate's name and email in the event object

            const event = {
                id: eventData.id,
                title: eventData.text,
                candidate: {id: eventData.candidate ,name: candidate.name, email: candidate.email},
                job: job.jobTitle,
                employerEmail: job.employerEmail,
                interviewers: eventData.interviewers,
                startTime: eventData.startTime,
                endTime: eventData.endTime,
                link: eventData.link,
                stage: eventData.stage,
                interviewData: job.pipeline[eventData.stage],
                timezone: eventData.timezone,
                feedback: eventData.feedback,
                completed: eventData.completed,
            }

            console.log(event);

            setSelectedEvent(event);


            // Continue with your logic here
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }


    const [config, setConfig] = useState({
        viewType: "WorkWeek", // Default view shows a week at a time
        locale: "en-us",
        timeRangeSelectedHandling: "Disabled",
        eventDeleteHandling: "Disabled",
        eventMoveHandling: "Disabled",
        eventResizeHandling: "Disabled",
        eventClickHandling: "Enabled",
        onEventClick: onEventClick,
        showCurrentTime: true,
        separators: [
            {
              location: DayPilot.Date.now(),
              color: "red"
            }
        ],
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setCanRun(true);
            } else {
                setCanRun(false);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const getSchedule = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('No authenticated user found');
                }

                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                    throw new Error('Member not found');
                }
                const docs = memberSnapshot.docs[0];
                const memberData = docs.data();


                setMemberData(memberData);

                let fetchedEvents = [];
                if (memberData.events && memberData.events.length > 0) {
                    fetchedEvents = await Promise.all(
                        memberData.events.map(async (eventId) => {
                            const eventRef = doc(db, "events", eventId);
                            const eventSnap = await getDoc(eventRef);

                            return eventSnap.exists() ? { id: eventId, ...eventSnap.data() } : null;
                        })
                    );
                }

                // remove all events which dont exist from memberData.events
                var eventIDs = [];

                fetchedEvents.forEach(event => {
                    if (event !== null) {
                        eventIDs.push(event.id);
                    }
                })
                const newEvents = memberData.events.filter(e => eventIDs.includes(e));

                updateDoc(doc(db, "members", docs.id), {events: newEvents});

                fetchedEvents = fetchedEvents.filter(e => e !== null).map(event => ({
                    ...event,
                    start: new Date((event.startTime.seconds * 1000) + (memberData.timezone.offset * 3600 * 1000)),
                    end: new Date((event.endTime.seconds * 1000) + (memberData.timezone.offset * 3600 * 1000)),
                    text: event.title || "No Title",
                    id: event.id
                }));
                setEvents(fetchedEvents);

            } catch (error) {
                console.error("Failed to fetch team members:", error);
            }
        };

        if (canRun) getSchedule();
    }, [canRun]);

    useEffect(() => {
        setConfig(prevConfig => ({
            ...prevConfig,
            events: events
        }));
    }, [events]);

    const calendarRef = useRef();

    const handleTimeRangeSelected = args => {
        calendarRef.current.control.update({
          startDate: args.day
        });
    }



    // Handling the event information popup

    const [eventInfoPopupIsOpen, setEventInfoPopupOpen] = useState(false);

    const closeEventInfoPopup = () => {
        setEventInfoPopupOpen(false);
        setSelectedEvent(null);
        setActiveSection('event');
    }

    // Handling the switching sections for event information

    const [activeSection, setActiveSection] = useState('event');


    const formatDate = (startTime, endTime) => {
        const startDate = new Date(startTime.seconds * 1000);
        const endDate = new Date(endTime.seconds * 1000);

        // Format dates to string
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: memberData.timezone.value,
        };

        const formattedStartTime = startDate.toLocaleTimeString('en-US', options);
        const formattedEndTime = endDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZone: memberData.timezone.value });

        // Output the formatted string
        return (`${formattedStartTime} to ${formattedEndTime}`);
    }


    // handling the event actions selector

    const [action, setAction] = useState('');

    const handleChange = (event) => {
        setAction(event.target.value);
    };


    const [cancelPopupOpen, setCancelPopupOpen] = useState(false);

    const [cancelReason, setCancelReason] = useState('');

    const closeCancelPopup = () => {
        setCancelPopupOpen(false);
        setAction('');
        setCancelReason('');
    }

    const takeAction = (action) => {
        if (action === 'cancel') {
            setCancelPopupOpen(true)
        }

        // add support for reschedule later
    }


    // HELPER FUNCTIONS

    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th'; // covers 4th to 20th
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }


    function formatDateWithOrdinal(date, timeZone) {
        const options = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          timeZone: timeZone
        };

        const formattedDate = date.toLocaleDateString('en-GB', options);

        const day = date.getDate();
        const ordinal = getOrdinalSuffix(day);

        return formattedDate.replace(/(\d+)/, `$1${ordinal}`);
    }


    const cancelInterview = () => {

        if (cancelReason === '') {
            sendNotification("Please enter a reason for cancelling the interview");
            return;
        }

        closeCancelPopup();

        const candidate = selectedEvent.candidate;

        const oldSlot = new Date(selectedEvent.startTime.seconds * 1000);


        // send a mail to the candidate letting them know

        const emailBody = `
        <p>Hello ${candidate.name}!</p>
        <p>Unfortunately, your interviewer has cancelled your interview. The details of the cancelled interview are given below: </p>
        <br>
        <p>Job: ${selectedEvent.job}</p>
        <p>Date: ${formatDateWithOrdinal(oldSlot, selectedEvent.timezone.value)}</p>
        <p>Time: ${oldSlot.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true, timeZone:  selectedEvent.timezone.value })} ${ selectedEvent.timezone.abbrev}</p>
        <p>Reason for cancellation: ${cancelReason}</p>
        `

        fetch("https://api.skillpool.tech/send_email", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                senderEmail: selectedEvent.employerEmail,
                receiverEmail: candidate.email,
                subject: "Meeting Cancelled: " + "Interview for " + selectedEvent.job,
                body: emailBody,
                candidateID: candidate.id,
            })
        })

        // delete the event

        const newEvents = events.filter(event => event.id !== selectedEvent.id);

        setEvents(newEvents);

        deleteDoc(doc(db, "events", selectedEvent.id));

        // close the event popup

        setEventInfoPopupOpen(false);
    }


    // Handling the interview feedback input fields

    const [interviewFeedback, setInterviewFeedback] = useState("");

    const [showSavedFeedback, setShowSavedFeedback] = useState(false);


    useEffect(() => {
      if (selectedEvent) {
        if (selectedEvent.feedback) {
          setInterviewFeedback(selectedEvent.feedback);
        }
      }
    }, [selectedEvent])

    const saveFeedback = async () => {

      const interviewNotes = selectedEvent.interviewData.interviewerNotes;

      setShowSavedFeedback(true);

      setTimeout(() => {
          setShowSavedFeedback(false);
      }, 1000)

      try {
        const response = await fetch('https://api.skillpool.tech/get_interview_summary', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                interviewFeedback,
                interviewNotes,
            }),
        });

        const interviewSummary = await response.json();


        const interviewRef = doc(db, "events", selectedEvent.id);
        await updateDoc(interviewRef, {
          feedback: interviewFeedback,
          summary: interviewSummary,
          completed: true,
        });


        // Handling the candidate data update

        try {

          // in this case, first try to identify if this event feedback has already been given and just needs to be updated
          if (selectedEvent.completed) {
            // the candidate already has the feedback for this event.
            const candidateRef = doc(db, "candidates", selectedEvent.candidate.id);
            const candidateData = (await getDoc(candidateRef)).data();
            const candidateInterviews = candidateData.interviews;

            // find the interview that matches the event stage
            const updatedInterviews = candidateInterviews.map(interview => {
                if (interview.stage === selectedEvent.stage) {
                  return {
                    ...interview,
                    feedback: interviewFeedback,
                    summary: interviewSummary,
                  }
                } else {
                  return interview;
                }
            })

            await updateDoc(candidateRef, {
              interviews: updatedInterviews,
            })
          } else {
            // the candidate does not have the feedback for this event yet
            const candidateInterviewData = {
              title: selectedEvent.title,
              summary: interviewSummary,
              feedback: interviewFeedback,
              stage: selectedEvent.stage,
              interviewers: selectedEvent.interviewers,
              startTime: selectedEvent.startTime,
              endTime: selectedEvent.endTime,
            }


            const candidateRef = doc(db, "candidates", selectedEvent.candidate.id);

            await updateDoc(candidateRef, {
              interviews: arrayUnion(candidateInterviewData),
            })

          }

        } catch(error) {
          console.error("Error", error);
        }

      } catch(error) {
        console.error("Error", error);
      }

    }





    return (
        <div className="calendar_holder">

            <div className='calendar_heading'>
                <h1 >Calendar</h1>
                <Link to="settings"><FaIcons.FaCog /></Link>
            </div>

            <div className='calendar_display_area'>

                <div className='calendar_display_left_side'>
                    <div className='navigator'>
                        <DayPilotNavigator
                            selectMode={"Week"}
                            // showMonths={3}
                            // skipMonths={3}

                            onTimeRangeSelected={handleTimeRangeSelected}
                        />
                    </div>

                    <Popup
                    open={eventInfoPopupIsOpen}
                    onOpen={() => setEventInfoPopupOpen(true)}
                    modal
                    nested
                    position="center center"
                    >
                        <div className='popup_holder' style={{zIndex:99999}}>
                            <div className='popup' style={{zIndex:99999}}>
                                <h1 className='popup_heading'>
                                    {
                                        selectedEvent && selectedEvent.title
                                    }
                                </h1>

                                <div className='popup_close' onClick={closeEventInfoPopup}>
                                    <X />
                                </div>

                                <div className='popup_body'>


                                    {/* <div className="event_info_section_switcher subnav">
                                        <div className={`subnav_item ${activeSection === 'event' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('event')}>
                                            <p>Event Details</p>
                                        </div>


                                        <div className={`subnav_item ${activeSection === 'interview' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('interview')}>
                                            <p>Interview Details</p>
                                        </div>
                                    </div> */}



                                    {activeSection === 'event' && (
                                        <div className="event_information_body">
                                            <div className='event_information_body_left event_information_body_section'>
                                                <div className='event_information_candidate event_information_item'>
                                                    <p className='event_information_item_heading'>Candidate: </p>
                                                    <p>{selectedEvent && selectedEvent.candidate.name}</p>
                                                </div>

                                                <div className='event_information_job event_information_item'>
                                                    <p className='event_information_item_heading'>Job: </p>
                                                    <p>{selectedEvent && selectedEvent.job}</p>
                                                </div>

                                                <div className='event_information_time event_information_item'>
                                                    <p className='event_information_item_heading'>Time: </p>
                                                    <p>{selectedEvent && formatDate(selectedEvent.startTime, selectedEvent.endTime)}</p>
                                                </div>


                                                <div className='event_actions'>
                                                    <select className='event_actions_select' value={action} onChange={handleChange}>
                                                        <option value=''>Actions</option>
                                                        <option value='cancel'>Cancel</option>
                                                    </select>

                                                    <Popup
                                                        open={cancelPopupOpen}
                                                        onOpen={() => setCancelPopupOpen(true)}
                                                        modal
                                                        nested
                                                        position="center center"
                                                    >
                                                        <div className='popup_holder'>
                                                            <div className='popup' style={{width: "50%", height:"50%"}}>
                                                                <h1 className='popup_heading'>Cancel the Interview</h1>

                                                                <div className='popup_close' onClick={closeCancelPopup}>
                                                                    <X />
                                                                </div>

                                                                <div className='popup_body'>
                                                                    <p>Please enter a cancellation reason*</p>

                                                                    <textarea className='cancel_reason' placeholder='Enter the reason for cancelling the interview here...' value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} ></textarea>

                                                                </div>

                                                                <div className='cancel_interview'>
                                                                    <div className="popup_action" onClick={cancelInterview}>
                                                                        Cancel
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </Popup>

                                                    {action !== '' && (
                                                        <div className='event_action_button' onClick={(e) => takeAction(action)}>
                                                            Take Action
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='event_information_item'>
                                                    <a href={selectedEvent?.link + '/interviewer'} className='event_link' target='_blank'>
                                                        Join Meeting
                                                    </a>
                                                </div>


                                            </div>

                                            <div className='event_information_body_right event_information_body_section'>
                                                <div className='event_information_interviewers'>
                                                    <p className='event_information_item_heading'>Interviewers: </p>

                                                    <div className='event_interviewers_holder'>
                                                        {selectedEvent && selectedEvent.interviewers.map((interviewer, index) => (
                                                            <div className='event_interviewer' key={index}>
                                                                <p>{interviewer.name}</p>
                                                            </div>

                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {activeSection === 'interview' && (
                                        <div className="event_information_body interview_information">
                                            <div className='event_information_body_section interview_information_section'>
                                                <p>Interview Notes</p>

                                                <div className='interview_notes_area interview_content_area'>
                                                    <p className='interview_notes' style={{textAlign:"left"}}>{selectedEvent.interviewData.interviewerNotes}</p>
                                                </div>
                                            </div>

                                            <div className='event_information_body_section interview_information_section'>
                                                <p>Interview Feedback</p>

                                                <div className='interview_feedback_area interview_content_area'>
                                                    <textarea className='interview_feedback' placeholder='Write the candidate feedback and takeaways here!' value={interviewFeedback} onChange={(e) => setInterviewFeedback(e.target.value)} />
                                                </div>

                                                <div className='interview_feedback_actions_area'>

                                                    <div className="interview_feedback_action">

                                                        {
                                                            showSavedFeedback && <div className="saved_message">
                                                                <FaIcons.FaCheck />
                                                                <p>Saved!</p>
                                                            </div>
                                                        }

                                                        {
                                                            !showSavedFeedback && <div className="save_changes_button" onClick={saveFeedback}>
                                                                <FaIcons.FaSave />
                                                                <p>Save</p>
                                                            </div>
                                                        }

                                                    </div>



                                                </div >
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </Popup>


                </div>


                <div className='calendar_display_right_side'>
                    <DayPilotCalendar {...config} ref={calendarRef} />
                </div>
            </div>

        </div>
    )
}
