import { useEffect, useState } from "react";
import { HiSparkles } from "react-icons/hi2"
import Popup from "reactjs-popup"
import "./styles/findBestFit.css"
import { X } from "lucide-react";
import { auth, db } from "../firebase";
import { Timestamp, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import ToggleButton from "./toggleButton";
import * as FaIcons from "react-icons/fa";
import { ShowCandidateResume } from "./resume";
import { CandidateEmailHistory, Placeholder, placeholders } from "./candidateEmailHistory";
import Tooltip from '@mui/material/Tooltip';
import ReactQuill from "react-quill";
import { AskToApply } from "./candidate_askToApply";
import { sendNotification } from "./notification";

function ScoreVisualizer({ score }) {
    const numberOfLinesToColor = Math.ceil(score / 20);
    let color;

    if (numberOfLinesToColor === 1) {
        color = 'red';
    } else if (numberOfLinesToColor === 2 || numberOfLinesToColor === 3) {
        color = 'orange';
    } else if (numberOfLinesToColor >= 4) {
        color = 'green';
    }

    const lines = [];
    for (let i = 1; i <= 5; i++) {
        lines.push(
            <div
                key={i}
                style={{
                    height: '1rem',
                    width: '0.35rem',
                    backgroundColor: i <= numberOfLinesToColor ? color : 'lightgray',
                    borderRadius: "0.2rem",
                    marginRight: '0.2rem',
                    display: 'inline-block'
                }}
            />
        );
    }

    return (
        <div style={{ display: 'flex'}}>
            {lines}
        </div>
    );
}


export const FindBestFit = ({jobs, candidates}) => {


    const [popupIsOpen, setPopupOpen] = useState(false);
    const [bestFitCandidates, setBestFitCandidates] = useState([]);
    const [selectedJob, setSelectedJob] = useState("");
    const [hideCandidatesInActiveJobs, setHideCandidatesInActiveJobs] = useState(true);
    const [employer, setEmployer] = useState(null);

    useEffect(() => {

        const getEmployer = async () => {
            const employerRef = doc(db, "teams", jobs[0].employer_id);
            const employerSnap = await getDoc(employerRef);
            if (employerSnap.exists()) {
                setEmployer(employerSnap.data());
            }
        }

        if (jobs.length > 0) {
            if (jobs[0].employer_id) {
                getEmployer();
            }
        }
        

    }, [jobs])


    const closePopup = () => {
        setPopupOpen(false);
        setBestFitCandidates([]);
        setHideCandidatesInActiveJobs(true);
        setSelectedJob('');
    }


    const findBestFit = async () => {
        const selectedJobObj = jobs.find(job => job.id === selectedJob);

        if (!selectedJobObj) {
            sendNotification("Please select a job first!")
            return;
        }
        const skillscoreObj = selectedJobObj.pipeline.find(stage => stage.label === "skillscore");

        const technicalSkills = skillscoreObj.technicalSkills.skills.map(skill => skill.name).join(", ");

        const softSkills = skillscoreObj.softSkills.skills.map(skill => skill.name).join(", ");

        const promises = candidates.map(async (candidate) => {
            const response = await fetch('https://api.skillpool.tech/find_best_fit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    // only pass the embedding from each candidate within an object
                    candidate: {embedding: candidate.embedding, education: candidate.education, work: candidate.work},
                    job: selectedJobObj,
                }),
                // Include headers if your server requires them
            });
            return response.json();
        });

        const updatedCandidates = await Promise.all(promises);

        // updated candidates contains the best fit score for each candidate. Add that to the actual candidates array 

        updatedCandidates.forEach((candidate, index) => {
            candidates[index].bestFitScore = candidate.bestFitScore;
        });

        candidates = candidates.filter(candidate => !candidate.hiredTime);
        candidates.sort((a, b) => b.bestFitScore - a.bestFitScore);

        setBestFitCandidates(candidates);
    }

    // handling selection options for candidates in active jobs
    const filteredCandidates = bestFitCandidates
    .filter(candidate => candidate.job_id !== selectedJob)
    .filter(candidate => {
        if (hideCandidatesInActiveJobs) {

            if (candidate.job_id){
                // Find the job that matches the candidate's job_id
                const job = jobs.find(job => job.id === candidate.job_id);
                // Return true if the job is found and job.filled is true, otherwise false
                return job && job.filled;
            }

            // if they're not even in a job
            return true;
            
        } else {
            // If hiding is not enabled, include all candidates
            return true;
        }
    });



    // Handling the "Ask to Apply" action

    


    return (
        <Popup trigger=
        {

            <div className="candidates_best_fit">
                <HiSparkles style={{marginRight: ".5rem"}} />
                Find Best Fit
            </div>
    
        }
    
        open={popupIsOpen}
        onOpen={() => setPopupOpen(true)}
        modal
        nested
        position="center center"
        >
        
        <div className="popup_holder">
            <div className="popup">

                <h1 className="popup_heading"> Find Best Fit </h1>
                <div className="popup_close" onClick={closePopup}>
                    <X />
                </div>


                <p style={{width: "100%", textAlign:"left", paddingLeft:"2rem"}}>Select one of your jobs, and the system will find you the most suitable candidates for this role, from <b>all</b> of the candidates 
                in your database! </p>

                <div className="select_job_holder">
                    <select className="find_best_fit_job_select" 
                        value={selectedJob}
                        onChange={(e) => {
                            setSelectedJob(e.target.value)
                            setBestFitCandidates([])
                        }}
                        >
                        <option value="" disabled selected>Select</option>
                        {jobs.filter(job => job.filled !== true)?.map((job, index) => (
                            <option key={index} value={job.id}>
                                {job.jobTitle}
                            </option>
                        ))}
                    </select>

                    <div className="candidate_search" onClick={findBestFit}>
                        <HiSparkles style={{marginRight: ".5rem"}} />
                        Search
                    </div>
                </div>

                {/* have the switch for candidates who are currently in an active job */}

                <div className="best_fit_selection_option">
                    <ToggleButton isOn={hideCandidatesInActiveJobs} setIsOn={setHideCandidatesInActiveJobs} style = {{marginRight:"2rem"}} />
                    <p>Hide Candidates In Active Jobs</p>
                </div>
                

                <div className="best_fit_candidates_holder">

                    <div className="best_fit_candidates_holder_heading">
                        <div className="best_fit_candidate_score">
                            <b><p>Fit</p></b>
                        </div>

                        <div className="best_fit_candidate_name">
                            <b><p>Name</p></b>
                        </div>

                        <div className="best_fit_candidate_email" style={{fontSize: "1rem"}}>
                            <b><p>Email</p></b>
                        </div>

                        <div className="best_fit_candidate_location" style={{justifyContent: "center"}}>
                            <b><p>Location</p></b>
                        </div>

                        <div className="best_fit_candidate_job_applied">
                            <b><p>Job Applied</p></b>
                        </div>

                        <div className="best_fit_candidate_actions"> 
                            <b><p>Actions</p></b>
                        </div>
                    </div>

                    <div className="best_fit_candidates_holder_body">
                        {filteredCandidates?.map((candidate, index) => (
                            <div key={index} className="best_fit_candidate">

                                <div className="best_fit_candidate_score">
                                    <ScoreVisualizer score={candidate.bestFitScore} />
                                </div>

                                <div className="best_fit_candidate_name">
                                    {candidate.name}
                                </div>

                                <div className="best_fit_candidate_email">

                                    {(candidate.email !== "NA") ? 
                                        <CandidateEmailHistory candidate={candidate} candidateId={candidate.id} emailHistory={candidate?.emailHistory} job={jobs.find(job => job.id === candidate.job_id)} employer={employer} additionalClass="best_fit_candidate_email_history" />
                                        : "Email Not Found" 
                                        }
                                    {/* <div >
                                        {candidate.email}
                                    </div>
                                     */}
                                </div>

                                <div className="best_fit_candidate_location">
                                    {candidate?.location?.label || "—"}
                                </div>

                                <div className="best_fit_candidate_job_applied">
                                    {jobs.find(job => job.id === candidate.job_id)?.jobTitle || "—"}
                                </div>

                                <div className="best_fit_candidate_actions"> 
                                    
                                   
                                    <ShowCandidateResume candidateInc={candidate} /> 

                                    <AskToApply candidate={candidate} selectedJob={selectedJob} employer={employer} jobs={jobs}/>

                                </div>

                            </div>
                        ))}
                    </div>
                    
                </div>

               
                <div className="find_best_fit_disclaimer">
                    <FaIcons.FaInfoCircle style={{marginRight: ".5rem"}} />
                    <p> Find Best Fit is lesser accurate than SkillScore. The candidate fit here is intended just as an indication, not as a score</p>
                </div>
               
            </div>
        </div>
    
        </Popup> 
)}