import { useState } from "react";
import Popup from "reactjs-popup"
import * as FaIcons from "react-icons/fa"
import { X } from "lucide-react";
import { DonutChart } from "../../../donutChart";


export const PersonalityTraitInfo = () => {

    const personality = {
        name: "Determination",
        score: 78, // Placeholder score
        sentences: [
            { sentence: "In my previous role, I set ambitious targets and consistently met them, even when faced with tight deadlines.", score: 90 },
            { sentence: "When our project hit a roadblock, I worked closely with the team to find a creative solution and get back on track.", score: 85 },
            { sentence: "I recall a time when I had to troubleshoot a critical issue; I didn't give up until I found the root cause and fixed it.", score: 80 },
            { sentence: "I firmly believe that dedication and perseverance are key to overcoming any challenge and achieving success.", score: 95 },
            { sentence: "After a project didn't go as planned, I immediately implemented changes to improve future outcomes.", score: 90 },
        ]
    };

    const [popupIsOpen, setPopupOpen] = useState(false);

    // Sort sentences based on the score
    const sortedSentences = personality.sentences.sort((a, b) => b.score - a.score);

    return (
        <Popup
            trigger={
            <div className="skillscore_info_icon">
                <FaIcons.FaInfoCircle />
            </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"

        >
        <div className="skill_popup_holder">
            <div className="skill_popup">

                <div className="skill_popup_header">
                    <div className="skill_popup_header_first">
                        <DonutChart percentage={personality.score} color="darkgreen" multiplier={0.075} fontSize={15} />
                        <h3>Personality Trait Example</h3>
                    </div>


                    <div className="skill_popup_close" onClick={() => setPopupOpen(false)}>
                        <X />
                    </div>
                </div>

                <p style={{marginLeft: "2rem"}}>The candidate displayed {personality.name} in their interview in these sentences: </p>

                <div className="skill_popup_showcases_holder skill_sentences_holder">
                    {sortedSentences.map((sentence, index) => (
                        <div key={index} className="skill_popup_sentence">

                            <p>"{sentence.sentence}"</p>

                        </div>
                    ))}
                </div>

            </div>
        </div>
        </Popup>
    )
}