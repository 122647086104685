import { useState } from "react";
import Popup from "reactjs-popup"
import * as FaIcons from "react-icons/fa"
import { X } from "lucide-react";
import { DonutChart } from "../../../donutChart";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../../shadcn/collapsible";


export const JobInfo = () => {

    const [popupIsOpen, setPopupOpen] = useState(false);
    const [companyDetailOpen, setCompanyDetailOpen] = useState(false)
    const [detailDetailOpen, setDetailDetailOpen] = useState(false);
    const [durationDetailOpem, setDurationDetailOpen] = useState(false);
    const [levelDetailOpen, setLevelDetailOpen] = useState(false);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const evaluateScore = score => score <= 20 ? "Poor" : score <= 40 ? "Low" : score <= 60 ? "Moderate" : score <= 80 ? "Good" : "High";

    const formatDuration = months => `${months >= 12 ? Math.floor(months / 12) + ' years and ' : ''}${months % 12} months`;
    

    const job = {
        title: "Software Engineer",
        score: 70,
        details: {
            score: {
                totalScore: 70,
                breakdown: {
                    impact: 60,
                    innovation: 70,
                    responsibility: 80,
                    technical_complexity: 75,
                    uniqueness: 65
                }
            },
            details: ["Developed a scalable e-commerce platform", "Implemented a machine learning model for customer segmentation", "Collaborated with cross-functional teams for project delivery"]
        },
        company: {
            name: "ACME Corp",
            score: {
                totalScore: 80,
                breakdown: {
                    company_recognition: 85,
                    company_size: 75,
                    funding: 90,
                    growth: 80,
                    industry_relevance: 85
                }
            }
        },
        duration: {
            score: 85,
            duration: {
                duration: 18,
                startMonth: 0, // Assuming January
                startYear: 2020
            }
        },
        jobLevel: {
            score: 80,
            level: "Senior"
        }
    }

    return (
        <Popup
            trigger={
            <div className="skillscore_info_icon" style={{top: "1rem", left:"1rem"}}>
                <FaIcons.FaInfoCircle />
            </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            

        >
            <div className="popup_holder">
                <div className="popup">

                    <div className="score_popup_heading">
                        <DonutChart percentage={job.score} color="darkgreen" multiplier={0.075} fontSize={15}   />
                        <h1 className="popup_heading"> Job Example </h1>

                    </div>

                    <div className="popup_body">
                        <p>The position {job.title} has received a score of {job.score} because:</p>


                        <div className="job_score_details">

                        {/* The factors to display are: Company, Details, Duration, Job Level */}


                            <Collapsible
                                open={detailDetailOpen}
                                onOpenChange={setDetailDetailOpen}
                                className="job_score_detail_collapsible"
                                >

                                <CollapsibleTrigger
                                className="job_score_detail_holder work_detail"
                                >
                                <div className="job_score_detail">


                                    <div className="job_score_detail_first">
                                        <DonutChart percentage={job.details.score.totalScore} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                        <p className="job_score_detail_head">
                                            Work Done
                                        </p>
                                    </div>

                                    <div className='role_arrow_showcase_holder'>
                                    {detailDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                    </div>

                                </div>

                                </CollapsibleTrigger>

                                <CollapsibleContent className="job_score_detail_reason">
                                <div className="job_score_detail_body">

                                    <div className="job_score_information">
                                        <p><strong>Details: </strong></p>
                                        <ul>
                                            {job.details.details.map((detail, index) => (
                                                <li key={index} className="candidate_work_history_job_list_item">{detail}</li>
                                            ))}
                                        </ul>
                                    </div>

                                    <p style={{marginBottom:"1rem"}}><strong>Score Justification: </strong></p>

                                    <div className="job_score_breakdown">

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.impact}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.details.score.breakdown.impact)} Impact</p>
                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.innovation}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.details.score.breakdown.innovation)} Innovation</p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.responsibility}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p> {evaluateScore(job.details.score.breakdown.responsibility)} Responsibility</p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.technical_complexity}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.details.score.breakdown.technical_complexity)} Complexity </p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.details.score.breakdown.uniqueness}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.details.score.breakdown.uniqueness)} Uniqueness</p>

                                        </div>


                                    </div>
                                </div>
                                </CollapsibleContent>
                            </Collapsible>

                            <Collapsible
                                open={companyDetailOpen}
                                onOpenChange={setCompanyDetailOpen}
                                className="job_score_detail_collapsible"
                                >

                                <CollapsibleTrigger
                                className="job_score_detail_holder company_detail"
                                >
                                <div className="job_score_detail">


                                    <div className="job_score_detail_first">
                                        <DonutChart percentage={job.company.score.totalScore} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                        <p className="job_score_detail_head">
                                            Company Level
                                        </p>
                                    </div>

                                    <div className='role_arrow_showcase_holder'>
                                    {companyDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                    </div>

                                </div>

                                </CollapsibleTrigger>

                                <CollapsibleContent>
                                <div className="job_score_detail_body">

                                    <div className="job_score_information">
                                    <p> <strong>Company: </strong> {job.company.name}</p>
                                    </div>

                                    <p style={{marginBottom:"1rem"}}><strong>Score Justification: </strong></p>

                                    <div className="job_score_breakdown">

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.company_recognition}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.company.score.breakdown.company_recognition)} Recognition</p>
                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.company_size}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.company.score.breakdown.company_size)} Size</p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.funding}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p> {evaluateScore(job.company.score.breakdown.funding)} Funding</p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.growth}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.company.score.breakdown.growth)} Growth </p>

                                        </div>

                                        <div className="job_score_breakdown_item">
                                            <DonutChart percentage={job.company.score.breakdown.industry_relevance}color="darkgreen" multiplier={0.035} fontSize={10}   />
                                            <p>{evaluateScore(job.company.score.breakdown.industry_relevance)} Relevance</p>

                                        </div>


                                    </div>
                                </div>
                                </CollapsibleContent>
                            </Collapsible>



                            <Collapsible
                                open={durationDetailOpem}
                                onOpenChange={setDurationDetailOpen}
                                className="job_score_detail_collapsible"
                                >

                                <CollapsibleTrigger
                                className="job_score_detail_holder"
                                >
                                <div className="job_score_detail">


                                    <div className="job_score_detail_first">
                                        <DonutChart percentage={job.duration.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                        <p className="job_score_detail_head">
                                            Duration
                                        </p>
                                    </div>

                                    <div className='role_arrow_showcase_holder'>
                                    {durationDetailOpem ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                    </div>

                                </div>

                                </CollapsibleTrigger>

                                <CollapsibleContent>
                                <div className="job_score_detail_body">
                                    <div className="job_score_information">
                                        <p><strong>Duration:</strong> {formatDuration(job.duration.duration.duration)}</p>
                                    </div>
                                </div>
                                </CollapsibleContent>
                            </Collapsible>


                            <Collapsible
                                open={levelDetailOpen}
                                onOpenChange={setLevelDetailOpen}
                                className="job_score_detail_collapsible"
                                >

                                <CollapsibleTrigger
                                className="job_score_detail_holder"
                                >
                                <div className="job_score_detail">


                                    <div className="job_score_detail_first">
                                        <DonutChart percentage={job.jobLevel.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                        <p className="job_score_detail_head">
                                            Job Level
                                        </p>
                                    </div>

                                    <div className='role_arrow_showcase_holder'>
                                    {levelDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                    </div>

                                </div>

                                </CollapsibleTrigger>

                                <CollapsibleContent>
                                <div className="job_score_detail_body">
                                    <div className="job_score_information">
                                        <p><strong>Job Level:</strong> {job.jobLevel.level}</p>
                                    </div>

                                    {/* <div className="job_score_breakdown">
                                        <p></p>
                                    </div> */}
                                </div>
                                </CollapsibleContent>
                            </Collapsible>

                        </div>

                    </div>



                    <div className="popup_close" onClick={() => setPopupOpen(false)}> <X /> </div>
                </div>
            </div>
        </Popup>
    )
}