"use client"

import {React, useState, useRef} from "react"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./shadcn/collapsible";
import "../pages/styles/candidate_details.css"
import { DonutChart } from "./donutChart";
import * as FaIcons from "react-icons/fa"
import { sendNotification } from "./notification";



export const CollapsibleRoot = ({children}) => {
    return (
        <div style={{display: "flex", justifyContent:"flex-end"}}>
            <div className="collapsible_root" style={{width:"95%"}}>
                {children}
            </div>  
        </div>
    )
}

export const CollapsibleDetail = ({ children, title, percentage, sublevel = 0, isRoot = false }) => {

    const [isOpen, setIsOpen] = useState(false)
    
    const checkToOpen = () => {
        if (percentage !== "NA") {
            // if (!isRoot) {
                setIsOpen(!isOpen);
            // } else {
                // sendNotification();
            // }
        } else {
            sendNotification();
        }
    }

    return (
    <div style={{
        display:"flex",
        justifyContent:"flex-end",
        width:"100%"

    }}>
    <Collapsible
        open={isOpen}
        onOpenChange={checkToOpen}
        style={{width: (100 - 5*sublevel).toString() + "%"}}>
        <CollapsibleTrigger 
            className="individual_detail_holder" 
        >
            <div className="individual_detail">
                <h3>
                    {title}
                </h3>
                <div className="collapsible_detail_right" >
                    {percentage !== 'NA' ? (
                        <DonutChart percentage={percentage} color="darkgreen" multiplier={0.05} fontSize={14} />
                    ) : (
                        <FaIcons.FaLock fontSize={14} style={{marginRight:"1.5rem"}}/>
                    )}
                    
                    {isOpen ?  <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}
                </div>
               
            </div>
        </CollapsibleTrigger>

        <CollapsibleContent>
            {children}
        </CollapsibleContent>
    </Collapsible>
    </div>
  )
};