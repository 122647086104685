"use client"

import {useState, useRef, useEffect} from "react"
import { Collapsible, CollapsibleContent, CollapsibleTrigger, } from "../../components/shadcn/collapsible";
import "../styles/candidate_details.css"
import { CollapsibleDetail, CollapsibleRoot } from "../../components/collapsible_detail";
import * as FaIcons from "react-icons/fa";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";


export var candidateHere = null;
export const updateCandidate = (newCandidate) => {
    candidateHere = newCandidate;
}

export const Details = () => {

    const [candidate, setCandidate] = useState(null);

    var candidateId = window.location.href.split('/')[6]


    useEffect(() => {

        const getCandidate = async () => {
            try {
                const docRef = doc(db, 'candidates', candidateId); // Create a reference to the candidate document
                const docSnap = await getDoc(docRef); // Get the document snapshot
    
                if (docSnap.exists()) {
                    setCandidate(docSnap.data()); // Set the candidate state with the data from Firestore
                }
            } catch (error) {
                console.error("Error fetching candidates:", error);
            }
        };

        if (candidateId) {
            getCandidate();
        }

    }, []);

    
    function getRankingRange(rank) {
        if (rank >= 1 && rank <= 20) {
            return 'Top 20';
        } else if (rank <= 50) {
            return 'Top 50';
        } else if (rank <= 100) {
            return 'Top 100';
        } else if (rank <= 250) {
            return 'Top 250';
        } else if (rank <= 500) {
            return 'Top 500';
        } else if (rank <= 1000) {
            return 'Top 1000';
        } else {
            return 'Top 1000+';
        }
    }

    return (
        <div className="extra_details_holder">
        
        {candidate?.total_score && (
            <Collapsible className="all_details_collapsible_holder">
                <CollapsibleDetail title = "Total Score" percentage={candidate?.total_score?.score} sublevel={0}> 
                    {/* <CollapsibleDetail title = "Capability" percentage={candidate?.total_score?.capability.score} sublevel={1}> */}

                        <CollapsibleDetail title = "Skills" percentage={candidate?.total_score.capability.skills.score} sublevel={1}>

                            {candidate?.total_score.capability.skills.skills.map((skill, index) => (
                                
                                <CollapsibleDetail title = {skill.name} percentage={skill.score} sublevel={1} isRoot="true">
                                    
                                    <CollapsibleRoot>
                                        {candidate?.personal_info.name} demonstrates proficiency in {skill.name} through the following experiences:

                                        <table>
                                            <tr>
                                                <td>
                                                <div>
                                                    <h3>Education:</h3>
                                                    {candidate?.education.skills.map((education_skill) => (
                                                        education_skill.name === skill.name && education_skill.usage === 1 ? (
                                                        <>
                                                        <p key={education_skill.name}> {candidate?.education.major} </p>
                                                        <p key={education_skill.name}> {candidate?.education.university} </p>
                                                        </>
                                                        ) : null
                                                    ))}
                                                </div>
                                                </td>

                                                <td>
                                                    <div>
                                                        <h3>Projects:</h3>
                                                        {candidate?.projects.flatMap((project) =>
                                                        project.skills.map((project_skill) =>
                                                        project_skill.name === skill.name && project_skill.usage === 1 ? (
                                                            <p key={project_skill.name}> {project.name} </p> // Ensure unique key
                                                            ) : null
                                                        )
                                                        ).filter(Boolean)}
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>
                                                    <div>
                                                        <h3>
                                                            Jobs
                                                        </h3>
                                                        {candidate?.jobs.flatMap((job) =>
                                                        job.skills.map((job_skill) =>
                                                            job_skill.name === skill.name && job_skill.usage === 1 ? (
                                                            <p key={job_skill.name + job.company}> {job.company} </p> // Ensure unique key
                                                            ) : null
                                                        )
                                                        ).filter(Boolean)}
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>       

                                    </CollapsibleRoot>

                                </CollapsibleDetail>
                            ))}

                            
                            

                        </CollapsibleDetail>

                        <CollapsibleDetail title = "Experience" percentage={candidate?.total_score.capability.experience.score} sublevel={1}>
                            
                            <CollapsibleDetail title = "Years of Experience" percentage={candidate?.total_score.capability.experience.yoe_score} sublevel={1} isRoot="true">
                                <CollapsibleRoot>
                                {candidate?.personal_info.name} has {Math.floor(parseFloat(candidate?.total_score.capability.experience.yoe))} years and {Math.round((parseFloat(candidate?.total_score.capability.experience.yoe) % 1) * 12)} months of experience. Here's the full breakdown:

                                    {candidate?.jobs
                                    .sort((a, b) => {
                                        if (a.startYear === b.startYear) {
                                        // Assuming startMonth is a number or can be directly compared
                                        return a.startMonth - b.startMonth;
                                        }
                                        return a.startYear - b.startYear;
                                    })
                                    .map((job, index) => {
                                        const months_list = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                                        const durationMonths = (parseInt(job.endYear) - parseInt(job.startYear)) * 12 + (months_list.indexOf(job.endMonth) - months_list.indexOf(job.startMonth));
                                        const durationYears = Math.floor(durationMonths / 12);
                                        const remainingMonths = durationMonths % 12;
                                        return (
                                        <div key={index}>
                                            <h3>{job.title}</h3>
                                            <p>Start: {job.startMonth} {job.startYear}</p>
                                            <p>End: {job.endMonth} {job.endYear}</p>
                                            <p>Duration: {durationYears > 0 ? `${durationYears} years` : ''} {remainingMonths > 0 ? `${remainingMonths} months` : ''}</p>
                                        </div>
                                        );
                                    })
                                }
                                </CollapsibleRoot>
                            </CollapsibleDetail>

                            <CollapsibleDetail title = "Jobs" percentage={candidate?.total_score.capability.experience.jobs.score} sublevel={1}>

                                <CollapsibleDetail title = "No. of Jobs" percentage={candidate?.total_score.capability.experience.jobs.number_jobs} sublevel={1} isRoot="true">
                                    <CollapsibleRoot>
                                    {candidate?.personal_info.name} has held {candidate?.jobs.length} jobs, for which they have received a score of {candidate?.total_score.capability.experience.jobs.number_jobs}. Here's some more info:

                                    {candidate?.jobs
                                    .map((job, index) => {
                                        return (
                                        <div key={index}>
                                            <p><span style={{fontWeight:600}}>{index + 1})</span> {job.title}</p>
                                        </div>
                                        );
                                    })}
                                    </CollapsibleRoot>
                                </CollapsibleDetail>

                                <CollapsibleDetail title = "Job Score" percentage={candidate?.total_score.capability.experience.jobs.average_job_score} sublevel={1}>
                                    {candidate?.total_score.capability.experience.jobs.jobs.map((job, index) => (

                                        <CollapsibleDetail title = {candidate?.jobs ? candidate?.jobs[index].title : ""} percentage={job.score} sublevel={1}>


                                            <CollapsibleDetail title = "Position Duration" percentage={job.position_duration} sublevel={1} isRoot="true">
                                                <CollapsibleRoot>
                                                {(() => {
                                                    const actualJob = candidate?.jobs[index];
                                                    const months_list = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                                                    const durationMonths = (parseInt(actualJob.endYear) - parseInt(actualJob.startYear)) * 12 + (months_list.indexOf(actualJob.endMonth) + 1) - (months_list.indexOf(actualJob.startMonth) + 1);
                                                    const durationYears = Math.floor(durationMonths / 12);
                                                    const remainingMonths = durationMonths % 12;
                                                    return (
                                                        <p>{candidate?.personal_info.name} remained in this position for <span style={{fontWeight:600}}> {durationYears > 0 ? `${durationYears} years` : ''} {remainingMonths > 0 ? `${remainingMonths} months` : ''}. </span></p>
                                                    );
                                                })()}
                                                </CollapsibleRoot>
                                            </CollapsibleDetail>

                                            <CollapsibleDetail title = "Position Level" percentage={job.position_level} sublevel={1} isRoot="true">
                                                <CollapsibleRoot>
                                                {(() => {
                                                    const actualJob = candidate?.jobs[index];
                                        
                                                    return (
                                                        <p>This position was identified to be an <span style={{fontWeight:600}}> {actualJob.jobLevel} </span>position.</p>
                                                    );
                                                })()}
                                                </CollapsibleRoot>
                                            </CollapsibleDetail>


                                            <CollapsibleDetail title = "Achievements" percentage={job.achievements} sublevel={1} isRoot="true">
                                                <CollapsibleRoot>
                                                    Here's the breakdown of {candidate?.personal_info.name}'s accomplishments at this position:
                                                    <ul>
                                                    {candidate?.jobs[index].details.map((detail) => (
                                                        <li> {detail} </li>
                                                    ))}  
                                                    </ul>    
                                                </CollapsibleRoot>
                                            </CollapsibleDetail>

                                        </CollapsibleDetail>
                                    ))}
                                    
                                    

                                    
                                    
                                </CollapsibleDetail>

                            </CollapsibleDetail>

                            <CollapsibleDetail title = "Projects" percentage={candidate?.total_score.capability.experience.projects.score} sublevel={1}>
                                
                                <CollapsibleDetail title = "No. of Projects" percentage={candidate?.total_score.capability.experience.projects.number_projects} sublevel={1} isRoot="true">
                                    <CollapsibleRoot>
                                    {candidate?.personal_info.name} has made {candidate?.projects.length} projects, for which they have received a score of {candidate?.total_score.capability.experience.projects.number_projects}. Here's the projects themselves:
                                        
                                    {candidate?.projects
                                    .map((project, index) => {
                                        return (
                                        <div key={index}>
                                            <p><span style={{fontWeight:600}}>{index + 1})</span> {project.name}</p>
                                        </div>
                                        );
                                    })}
                                    </CollapsibleRoot>
                                </CollapsibleDetail>

                                <CollapsibleDetail title = "Project Score" percentage={candidate?.total_score.capability.experience.projects.average_project_score} sublevel={1}>
                                    
                                    {candidate?.total_score.capability.experience.projects.projects.map((project, index) => (
                                        <CollapsibleDetail title = {candidate?.projects ? candidate?.projects[index].name : ""} percentage={project} sublevel={1} isRoot="true">

                                            <CollapsibleRoot>
                                                Here's the breakdown of {candidate?.personal_info.name}'s accomplishments for this project:
                                                    <ul>
                                                    {candidate?.projects[index].details.map((detail) => (
                                                        <li> {detail} </li>
                                                    ))}  
                                                    </ul>    
                                            </CollapsibleRoot>

                                        </CollapsibleDetail>
                                    ))}
                                    

                                </CollapsibleDetail>

                            </CollapsibleDetail>

                        </CollapsibleDetail>

                        <CollapsibleDetail title = "Education" percentage={candidate?.total_score.capability.education.score} sublevel={1}>
                            
                            <CollapsibleDetail title = "Major" percentage={candidate?.total_score.capability.education.major} sublevel={1} isRoot="true">

                                <CollapsibleRoot>
                                    <p> {candidate?.personal_info.name} studied <span style={{fontWeight: 600}}> {candidate?.education.major}</span>, which is <span style={{fontWeight: 600}} >{candidate?.total_score.capability.education.major === 100 ? "relevant" : "not relevant"}</span> to this job. </p>
                                </CollapsibleRoot>

                            </CollapsibleDetail>

                            <CollapsibleDetail title = "GPA" percentage={candidate?.total_score.capability.education.gpa} sublevel={1} isRoot="true">
                                
                                <CollapsibleRoot>
                                <p> {candidate?.personal_info.name}'s GPA was <span style={{fontWeight: 600}}> {candidate?.education.gpa}</span>, earning them a score of <span style={{fontWeight: 600}} >{candidate?.total_score.capability.education.gpa}. </span> </p>
                                </CollapsibleRoot>

                            </CollapsibleDetail>


                            <CollapsibleDetail title = "College Standing" percentage={candidate?.total_score.capability.education.college_level} sublevel={1} isRoot="true">
                                
                                <CollapsibleRoot>


                                    <p> {candidate?.personal_info.name} attended the <span style={{fontWeight: 600}}> {candidate?.education.university}</span>, 
                                    which is ranked among the <span style={{fontWeight: 600}} >{getRankingRange(candidate?.total_score.capability.education.college_ranking)} </span> 
                                    universities in the world. </p>
                                      

                                </CollapsibleRoot>

                            </CollapsibleDetail>

                        </CollapsibleDetail>

                    </CollapsibleDetail>

                    {/* <CollapsibleDetail title = "Fit" percentage={candidate?.total_score.fit.score} sublevel={1}>

                        <CollapsibleDetail title= "Confidence" percentage={candidate?.total_score.fit.confidence.score} sublevel={1}>
                                
                            <CollapsibleDetail title = "Eye Contact" percentage={candidate?.total_score.fit.confidence.eye_contact} sublevel={1}>
                                
                                <CollapsibleRoot>
                                    [Reverse Sigmoid function over the number of times eye contact looked away]
                                </CollapsibleRoot>

                            </CollapsibleDetail>

                            <CollapsibleDetail title = "Speech Confidence" percentage={candidate?.total_score.fit.confidence.speech} sublevel={1}>
                                
                                <CollapsibleRoot>
                                    [Reverse Sigmoid function over the number of times candidate said uhh or umm]
                                </CollapsibleRoot>

                            </CollapsibleDetail>

                        </CollapsibleDetail>

                        <CollapsibleDetail title= "Personality Fit" percentage={candidate?.total_score.fit.personality_type.score} sublevel={1}>
                            
                            <CollapsibleRoot>
                                {candidate?.total_score.fit.personality_type.message}
                            </CollapsibleRoot>   

                        </CollapsibleDetail>

                        <CollapsibleDetail title= "Interest" percentage={candidate?.total_score.fit.interest.score} sublevel={1}>

                            <CollapsibleDetail title= "Interest in Field" percentage={candidate?.total_score.fit.interest.field_interest} sublevel={1}>
                                [Figure out details regarding job interest here]
                            </CollapsibleDetail>

                            <CollapsibleDetail title= "Interest in Company" percentage={candidate?.total_score.fit.interest.company_interest} sublevel={1}>
                                [Figure out details regarding job interest here]
                            </CollapsibleDetail>

                        </CollapsibleDetail>

                    </CollapsibleDetail>

                
                    <Collapsible>

                        <CollapsibleContent>

                            <Collapsible>
                                <CollapsibleTrigger>Confidence</CollapsibleTrigger>
                                <CollapsibleContent>
                                        [This is confidence]
                                </CollapsibleContent>
                            </Collapsible>

                            <Collapsible>
                                <CollapsibleTrigger>Interest</CollapsibleTrigger>
                                <CollapsibleContent>
                                        [This is interest]
                                </CollapsibleContent>
                            </Collapsible>

                            <Collapsible>
                                <CollapsibleTrigger>Personality Fit</CollapsibleTrigger>
                                <CollapsibleContent>
                                        [This should include the way they work, the way they respond in pressure situations]
                                </CollapsibleContent>
                            </Collapsible>

                        </CollapsibleContent>
                </Collapsible>

                </CollapsibleDetail> */}
                {/* <CollapsibleTrigger className="individual_detail_holder" >
                    <div className="individual_detail">
                        <h3 className="detail_lmao_haha">
                        Total Score
                        </h3>
                        <DonutChart percentage={80} color="darkgreen" multiplier={0.05} fontSize={14} />
                    </div>
                </CollapsibleTrigger> */}
            </Collapsible>
        )}
        </div>

  )
};