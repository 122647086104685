import { useState } from "react"
import Popup from "reactjs-popup";
import * as FaIcons from "react-icons/fa"
import { X } from "lucide-react";
import ToggleButton from "../../../components/toggleButton";



export const CandidateEligibilityGeneral = ({workAuthorizationTrue, setWorkAuthorizationTrue, sponsorshipNeeded, setSponsorshipNeeded}) => {

    const [popupIsOpen, setPopupOpen] = useState(false);
    
    return (

        <Popup
            trigger={
                <div className="add_candidates_section_detail" style={{marginTop:"2rem"}}>
                    <p className="add_candidates_section_detail_name">Eligibility</p>

                    <p className="add_candidates_section_detail_desc">Click to change eligibility</p>
                    <FaIcons.FaExpandAlt className="add_candidates_section_detail_icon"/>
                </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup">

                    <h1 className="popup_heading">Candidate Eligibility</h1>
                    
                    <div className="popup_close" onClick={(e) => setPopupOpen(false)}>
                        <X />
                    </div>

                    <div className="popup_body">
                        <p> These details are typically entered directly by the candidates to pass through the pre-screening requirements. </p>

                        <p style={{marginTop:"0.5rem", marginBottom:"2rem"}}> If you haven't set any pre-screening requirements, or if you want these candidates to pass them anyway, feel free to leave these values unchanged! </p>


                        <div className="add_candidate_detail_workAuthorization add_candidate_detail">

                            <div>
                                <p className="candidate_detail_name">Work Authorization</p>
                                <p className="candidate_detail_desc">Is the candidate(s) authorized to work in the country for this job?</p>
                            </div>

                            <ToggleButton isOn={workAuthorizationTrue} setIsOn={setWorkAuthorizationTrue} />

                        </div>

                       

                        <div className="add_candidate_detail_sponsorshipNeeded add_candidate_detail">

                            <div>
                                <p className="candidate_detail_name">Sponsorship Needed</p>
                                <p className="candidate_detail_desc">Will the candidate(s) require sponsorship to work in this country?</p>
                            </div>

                            <ToggleButton isOn={sponsorshipNeeded} setIsOn={setSponsorshipNeeded} />
                        </div>

                    </div>
                </div>  
            </div>
        </Popup>
    )
}