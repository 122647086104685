import { useState } from "react";
import Popup from "reactjs-popup"
import * as FaIcons from "react-icons/fa"
import { X } from "lucide-react";
import { DonutChart } from "../../../donutChart";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../../shadcn/collapsible";


export const EducationInfo = () => {

    const [popupIsOpen, setPopupOpen] = useState(false);
    const [gpaDetailOpen, setGpaDetailOpen] = useState(false);
    const [universityDetailOpen, setUniversityDetailOpen] = useState(false);

    const getUniversityRanking = rank =>
        rank <= 5 ? "Top 5" :
        rank <= 20 ? "Top 20" :
        rank <= 50 ? "Top 50" :
        rank <= 100 ? "Top 100" :
        rank <= 200 ? "Top 200" :
        rank <= 500 ? "Top 500" :
        rank <= 1000 ? "Top 1000" :
        "Top 1000+";

    const candidate = {
        education: {
            highestLevel: "Bachelors",
            major: "Computer Science",
            startYear: 2019,
            graduationYear: 2023,
            score: 91,
            gpa: {
                score: 85,
                value: 3.4,
            },
            university: {
                name: "Stanford University",
                ranking: "1",
                score: 100
            }
        }
    }

    return (
        <Popup
            trigger={
            <div className="skillscore_info_icon">
                <FaIcons.FaInfoCircle />
            </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            

        >
            <div className="popup_holder">
                <div className="popup">
                    <div className="score_popup_heading">
                        <DonutChart percentage={candidate?.education.score} color="darkgreen" multiplier={0.075} fontSize={15}   />
                        <h1 className="popup_heading"> Education Example </h1>

                    </div>


                    <div className="popup_close" onClick={() => setPopupOpen(false)}> <X /> </div>


                    <div className="popup_body">
                        <div className="education_information">

                            <p><strong>Details: </strong></p>

                            <div className="education_information_item">
                                <div className="education_information_item_first">
                                    <p style={{fontWeight:500, margin: 0}}>Highest Education Level: </p>
                                </div>

                                {candidate?.education.highestLevel}

                            </div>

                            <div className="education_information_item">
                                <div className="education_information_item_first">
                                    <p style={{fontWeight:500, margin: 0}}>Field of Study: </p>
                                </div>

                                {candidate?.education.major}
                            </div>

                            <div className="education_information_item">
                                <div className="education_information_item_first">
                                    <p style={{fontWeight:500, margin: 0}}>Time: </p>
                                </div>

                                <p style={{margin: 0}}>{candidate?.education.startYear} - {candidate?.education.graduationYear} </p>
                            </div>


                            <div className="education_information_item">
                                <div className="education_information_item_first">
                                    <p style={{fontWeight:500, margin: 0}}>GPA: </p>
                                </div>

                                <p style={{margin: 0}}>{candidate?.education.gpa.value} </p>
                            </div>

                            <div className="education_information_item">
                                <div className="education_information_item_first">
                                    <p style={{fontWeight:500, margin: 0}}>University: </p>
                                </div>

                                <p style={{margin: 0}}>{candidate?.education.university.name} </p>
                            </div>

                        </div>


                        <div className="education_score_breakdown">

                            <p><strong>Score Breakdown: </strong></p>

                            <div className="education_score_collapsibles_holder">


                                <Collapsible
                                    open={gpaDetailOpen}
                                    onOpenChange={setGpaDetailOpen}
                                    className="job_score_detail_collapsible"
                                    >

                                    <CollapsibleTrigger
                                    className="job_score_detail_holder"
                                    >
                                    <div className="job_score_detail education_score_detail">


                                        <div className="job_score_detail_first">
                                            <DonutChart percentage={candidate?.education.gpa.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                            <p className="job_score_detail_head">
                                                GPA
                                            </p>
                                        </div>

                                        <div className='role_arrow_showcase_holder'>
                                        {gpaDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                        </div>

                                    </div>

                                    </CollapsibleTrigger>

                                    <CollapsibleContent>
                                    <div className="job_score_detail_body">
                                        <div className="job_score_information">
                                            <p>A GPA of {candidate?.education.gpa.value} receives a score of {candidate?.education.gpa.score}</p>
                                        </div>
                                    </div>
                                    </CollapsibleContent>
                                </Collapsible>

                                <Collapsible
                                    open={universityDetailOpen}
                                    onOpenChange={setUniversityDetailOpen}
                                    className="job_score_detail_collapsible"
                                    >

                                    <CollapsibleTrigger
                                    className="job_score_detail_holder"
                                    >
                                    <div className="job_score_detail education_score_detail">


                                        <div className="job_score_detail_first">
                                            <DonutChart percentage={candidate?.education.university.score} color="darkgreen" multiplier={0.05} fontSize={12}   />
                                            <p className="job_score_detail_head">
                                                University
                                            </p>
                                        </div>

                                        <div className='role_arrow_showcase_holder'>
                                        {gpaDetailOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}

                                        </div>

                                    </div>

                                    </CollapsibleTrigger>

                                    <CollapsibleContent>
                                    <div className="job_score_detail_body">
                                        <div className="job_score_information">
                                            <p><strong>University: </strong> {candidate?.education.university.name}</p>
                                        </div>

                                        <div>
                                            <p>This university is ranked in the {getUniversityRanking(candidate?.education.university.ranking)} universities,
                                            and gets a score of {candidate?.education.university.score}.</p>
                                        </div>
                                    </div>
                                    </CollapsibleContent>
                                </Collapsible>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Popup>
    )
}