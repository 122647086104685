import React from 'react';
import { Pie } from 'react-chartjs-2';
import { brandColors, chartOptions } from '../../pages/job_subPages/job_dashboard';

export const ApplicationSourceChart = ({ candidates }) => {
  const sourceCounts = candidates.reduce((acc, candidate) => {
    let source;
    if (candidate.source && candidate.source.name) {
      source = candidate.source.name;
    } else if (candidate.uploaded) {
      source = 'Uploaded';
    } else {
      source = 'Applied';
    }
    acc[source] = (acc[source] || 0) + 1;
    return acc;
  }, {});

  const labels = Object.keys(sourceCounts);
  const data = Object.values(sourceCounts);

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: labels.map((_, index) => 
          index % 2 === 0 ? brandColors.primary40 : brandColors.secondary
        ),
        borderColor: brandColors.primary,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    ...chartOptions,
    scales: {},
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
    },
  };

  return (
    <div className='company_report_chart_holder application_source_chart'>
      <p className='company_report_chart_heading'>Application Source </p>

      <div className='company_report_chart' style={{height: "75%"}}>
        <Pie data={chartData} options={options} />
      </div>
      
    </div>
  );
};
