import * as FaIcons from "react-icons/fa"
import "./styles/automation.css"
import { X } from "lucide-react";

export const sendAutomation = (message, action = null, timeout = 2000) => {
    if (canCall) {
        canCall = false;
        const notif = document.getElementById('automation');
        const notif_message = document.getElementById('automation_message_description');
        notif_message.innerHTML = message;
        notif.style.display = "flex";
        notif.style.animation = "flyInAutomation 0.5s ease";
        setTimeout(() => {
            notif.style.animation = "flyOutAutomation 0.5s ease";

            setTimeout(() => {
                notif.style.display = "none";
                canCall = true;
                if (action && !actionCancelled) {
                    action();
                }
            }, 500);

        }, timeout);
    }
}

var canCall = true;
var actionCancelled = false;

export const Automation = () => {
    const conductAction = () => {
        console.log("Conducting action");
    }

    const undoAction = () => {
        console.log("Undoing action");
        const notif = document.getElementById('automation');
        notif.style.display = "none";
        canCall = true;
        actionCancelled = true;
    }

    const closeAutomation = () => {
        const notif = document.getElementById('automation');
        notif.style.animation = "flyOut 0.5s ease";

        setTimeout(() => {
            notif.style.display = "none";
            canCall = true;
        }, 500);
    }

    return (
        <div className="automation" id="automation">
            <div>
                <FaIcons.FaCog style={{color:"var(--primary)", fontSize:"1.5rem"}} />
            </div>

            <div id="automation_message" className="automation_message">
                <div className="automation_message_title">
                    Automation!
                </div>
                <div className="automation_message_description" id="automation_message_description">
                    Sent the scheduling email!
                </div>

                <div className="automation_message_action">
                    <span className="automation_message_action_text" onClick={undoAction}>Undo</span>
                </div>
            </div>

            <div className="automation_close" onClick={closeAutomation}>
                <X className="automation_close_icon"/>
            </div>
        </div>
    )
}