import { X } from "lucide-react"
import React, { useEffect, useState } from 'react';
import "./styles/createQuestion.css"
import * as FaIcons from "react-icons/fa";
import Popup from "reactjs-popup";
import NumberSlider from "../../slider";
import { PrettyCheckbox } from "../../prettyCheckbox";


export const CreateQuestion = ({ questions, setQuestions, setPopupOpen }) => {
    const [questionType, setQuestionType] = useState('');
    const [questionText, setQuestionText] = useState('');
    const [questionRequired, setQuestionRequired] = useState(false);
    const [options, setOptions] = useState([]);
    const [rangeDescription, setRangeDescription] = useState({ min: '', max: '' });

    const addQuestion = () => {
        // Implementation to handle adding a question
        const newQuestion = {
            questionType,
            questionText,
            questionRequired,
            options,
            rangeDescription,
        };

        setQuestions([...questions, newQuestion])

        setPopupOpen(false);
    };

    const handleOptionChange = (option, index) => {
        const newOptions = [...options];
        newOptions[index] = option;
        setOptions(newOptions);
    };

    const changeType = (e) => {
        setOptions([]);
        setRangeDescription({min: '', max: '' });
        setQuestionType(e.target.value);
        
    }


    // handling editing of options

    const [optionsPopupOpen, setOptionsPopupOpen] = useState(false);

    const [newOption, setNewOption] = useState('');

    const addOption = () => {
        if (newOption.trim() !== '') {
            setOptions([...options, newOption.trim()]);
            setNewOption("");
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addOption();
        }
    }

    //  handling the preview

    const [sliderValue, setSliderValue] = useState(0);
    const [selectedOption, setSelectedOption] = useState('Yes');

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    }

    return (
        <div className="popup_holder">
            <div className="popup" style={{ width: "50%", height: "75%" }}>

                <h2 className="popup_heading">Create Question</h2>
                <div className="popup_body">

                    <div className="new_question_info">

                        <div className="new_question_section">
                            <h3 className="new_question_section_head">Question:</h3>
                            <input
                                type="text"
                                className="new_question_input"
                                value={questionText}
                                onChange={(e) => setQuestionText(e.target.value)}
                                placeholder="Enter your question"
                            />
                        </div>

                        <div className="new_question_section">
                            <h3 className="new_question_section_head">Type:</h3>
                            <select value={questionType} onChange={changeType} className="new_question_select">
                                <option value="" selected disabled>Pick One</option>
                                <option value="Range">Range</option>
                                <option value="Select">Select</option>
                                <option value="Single Line">Single Line</option>
                                <option value="Yes/No">Yes/No</option>
                                <option value="Score">Score</option>
                                <option value="Checkbox">Checkbox</option>
                                <option value="Long Answer">Long Answer</option>
                            </select>
                        </div>

                        {((questionType === 'Select') || (questionType === 'Checkbox')) && (
                            <div className="new_question_section">
                                <h3 className="new_question_section_head">Options:</h3>


                                <Popup
                                    trigger={
                                        <div className="edit_options_trigger">
                                            Edit Options
                                            <FaIcons.FaExpandAlt style={{marginLeft:"0.5rem"}} />
                                        </div>
                                    }
                                    open={optionsPopupOpen}
                                    onOpen={() => setOptionsPopupOpen(true)}
                                    modal
                                    nested
                                    position="center center"
                                >

                                    <div className="popup_holder second">
                                        <div className="popup" style={{ width: "40%", height: "50%" }}>
                                            <h2 className="popup_heading">Edit Options</h2>

                                            <div className="popup_close" onClick={() => setOptionsPopupOpen(false)}>
                                                <X  />
                                            </div>

                                            <div className="edit_options_heading">
                                                <input 
                                                    className="add_option_input" 
                                                    value={newOption} 
                                                    onChange={(e) => setNewOption(e.target.value)}
                                                    onKeyDown={(e) => e.key === 'Enter' && handleKeyPress(e)}
                                                    placeholder="Enter Option"
                                                />

                                                <div className="add_option" onClick={addOption}>
                                                    <FaIcons.FaPlus style={{marginRight:"0.5rem"}} />
                                                    Add
                                                </div>
                                            </div>

                                            <div className="edit_options_list">
                                                {options.map((option, index) => (
                                                    <div className="edit_option" key={index}>
                                                        <p>{option}</p>
                                                        <FaIcons.FaTrash
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => setOptions(options.filter((_, i) => i !== index))}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                </Popup>

                            </div>
                        )}

                        {questionType === 'Range' && (
                            <div className="new_question_section">
                                <h3 className="new_question_section_head">Range:</h3>
                                <input
                                    type="text"
                                    className="new_question_input"
                                    style={{marginRight: "1rem", width:"35%"}}
                                    value={rangeDescription.min}
                                    onChange={(e) => setRangeDescription({ ...rangeDescription, min: e.target.value })}
                                    placeholder="Minimum value description"
                                />
                                <span style={{marginRight:"1rem"}}>–</span>
                                <input
                                    type="text"
                                    style={{width:"35%"}}
                                    className="new_question_input"
                                    value={rangeDescription.max}
                                    onChange={(e) => setRangeDescription({ ...rangeDescription, max: e.target.value })}
                                    placeholder="Maximum value description"
                                />
                            </div>
                        )}

                        <div className="new_question_section">
                            <h3 className="new_question_section_head">Required:</h3>
                            <div style = {{
                                width: "5%",
                                display:"flex",
                                alignItems:"center",
                            }}
                            >
                                <div
                                    style={{
                                        border: "1px solid var(--primary)",
                                        width: ".7rem",
                                        height: ".7rem",
                                        borderRadius: "20%",
                                        backgroundColor: questionRequired ? "var(--primary)" : "transparent",
                                        cursor: "pointer",
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setQuestionRequired(!questionRequired);
                                    }}
                                    />
                                </div>
                            </div>


                        </div>

                        <div className="new_question_preview">
                            <h3>Preview: </h3>

                            <div className="question_preview_area">
                                <p className="question_preview_text">{questionText} {questionRequired ? '*' : ''}</p>

                                <div className="question_preview_response">

                                    {questionType === 'Range' && (
                                        <div className="range_slider_preview">
                                            <div className="range_slider_value">
                                                <span>{sliderValue}</span>
                                            </div>

                                            <div className="range_slider_slider">
                                                <p className="range_slider_desc">{rangeDescription.min}</p>
                                                <div className="question_preview_range">
                                                    <p>1</p>
                                                    <div className='number_slider question_preview_slider'>
                                                        <input
                                                            type="range"
                                                            min={1} // Minimum value
                                                            max={4} // Maximum value
                                                            value={sliderValue} // Controlled by the parent component
                                                            onChange={(e) => setSliderValue(e.target.value)} // Handled by the parent component
                                                        />
                                                    </div>
                                                    <p>4</p>
                                                </div>
                                                <p className="range_slider_desc">{rangeDescription.max}</p>
                                            </div>
                                            
                                        </div>
                                    )}

                                    {questionType === 'Select' && (
                                        <select className="new_question_select" style={{width: "70%"}}>
                                            <option selected disabled>Select Response</option>
                                            {options.map((option, index) => (
                                                <option key={index}>{option}</option>
                                            ))}
                                        </select>
                                    )}

                                    {questionType === 'Single Line' && (
                                        <input type="text" placeholder="Enter response" className="new_question_input" style={{width: "100%"}} />
                                    )}

                                    {questionType === 'Yes/No' && (
                                            <div className="yes_no_preview">
                                                <div 
                                                    className={`yes_no_option ${selectedOption === 'Yes' ? 'selected' : ''}`} 
                                                    onClick={() => handleOptionClick('Yes')}
                                                >
                                                    Yes
                                                </div>
                                                <div 
                                                    className={`yes_no_option ${selectedOption === 'No' ? 'selected' : ''}`} 
                                                    onClick={() => handleOptionClick('No')}
                                                >
                                                    No
                                                </div>
                                            </div>
                                    )}

                                    {questionType === 'Score' && (
                                    
                                        <div className="score_preview">
                                            <div className="score_slider_preview" style={{display:"flex", alignItems:"center"}}>
                                                <p style={{marginRight:"1rem", width:"10%"}}>{sliderValue}</p>
                                                <div className='number_slider question_preview_slider' style={{width:"90%"}}>
                                                    <input
                                                        type="range"
                                                        min={0} // Minimum value
                                                        max={100} // Maximum value
                                                        value={sliderValue} // Controlled by the parent component
                                                        onChange={(e) => setSliderValue(e.target.value)} // Handled by the parent component
                                                    />
                                                </div>

                                                    
                                            </div>
                                        </div>
                                    )}

                                    {questionType === 'Checkbox' && (
                                        <div className="checkbox_preview">
                                            <div className="checkbox_preview_options">
                                                {options.map((option, index) => (
                                                    <div key={index} className="checkbox_option" style={{display:"flex", alignItems:"center"}}>
                                                        <PrettyCheckbox  />
                                                        <p style={{margin:"0"}}>{option}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {
                                        questionType === 'Long Answer' && (
                                            <textarea placeholder="Enter response" className="new_question_long_input" style={{width: "100%"}} />
                                        )
                                    }
                                
                                </div>

                                

                            </div>

                            

                        </div>
                </div>

                <div className='actions_bottom'>
                    <div className='close_popup' onClick={() => setPopupOpen(false)}>Close</div>
                    <div className='create_member' onClick={addQuestion}>Add</div>
                </div>
            </div>
        </div>
    );
}


export const EditQuestion = ({ questions, setQuestions, index }) => {
    const [questionType, setQuestionType] = useState(questions[index].questionType);
    const [questionText, setQuestionText] = useState(questions[index].questionText);
    const [questionRequired, setQuestionRequired] = useState(questions[index].questionRequired);
    const [options, setOptions] = useState(questions[index].options);
    const [rangeDescription, setRangeDescription] = useState(questions[index].rangeDescription);

    const addQuestion = () => {
        // Implementation to handle adding a question
        const newQuestion = {
            questionType,
            questionText,
            questionRequired,
            options,
            rangeDescription,
        };

        const newQuestions = [...questions];
        newQuestions[index] = newQuestion;
        setQuestions(newQuestions);

        setEditQuestionPopupOpen(false);
    };

    const changeType = (e) => {
        setOptions([]);
        setRangeDescription({min: '', max: '' });
        setQuestionType(e.target.value);
    }


    // handling editing of options

    const [optionsPopupOpen, setOptionsPopupOpen] = useState(false);

    const [newOption, setNewOption] = useState('');

    const addOption = () => {
        if (newOption.trim() !== '') {
            setOptions([...options, newOption.trim()]);
            setNewOption("");
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addOption();
        }
    }

    //  handling the preview

    const [sliderValue, setSliderValue] = useState(1);
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    }


    const [editQuestionPopupOpen, setEditQuestionPopupOpen] = useState(false);


    return (

        <Popup
            trigger={
                <FaIcons.FaPen className="question_action_icon question_edit_icon" />
            }
            open={editQuestionPopupOpen}
            onOpen={() => setEditQuestionPopupOpen(true)}
            modal
            nested
            position="center center"
        >
        
        <div className="popup_holder">
            <div className="popup" style={{ width: "50%", height: "75%" }}>

                <h2 className="popup_heading">Edit Question</h2>
                <div className="popup_body">

                    <div className="new_question_info">

                        <div className="new_question_section">
                            <h3 className="new_question_section_head">Question:</h3>
                            <input
                                type="text"
                                className="new_question_input"
                                value={questionText}
                                onChange={(e) => setQuestionText(e.target.value)}
                                placeholder="Enter your question"
                            />
                        </div>

                        <div className="new_question_section">
                            <h3 className="new_question_section_head">Type:</h3>
                            <select value={questionType} onChange={changeType} className="new_question_select">
                                <option value="" selected disabled>Pick One</option>
                                <option value="Range">Range</option>
                                <option value="Select">Select</option>
                                <option value="Single Line">Single Line</option>
                                <option value="Yes/No">Yes/No</option>
                                <option value="Score">Score</option>
                                <option value="Checkbox">Checkbox</option>
                                <option value="Long Answer">Long Answer</option>
                            </select>
                        </div>

                        {((questionType === 'Select') || (questionType === 'Checkbox')) && (
                            <div className="new_question_section">
                                <h3 className="new_question_section_head">Options:</h3>


                                <Popup
                                    trigger={
                                        <div className="edit_options_trigger">
                                            Edit Options
                                            <FaIcons.FaExpandAlt style={{marginLeft:"0.5rem"}} />
                                        </div>
                                    }
                                    open={optionsPopupOpen}
                                    onOpen={() => setOptionsPopupOpen(true)}
                                    modal
                                    nested
                                    position="center center"
                                >

                                    <div className="popup_holder second">
                                        <div className="popup" style={{ width: "40%", height: "50%" }}>
                                            <h2 className="popup_heading">Edit Options</h2>

                                            <div className="popup_close" onClick={() => setOptionsPopupOpen(false)}>
                                                <X  />
                                            </div>

                                            <div className="edit_options_heading">
                                                <input 
                                                    className="add_option_input" 
                                                    value={newOption} 
                                                    onChange={(e) => setNewOption(e.target.value)} 
                                                    onKeyDown={(e) => e.key === 'Enter' && handleKeyPress(e)}
                                                    placeholder="Enter Option"
                                                />

                                                <div className="add_option" onClick={addOption}>
                                                    <FaIcons.FaPlus style={{marginRight:"0.5rem"}} />
                                                    Add
                                                </div>
                                            </div>

                                            <div className="edit_options_list">
                                                {options.map((option, index) => (
                                                    <div className="edit_option" key={index}>
                                                        <p>{option}</p>
                                                        <FaIcons.FaTrash
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => setOptions(options.filter((_, i) => i !== index))}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                </Popup>

                            </div>
                        )}

                        {questionType === 'Range' && (
                            <div className="new_question_section">
                                <h3 className="new_question_section_head">Range:</h3>
                                <input
                                    type="text"
                                    className="new_question_input"
                                    style={{marginRight: "1rem", width:"35%"}}
                                    value={rangeDescription.min}
                                    onChange={(e) => setRangeDescription({ ...rangeDescription, min: e.target.value })}
                                    placeholder="Minimum value description"
                                />
                                <span style={{marginRight:"1rem"}}>–</span>
                                <input
                                    type="text"
                                    style={{width:"35%"}}
                                    className="new_question_input"
                                    value={rangeDescription.max}
                                    onChange={(e) => setRangeDescription({ ...rangeDescription, max: e.target.value })}
                                    placeholder="Maximum value description"
                                />
                            </div>
                        )}

                        <div className="new_question_section">
                            <h3 className="new_question_section_head">Required:</h3>
                            <div style = {{
                                width: "5%",
                                display:"flex",
                                alignItems:"center",
                            }}
                            >
                                <div
                                    style={{
                                        border: "1px solid var(--primary)",
                                        width: ".7rem",
                                        height: ".7rem",
                                        borderRadius: "20%",
                                        backgroundColor: questionRequired ? "var(--primary)" : "transparent",
                                        cursor: "pointer",
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setQuestionRequired(!questionRequired);
                                    }}
                                    />
                                </div>
                            </div>


                        </div>

                        <div className="new_question_preview">
                            <h3>Preview: </h3>

                            <div className="question_preview_area">
                                <p className="question_preview_text">{questionText} {questionRequired ? '*' : ''}</p>

                                <div className="question_preview_response">

                                    {questionType === 'Range' && (
                                        <div className="range_slider_preview">
                                            <div className="range_slider_value">
                                                <span>{sliderValue}</span>
                                            </div>

                                            <div className="range_slider_slider">
                                                <p className="range_slider_desc">{rangeDescription.min}</p>
                                                <div className="question_preview_range">
                                                    <p>1</p>
                                                    <div className='number_slider question_preview_slider'>
                                                        <input
                                                            type="range"
                                                            min={1} // Minimum value
                                                            max={4} // Maximum value
                                                            value={sliderValue} // Controlled by the parent component
                                                            onChange={(e) => setSliderValue(e.target.value)} // Handled by the parent component
                                                        />
                                                    </div>
                                                    <p>4</p>
                                                </div>
                                                <p className="range_slider_desc">{rangeDescription.max}</p>
                                            </div>
                                            
                                        </div>
                                    )}

                                    {questionType === 'Select' && (
                                        <select className="new_question_select" style={{width: "70%"}}>
                                            <option selected disabled>Select Response</option>
                                            {options.map((option, index) => (
                                                <option key={index}>{option}</option>
                                            ))}
                                        </select>
                                    )}

                                    {questionType === 'Single Line' && (
                                        <input type="text" placeholder="Enter response" className="new_question_input" style={{width: "100%"}} />
                                    )}

                                    {questionType === 'Yes/No' && (
                                            <div className="yes_no_preview">
                                                <div 
                                                    className={`yes_no_option ${selectedOption === 'Yes' ? 'selected' : ''}`} 
                                                    onClick={() => handleOptionClick('Yes')}
                                                >
                                                    Yes
                                                </div>
                                                <div 
                                                    className={`yes_no_option ${selectedOption === 'No' ? 'selected' : ''}`} 
                                                    onClick={() => handleOptionClick('No')}
                                                >
                                                    No
                                                </div>
                                            </div>
                                    )}

                                    {questionType === 'Score' && (
                                    
                                        <div className="score_preview">
                                            <div className="score_slider_preview" style={{display:"flex", alignItems:"center"}}>
                                                <p style={{marginRight:"1rem", width:"10%"}}>{sliderValue}</p>
                                                <div className='number_slider question_preview_slider' style={{width:"90%"}}>
                                                    <input
                                                        type="range"
                                                        min={0} // Minimum value
                                                        max={100} // Maximum value
                                                        value={sliderValue} // Controlled by the parent component
                                                        onChange={(e) => setSliderValue(e.target.value)} // Handled by the parent component
                                                    />
                                                </div>

                                                    
                                            </div>
                                        </div>
                                    )}

                                    {questionType === 'Checkbox' && (
                                        <div className="checkbox_preview">
                                            <div className="checkbox_preview_options">
                                                {options.map((option, index) => (
                                                    <div key={index} className="checkbox_option" style={{display:"flex", alignItems:"center"}}>
                                                        <PrettyCheckbox  />
                                                        <p style={{margin:"0"}}>{option}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {
                                        questionType === 'Long Answer' && (
                                            <textarea placeholder="Enter response" className="new_question_long_input" style={{width: "100%"}} />
                                        )
                                    }

                                </div>

                                

                            </div>

                            

                        </div>
                </div>

                <div className='actions_bottom'>
                    <div className='close_popup' onClick={() => setEditQuestionPopupOpen(false)}>Close</div>
                    <div className='create_member' onClick={addQuestion}>Add</div>
                </div>
            </div>
        </div>
        </Popup>
    );
}
