import React from "react"
import { Link } from "react-router-dom";
import "./styles/notFound.css";

export const NotFound = () => {
    return (
        <div className="not_found_holder">
            <h1>
                Unfortunately, this page could not be found!
            </h1>

            <p>How about going to the <Link to="/">homepage</Link> and navigating from there?</p>
        </div>
    );
};
