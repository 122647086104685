import { Link, useNavigate } from "react-router-dom";
import "./styles/dashboard.css"
import "./styles/reports.css"

import { useEffect, useState } from "react";
import { isEmailValid } from "../functions/isEmailValid";
import * as FaIcons from "react-icons/fa" 
import { auth, db } from "../firebase";
import { arrayRemove, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { differenceInDays, isBefore, parseISO } from 'date-fns';
import { stagesNames } from "./job";
import { JobsByTimeChart } from "../charts/company/jobsByTime";
import { TimeToHireChart } from "../charts/company/timeToHire";
import { JobsFilledChart } from "../charts/company/jobsFilledChart";
import { ApplicationSourceChart } from "../charts/company/applicationSource";
import { HiresHistoryChart } from "../charts/company/hiresByTime";
import { CandidatesByTimeChart } from "../charts/company/candidatesByTime";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";

export function formatDate(isoString) {
    const date = new Date(isoString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });

    // Get the correct suffix for the day
    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // for 4-20
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    };

    return `${day}${daySuffix(day)} ${month}`;
}

export const Dashboard = () => {


    const [canRun, setCanRun] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [employer, setEmployer] = useState(null);
    const [candidates, setCandidates] = useState([]);
    // const [userId, setUserId] = useState(null);
    // const [events, setEvents] = useState([]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const labelStyle = "original"
    const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
    }


    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })


    const [defaultTimezone, setDefaultTimezone] = useState(parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone));



    const fetchAll = async () => {
        // first fetch the employer and set it

        try {
            const user = auth.currentUser;
            if (!user) {
            throw new Error('No authenticated user found');
            }
            
            // Step 1: Get the user's member document to find their team_id
            const membersRef = collection(db, "members");
            const qMember = query(membersRef, where("email", "==", user.email));
            const memberSnapshot = await getDocs(qMember);
            if (memberSnapshot.empty) {
            throw new Error('Member not found');
            }
            
            const memberData = memberSnapshot.docs[0].data();

            const memberTimezone = memberData.timezone;

            if (memberTimezone !== defaultTimezone) {
                const memberRef = doc(db, "members", memberSnapshot.docs[0].id);
                await updateDoc(memberRef, { timezone: defaultTimezone });
            }

            const { team_id } = memberData;

            const employerRef = doc(db, "teams", team_id);
            const employerDoc = await getDoc(employerRef);
            if (!employerDoc.exists()) {
                throw new Error('Employer not found');
            }
            
            const employerData = employerDoc.data();
            setEmployer(employerData);

            // Step 2: Get all jobs for the employer

            const jobsRef = collection(db, "jobs");
            const qJobs = query(jobsRef, where("employer_id", "==", team_id));
            const jobsSnapshot = await getDocs(qJobs);
            if (jobsSnapshot.empty) {
                throw new Error('No jobs found');
            }

            const jobsData = jobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Fetch candidates by job_id
            const candidateJobPromises = jobsData.map(job =>
                getDocs(query(collection(db, "candidates"), where("job_id", "==", job.id)))
            );

            // Fetch candidates by employer_id
            const candidatesEmployerRef = collection(db, "candidates");
            const candidatesEmployerQuery = query(candidatesEmployerRef, where("employer_id", "==", team_id));
            const candidatesEmployerPromise = getDocs(candidatesEmployerQuery);

            // Resolve all promises from candidate fetches concurrently
            const [candidatesJobSnapshots, candidatesEmployerSnapshot] = await Promise.all([
                Promise.all(candidateJobPromises),
                candidatesEmployerPromise
            ]);

            const candidatesFromJobs = candidatesJobSnapshots.flatMap(snapshot => 
                snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            );

            const candidatesFromEmployer = candidatesEmployerSnapshot.docs.map(doc => 
                ({ id: doc.id, ...doc.data() })
            );

            const allCandidates = [...candidatesFromJobs, ...candidatesFromEmployer];

            // Filter jobs to exclude deleted ones
            const filteredJobs = jobsData.filter(job => job.deleted !== true);
            setJobs(filteredJobs);
            setCandidates(allCandidates);


        } catch (error) {
            console.error("Error fetching data: ", error);
        }

    }


    useEffect(() => {

        if (auth.currentUser) {
            fetchAll();
        }
        // fetchUserEvents();
    
    }, [canRun])

    let navigate = useNavigate();
    useEffect(() => {
        if ((window.location.href.split('/')[3])) {
            if ((window.location.href.split('/')[3]) == "login") {
                navigate('/');
            };
        }

        // This si for redirecting when the user first logs in with the magic email
    })

    

    return (
        <div className="dashboard_holder"> 

            <div className="dashboard_header">
                <h1>
                    {employer ? employer.name + " Dashboard" : "Dashboard"}
                </h1>
            </div> 

            {candidates && jobs && employer && ( 
                <div id="company_reports" className="reporting_area">
                    
                    <div className="company_report_chart_row">

                        <div className="company_report_chart_section">
                            <JobsByTimeChart jobs={jobs} employer={employer} />
                        </div>

                        <div className="company_report_chart_section">
                    
                            <TimeToHireChart jobs={jobs} />

                            <div className="company_pie_charts">
                                <JobsFilledChart jobs={jobs} />
                                <ApplicationSourceChart candidates={candidates} /> 
                            </div>
                        </div>
                        
                       
                    </div>

                    <div className="company_report_chart_row">

                        <div className="company_report_chart_section">
                            <CandidatesByTimeChart candidates={candidates}  employer={employer} />
                        </div>

                        <div className="company_report_chart_section">
                            <HiresHistoryChart candidates={candidates}  employer={employer} /> 
                        </div>
                       
                    
                    </div>
                 
                   


                </div>
        
            )}





            {/* <div className="dashboard_assigned">

                <div className="dashboard_section dashboard_assigned_jobs">

                    <h3>Assigned Jobs</h3>

                    <div className="assigned_jobs_holder">
                        {jobs.map((job) => (
                            <Link to={"/job/" + job.id} style={{textDecoration:"none", color:"black"}}>
                                <div key={job.id} className="dashboard_job_display">

                                    <div className="dashboard_job_display_head">
                                        <h4 className="dashboard_job_display_title">{job.jobTitle}</h4>

                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <p>{formatDate(job.createdTime)}</p>
                                        </div>
                                        
                                    </div>


                                    <div className="dashboard_job_display_foot">
                                        <div className="dashboard_job_location">
                                            <span style={{fontWeight:500, marginRight:"0.25rem"}}><b>Location: </b></span>
                                            <span>{job?.location?.label || "Remote"} </span>
                                        </div>

                                        <FaIcons.FaExternalLinkAlt />
                                    </div>
                                    
                                
                                        
                                    
                                </div>
                            </Link>
                        ))}

                    </div>
                    
                </div>

                <div className="dashboard_section dashboard_upcoming_interviews">
                    <h3 className="">Upcoming Interviews</h3>

                    <div className="upcoming_interiews_area">
                        {events.length === 0 ? (
                            <p>No upcoming events :)</p>
                        ) : (
                            events.map((event) => (
                                <div key={event.id} className="dashboard_upcoming_interview">
                                    <p style={{margin: 0, textAlign:"center", verticalAlign:"center"}}>Interview with Elon Musk</p>
                                    <p>{new Date(event.startTime.seconds * 1000).toLocaleString()}</p>
                                </div>
                            ))
                        )}
                    </div>
                    
                </div>

            </div> */
            
            
            
            }

            {/* <div className="dashboard_section dashboard_notifications">
                <h3>Notifications</h3>

                <div className="dashboard_notifications_display">
                    <div className="dashboard_notification" id = "dashboard_notification_1">
                        Mark Zuckerberg has applied

                        <div className="dashboard_notification_settings" onClick={hideNotification}>
                            <FaIcons.FaCog />
                        </div>
                        
                    </div>
                </div>
            </div> */}
        </div>
    );
};