import { useEffect } from "react"
import "../styles/login.css"
import { Link, useNavigate } from "react-router-dom";

export const GetInTouch = () => {

    return (
        <div className="signup_holder">
            <h1>YOOO SIGNING UP!</h1>

            <input placeholder="Please enter your email here..." className="login_email_input"/>

            <p style={{marginTop:"3rem"}}> Or <Link to="/login">Log In</Link> </p>


            {/* 
                What all do I need when a user signs up?

                Email
                Company Name

            
            */}
        </div>
    )
}