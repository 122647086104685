import { useState } from "react";
import Popup from "reactjs-popup"
import * as FaIcons from "react-icons/fa"
import { X } from "lucide-react";
import { DonutChart } from "../../../donutChart";


export const TechnicalSkillsInfo = () => {

    const skill = {
        name: "Python",
        score: 76, 
        showcases: [
            { title: "Data Analysis Project", score: 6.2, reason: "Utilized Python to analyze and visualize large datasets, resulting in actionable insights for the company." },
            { title: "Web Development", score: 7.8, reason: "Built a web application using Python's Flask framework, demonstrating proficiency in backend development." },
            { title: "Machine Learning Model", score: 8.2, reason: "Developed and trained a machine learning model using Python's scikit-learn library, achieving an accuracy of 90%." },
        ],
    };

    const [popupIsOpen, setPopupOpen] = useState(false);
    const showcases = skill.showcases || [];
    // Sort showcases based on the score
    const sortedShowcases = showcases.sort((a, b) => b.score - a.score);
    // Extract the names
    const showcaseNames = sortedShowcases.map(showcase => showcase.title).join(', ');

    return (
        <Popup
            trigger={
            <div className="skillscore_info_icon">
                <FaIcons.FaInfoCircle />
            </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"

        >
        <div className="skill_popup_holder">
            <div className="skill_popup">

                <div className="skill_popup_header">
                    <div className="skill_popup_header_first">
                        <DonutChart percentage={skill.score} color="darkgreen" multiplier={0.075} fontSize={15} />
                        <h3>Technical Skill Example</h3>
                    </div>

                    <div className="skill_popup_close" onClick={() => setPopupOpen(false)}>
                        <X />
                    </div>
                </div>

                <p style={{marginLeft: "2rem"}}>The candidate displays {skill.name} in the following experiences: </p>

                <div className="skill_popup_showcases_holder">
                    {sortedShowcases.map((showcase, index) => (
                        <div key={index} className="skill_popup_showcase">

                            <div className="skill_popup_showcase_info">
                                <h4 className="skill_popup_showcase_title">{showcase.title}</h4>

                                <div className="skill_popup_showcase_score">
                                    <p>Level of Usage: </p>
                                    <DonutChart percentage={showcase.score * 10} color="darkgreen" multiplier={0.05} fontSize={12} />
                                </div>

                            </div>

                            <div className="skill_popup_showcase_reason">
                                <p>{showcase.reason}</p>
                            </div>

                        </div>
                    ))}
                </div>

            </div>
        </div>
        </Popup>
    )
}