import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as FaIcons from "react-icons/fa"
import { DonutChart } from '../../components/donutChart';
import { changeJob } from './job_settings';
import { globalCandidates, setGlobalCandidates, stagesNames } from '../job';


//The next few variables function as session variables enabling shuffling through the different candidates

//We're gonna have to change this to using localStorage lmao. Otherwise it fucks up on refresh
export var candidatesList = [];
export var curr_index = 0;

export const setCandidateListIndex = (index) => {
    curr_index = index;
}
export const setCandidateList = (newCandidates) => {
    candidatesList = newCandidates
}
  
    
export const Pipeline = () => {

    const [candidates, setCandidates] = useState([]);
    const [stages, setStages] = useState([])
    const [job, setJob] = useState(null);
    const jobId = window.location.href.split('/')[4];

    useEffect(() => {

        const fetchCandidates = async () => {
            try {
            const response = await fetch('https://api.skillpool.tech/getCandidates', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ job_id: jobId }),
            });
            const data = await response.json();
            setCandidates(data);

            setGlobalCandidates(data
                .sort((a, b) => {
                    if (a.stage !== b.stage) {
                        return a.stage - b.stage;
                    }
                    const scoreA = a.score || 0;
                    const scoreB = b.score || 0;
                    if (scoreA < 0 && scoreB < 0) return scoreA - scoreB;
                    if (scoreA < 0) return -1;
                    if (scoreB < 0) return 1;
                    return scoreA - scoreB;
                })
                .map((candidate) => (
                    candidate.id
                ))
            );

            } catch (error) {
            console.error("Error fetching candidates:", error);
            }
        };

        const fetchJob = async () => {
            try {
            const response = await fetch('https://api.skillpool.tech/getJobById', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ jobId: jobId }),
            });
            const data = await response.json();
            changeJob(data);
            setJob(data);
            setFinishedLoading(true);
            setStages(data.pipeline);
            } catch (error) {
            console.error("Error fetching candidates:", error);
            }
        };

        if (jobId) {
            fetchCandidates();
            fetchJob();
        }

    }, []);

    




    //Handle the mass viewing setup 

    const setCandidateArrayByStage = (stage) => {
        const candidatesToShow = candidates
        .filter(candidate => candidate.stage === stage) 
        .sort((a, b) => {
            // First, prioritize by viewed status (unviewed first)
            if (a.viewed !== b.viewed) {
                return a.viewed ? 1 : -1; // false values first (unviewed)
            }
            // // Next, sort by score (higher scores first) TODO: AFTER INTERVIEW
            if (b.score && a.score) {
                return b.score - a.score;
            }
        })
        .map(candidate => candidate.id); 
        
        setCandidateList(candidatesToShow);
    }


    let navigate = useNavigate();

    const reviewCandidates = (stage) => {
        setCandidateArrayByStage(stage);        
        setCandidateListIndex(0);
        navigate('../candidate/' + candidatesList[0]);

    }

    const openCandidate = (stage, index) => {
        setCandidateArrayByStage(stage);
        setCandidateListIndex(index);
        navigate('../candidate/' + candidatesList[index]);
    }

    
    const renderCandidates = (stage) => {
        return candidates
        .filter(candidate => candidate.stage === stage)
        .sort((a, b) => {
            // Sort by score (higher scores first)
            if ((a.score !== undefined) && (b.score !== undefined)) {
                return b.score - a.score;
            }
            return 0; // If scores are undefined or equal, maintain original order
        })
        .map((candidate, index) => (
            //  to={`../candidate/${candidate.id}/details`} draggable onDragStart={(event) => handleDragStart(event, candidate.id)} 
            <div className='candidate_link' onClick={() => openCandidate(stage, index)}> 
                <div className="candidateLink" >
                    <div key={candidate.id} className="candidate_pipeline">
                        <div className='candidate_left_side'>
                            <div className='candidate_opened'>
                                {(candidate.viewed === false) && <div className="glowing-indicator"></div>}
                            </div>
                            <h3>
                                {candidate.name}
                            </h3>
                        </div>

                        { (candidate.score !== undefined) && 
                        <div className='candidate_right_side'>
                            <DonutChart percentage={candidate.score} color="darkgreen" multiplier={0.04} fontSize={12} />
                        </div>
                        } 

                    </div>
                </div>
            </div>
        
        ));
    };


    const [finishedLoading, setFinishedLoading] = useState(false);


    return (
        <div className='all_pipeline_holder'>

            <div className="pipeline_container">

                {
                    finishedLoading && (
                        <>
                            {stages?.map((stage, index) => (

                                <div className="stage">
                                    
                                    <div className='stage_top_section'>
                                        
                                        <div className='stage_title_center'>
                                            <h3>{stage?.details?.name || stagesNames[stage.label]}</h3>    
                                        </div>

                                        {/* write the number of candidates with their stage as stage */}
                                        <div className='stage_candidates_number'>
                                            {candidates.filter(candidate => candidate.stage === index).length}
                                        </div>

                                        {/* <div className='review_candidates_holder' onClick={() => reviewCandidates(index)}>
                                            <div className='review_candidates'>
                                                Review
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className='dropArea'>
                                        {renderCandidates(index)}
                                    </div>

                                    
                                </div>

                                ))}


                                <div className="stage">

                                <div className='stage_top_section'>
                                    
                                    <div className='stage_title_center'>
                                        <h3>Rejected</h3>   
                                    </div>

                                    <div className='stage_candidates_number'>
                                        {candidates.filter(candidate => candidate.stage === -1).length}
                                    </div>

                                    {/* <div className='review_candidates_holder' style={{background: "none"}}>
                                        {/* <div className='review_candidates'>
                                            Review
                                        </div> 
                                    </div> */}
                                </div>

                                <div className='dropArea'>
                                    {renderCandidates(-1)}
                                </div>


                            </div>
                        </>
                    )
                }
                

                

            </div>
        </div>
    );

    
};