import { X } from "lucide-react";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "./styles/skillscore_category.css";
import {
  WeightageSlider,
  getBackgroundColor,
  interpolateColor,
} from "./components";
import * as FaIcons from "react-icons/fa";
import { EducationInfo } from "./skillscore_info/educationInfo";

export const EducationCategory = ({
  weightage,
  setWeightage,
  complete,
  setComplete,
  data,
  setData,
}) => {
  const [popupIsOpen, setPopupOpen] = useState(false);

  // const [weightage, setWeightage] = useState(r);

  const handleWeightageChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setWeightage(event.target.value);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const [gpaWeightage, setGPAWeightage] = useState(data.gpaRating || 10);
  const [universityWeightage, setUniversityWeightage] = useState(data.collegeLevelRating || 10);

  // Handling completeness

  useEffect(() => {
    if (parseInt(weightage) > 0) {
      // if both gpa and university weightage are 0
      if (parseInt(gpaWeightage) === 0 && parseInt(universityWeightage) === 0) {
        setComplete(false);
      } else {
        setComplete(true);
      }
    } else {
      setComplete(true);
    }
  }, [gpaWeightage, universityWeightage, weightage]);


  // Handling saving the data

  useEffect(() => {
    const data = {
      rating: parseInt(weightage),
      gpaRating: parseInt(gpaWeightage),
      collegeLevelRating: parseInt(universityWeightage)
    }

    setData(data);
  }, [gpaWeightage, universityWeightage, weightage])


  // handling the example popup

  const [examplePopupIsOpen, setExamplePopupIsOpen] = useState(false);

  return (
    <>
      <div
        className="skillscore_category"
        onClick={openPopup}
        style={{ backgroundColor: getBackgroundColor(weightage) }}
      >
        {!complete && (
          <div className="node_incomplete">
            !{/* Note that this will actually be an orange circle / symbol.  */}
          </div>
        )}

        <p className="skillscore_category_heading">Education</p>

        <div className="skillscore_category_expand">
          <p style={{ margin: 0, marginRight: "1rem" }}>Click to customize</p>
          <FaIcons.FaExpandAlt />
        </div>

        <div className="weightage_section">
          <div className="weightage_top_row">
            <p>Weightage</p>
            <p>{weightage}</p>
          </div>

          <WeightageSlider
            value={weightage}
            onChange={handleWeightageChange}
            max={10}
          />
        </div>
      </div>

      <Popup
        open={popupIsOpen}
        onOpen={() => setPopupOpen(true)}
        modal
        nested
        position="center center"
      >
        <div className="popup_holder">
          <div className="popup" style={{ width: "50%", height: "50%" }}>
            <h1 className="popup_heading">Education</h1>

            <div className="popup_body">
              <p
                style={{
                  margin: 0,
                  textAlign: "left",
                  width: "100%",
                  marginBottom: "1.5rem",
                }}
              >
                Please configure the weightage of the subcategories for
                Education:
              </p>

              {/* Create two objects here for GPA and University seperately */}
              <div className="skillscore_subcategory_holder">
                <div
                  className="skillscore_subcategory"
                  style={{ backgroundColor: getBackgroundColor(gpaWeightage) }}
                >
                  <p className="skillscore_subcategory_heading">GPA</p>

                  <div className="skillscore_subcategory_body">
                    <p>Measures and scores the latest GPA of the candidate.</p>
                  </div>

                  <div className="subcategory_weightage_section">
                    <div className="weightage_top_row">
                      <p>Weightage</p>
                      <p>{gpaWeightage}</p>
                    </div>

                    <WeightageSlider
                      value={gpaWeightage}
                      onChange={(e) => setGPAWeightage(e.target.value)}
                      max={10}
                    />
                  </div>
                </div>

                <div
                  className="skillscore_subcategory"
                  style={{
                    backgroundColor: getBackgroundColor(universityWeightage),
                  }}
                >
                  <p className="skillscore_subcategory_heading">University</p>

                  <div className="skillscore_subcategory_body">
                    <p>
                      Identifies and scores the ranking of the candidate's
                      university.
                    </p>
                  </div>

                  <div className="subcategory_weightage_section">
                    <div className="weightage_top_row">
                      <p>Weightage</p>
                      <p>{universityWeightage}</p>
                    </div>

                    <WeightageSlider
                      value={universityWeightage}
                      onChange={(e) => setUniversityWeightage(e.target.value)}
                      max={10}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="popup_close" onClick={() => setPopupOpen(false)}>
              <X />
            </div>


            <EducationInfo />
            
          </div>
        </div>
      </Popup>
    </>
  );
};
