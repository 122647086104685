import { useState, useRef, useEffect } from 'react';
import { registerBlockType } from '@quillforms/blocks';

const SkillSelectorDisplay = ({ setIsValid, setIsAnswered, setVal, val = [] }) => {
    
    const [inputValue, setInputValue] = useState('');
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && inputValue && val.length < 3) {
        event.preventDefault();
        event.stopPropagation();
        const newSkills = [...val, inputValue.trim()];
        setVal(newSkills);
        setInputValue('');
        setIsValid(newSkills.length > 0);
        setIsAnswered(true);
      }
    };

    useEffect(() => {
        setIsAnswered(false)
        setIsValid(false)
    }, []);

    const removeSkill = (index) => {
      const newSkills = val.filter((_, i) => i !== index);
      setVal(newSkills);
      setIsValid(newSkills.length > 0);
    };


    return (
      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '8px' }}>
          {val.map((skill, index) => (
            <div key={index} style={{ 
              margin: '2px', padding: '4px 8px', 
              backgroundColor: '#ddeeff', borderRadius: '12px', 
              display: 'flex', alignItems: 'center' 
            }}>
              {skill}
              <button onClick={() => removeSkill(index)} style={{ marginLeft: '4px', cursor: 'pointer' }}>×</button>
            </div>
          ))}
        </div>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a skill and press Enter"
          disabled={val.length >= 3}
        />
      </div>
    );
};

registerBlockType('skill-selector', {
  supports: {
    editable: true,
  },
  attributes: {
    skills: {
      type: 'array',
      default: [],
    },
  },
  display: SkillSelectorDisplay,
});
