// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXpDE3YbkaoM9kvlZ2TCQhajC_WY2hnqU",
  authDomain: "skillpool-tech.firebaseapp.com",
  projectId: "skillpool-tech",
  storageBucket: "skillpool-tech.appspot.com",
  messagingSenderId: "642969181995",
  appId: "1:642969181995:web:e8b7e3a62786cf0b90af6e",
  measurementId: "G-SJB9ZE24V7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6LekPAoqAAAAAGRZgwUSaPwLPTy3TVC-UQSnRtuz"),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export const auth = getAuth(app);
export default app;
// const analytics = getAnalytics(app);
