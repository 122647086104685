import "./styles/node.css"
import "./styles/appliedNode.css"
import Popup from "reactjs-popup";
import { useState } from "react";
import { Handle } from "reactflow";


export const AppliedNode = ({ data }) => {
    
    const [popupIsOpen, setPopupOpen] = useState(false);

    return (

        <div className='pipeline_node_display'>
            Applied
            <Handle type="source" position="right" id="1"  style={{pointerEvents:"none"}}/>
            {/* <Handle type="source" position="bottom" id="2"  style={{pointerEvents:"none"}}/> */}
            

            {/* <div className="node_incomplete"> 
                ! 
            </div> */}
        </div>
    
    
    // <Popup trigger={

    //     <div className='pipeline_node_display'>
    //         Applied
    //         <Handle type="source" position="right" id="1"  style={{pointerEvents:"none"}}/>
    //         {/* <Handle type="source" position="bottom" id="2"  style={{pointerEvents:"none"}}/> */}
            

    //         {/* <div className="node_incomplete"> 
    //             ! 
    //         </div> */}
    //     </div>

    // }

    // open={popupIsOpen}
    // onOpen={() => setPopupOpen(true)}
    // modal
    // nested
    // position="center center"
    // >
    
    // <div className="create_stage_holder">
    //     <div className="create_stage">
    //         <h1>Applied </h1>

    //         <div className="stage_bottom_actions">
    //             <div className="stage_cancel" onClick={(e) => setPopupOpen(false)}>
    //                 Cancel
    //             </div>

    //             <div className="stage_save">
    //                 Save
    //             </div>
    //         </div>
    //     </div>
    // </div>

    // </Popup>
)};