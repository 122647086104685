import React, { useEffect, useState, useRef } from 'react';
import { collection, query, where, getDocs, getDoc, doc, addDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import "./styles/team.css"
import { auth, db } from '../firebase';
import Popup from 'reactjs-popup';
import { Link, Outlet } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { sendNotification } from "../components/notification";
import { EditMember } from '../components/teamActions/editMember';
import { DeleteMember } from '../components/teamActions/deleteMember';

const SubNavigation = () => {

    const parts = window.location.href.split("/");

    var curr_active = parts[parts.length - 1];

    if ((curr_active !== "members") && (curr_active !== "roles")) {
        curr_active = "members";
    }

    const [activeItem, setActiveItem] = useState(curr_active);

    const changeActive = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <div className="team_subnav">
    <div className={`team_subnav_item ${activeItem === 'members' ? 'active_team_subnav_item' : ''}`} onClick={() => changeActive('members')}>
      <Link className="subnav_link" to="members">Members</Link>
    </div>

    <div className={`team_subnav_item ${activeItem === 'roles' ? 'active_team_subnav_item' : ''}`} onClick={() => changeActive('roles')}>
      <Link className="subnav_link" to="roles">Roles</Link>
    </div>
  </div>
  );
};

export default SubNavigation;




export const Team = () => {

    const [employerID, setEmployerID] = useState(null);
    const [currentMember, setCurrentMember] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [canRun, setCanRun] = useState(false);
    const [roles, setRoles] = useState([]);
    const newRolePopup = useRef(null);

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
          setCanRun(true);
          } else {
          // No user is signed in.
          setCanRun(false);
          }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
  }, []);

    const getRoles = async () => {
        try {
            const rolesRef = collection(db, 'roles');
            const q = query(rolesRef, where('employer_id', '==', employerID));
            const querySnapshot = await getDocs(q);
            const fetchedRoles = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            setRoles(fetchedRoles);
          } catch (e) {
            console.error('Error fetching roles:', e);
          }
    }


    useEffect(() => {



        const fetchTeamMembers = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }

                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }

                const memberData = memberSnapshot.docs[0].data();
                setCurrentMember(memberData);
                const { team_id } = memberData;
                setEmployerID(team_id);

                // Step 2: Use team_id to find the team and retrieve the members array
                const teamSnapshot = await getDoc(doc(db, "teams", team_id));
                if (!teamSnapshot.exists()) {
                    throw new Error('Team not found');
                }

                const teamData = teamSnapshot.data();
                const teamMembersIds = teamData.members;
                const validMemberIds = [];
                const teamMembersPromises = teamMembersIds.map(async id => {
                    const memberSnap = await getDoc(doc(db, "members", id));
                    if (!memberSnap.exists()) {
                        return null; // Return null for non-existing members
                    }
                    validMemberIds.push(id); // Add valid member ID to the array
                    return {...memberSnap.data(), id: id};
                });

                let team = await Promise.all(teamMembersPromises);
                team = team.filter(member => member !== null); // Remove null entries
                // Check if any IDs were removed, and update the team document if necessary

                // this accounts for when I delete members (since that doesnt update the team immedidately)
                if (validMemberIds.length !== teamMembersIds.length) {
                    await updateDoc(doc(db, "teams", team_id), {
                        members: validMemberIds
                    });
                }
                setTeamMembers(team);

            } catch (error) {
                console.error("Failed to fetch team members:", error);
            }
        };

        fetchTeamMembers();
        getRoles();
    }, [canRun]);

    const addNewMember = async () => {
        const first_name = document.getElementById("add_member_first_name").value;
        const last_name = document.getElementById("add_member_last_name").value;
        const email = document.getElementById("add_member_email").value;
        if (first_name == "") {
            sendNotification("Please enter the first name!");
            return;
        }

        if (last_name == "") {
            sendNotification("Please enter the last name!");
            return;
        }

        if (email == "") {
            sendNotification("Please enter the email!");
            return;
        }

        setAddMemberMessage(true);

        setTimeout(() => {
          setAddMemberMessage(false);
          setPopupOpen(false);
      }, 1000);

        try {
            // Step 1: Add a new member to the 'members' collection
            const membersRef = collection(db, 'members');
            const newMemberRef = await addDoc(membersRef, {
                email: email,
                first_name: first_name,
                last_name: last_name,
                team_id: employerID,
                availability: currentMember.availability,
                timezone: currentMember.timezone,
            });
            // Step 2: Update the 'teams' document to include the new member's ID in the 'members' array
            const teamDocRef = doc(db, 'teams', employerID); // Assuming employerID is the document ID for the team
            await updateDoc(teamDocRef, {
              members: arrayUnion(newMemberRef.id) // Add the new member's ID to the 'members' array
            });


            // append the new member to the teamMembers array

            const newMember = {
                first_name: first_name,
                last_name: last_name,
                email: email,
                team_id: employerID,
            }

            setTeamMembers([...teamMembers, {...newMember, id: newMemberRef.id}]);
          } catch (error) {
            console.error('Error adding new member and updating team:', error);
          }



    }


    // handlign the message after adding a new member

    const [addMemberMessage, setAddMemberMessage] = useState(false);

    const [popupIsOpen, setPopupOpen] = useState(false);

    const openNewMemberPopup = () => {
        getRoles();
        setPopupOpen(true)
    }


    // handling team member search

    const [searchTerm, setSearchTerm] = useState("")

    const filteredTeamMembers = teamMembers
    .filter(member =>
      (member.first_name + " " + member.last_name).toLowerCase().includes(searchTerm.toLowerCase())
    )


    // handle the team members actions

    const [activePopupMemberId, setActivePopupMemberId] = useState(null);

    const openMemberActionsPopup = (memberId) => {
      setActivePopupMemberId(memberId);
    }

    return (
        <div className="team_holder">

            <h1>Manage Team</h1>

            {/* <div className="pageSelector">
                <SubNavigation />

                Removing the subnavigation because I don't want to deal with the roles module yet
            </div> */}

            {/* <Outlet /> */}

            <div className='team_members_heading' >
              <div style={{display:"flex", alignItems: "center", width:"90%"}}>
                  <FaIcons.FaSearch style={{opacity:0.5}} />
                  <input
                      className="searchBar"
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                      placeholder={(
                          "Search!"
                      )}
                      style={{
                        width:"90%"
                      }}
                  />
              </div>

              { currentMember && currentMember.role && currentMember.role === "Admin" && (
              // this should only show if the current member is an admin
                <Popup trigger={
                  <div className="add_member" id="add_member">
                      <div> <FaIcons.FaPlus /></div>
                      <div>Add</div>
                  </div>


                }

                    open={popupIsOpen}
                    onOpen={openNewMemberPopup}
                    modal
                    nested
                    position="center center"
                >
                    <div className="popup_holder">

                        <div className='popup' style={{width: "40%", height:"45%"}}>

                            <div className='popup_heading_area'>
                                <h1>Add Team Member</h1>
                            </div>


                            <div className='popup_body'>
                              { addMemberMessage && (
                                <div className='edit_message' style={{marginTop:"1.5rem"}}>
                                    A new team member has been added! They will now be able to log in with the provided email address!
                                </div>
                              )}

                              { !addMemberMessage && (
                                <div className="team_member_info">

                                    <div className='member_name_input'>
                                        <input type="text" id="add_member_first_name" placeholder="First Name Here*" className="first_name_enter member_enter_info" />
                                        <input type="text" id="add_member_last_name" placeholder="Last Name Here*" className="last_name_enter member_enter_info" />
                                    </div>
                                    <input type="email" id="add_member_email" placeholder="Email Here*" className="email_enter member_enter_info" />

                                </div>
                              )}

                            </div>




                            <div className='actions_bottom'>
                                <div className='close_popup' onClick={() => setPopupOpen(false)}>Close</div>

                                <div className='create_member' onClick={addNewMember}>
                                    Add
                                </div>
                            </div>
                        </div>

                    </div>

                </Popup>
              )}
            </div>



            <div className='team_members_area'>
              {filteredTeamMembers.map(member => (
                  <div className='member_info'>

                    <div className='member_information_first'>
                      <p className='member_name'> {member.first_name} {member.last_name} </p>
                      <p className='member_role'> {member.role} </p>
                    </div>


                    <Popup
                      trigger={
                        <a className='member_settings'>
                          <FaIcons.FaCog  />
                        </a>
                      }
                      open={activePopupMemberId === member.id}
                      // modal
                      onOpen={() => openMemberActionsPopup(member.id)}
                      contentStyle={{ padding: '0px', border: 'none' }}
                      nested
                      position="left top"
                      >
                        <div className="job_actions_holder">

                          <EditMember member={member} currentMember={currentMember} teamMembers={teamMembers} setTeamMembers={setTeamMembers} setActionsPopupOpen={setActivePopupMemberId} />
                          <DeleteMember member={member} currentMember={currentMember} teamMembers={teamMembers} setTeamMembers={setTeamMembers} setActionsPopupOpen={setActivePopupMemberId} />
                        </div>
                    </Popup>



                  </div>
              ))}
            </div>






        </div>
    )
}
