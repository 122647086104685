import "./styles/notification.css"
import * as FaIcons from "react-icons/fa";
import React, { useState, useEffect } from 'react';


export const sendNotification = (message, timeout = 2000) => {
    if (canCall) {
        canCall = false;
        const notif = document.getElementById('notification');
        const notif_message = document.getElementById('notification_message');
        notif_message.innerHTML = message;
        notif.style.display = "flex";
        notif.style.animation = "flyIn 0.5s ease";
        setTimeout(() => {
            notif.style.animation = "flyOut 0.5s ease";

            setTimeout(() => {
                notif.style.display = "none";
                canCall = true;
            }, 500);

        }, timeout);
    }


}

var canCall = true;

export const Notification = () => {

    return (
        <div className="notification" id = "notification">

            <div>
                <FaIcons.FaExclamationCircle style={{color:"var(--primary)"}} />
            </div>

            <div id="notification_message">
                Implementing that soon!
            </div>

        </div>
    );
};
