import { Link, Outlet } from "react-router-dom";
import "./styles/settings.css"
import { useState } from "react";


const SubNavigation = () => {


    const parts = window.location.href.split("/");

    var curr_active = parts[parts.length - 1];

    if ((curr_active !== "emails") && (curr_active !== "scheduling") ) {
      curr_active = "account";
  }

    const [activeItem, setActiveItem] = useState(curr_active);

    const changeActive = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <div className="subnav">

    <div className={`subnav_item ${activeItem === 'account' ? 'active_subnav_item' : ''}`} onClick={() => changeActive('account')}>
      <Link className="subnav_link" to="">Account</Link>
    </div>


    <div className={`subnav_item ${activeItem === 'emails' ? 'active_subnav_item' : ''}`} onClick={() => changeActive('emails')}>
      <Link className="subnav_link" to="emails">Emails</Link>
    </div>

    <div className={`subnav_item ${activeItem === 'scheduling' ? 'active_subnav_item' : ''}`} onClick={() => changeActive('scheduling')}>
      <Link className="subnav_link" to="scheduling">Scheduling</Link>
    </div>

    {/* <div className={`subnav_item ${activeItem === 'jobs_page' ? 'active_subnav_item' : ''}`} onClick={() => changeActive('jobs_page')}>
      <Link className="subnav_link" to="jobs_page">Jobs Page</Link>
    </div> */}


  </div>
  );
};


export const Settings = () => {
    return (
        <div className="settings_holder">
            <h1 className="settings_title">
                Settings
            </h1>

            <div className="pageSelector">
                <SubNavigation />
            </div>

            <Outlet />

        </div>
    );
};