import { useState, useEffect } from "react"
import * as FaIcons from "react-icons/fa"
import { LocationEntry } from "../../location";


export const Details = ({
    name, 
    handleNameChange,
    interviewType,
    setInterviewType,
    duration,
    handleDurationChange,
    location,
    handleLocationChange,
    inPersonLocation, 
    setInPersonLocation,
    interviewNotes,
    setInterviewNotes,
    complete, 
    setComplete,
}) => {


    // handling the google key
    const [googleKey, setGoogleKey] = useState(null);

    useEffect(() => {
        const fetchGoogleKey = async () => {
        const response = await fetch('https://api.skillpool.tech/google_api_key');
        const data = (await response.json()).key;
        setGoogleKey(data);
        }

        fetchGoogleKey();
    }, [])


    // handling completeness

    const [nameComplete, setNameComplete] = useState(true);
    const [typeComplete, setTypeComplete] = useState(false);
    const [locationComplete, setLocationComplete] = useState(false);
    const [notesComplete, setNotesComplete] = useState(false);
    const [durationComplete, setDurationComplete] = useState(false);


    useEffect(() => {
        if (nameComplete && typeComplete && locationComplete && notesComplete && durationComplete) {
            setComplete(true);
        } else {
            setComplete(false);
        }
    }, [nameComplete, typeComplete, locationComplete, notesComplete, durationComplete])

    useEffect(() => {
        if (name && name !== "") {
            setNameComplete(true);
        } else {
            setNameComplete(false);
        }
    }, [name])


    useEffect(() => {
        if (interviewType) {
            setTypeComplete(true);
        } else {
            setTypeComplete(false);
        }
    }, [interviewType])

    useEffect(() => {

        console.log(duration > 0);
        if (duration && duration > 0 ) {
            setDurationComplete(true);
        } else {
            console.log("false");
            setDurationComplete(false);
        }
    }, [duration])

    useEffect(() => {
        if (interviewNotes && interviewNotes !== "" ) {
            setNotesComplete(true);
        } else {
            setNotesComplete(false);
        }
    }, [interviewNotes])


    useEffect(() => {
        if (location) {
            if (location === "In-Person") {
                if (inPersonLocation) {
                    setLocationComplete(true);
                } else {
                    setLocationComplete(false);
                }
            } else {
                setLocationComplete(true);
            }
        } else {
            setLocationComplete(false);
        }
    }, [location, inPersonLocation])



    return (
        <div className="interview_stage_body">
            <div className="interview_top_row">
            <div className="interview_card">
                <h3 className="interview_card_heading">Name</h3>

                <div style={{display:"flex", alignItems:"center"}}>
                <FaIcons.FaPen 
                    style={{
                    opacity:"0.75"
                    }}
                />
                <input 
                className="interview_card_input" 
                type="text" 
                value={name} 
                onChange={handleNameChange} 
                placeholder="Change the interview name here!" 
                />
                </div>
                
            </div>

            <div className="interview_card">
                <h3 className="interview_card_heading">Type</h3>
                <select value={interviewType} className="interview_section_select" onChange={(e) => setInterviewType(e.target.value)} style={{height: "2rem", width:"12rem", padding:"0.25rem 0.5rem"}}>
                    <option value="" disabled>Select Interview Type</option>
                    <option value="Technical">Technical Interview</option>
                    <option value="Behavioral">Behavioral Interview</option>
                    <option value="Managerial">Managerial Interview</option>
                    <option value="Onsite">Onsite Interview</option>
                    <option value="Case Study">Case Study</option>
                    <option value="Panel">Panel</option>
                    <option value="Other">Other</option>
                </select>

                {
                    !typeComplete && (
                        <div className="node_incomplete">
                            !
                        </div>
                    )
                }
                
            </div>
            </div>

            <div className="interview_bottom_row">
            <div className="interview_bottom_left_row">
                
                <div className="interview_card duration_card">
                <h3 className="interview_card_heading">Duration</h3>
                <div className="duration_input">
                    <input 
                    type="number" 
                    className="interview_section_number_input" 
                    value={duration} 
                    onChange={handleDurationChange} 
                    />
                    <span>minutes</span>
                </div>

                {
                    !durationComplete && (
                        <div className="node_incomplete">
                            !
                        </div>
                    )
                }

                </div>

                <div className="interview_card location_card">
                <h3 className="interview_card_heading">Location</h3>

                {
                    !locationComplete && (
                        <div className="node_incomplete">
                            !
                        </div>
                    )
                }
                <div className="location_buttons">
                    <button 
                    className={location === 'Virtual' ? 'active' : ''} 
                    onClick={() => handleLocationChange('Virtual')}
                    >
                    Virtual
                    </button>
                    <button 
                    className={location === 'In-Person' ? 'active' : ''} 
                    onClick={() => handleLocationChange('In-Person')}
                    >
                    In-Person
                    </button>
                </div>
                {location === 'In-Person' && (

                    <LocationEntry value={inPersonLocation} setValue={setInPersonLocation} givenGoogleKey={googleKey} />
                )}
                </div>
            </div>

            <div className="interview_card">
                {
                    !notesComplete && (
                        <div className="node_incomplete">
                            !
                        </div>
                    )
                }
                <h3 className="interview_card_heading">Interview Criteria / Notes</h3>
                <textarea
                value={interviewNotes}
                onChange={(e) => setInterviewNotes(e.target.value)}
                placeholder="Please type what the interviewer should focus on!"
                className="notes_input"
                />
            </div>
            
            </div>
        
        </div>
    )
}