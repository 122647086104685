import { useState } from "react";
import Popup from "reactjs-popup"
import * as FaIcons from "react-icons/fa"
import { X } from "lucide-react";
import { DonutChart } from "../../../donutChart";


export const ProjectInfo = () => {

    const [popupIsOpen, setPopupOpen] = useState(false);

    const evaluateScore = score => score <= 20 ? "Poor" : score <= 40 ? "Low" : score <= 60 ? "Moderate" : score <= 80 ? "Good" : "High";

    const formatDuration = months => `${months >= 12 ? Math.floor(months / 12) + ' years and ' : ''}${months % 12} months`;
    

    const project = {
        name: "Personal Website Development",
        score: 45, 
        details: {
            details: ["Designed and developed a personal website using React and Node.js", "Implemented responsive design for a seamless user experience", "Integrated a blog feature with a custom CMS"],
            scores: {
                impact: 3.5, 
                initiative: 5, 
                innovation: 4, 
                technical_complexity: 5.5, 
            },
        },
    }

    return (
        <Popup
            trigger={
            <div className="skillscore_info_icon" style={{top: "1rem", left:"1rem"}}>
                <FaIcons.FaInfoCircle />
            </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"

        >
        <div className="popup_holder">
            <div className="popup">

                <div className="score_popup_heading">
                    <DonutChart percentage={project.score} color="darkgreen" multiplier={0.075} fontSize={15}   />
                    <h1 className="popup_heading"> Project Example </h1>
                </div>

                <div className="popup_body">

                    <div className="project_information">
                        <p><strong>Details: </strong></p>
                        <ul>
                            {project.details.details.map((detail, index) => (
                                <li key={index} className="candidate_work_history_job_list_item">{detail}</li>
                            ))}
                        </ul>
                    </div>

                    <div className="project_score_breakdown">
                        <p><strong>Score Justification:</strong></p>
                        <div className="project_score_breakdown_item">
                            <DonutChart percentage={project.details.scores.impact * 10} color="darkgreen" multiplier={0.05} fontSize={12}  />
                            <p>{evaluateScore(project.details.scores.impact * 10)} Impact</p>
                        </div>

                        <div className="project_score_breakdown_item">
                            <DonutChart percentage={project.details.scores.initiative * 10} color="darkgreen" multiplier={0.05} fontSize={12}  />
                            <p>{evaluateScore(project.details.scores.initiative * 10)} Initiative</p>
                        </div>

                        <div className="project_score_breakdown_item">
                            <DonutChart percentage={project.details.scores.innovation * 10} color="darkgreen" multiplier={0.05} fontSize={12}  />
                            <p>{evaluateScore(project.details.scores.innovation * 10)} Innovation</p>
                        </div>

                        <div className="project_score_breakdown_item">
                            <DonutChart percentage={project.details.scores.technical_complexity * 10} color="darkgreen" multiplier={0.05} fontSize={12}  />
                            <p>{evaluateScore(project.details.scores.technical_complexity * 10)} Complexity</p>
                        </div>

                    </div>

                </div>

                <div className="popup_close" onClick={() => setPopupOpen(false)}> <X /> </div>
            </div>
        </div>
        </Popup>
    )
}