import { useState, useEffect } from "react"
import Popup from "reactjs-popup";
import * as FaIcons from "react-icons/fa"
import { ChevronUp, X } from "lucide-react";
import ToggleButton from "../../../components/toggleButton";
import { ChevronDown } from "lucide-react";
import "./candidateSourcing.css"
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export const CandidateSourcing = ({employer, employerID, candidateSource, setCandidateSource}) => {
    const [popupIsOpen, setPopupOpen] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [sources, setSources] = useState([
        { category: "Career Site", name: "Company Career Site" },
        { category: "Internal", name: "Internal Candidates" },
        { category: "Referral", name: "Referral" },
        { category: "Manual", name: "LinkedIn" },
        { category: "Manual", name: "Indeed" },
        { category: "Manual", name: "Github" },
        { category: "Other", name: "Uploaded" },
        { category: "Other", name: "Other" },
    ]);

    // We will get these from the employer data, and if they don't already have them, we'll save these as the default

    const categories = ["Career Site", "Internal", "Referral", "Manual", "Other"];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest('.selector-container')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);


    // get the sources from employer. If sources dont exist in the employer data then add the ones which are currently hard coded

    useEffect(() => {
        if (employer) {
            if (!employer.sources) {
                const employerRef = doc(db, "teams", employerID);
                updateDoc(employerRef, {
                    sources: sources
                });
            } else {
                setSources(employer.sources);
            }
        }
    }, [employer])



    // handling creating a new source

    const [createSourcePopupOpen, setCreateSourcePopupOpen] = useState(false);
    const [newSourceCategory, setNewSourceCategory] = useState('');
    const [newSourceName, setNewSourceName] = useState('');

    const addNewSource = () => {
        if (newSourceCategory && newSourceName) {
        setSources([...sources, { category: newSourceCategory, name: newSourceName }]);

        const employerRef = doc(db, "teams", employerID);
        updateDoc(employerRef, {
            sources: arrayUnion({ category: newSourceCategory, name: newSourceName })
        });
        setCreateSourcePopupOpen(false);
        setNewSourceCategory('');
        setNewSourceName('');
        }
    };


    return (
        <>
        <Popup
            trigger={
                <div className="add_candidates_section_detail">
                    <p className="add_candidates_section_detail_name">Source</p>
                    <p className="add_candidates_section_detail_desc">{candidateSource.name}</p>
                    <FaIcons.FaExpandAlt className="add_candidates_section_detail_icon"/>
                </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup" style={{width: "40%", height:"40%"}}>

                    <h1 className="popup_heading" style={{marginBottom:"2rem"}}>Candidate Source</h1>
                    
                    <div className="popup_close" onClick={(e) => setPopupOpen(false)}>
                        <X />
                    </div>

                    <div className="popup_body" style={{marginTop:"0"}}>
                        <p className="candidate_sourcing_desc">Where did you find these candidates? </p>
                        <p className="candidate_sourcing_desc">Select a source or <span className="create_new_source_text" onClick={() => setCreateSourcePopupOpen(true)}>create a new one!</span></p>

                        <div className="selector-container">
                            <div 
                                className="selector" 
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <span>{candidateSource.name}</span>
                                {isOpen ? <ChevronUp /> : <ChevronDown />}
                            </div>
                            {isOpen && (
                                <ul className="options-list">
                                    {categories.map((category, categoryIndex) => (
                                        <li key={categoryIndex} className="category">
                                            <div className="category-name">{category}</div>
                                            <ul className="category-sources">
                                                {sources
                                                    .filter(source => source.category === category)
                                                    .map((source, sourceIndex) => (
                                                        <li 
                                                            key={sourceIndex}
                                                            onClick={() => {
                                                                setCandidateSource(source);
                                                                setIsOpen(false);
                                                            }}
                                                        >
                                                            {source.name}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        
                    </div>
                </div>  
            </div>
        </Popup>


        <Popup 
            open={createSourcePopupOpen}
            onOpen={() => setCreateSourcePopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder" style={{backdropFilter: "none"}}>
                <div className="popup" style={{width: "40%", height:"40%"}}>
                    <h1 className="popup_heading" style={{marginBottom:"2rem"}}>Create New Source</h1>
                    
                    <div className="popup_close" onClick={(e) => setCreateSourcePopupOpen(false)}>
                        <X />
                    </div>

                    <div className="popup_body">
                        <div className="add_source_section">
                            <p className="add_source_section_name">Category:</p>
                            <select 
                            className="add_source_section_input"
                            id="category-select"
                            value={newSourceCategory}
                            onChange={(e) => setNewSourceCategory(e.target.value)}
                            >
                            <option value="">Select a category</option>
                            {categories.map((category, index) => (
                                <option key={index} value={category}>{category}</option>
                            ))}
                            </select>
                        </div>

                        <div className="add_source_section">
                            <p className="add_source_section_name">Source Name:</p>
                            <input 
                            type="text"
                            className="add_source_section_input"
                            id="source-name"
                            value={newSourceName}
                            onChange={(e) => setNewSourceName(e.target.value)}
                            placeholder="Enter source name"
                            />
                        </div>

                        <button 
                            className="add_source_button"
                            onClick={addNewSource}
                            disabled={!newSourceCategory || !newSourceName}
                        >
                            Add Source
                        </button>
                    </div>

                    {/* eventually add a way to create new categories too */}
                </div>
            </div>
        </Popup>

        </>
    )
}