import Popup from "reactjs-popup"
import * as FaIcons from "react-icons/fa"
import "./candidatesMassActions.css"
import { useEffect, useRef, useState } from "react"
import { sendNotification } from "../../../components/notification"
import { X } from "lucide-react"
import { Timestamp, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../../firebase"
import ReactQuill from "react-quill"
import { Placeholder, placeholders } from "../../../components/candidateEmailHistory"

export const CandidatesMassActions = ({selectedCandidates, deleteCandidates, job, candidates, setCandidates, setIsAllChecked}) => {

  const [deleteCandidatePopupOpen, setDeleteCandidatePopupOpen] = useState(false);
  const [sendEmailPopupOpen, setSendEmailPopupOpen] = useState(false);
  const [rejectCandidatePopupOpen, setRejectCandidatePopupOpen] = useState(false);

  const [selectedAction, setSelectedAction] = useState("Actions");
  const [employer, setEmployer] = useState(null);

  useEffect(() => {
    const fetchEmployer = async () => {
      const employerRef = doc(db, "teams", job.employer_id);
      const employerSnap = await getDoc(employerRef);
      if (employerSnap.exists()) {
          setEmployer(employerSnap.data());
      }
    }

    if (job) {
      fetchEmployer();
    }
    
  }, [job])

  const handleChangedOption = (e) => {

    if (e.target.value === "Actions") {
      setSelectedAction("Actions");
      return;
    }

    if (selectedCandidates.length === 0) {
      sendNotification("Please select at least one candidate");
      return;
    }
    
    setSelectedAction(e.target.value)

    switch (e.target.value) {
      case "Send Email":
        setSendEmailPopupOpen(true);
        break;
      case "Reject":
        loadRejectionEmail();
        setRejectCandidatePopupOpen(true);
        break;
      case "Delete":
        setDeleteCandidatePopupOpen(true);
        break;
    }
    
  }


  const handleClosingPopup = () => {
    setEmailBody('');
    setEmailSubject('');
    setSelectedAction("Actions");
    setDeleteCandidatePopupOpen(false);
    setSendEmailPopupOpen(false);
    setRejectCandidatePopupOpen(false);
  }

  const handleDeleteCandidates = () => {
    deleteCandidates();
    handleClosingPopup();
  }

  const removeAllChecks = (givenCandidates = null) => {


    //should check all the FILTERED candidates

    if (!givenCandidates) {
      givenCandidates = candidates
    }

    const updatedCandidates = givenCandidates.map(candidate => {
      return {
        ...candidate,
        checked: false
      };
    })
    setCandidates(updatedCandidates);

    setIsAllChecked(false);
  }


  // Handling the send email section

  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const handleEmailBodyChange = (content, delta, source, editor) => {
    setEmailBody(editor.getHTML()); // or content for just the HTML content
  } ;

  const loadTemplate = (templateKey) => {
    let subject, body;

    switch (templateKey) {
        case 'no_update':
            subject = employer.settings.email_templates.no_update.subject;
            body = employer.settings.email_templates.no_update.body;
            break;
        case 'default':
            subject = "";
            body = "";
            break;
        default:
            subject = employer.settings.email_templates.custom[parseInt(templateKey)].subject;
            body = employer.settings.email_templates.custom[parseInt(templateKey)].body;
            // this means that it's an index of the custom emails
            break;
    }

    // subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
    subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
    subject = subject.replace(/{{employer_name}}/g, employer?.name);

    // body = body.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
    body = body.replace(/{{job_title}}/g, job?.jobTitle);
    body = body.replace(/{{employer_name}}/g, employer?.name);

    setEmailSubject(subject);
    setEmailBody(body);
  };

  const parseEmail = ({subject, body}) => {


    if ((!subject) || !(body)) {
      return;
    }

    subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
    subject = subject.replace(/{{employer_name}}/g, employer?.name);

    body = body.replace(/{{job_title}}/g, job?.jobTitle);
    body = body.replace(/{{employer_name}}/g, employer?.name);

    return [subject, body];

  }

  useEffect(() => {
    if ((emailSubject !== undefined) && (emailBody !== undefined)) {
      let emailData = parseEmail(emailSubject, emailBody);
      if (emailData) {
        setEmailSubject(emailData[0]);
        setEmailBody(emailData[1]);
      }
      
    }
  }, [emailBody, emailSubject])


  const sendCandidatesEmail = () => {

    if (emailBody === '') {
      sendNotification("Please enter an email body")
      return;
    }

    if (emailSubject === '') {
        sendNotification("Please enter an email subject")
        return;
    }

    selectedCandidates.forEach(candidate => {
      fetch('https://api.skillpool.tech/send_email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            senderEmail: candidate?.employerEmail,
            receiverEmail: candidate?.email,
            subject: emailSubject,
            body: emailBody,
            candidateID: candidate.id,
        }),
        // Include headers if your server requires them
      });
    });

    setSentCandidateEmails(true);

    setTimeout(() => {
      setSentCandidateEmails(false);
      handleClosingPopup();
      removeAllChecks();
    }, 1000)

    
  }


  // Handling rejecting a candidate

  const loadRejectionEmail = () => {

    let subject = employer.settings.email_templates.rejection.default.subject
    let body = employer.settings.email_templates.rejection.default.body

    subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
    subject = subject.replace(/{{employer_name}}/g, employer?.name);

    body = body.replace(/{{job_title}}/g, job?.jobTitle);
    body = body.replace(/{{employer_name}}/g, employer?.name);

  
    setEmailSubject(subject);
    setEmailBody(body);

    
  }

  const rejectCandidates = () => {

    if (emailBody === '') {
      sendNotification("Please enter an email body")
      return;
    }

    if (emailSubject === '') {
        sendNotification("Please enter an email subject")
        return;
    }

    sendCandidatesEmail();

    const updatedCandidates = candidates.map(candidate => {
        if (selectedCandidates.some(selected => selected.id === candidate.id)) {
            return { ...candidate, stage: -1 };
            
        }
        return candidate;
    });

    setCandidates(updatedCandidates);

    selectedCandidates.forEach(async (candidate) => {
        try {
            const candidateRef = doc(db, "candidates", candidate.id);
            await updateDoc(candidateRef, {
                stage: -1
            });
        } catch (error) {
            console.error("Error rejecting candidate:", error);
        }
    });


    setRejectedCandidates(true);

    setTimeout(() => {
      setRejectedCandidates(false);
      handleClosingPopup();
      removeAllChecks(updatedCandidates);
    }, 1000)

  }
  

  // handling confirmation messages

  const [sentCandidateEmails, setSentCandidateEmails] = useState(false);
  const [rejectedCandidates, setRejectedCandidates] = useState(false);

  return (
    <>

      <select className="job_candidates_actions" value={selectedAction} onChange={handleChangedOption}>
        <option value="Actions" className="candidates_action">
          Actions
        </option>
        <option value="Send Email" className="candidates_action">
          Send Email
        </option>
        <option value="Reject" className="candidates_action">
          Reject
        </option>
        <option value="Delete" className="candidates_action">
          Delete
        </option>
      </select>


      <Popup
          open={sendEmailPopupOpen}
          onOpen={() => setSendEmailPopupOpen(true)}
          modal
          nested
          position="center center"
      >

      <div className="popup_holder">
        <div className="popup">

            <div className="popup_close" onClick={handleClosingPopup}>
              <X />
            </div>

            <h1 className="popup_heading"> Send Emails </h1>

            

            {
              sentCandidateEmails ? (
                <div className="popup_body">
                  <p>The emails have been sent to the selected candidates!</p>
                </div>
              ) : (
                <>
                  <div className="candidate_send_email_description" style={{display: "flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                    <p style={{width:"70%"}}>Go ahead and enter the details for the email, or choose from one of your templates to the right. </p>
                    <div className="send_email_template">
                        <select className="send_email_select" onChange={(e) => loadTemplate(e.target.value)}>
                            <option value="default" selected >Load Template</option>
                            <option value="no_update">No Update</option>
                            {employer && employer.settings.email_templates.custom.map((template, index) => (
                                <option value={index} key={index}>{template.name}</option>
                            ))}
                            {/* now run a map through all of the custom emails */}
                        </select>
                    </div>
                  </div>

                  <div className="candidate_send_email_holder">
                    <div className="candidate_send_email_details">

                        <h3>Subject: </h3>
                        <input type="text" placeholder="Subject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} className="send_email_subject" />

                        <h3>Body: </h3>
                        <ReactQuill theme="snow" value={emailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="send_email_body"/>
                    </div>

                    <div className="candidate_send_email_placeholders">
                        <h3>Usable Placeholders</h3>
                        <div className="placeholders_list">
                            {placeholders.map((text, index) => (
                                <Placeholder key={index} text={text} />
                            ))}
                        </div>
                    </div>
                </div>
                

                <div className="popup_bottom_actions">
                    <div className="popup_cancel" onClick={handleClosingPopup}>
                        Cancel
                    </div>

                    <div className="popup_action" onClick={sendCandidatesEmail}>
                        Send
                    </div>
                </div>
              </>
              )
            }

        </div>
      </div>

      </Popup>


      <Popup
          open={rejectCandidatePopupOpen}
          onOpen={() => setRejectCandidatePopupOpen(true)}
          modal
          nested
          position="center center"
      >

      <div className="popup_holder">
        <div className="popup">
            <div className="popup_close" onClick={handleClosingPopup}>
              <X />
            </div>

            <h1 className="popup_heading"> Reject Candidates </h1>

            {
              rejectedCandidates ? (
                <div className="popup_body">
                  <p>The selected candidates have been rejected!</p>

                </div>
              ) : (
                <>
                  <div className="popup_body">
                    <input type="text" placeholder="Subject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} className="change_stage_email_subject" />

                    <ReactQuill theme="snow" value={emailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="change_stage_email_body" id="change_stage_email_body" style={{height: "75%"}}/>

                  </div>

                  
                  <div className="candidate_reject_action" onClick={rejectCandidates}>
                      Reject Candidates
                  </div>
                </>
              )
            }



        </div>
      </div>

      </Popup>

      <Popup
          open={deleteCandidatePopupOpen}
          onOpen={() => setDeleteCandidatePopupOpen(true)}
          modal
          nested
          position="center center"
      >

      <div className="popup_holder">
        <div className="popup" style={{width: "40%", height:"40%"}}>
            <div className="popup_close" onClick={handleClosingPopup}>
              <X />
            </div>

            <h1 className="popup_heading">Delete Candidates</h1>

            <div className="popup_body">
              <p>Are you sure you want to delete these candidates?</p>
              <p>This decision cannot be undone!</p>
            </div>

            <div className="popup_bottom_actions delete_bottom_actions">
                <div className="cancel_delete" onClick={handleClosingPopup}>
                    Cancel
                </div>

                <div className="delete_candidate" onClick={handleDeleteCandidates}>
                    Delete
                </div>
            </div>

        </div>
      </div>

      </Popup>

    </>
  )
}
