import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa"
import React, { useState, useEffect, useRef } from 'react';
import NumberSlider from "../../components/slider";
import "../styles/candidates.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { globalCandidates, setGlobalCandidates, stagesNames } from "../job";
import { ButtonGroup, IconButton } from "@mui/material";
import { set } from "date-fns";
import Popup from "reactjs-popup";
import { X } from "lucide-react";
import ToggleButton from "../../components/toggleButton";
import { CandidatesMassActions } from "./candidatesMassActions/candidatesMassActions";
import { CandidateEligibility } from "./candidateUploadingComponents/candidateEligibility";
import { CandidateSourcing } from "./candidateUploadingComponents/candidateSourcing";
import { CandidateDuplicateHandling } from "../../components/candidate_duplicate_handling";
import { HiSparkles } from "react-icons/hi2";


export const Candidates = () => {


    const [candidates, setCandidates] = useState([])
    const [job, setJob] = useState(null);
    const [employer, setEmployer] = useState(null);
    const jobId = window.location.href.split('/')[4];



    useEffect(() => {


        const fetchJob = async () => {
            try {

                const docRef = doc(db, 'jobs', jobId); // Create a reference to the candidate document
                const docSnap = await getDoc(docRef); // Get the document snapshot
                if (docSnap.exists()) {
                    setJob(docSnap.data()); // Set the candidate state with the data from Firestore
                }
                // fetch the employe
                const employerId = docSnap.data().employer_id;
                const employerRef = doc(db, 'teams', employerId); // Create a reference to the employer document
                const employerSnap = await getDoc(employerRef); // Get the document snapshot
                if (employerSnap.exists()) {
                    setEmployer(employerSnap.data()); // Set the employer state with the data from Firestore
                }

            } catch (error) {
                console.error("Error fetching job:", error);
            }
        };


        const fetchCandidates = async () => {
            try {
            const response = await fetch('https://api.skillpool.tech/getCandidates', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ job_id: jobId }),
            });
            const data = await response.json();

            // for each candidate, add a checked state, that detemines if they are selected or not

            data.forEach(candidate => {
                candidate.checked = false;
            });

            setLoading(false);

            setCandidates(data);


            // globalCandidates should be these candidates sorted by stage (highest should be last)

            setGlobalCandidates(data
                .sort((a, b) => {
                    if (a.stage !== b.stage) {
                        return a.stage - b.stage;
                    }
                    const scoreA = a.score || 0;
                    const scoreB = b.score || 0;
                    if (scoreA < 0 && scoreB < 0) return scoreA - scoreB;
                    if (scoreA < 0) return -1;
                    if (scoreB < 0) return 1;
                    return scoreA - scoreB;
                })
                .map((candidate) => (
                    candidate.id
                ))
            );

            } catch (error) {
            console.error("Error fetching candidates:", error);
            }
        };

        if (jobId) {
            fetchJob();
            fetchCandidates();
        }

    }, []);




    const [inputSkillsText, setInputSkillsText] = useState('');
    const inputSkillsRef = useRef();
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            addStringToArray(inputSkillsText);
            inputSkillsRef.current.close();
            setInputSkillsText('');
        // You can perform any action you want with the entered text here
        }
    };


    const handleChange = (event) => {
        setInputSkillsText(event.target.value);
    };

    //This logic handles which checkmarks are currently valid
    const [allFalse, setAllFalse] = useState(true);
    const [checkmarks, setCheckmarks] = useState([new Array(job?.pipeline.length).fill(false)]);


    useEffect(() => {
        setCheckmarks(new Array(job?.pipeline.length).fill(false));
    }, [job?.pipeline.length]);

    const changeCheckmarks = (stage) => {
        stage = stage.stage;
        const newCheckmarks = [...checkmarks];
        newCheckmarks[job?.pipeline.indexOf(stage)] = !newCheckmarks[job?.pipeline.indexOf(stage)];
        setCheckmarks(newCheckmarks);
        var nothingTrue = true;
        newCheckmarks.forEach((checkmark, index) => {
            if (checkmark == true) {
                nothingTrue = false;
            }
        });
        setAllFalse(nothingTrue);
    }

    const [applied, setApplied] = useState(false);
    const [aiInterview, setAiInterview] = useState(false);
    const [considering, setConsidering] = useState(false);
    const [madeOffer, setMadeOffer] = useState(false);
    const [filteredSkills, setFilteredSkills] = useState([]);

    const addStringToArray = (inputValue) => {
        if (!inputValue.trim()) return; // Ignore empty or whitespace-only strings
        setFilteredSkills([...filteredSkills, inputValue]); // Add the new string to the array
    };


    // Handling the filter view

    const [activeView, setActiveView] = useState('regular'); // 'regular' or 'skillscore'

    const changeView = (view) => {
        setActiveView(view);
    };



    // Handling each filter type


    const [locationAvailability, setLocationAvailability] = useState(false);

    const [workAuthorization, setWorkAuthorization] = useState(false);

    const [sponsorshipNotNeeded, setSponsorshipNotNeeded] = useState(false);

    // 1. TOTAL SCORE
    const [minScore, setMinScore] = useState(0);

    const handleMinScoreChange = (event) => {
        setMinScore(event.target.value);
    };


    // 2. EDUCATION

    const [minEducationScore, setMinEducationScore] = useState(0);

    const handleMinEducationScoreChange = (event) => {
        setMinEducationScore(event.target.value);
    };


    // 3. WORK HISTORY

    const [minWorkHistoryScore, setMinWorkHistoryScore] = useState(0);

    const handleMinWorkHistoryScoreChange = (event) => {
        setMinWorkHistoryScore(event.target.value);

    };

    // 4. TECHNICAL SKILLS

    const [minTechnicalSkillsScore, setMinTechnicalSkillsScore] = useState(0);

    const handleMinTechnicalSkillsScoreChange = (event) => {
        setMinTechnicalSkillsScore(event.target.value);
    };

    // 5. SOFT SKILLS

    const [minSoftSkillsScore, setMinSoftSkillsScore] = useState(0);

    const handleMinSoftSkillsScoreChange = (event) => {
        setMinSoftSkillsScore(event.target.value);
    };

    // 6. PERSONALITY FIT

    const [minPersonalityFitScore, setMinPersonalityFitScore] = useState(0);

    const handleMinPersonalityFitScoreChange = (event) => {
        setMinPersonalityFitScore(event.target.value);
    };


    


    //  Identifying how to sort the cnadidates .

    const [sortingCriteria, setSortingCriteria] = useState('stage');

     // handling candidate search


    const [searchTerm, setSearchTerm] = useState('');

    const [semanticSearchResults, setSemanticSearchResults] = useState([]);

    useEffect(() => {
        setSemanticSearchResults([]);
    }, [searchTerm]);

    const conductSemanticSearch = async () => {
        // first get an embedding for the search term

        const response =await fetch('https://api.skillpool.tech/get_embeddings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text: searchTerm,
            }),
        });

        const data = await response.json();

        // if (!data.embedding) {
        //     console.error('No embedding received from the server');
        //     return;
        // }

        const searchEmbedding = Object.values(data);

        // Function to calculate cosine similarity
        const cosineSimilarity = (embeddingA, embeddingB) => {
            const dotProduct = embeddingA.reduce((sum, a, i) => sum + a * embeddingB[i], 0);
            const magnitudeA = Math.sqrt(embeddingA.reduce((sum, a) => sum + a * a, 0));
            const magnitudeB = Math.sqrt(embeddingB.reduce((sum, b) => sum + b * b, 0));
            return dotProduct / (magnitudeA * magnitudeB);
        };

        // Calculate similarity scores for each candidate
        const candidatesWithScores = candidates.map(candidate => {
            if (!candidate.embedding) {
                console.warn(`Candidate ${candidate.id} has no embedding`);
                return { ...candidate, similarityScore: -1 };
            }
            const similarityScore = cosineSimilarity(searchEmbedding, candidate.embedding);
            return { ...candidate, similarityScore };
        });

        // Sort candidates by similarity score in descending order
        const sortedCandidates = candidatesWithScores
            .sort((a, b) => b.similarityScore - a.similarityScore)
            .filter(candidate => candidate.similarityScore > -1);

        setSemanticSearchResults(sortedCandidates);

        // compare the embedding with the candidate embeddings

        

        // then sort by candidates which are most similar
    }


    // whenever a single filter value changes, set the searchTerm to ""

    useEffect(() => {
        setSearchTerm("");
    }, [allFalse, sortingCriteria, locationAvailability, workAuthorization, sponsorshipNotNeeded, minScore, minEducationScore, minWorkHistoryScore, minTechnicalSkillsScore, minSoftSkillsScore, minPersonalityFitScore]);

 


    const filteredCandidates = candidates
    .filter(candidate => {
        if (allFalse) {
            return true;
        } else {
            var selectedStages = checkmarks.reduce((acc, currentState, index) => {
                if (currentState) acc.push(index);
                return acc;
            }, []);

            // Check if candidate's stage matches any of the selected stages
            return selectedStages.includes(candidate.stage);
        }
    })
    .filter(candidate => {
        if (locationAvailability) {
            return (candidate.locationAvailability === "Yes"); // Assumes candidate.location is a boolean indicating availability
        }
        return true; // If locationAvailability is not true, do not filter out on this basis
    })
    .filter(candidate => {
        if (workAuthorization) {
            return (candidate.workElibility === "Yes"); // Assumes candidate.location is a boolean indicating availability
        }
        return true; // If workAuthorization is not true, do not filter out on this basis
    })
    .filter(candidate => {
        if (sponsorshipNotNeeded) {
            return (candidate.sponsorship === "No"); // Assumes candidate.location is a boolean indicating availability
        }
        return true; // If sponsorshipNotNeeded is not true, do not filter out on this basis
    })
    .filter(candidate => {
        // Check if the candidate's skillscore and total score exist and meet the minimum
        if (minScore > 0) {
            return candidate.score && candidate.score >= minScore;
        }
        return true;
    })
    .filter(candidate => {
        // Filter for candidate education score
        if (minEducationScore > 0) {
            return candidate.education && candidate.education.score >= minEducationScore;
        }
        return true;
    })
    .filter(candidate => {
        // Filter for candidate work history score
        if (minWorkHistoryScore > 0) {
            return candidate.work && candidate.work.score >= minWorkHistoryScore;
        }
        return true;
    })
    .filter(candidate => {
        // Filter for candidate work history score
        if (minTechnicalSkillsScore > 0) {
            return candidate.technical_skills && candidate.technical_skills.score >= minTechnicalSkillsScore;
        }
        return true;
    })
    .filter(candidate => {
        // Filter for candidate work history score
        if (minSoftSkillsScore > 0) {
            return candidate.soft_skills && candidate.soft_skills.score >= minSoftSkillsScore;
        }
        return true;
    })
    .filter(candidate => {
        // Filter for candidate work history score
        if (minPersonalityFitScore > 0) {
            return candidate.personality_traits && candidate.personality_traits.score >= minPersonalityFitScore;
        }
        return true;
    })
    .filter(candidate => {
        if (searchTerm) {
            const searchTermLower = searchTerm.toLowerCase();
            return (
                candidate.name.toLowerCase().includes(searchTermLower) ||
                candidate.email.toLowerCase().includes(searchTermLower) ||
                candidate.phone.toString().toLowerCase().includes(searchTermLower) ||
                (candidate.location?.label || "").toLowerCase().includes(searchTermLower)
            );
        }
        return true;
    })
    .sort((a, b) => {
        // Primary sorting: Stage other than 0 should be above those at stage 1
        if (a.stage === -1 && b.stage !== -1) {
            return 1; // a is less preferred, goes below
        } else if (a.stage !== -1 && b.stage === -1) {
            return -1; // a is more preferred, goes above
        }

        // Secondary sorting based on sorting_type
        switch (sortingCriteria) {
            case "stage":
                return -(a.stage - b.stage); // Descending sort by stage
            case "name":
                return a.name.localeCompare(b.name); // Alphabetical sort by name
            case "apply_time":
                
                return (a.application_time._seconds || a.application_time.seconds) - (b.application_time._seconds || b.application_time.seconds); // Ascending sort by application time
            case "apply_time_desc":
                return -(a.application_time._seconds || a.application_time.seconds) + (b.application_time._seconds || b.application_time.seconds); // Descending sort by application time
            case "score":
                // Safe checks for accessing skillscore.score
                const scoreA = a.score ? a.score : 0;
                const scoreB = b.score ? b.score : 0;
                return scoreB - scoreA; // Descending sort by score
            default:
                return 0; // Default case to handle unexpected sorting_type values
        }
    });
    // .sort((a, b) => b.total_score.score - a.total_score.score);

    //The filter logic section ends here




    // Handling candidate selection

    const [isAllChecked, setIsAllChecked] = useState(false);


    const handleAllCheck = (event) => {


        //should check all the FILTERED candidates

        const updatedCandidates = candidates.map(candidate => {
            if (filteredCandidates.includes(candidate)) {
                return {
                    ...candidate,
                    checked: !isAllChecked
                };
            } else {
                return candidate;
            }
        })
        setCandidates(updatedCandidates);

        setIsAllChecked(!isAllChecked);
    };

    const handleSingleCheck = (event, id) => {
        const updatedCandidates = candidates.map(candidate => {
            if (candidate.id === id) {
                return {
                    ...candidate,
                    checked: !candidate.checked
                };
            }
            return candidate;
        });
        setCandidates(updatedCandidates);

        // Determine if all checkboxes are checked
        setIsAllChecked(updatedCandidates.every(candidate => candidate.checked));
        event.preventDefault();
    };



    const deleteCandidates = async () => {

        // the candidates to delete are the ones with checked state true

        const candidatesToDelete = candidates.filter(candidate => candidate.checked);

        const remainingCandidates = candidates.filter(candidate =>
            !candidatesToDelete.some(toDelete => toDelete.id === candidate.id)
        );

        // Reset the checkboxes for the remaining candidates by making all their checked variables false
        remainingCandidates.forEach(candidate => candidate.checked = false);
        setCandidates(remainingCandidates);


        setIsAllChecked(false);

        candidatesToDelete.map((candidate) => {
            try {
                const response = fetch('https://api.skillpool.tech/delete_candidate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ candidateId: candidate.id }),
                });

            } catch (error) {
                console.error("Error deleting candidates", error);
            }
        });


    }


    // Logic to upload new candidates

    const [addCandidatePopupIsOpen, setAddCandidatePopupOpen] = useState(false);

    const [uploadingFiles, setUploadingFiles] = useState(false);

    const [files, setFiles] = useState([]); // This will store file objects with { name, id }

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        newFiles.forEach(file => {
            uploadFile(file);
        });
    };

    const uploadFile = (file) => {

        setUploadingFiles(true);
        const formData = new FormData();
        formData.append('file', file);
        console.log("hello???");
        fetch('https://api.skillpool.tech/apply/upload', {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            setUploadingFiles(false);
            if (data && data.id) {
                
                setFiles(prevFiles => [...prevFiles, { name: file.name, id: data.id }]);
            }
        })
        .catch(error => console.error('Error uploading file', error));
    };

    const handleRemoveFile = (fileId, fileName) => {
        fetch('https://api.skillpool.tech/apply/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fileName: fileId })
        })
        .then(response => response.json())
        .then(data => {
            setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
        })
        .catch(error => console.error('Error removing file', error));
    };

    const handleUploadAll = () => {

        fetch('https://api.skillpool.tech/upload_candidates', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                files: files.map(file => file.id),
                job_id: jobId,
                locationResponse: locationTrue,
                workAuthorization: workAuthorizationTrue,
                sponsorshipNeeded: sponsorshipNeeded,
                source: candidateSource,
            })
        })
 

        setLocationTrue(true);
        setWorkAuthorizationTrue(true);
        setSponsorshipNeeded(false);
        setFiles([]);

        setSubmitted(true);


        setTimeout(() => {
            setAddCandidatePopupOpen(false);
            setSubmitted(false);
            setReloadPage(true);
        }, 1000);

    };


    const [reloadPage, setReloadPage] = useState(false);

    // Logic to display confirmation screen

    const [submitted, setSubmitted] = useState(false);


    const cancelAddCandidates = () => {
        setLocationTrue(true);
        setWorkAuthorizationTrue(true);
        setSponsorshipNeeded(false);
        setFiles([]);
        setAddCandidatePopupOpen(false)
    }



    // Handling settings for adding new candidates

    const [locationTrue, setLocationTrue] = useState(true);
    const [workAuthorizationTrue, setWorkAuthorizationTrue] = useState(true);
    const [sponsorshipNeeded, setSponsorshipNeeded] = useState(false);

    // handling the logic for candidate sourcing
    const [candidateSource, setCandidateSource] = useState({
        name: "Uploaded",
        category:"Other",
    });


    // Handle loading and displays for candidate table

    const [loading, setLoading] = useState(true);
    


    return (
        <div className="candidates">

        <div className = "candidateSection">

            <div className="job_candidates_options_holder">
                <div className="job_candidates_options_start" style={{width: "20%" }}>

                </div>



                <div className="job_candidates_options">

                    <div style={{display: "flex", alignItems:"center"}}>
                        <div className="job_candidates_add_candidates">

                            <Popup
                                trigger={
                                    <IconButton>
                                        <FaIcons.FaPlus className="job_candidates_option_icon" />
                                    </IconButton>
                                }
                                open={addCandidatePopupIsOpen}
                                onOpen={() => setAddCandidatePopupOpen(true)}
                                modal
                                nested
                                position="center center"
                            >
                                <div className="popup_holder">
                                    <div className="popup">
                                        <h1 className="popup_heading">Add Candidates</h1>

                                        { !submitted && (
                                        <>
                                            <div className="add_candidates_body">

                                            <div className="add_candidates_file_input">
                                                <div className="file-input-container">
                                                    <label className="label-file-input">
                                                        Add Resumes
                                                        <input type="file" className="file-input" multiple onChange={handleFileChange}
                                                            // style={{
                                                            //     display:"none"
                                                            // }}
                                                        />
                                                    </label>
                                                </div>


                                                <div className="file_list_area">

                                                    {
                                                        uploadingFiles && (
                                                            <p>Uploading files...</p>
                                                        )
                                                    }
                                                    <ul className="file-list">
                                                        {files.map((file, index) => (
                                                            <li key={index}>
                                                                {file.name}
                                                                <button className="button button-remove" onClick={() => handleRemoveFile(file.id, file.name)}>
                                                                    <FaIcons.FaTrash
                                                                        style={{
                                                                            fontSize: ".8rem"
                                                                        }}
                                                                    />
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                            </div>

                                            <div className="add_candidates_details">

                                                <h2 className="add_candidate_details_heading">Details</h2>

                                                <CandidateEligibility 
                                                    job={job}
                                                    locationTrue={locationTrue}
                                                    setLocationTrue={setLocationTrue}
                                                    workAuthorizationTrue={workAuthorizationTrue}
                                                    setWorkAuthorizationTrue={setWorkAuthorizationTrue}
                                                    sponsorshipNeeded={sponsorshipNeeded}
                                                    setSponsorshipNeeded={setSponsorshipNeeded}
                                                />

                                                {
                                                    job && (
                                                        <CandidateSourcing 
                                                            employer={employer}
                                                            employerID={job.employer_id}
                                                            candidateSource={candidateSource}
                                                            setCandidateSource={setCandidateSource}
                                                        />
                                                    )
                                                }

                                                

                                            </div>

                                            </div>

                                            <div className="popup_bottom_actions">
                                                <div className="popup_cancel" onClick={cancelAddCandidates}>
                                                    Cancel
                                                </div>

                                                <div className="popup_action" onClick={handleUploadAll}>
                                                    Add
                                                </div>
                                            </div>
                                        </>
                                        )}


                                        {submitted && (
                                            <>
                                            <p>The candidates have been submitted, they will be processed now!</p>
                                            <p>Please refresh the page to see the uploaded candidates in the candidates list!</p>
                                            </>
                                        )}


                                    </div>
                                </div>

                            </Popup>


                        </div>


                        <CandidatesMassActions 
                            selectedCandidates={candidates.filter(candidate => candidate.checked)} 
                            deleteCandidates={deleteCandidates}
                            job={job}
                            candidates={candidates}
                            setCandidates={setCandidates}
                            setIsAllChecked={setIsAllChecked}
                        />



                    </div>




                    <div className="job_candidates_sort_by">
                        <select className="job_candidates_sort_by_select"
                            value={sortingCriteria}
                            onChange={(e) => setSortingCriteria(e.target.value)}
                        >
                            <option value="stage" selected>Sort By Stage</option>
                            <option value="name">Sort By Name</option>
                            <option value="apply_time">Sort By Earliest - Latest</option>
                            <option value="apply_time_desc">Sort By Latest - Earliest</option>
                            <option value="score">Sort By Score</option>
                        </select>
                    </div>
                </div>
            </div>

            <div style={{display:"flex", height:"100%"}}>
                <div className="candidateFilter" >

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: ".5rem"}}>
                        <h3 className="filter_options_heading">
                            Filtering Options
                        </h3>
                    </div>

                    {activeView === 'regular' ? (
                    <div className="regular_filter">

                        <p>Regular Filter</p>

                        <div className="filterCriteria stage_filter">
                            <p style={{fontSize:"1rem", fontWeight:"600"}}>
                                Stage
                            </p>
                            <div className="stage_filter_area">
                                {
                                    job?.pipeline.map((stage, index) => (
                                        <div style={{marginBottom:"0.5rem", display:"flex", alignItems:"center"}}>
                                            <input
                                            type="checkbox"
                                            value={checkmarks[index]}
                                            checked = {checkmarks[index]}
                                            onChange={(e) => changeCheckmarks({stage})}
                                            style={{marginRight:"0.5rem", fontSize:"0.8rem"}}
                                            />
                                            <p style={{margin: "0", fontSize:".9rem"}}>{stage?.details?.name || stagesNames[stage.label]}</p>
                                        </div>
                                ))}
                            </div>

                        </div>

                        <div className="filterCriteria availability">
                            <p style={{fontSize:"1rem", fontWeight:"600"}}>
                                Availability
                            </p>


                            {job?.location && (
                                <div className="filter_options_location filter_option_availability" style={{display:"flex", alignItems:"center"}}>
                                    <input
                                    type="checkbox"
                                    style={{marginRight:"0.5rem", fontSize:"0.8rem"}}
                                    checked={locationAvailability}
                                    onChange={() => setLocationAvailability(!locationAvailability)}
                                    />
                                    <p style={{fontSize:".9rem"}}>Available in {job?.location?.label?.split(',')[0]}</p>
                                </div>
                            )}


                            <div className="filter_options_legally_authorized filter_option_availability" style={{display:"flex", alignItems:"center"}}>
                                <input
                                type="checkbox"
                                style={{marginRight:"0.5rem", fontSize:"0.8rem"}}
                                checked={workAuthorization}
                                onChange={() => setWorkAuthorization(!workAuthorization)}
                                />
                                <p style={{fontSize:".9rem"}}>Authorized to work in country</p>
                            </div>

                            <div className="filter_options_legally_authorized filter_option_availability" style={{display:"flex", alignItems:"center"}}>
                                <input
                                type="checkbox"
                                style={{marginRight:"0.5rem", fontSize:"0.8rem"}}
                                checked={sponsorshipNotNeeded}
                                onChange={() => setSponsorshipNotNeeded(!sponsorshipNotNeeded)}
                                />
                                <p style={{fontSize:".9rem"}}> <b>Doesn't</b> need sponsorship</p>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div className="skillscore_filter">
                            <p>SkillScore Filter</p>
                            {/* Content for SkillScore Filter */}

                            <div className = "filterCriteria filterScore">
                                <div className="filter_heading">
                                    <p style={{fontSize:"1rem", fontWeight:"600"}}>
                                        Total Score
                                    </p>
                                    <p>
                                        {minScore}
                                    </p>
                                </div>

                                <NumberSlider
                                    value={minScore}
                                    onChange={handleMinScoreChange}
                                />
                            </div>


                            <div className = "filterCriteria filterScore">
                                <div className="filter_option_subcategory">
                                    <p style={{fontSize:"1rem", fontWeight:"500"}}>
                                        Education
                                    </p>
                                    <p>
                                        {minEducationScore}
                                    </p>
                                </div>

                                <NumberSlider
                                    value={minEducationScore}
                                    onChange={handleMinEducationScoreChange}
                                />

                            </div>


                            <div className = "filterCriteria filterScore">
                                <div className="filter_option_subcategory">
                                    <p>
                                        Work History
                                    </p>
                                    <p>
                                        {minWorkHistoryScore}
                                    </p>
                                </div>

                                <NumberSlider
                                    value={minWorkHistoryScore}
                                    onChange={handleMinWorkHistoryScoreChange}
                                />
                            </div>


                            <div className = "filterCriteria filterScore">
                                <div className= "filter_option_subcategory">
                                    <p>
                                        Technical Skills
                                    </p>
                                    <p>
                                        {minTechnicalSkillsScore}
                                    </p>
                                </div>

                                <NumberSlider
                                    value={minTechnicalSkillsScore}
                                    onChange={handleMinTechnicalSkillsScoreChange}
                                />
                            </div>


                            <div className = "filterCriteria filterScore">
                                <div className="filter_option_subcategory">
                                    <p>
                                        Soft Skills
                                    </p>
                                    <p>
                                        {minSoftSkillsScore}
                                    </p>
                                </div>

                                <NumberSlider
                                    value={minSoftSkillsScore}
                                    onChange={handleMinSoftSkillsScoreChange}
                                />
                            </div>


                            <div className = "filterCriteria filterScore">
                                <div className="filter_option_subcategory">
                                    <p>
                                        Personality Fit
                                    </p>
                                    <p>
                                        {minPersonalityFitScore}
                                    </p>
                                </div>

                                <NumberSlider
                                    value={minPersonalityFitScore}
                                    onChange={handleMinPersonalityFitScoreChange}
                                />
                            </div>



                        </div>
                    )}

                    <div className="switch_filter_view" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>

                        <div class="arrow left"  onClick={() => changeView('regular')} style={{display:"flex", alignItems:"center"}}>
                            <FaIcons.FaChevronLeft />
                        </div>

                        <div style={{display: 'flex', marginLeft: "1rem", marginRight:"1rem"}} >
                            <div
                                className={`dot ${activeView === 'regular' ? 'active' : ''}`}
                                onClick={() => changeView("regular")}
                            />
                            <div
                                className={`dot ${activeView === 'skillscore' ? 'active' : ''}`}
                                onClick={() => changeView("skillscore")}
                            />
                        </div>

                        <div class="arrow right"  onClick={() => changeView('skillscore')} style={{display:"flex", alignItems:"center"}}>
                            <FaIcons.FaChevronRight />
                        </div>

                    </div>




                </div>



                <div className="candidateTable">

                    <div className="candidatesSearchHolder">
                        <FaIcons.FaSearch style={{opacity:0.5}} />
                        <input
                            className="searchBar"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            placeholder={(
                                "Search!"
                        )}/>
                    </div>

                    <div className="candidateHeading" style = {{width: "100%"}}>

                        <div className="job_candidate_checkbox_cell">
                            <div
                                style={{
                                    border: "1px solid var(--primary)",
                                    width: ".7rem",
                                    height: ".7rem",
                                    borderRadius: "20%",
                                    backgroundColor: isAllChecked ? "var(--primary)" : "transparent",
                                    cursor: "pointer",
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleAllCheck(event);
                                }}
                            />
                        </div>

                        <div className="job_candidate_name_cell">
                            Name
                        </div>

                        <div className="job_candidate_email_cell">
                            Email
                        </div>

                        <div className="job_candidate_phone_cell">
                            Phone
                        </div>

                        <div className="job_candidate_stage_cell">
                            Stage
                        </div>

                        <div className="job_candidate_score_cell">
                            Score
                        </div>

                        <div className="job_candidate_info_cell">
                            {/* this is for any icons (such as the thing I'll use to show duplicate candidates) */}
                                {/* obviously nothing to show in the header */}
                        </div>

                    {/*
                        This will later be changed into a for-each type data entry
                        */}
                    </div>

                    {/* <Link to = "../candidate/47829" className="candidateLink"> */}

                    <div className="candidatesBody">

                            {
                                loading && (
                                    <div className="loading_candidates">
                                        <div className="loading_candidates_spinner">Loading Candidates...</div>
                                    </div>
                                )
                            }

                            {
                                !loading && !reloadPage && filteredCandidates.length === 0 && semanticSearchResults.length === 0 && (
                                    searchTerm === "" ? (
                                        <div className="no_candidates">
                                            <div className="no_candidates_text">
                                                No candidates yet! Feel free to add some candidates!
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="no_candidates">
                                            <div className="no_candidates_text">
                                                <p style={{margin:0}}>Looks like no candidates match your exact search! Do you want to try a semantic search instead?</p>
                                                <button className="semantic_search_button" onClick={conductSemanticSearch}>
                                                    <HiSparkles style={{marginRight:"0.5rem"}} />
                                                    Semantic Search
                                                </button>
                                            </div>
                                        </div>
                                    )
                                )
                            }

                        {
                            !loading && reloadPage && (
                            <div className="loading_candidates">
                                <div className="loading_candidates_spinner">Please reload the page to view the uploaded candidates!</div>
                            </div>
                        )}

                        

                        {
                            !loading && !reloadPage && (semanticSearchResults.length > 0 ? semanticSearchResults : filteredCandidates).map((candidate, index) => (
                            <Link to={`../candidate/${candidate.id}`} className='candidate_link' style={{width:"100%"}}>
                                <div style = {{width: "100%"}} className="candidate">

                                    <div className="job_candidate_checkbox_cell">
                                        <div
                                            className="checkbox"
                                            style={{
                                                border: "1px solid var(--primary)",
                                                width: ".7rem",
                                                height: ".7rem",
                                                borderRadius: "20%",
                                                backgroundColor: candidate?.checked ? "var(--primary)" : "transparent",
                                                cursor: "pointer",
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleSingleCheck(event, candidate?.id);
                                            }}
                                        />
                                    </div>

                                    <div className="job_candidate_name_cell">
                                        {candidate?.name}
                                    </div>

                                    <div className="job_candidate_email_cell" style={{overflowX:"hidden"}}>
                                        {candidate?.email}
                                    </div>

                                    <div className="job_candidate_phone_cell">
                                        {candidate?.phone}
                                    </div>

                                    <div className="job_candidate_stage_cell">
                                        {candidate?.stage >= 0 ? (job?.pipeline[candidate?.stage]?.details?.name || stagesNames[job?.pipeline[candidate?.stage]?.label]) : "Rejected"}
                                    </div>

                                    <div className="job_candidate_score_cell">
                                        {candidate?.score || "NA"}
                                    </div>
                                    
                                    <div className="job_candidate_info_cell">
                                        {
                                            (candidate.duplicates && candidate.duplicates.length > 0) && (
                                                <CandidateDuplicateHandling 
                                                    candidate={candidate}
                                                    duplicates={candidates.filter(c => candidate.duplicates.includes(c.id))}
                                                    jobs={[{...job, id: jobId}]}
                                                    candidates={candidates}
                                                    setCandidates={setCandidates}
                                                />
                                            )
                                        }
                                        {/* ! */}
                                    </div>

                                </div>
                            </Link>

                        ))}
                    </div>


                    {/* </Link> */}


                </div>


            </div>




        </div>
    </div>
    );
};
