import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import "./styles/locationSelector.css"

export const LocationEntry = ({value, setValue, givenGoogleKey = null, additionalClassname = "", additionalHolderClassname = ""}) => {

  const [googleKey, setGoogleKey] = useState(givenGoogleKey);

  useEffect(() => {
    const fetchGoogleKey = async () => {
      const response = await fetch('https://api.skillpool.tech/google_api_key');
      const data = (await response.json()).key;
      setGoogleKey(data);
    }

    fetchGoogleKey();
  }, [])

  return (

  <div style={{paddingRight: "6rem", boxSizing: "border-box"}} className={`location_selector_holder ${additionalHolderClassname}`}>
    {
      googleKey && (
        <GooglePlacesAutocomplete
          apiKey={googleKey}
          selectProps={{
            value,
            className: `location_selector ${additionalClassname}`,
            onChange: setValue,
            styles: {
              control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "var(--secondary40)",
                  backgroundColor: "var(--bg)",
                  background: "var(--bg)",
                  outline: "none",
                  boxShadow: "none",

                  "&:hover": {
                      borderColor: "var(--secondary40)"
                  }
              }),
              option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? 'var(--secondary20)' : 'var(--bg)',
              }),
              menu: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "var(--bg)",
                  color: "var(--text)",
                  zIndex: 999,
                  border: "1px solid var(--secondary40)",
                  borderTopWidth: 0,
                  borderRadius: "0 0 5px 5px",
                  boxShadow: "0px 2px 5px var(--secondary10)"
              }),
          },
          }}
          />
      )
    }

  </div>
)};
