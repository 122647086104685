import { useState } from "react";
import { Draggable } from "react-beautiful-dnd"
import * as FaIcons from "react-icons/fa"
import { stagesNames } from "../../job";
import Popup from "reactjs-popup";
import { X } from "lucide-react";
import ToggleButton from "../../../components/toggleButton";
import { sendNotification } from "../../../components/notification";

export const EditPrescreening = ({index, provided, stage, deletePipelineStage, job, employer}) => {

    const [popupIsOpen, setPopupOpen] = useState(false)

    const openPopup = (event) => {
        setPopupOpen(true);
        event.preventDefault();
    }

    const [locationOn, setLocationOn] = useState(false);

    const changeLocationSetting = () => {

        // check if jobLocation variabel exists in window.localStorage

        if (job.location) {
            setLocationOn(!locationOn);
        } else {
            sendNotification("Please set the job location first!");
            return;
        } 

    }

    const [workEligibilityOn, setWorkEligibilityOn] = useState(false);
    const [sponsorshipOn, setSponsorshipOn] = useState(false);


    const [complete, setComplete] = useState(true);

    const [locationValue, setLocationValue] = useState('');

    return (
        <>
            <Draggable key={stage.id || index} draggableId={`stage-${index}`} index={index}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="edit_pipeline_stage"
                        onClick={openPopup}
                    >
                        <div className="stage_details" style={{display:"flex", alignItems:"center"}}>
                            <div style={{width: "2rem"}}>
                                {
                                    stage.label === "interview" && (
                                        <FaIcons.FaGripVertical style={{ cursor: 'grab' }} />
                                    )
                                }
                            </div>

                            <p style={{margin:0, marginRight:"0.5rem", width:"10rem"}}> {stagesNames[stage.label]}</p>

                            {!complete && (
                                    <div className="stage_complete">
                                        <FaIcons.FaCheck />
                                    </div>
                            )  }

                            {
                                (stage.label === "applied" || stage.label === "hired" || stage.label === "made_offer") ? (
                                    <>
                                    </>
                            ) : (
                                <p style={{margin:0, opacity:0.5, fontWeight:"500"}}>Click to Edit</p>
                            )}
                        </div>
                        
                        <div className="stage_actions">
                            {
                                ((stage.label === "applied" || stage.label === "hired" || stage.label === "made_offer" || stage.label === "skillscore")) ? (
                                    <>
                                    </>
                                ) : (
                                    <FaIcons.FaTrash className="stage_action_icon" onClick={(e) => {
                                        deletePipelineStage(index);
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }} />
                                )
                            }
                        </div>
                    </div>
                )}
            </Draggable>


            <Popup 
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            >
                <div className="popup_holder overlay">
                    <div className="popup">
                        <div className="top_section">

                            <h1 className="create_stage_heading">Pre-Screening </h1>

                            <div className="popup_close" onClick={() => setPopupOpen(false)}>
                                <X />
                            </div>

                            <p className="prescreening_description"> Feel free to add any requirements for auto-rejecting candidates. We try limiting these to <b>deterministic</b> metrics, such as location or work eligibility.</p>

                            {/* Add Screening Questions */}

                            <div className="prescreening_questions">

                            <div className="prescreening_heading">

                                <div className="left_side" style={{display:"flex"}}>

                                    <h4 className="prescreening_heading_category"> Requirement </h4>
                                </div>

                                <div className="right_side">
                                    <h4 className="prescreening_heading_category"> Required </h4>
                                </div>

                            </div>

                                <div className="prescreening_category prescreening_category_first">

                                    <div className="left_side" style={{display:"flex", alignItems:"center"}}>
                                        <FaIcons.FaMapMarkerAlt className="prescreening_icon"/>
                                        <h4 className="prescreening_category_heading"> Can work at the job location </h4>


                                    </div>

                                    <div className="right_side">
                                        <ToggleButton isOn={locationOn} setIsOn={changeLocationSetting} />
                                        {/* Add some sort of button to signify that it's being used */}
                                    </div>

                                </div>

                                <div className="prescreening_category">

                                    <div className="left_side" style={{display:"flex"}}>
                                        <FaIcons.FaFile className="prescreening_icon"/>
                                        <h4 className="prescreening_category_heading"> Work Eligibility </h4>
                                    </div>

                                    <div className="right_side">
                                        <ToggleButton isOn={workEligibilityOn} setIsOn={setWorkEligibilityOn} />
                                        {/* Add some sort of button to signify that it's being used */}
                                    </div>

                                </div>

                                <div className="prescreening_category">

                                    <div className="left_side" style={{display:"flex"}}>
                                        <FaIcons.FaBalanceScale className="prescreening_icon"/>
                                        <h4 className="prescreening_category_heading"> Sponsorship Not Needed </h4>
                                    </div>

                                    <div className="right_side">
                                        <ToggleButton isOn={sponsorshipOn} setIsOn={setSponsorshipOn} />
                                        {/* Add some sort of button to signify that it's being used */}
                                    </div>

                                </div>
                            </div>

                            </div>



                            <div className="stage_bottom_actions" style={{width:"100%", padding: "0rem 1rem", boxSizing:"border-box"}}>
                            <div className="stage_delete" onClick={(e) => deletePipelineStage(index)}>
                                Delete
                            </div>

                            <div className="stage_save" onClick={(e) => setPopupOpen(false)}>
                                Save
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    )
}