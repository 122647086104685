import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "./candidateNotes.css"
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";


export const CandidateNotes = ({candidate, candidateId, setActionsPopupOpen}) => {

    const [popupIsOpen, setPopupOpen] = useState(false);

    const [savedNotes, setSavedNotes] = useState(candidate?.notes)
    const [currentNotes, setCurrentNotes] = useState(candidate?.notes);

    useEffect(() => {
        if (candidate?.notes) {
            setCurrentNotes(candidate?.notes);
        }
    }, [candidate])

    const cancelNotes = () => {
        setCurrentNotes(savedNotes);
        setActionsPopupOpen(false);
        setPopupOpen(false);
    }


    const saveNotes = () => {
        const docRef = doc(db, "candidates", candidateId);
        updateDoc(docRef, {notes: currentNotes})
        setSavedNotes(currentNotes);
        setPopupOpen(false);
        setActionsPopupOpen(false);
    }

    return (
        <Popup
            trigger={
                <div className="candidate_action">
                    View Notes
                </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup">
                    <h1 className="popup_heading">Candidate Notes</h1>

                    <div className="candidate_notes_holder">
                        <textarea 
                            value={currentNotes}
                            onChange={(e) => setCurrentNotes(e.target.value)}
                            className="candidate_notes_input"
                        />
                    </div>

                   

                    <div className="popup_bottom_actions">
                        <div className="popup_cancel" onClick={cancelNotes}>
                            Cancel
                        </div>

                        <div className="popup_action" onClick={saveNotes}>
                            Save
                        </div>
                    </div>


                </div>
            </div>
        </Popup>
    )
}
