import { useEffect, useState } from "react"
import "./styles/scheduling.css"
import ToggleButton from "../../toggleButton";
import * as FaIcons from "react-icons/fa"
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { EmailTemplatePopup } from "../../../pages/settings_subPages/components/emailTemplatePopup";
import Popup from "reactjs-popup";
import { sendNotification } from "../../notification";

export const Scheduling = ({
    automateScheduling,
    setAutomateScheduling,
    automatedSchedulingChoice,
    setAutomatedSchedulingChoice,
    enableInterviewReminder,
    setEnableInterviewReminder,
    reminderTime, 
    setReminderTime,
    employerData,
    employerID,
    hasEmails
}) => {

    

    useEffect(() => {
        if (automateScheduling === true) {
            if (!automatedSchedulingChoice) {
                if (hasEmails) {
                    setAutomatedSchedulingChoice("availability")
                } else {
                    setAutomatedSchedulingChoice("direct")
                }
            }
        } else {
            setAutomatedSchedulingChoice(null);
        }
    }, [automateScheduling])

    useEffect(() => {
       if (hasEmails) {
            if (automatedSchedulingChoice === "direct") {
                sendNotification("Can't choose direct booking when using external interviewers");
                setAutomatedSchedulingChoice("availability");
            }
        }
    }, [automatedSchedulingChoice, hasEmails])


    // handling the interview emails data

    const [interviewEmails, setInterviewEmails] = useState({
        directBooking: {
            body: `<p>Hi {{candidate_name}},</p><p>Congratulations on making it to the next round! We're excited to learn more about you and discuss how your background might be a fit for the {{job_title}} at {{employer_name}}.</p><p>Please schedule a time for our in-depth discussion at your earliest convenience by clicking the following link:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Schedule Interview</a></p><p>We can't wait to meet you virtually and explore the possibilities of working together!</p><p>All the best,</p><p>The {{employer_name}} Recruitment Team</p>`,
            subject: "Congratulations, {{candidate_name}}! Next Steps in Your Application for {{job_title}}",
        },
        availability: {
            body: `<p>Hi {{candidate_name}},</p><p>Congratulations on making it to the next round! We're excited to learn more about you and discuss how your background might be a fit for the {{job_title}} at {{employer_name}}.</p><p>Please indicate your availability for our in-depth discussion at your earliest convenience:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Give Availability</a></p><p>We can't wait to meet you virtually and explore the possibilities of working together!</p><p>All the best,</p><p>The {{employer_name}} Recruitment Team</p>`,
            subject: "Congratulations, {{candidate_name}}! Next Steps in Your Application for {{job_title}}",
        },
        reminder: {
            body: `<p>Hi {{candidate_name}},</p><p>We're sending over this email to remind you of your interview in {{time}}.</p><p>Here's the link for the interview:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Meeting Link</a></p><p>Looking forward to getting in touch with you!</p><p>All the best,</p><p>The {{employer_name}} Recruitment Team</p>`,
            subject: "Reminder for your Interview in {{time}}!",
        }
    })

    // check to see if this exists in the employer data - if not, add it.

    useEffect(() => {
        if (employerData) {
            if (!employerData.settings.email_templates.interviews) {
                // update the actual employer data
                const updatedSettings = {
                    ...employerData.settings,
                    email_templates: {
                        ...employerData.settings.email_templates,
                        interviews: interviewEmails
                    }
                };

                const docRef = doc(db, "teams", employerID);
                updateDoc(docRef, {settings: updatedSettings})
            } else {
                setInterviewEmails(employerData.settings.email_templates.interviews)
            }
        }
    }, [employerData])

    // handling showing the emails

    const [directBookingPopupOpen, setDirectBookingPopupOpen] = useState(false);
    const [availabilityPopupOpen, setAvailabilityPopupOpen] = useState(false);
    const [reminderPopupOpen, setReminderPopupOpen] = useState(false);

    const [directBookingSubject, setDirectBookingSubject] = useState(interviewEmails.directBooking.subject);
    const [directBookingBody, setDirectBookingBody] = useState(interviewEmails.directBooking.body);
    const [availabilitySubject, setAvailabilitySubject] = useState(interviewEmails.availability.subject);
    const [availabilityBody, setAvailabilityBody] = useState(interviewEmails.availability.body);
    const [reminderSubject, setReminderSubject] = useState(interviewEmails.reminder.subject);
    const [reminderBody, setReminderBody] = useState(interviewEmails.reminder.body);



    useEffect(() => {
        setDirectBookingSubject(interviewEmails.directBooking.subject);
        setDirectBookingBody(interviewEmails.directBooking.body);
        setAvailabilitySubject(interviewEmails.availability.subject);
        setAvailabilityBody(interviewEmails.availability.body);
        setReminderSubject(interviewEmails.reminder.subject);
        setReminderBody(interviewEmails.reminder.body);
    }, [interviewEmails])

    const saveEmail = () => {
        const updatedInterviewEmails = {
            directBooking: {
                subject: directBookingSubject,
                body: directBookingBody
            },
            availability: {
                subject: availabilitySubject,
                body: availabilityBody
            },
            reminder: {
                subject: reminderSubject,
                body: reminderBody
            }
        }

        setInterviewEmails(updatedInterviewEmails);

        const docRef = doc(db, "teams", employerID);
        updateDoc(docRef, {settings: {
            ...employerData.settings,
            email_templates: {
                ...employerData.settings.email_templates,
                interviews: updatedInterviewEmails
            }
        }})
    }

    useEffect(() => {
        saveEmail();
    }, [directBookingSubject, directBookingBody, availabilitySubject, availabilityBody, reminderSubject, reminderBody])

    return (
        <div className="interview_section_holder">
            <div className={`scheduling_section automate_section ${automateScheduling ? 'automate' : ''}`}>
                <div className="scheduling_section_top">
                    <p className="interview_section_text">
                        Automate Scheduling Emails Sent
                    </p>

                    <ToggleButton isOn={automateScheduling} setIsOn={setAutomateScheduling} />

                 </div>

                 {
                    automateScheduling && (
                        <div className="scheduling_section_bottom">
                            <p>
                                Scheduling Type
                            </p>

                            <div className="scheduling_choices">
                                <div className={`scheduling_choice ${(automatedSchedulingChoice === "direct") ? 'selected' : ''}`} onClick={() => setAutomatedSchedulingChoice("direct")}>
                                    Candidate Direct Booking
                                </div>

                                <div className={`scheduling_choice ${(automatedSchedulingChoice === "availability") ? 'selected' : ''}`} onClick={() => setAutomatedSchedulingChoice("availability")}>
                                    Candidate Availability
                                </div>
                            </div>
                        </div>
                    )
                 }
            </div>

            <div className="scheduling_section">
                <p className="interview_section_text" style={{margin: 0}}>
                    View Email Templates
                </p>

               

                <div className="scheduling_templates_holder">

                    <Popup
                        trigger={
                            <div className="scheduling_template">
                                <p className="scheduling_template_name">
                                    Candidate Booking Email
                                </p>

                                <div className="scheduling_template_action">
                                    <p>View</p>
                                    <FaIcons.FaExpandAlt />
                                </div>
                            </div>
                        }
                        open={directBookingPopupOpen}
                        onOpen={() => setDirectBookingPopupOpen(true)}
                        modal
                        nested
                        position="center center"
                    >
                        <EmailTemplatePopup
                            subject={directBookingSubject}
                            setSubject={setDirectBookingSubject}
                            body={directBookingBody}
                            setBody={setDirectBookingBody}
                            name={"Candidate Booking Email"}
                            // saveEmail={saveEmail}
                            setPopupOpen={setDirectBookingPopupOpen}
                        />
                    </Popup>
                    

                    <Popup
                        trigger={
                            <div className="scheduling_template">
                                <p className="scheduling_template_name">
                                    Candidate Availability Email
                                </p>

                                <div className="scheduling_template_action">
                                    <p>View</p>
                                    <FaIcons.FaExpandAlt />
                                </div>
                            </div>
                        }
                        open={availabilityPopupOpen}
                        onOpen={() => setAvailabilityPopupOpen(true)}
                        modal
                        nested
                        position="center center"
                    >
                        <EmailTemplatePopup
                            subject={availabilitySubject}
                            setSubject={setAvailabilitySubject}
                            body={availabilityBody}
                            setBody={setAvailabilityBody}
                            name={"Candidate Availability Email"}
                            // saveEmail={saveEmail}
                            setPopupOpen={setAvailabilityPopupOpen}
                        />
                    </Popup>
                    


                    <Popup
                        trigger={
                            <div className="scheduling_template">
                                    <p className="scheduling_template_name">
                                    Reminder Email
                                </p>

                                <div className="scheduling_template_action">
                                <p>View</p>
                                        <FaIcons.FaExpandAlt />
                                    </div>
                                </div>
                            }
                        open={reminderPopupOpen}
                        onOpen={() => setReminderPopupOpen(true)}
                        modal
                        nested
                        position="center center"
                    >
                        <EmailTemplatePopup
                            subject={reminderSubject}
                            setSubject={setReminderSubject}
                            body={reminderBody}
                            setBody={setReminderBody}
                            name={"Reminder Email"}
                            // saveEmail={saveEmail}
                            setPopupOpen={setReminderPopupOpen}
                        />
                    </Popup>
                        

                </div>
            </div>

            <div className={`scheduling_section reminder_section ${enableInterviewReminder ? 'remind' : ''}`}>
                <div className="scheduling_section_top">
                    <p className="interview_section_text">
                        Enable Interview Reminders
                    </p>

                    <ToggleButton isOn={enableInterviewReminder} setIsOn={setEnableInterviewReminder} />

                 </div>

                 {
                    enableInterviewReminder && (
                        <div className="scheduling_section_bottom reminder">
                            <select className="scheduling_section_select" value={reminderTime} onChange={(e) => setReminderTime(parseInt(e.target.value))}>
                                <option value="12">12 hours</option>
                                <option value="24" selected>24 hours</option>
                                <option value="36">36 hours</option>
                                <option value="48">2 days</option>                             
                            </select> 

                            <p>before the interview</p>
                        </div>
                    )
                 }
            </div>

        </div>
    )
}