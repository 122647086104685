import { Handle } from "reactflow";

import "./styles/node.css"
import "./styles/prescreeningNode.css"
import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import ToggleButton from "../toggleButton";
import { deleteNode, job_location, nodesOrder, pipeline, updateNodeData } from "../../pages/createJob";
import { X } from "lucide-react";
import { globalEdges, globalNodes } from "../pipelineEditor";
import { sendNotification } from "../notification";


export const PreScreeningNode = ({ data }) => {





  const [locationOn, setLocationOn] = useState(false);

  const changeLocationSetting = () => {

    // check if jobLocation variabel exists in window.localStorage

    if (job_location) {
      setLocationOn(!locationOn);
    } else {
      sendNotification("Please set the job location first");
      return;
    }


  }

    const [workEligibilityOn, setWorkEligibilityOn] = useState(false);
    const [sponsorshipOn, setSponsorshipOn] = useState(false);

    const [popupIsOpen, setPopupOpen] = useState(false);


    const [complete, setComplete] = useState(true);

    const [locationValue, setLocationValue] = useState('');



    const deleteNode = () => {

        data.deleteNode(data.id, data.pipelineIndex);
        setPopupOpen(false);
    }


    // useEffect(() => {
    //     if (locationOn && locationValue !== '') {
    //         setComplete(true);
    //     } else if (locationOn && locationValue === '') {
    //         setComplete(false);
    //     } else {
    //         setComplete(true);
    //     }
    // }, [locationOn, locationValue]);

    useEffect(() => {
        const nodeData = {
            location: {
                on: locationOn,
                value: locationValue
            },
            workEligibility: workEligibilityOn,
            sponsorship: sponsorshipOn,
            complete: complete,
        }
        updateNodeData(data.index, nodeData);
    }, [locationValue, locationOn, workEligibilityOn, sponsorshipOn])

    return (

        <Popup trigger={

            <div className='pipeline_node_display'>
               
                <div className="pipeline_node_actual_display">
                    <p> Pre-Screening </p>
                    <FaIcons.FaExpandAlt />
                </div>
                <Handle type="target" position="left" id="5"  style={{pointerEvents:"none"}}/>
                {/* <Handle type="target" position="top" id="6"  style={{pointerEvents:"none"}}/> */}
                <Handle type="source" position="right" id="4"  style={{pointerEvents:"none"}}/>
                {/* <Handle type="source" position="bottom" id="3"  style={{pointerEvents:"none"}}/> */}


                {!complete && (
                    <div className="node_incomplete">
                        !
                        {/* Note that this will actually be an orange circle / symbol.  */}
                    </div>
                )}


            </div>

        }

            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >

        <div className="create_stage_holder">
            <div className="create_stage">


                <div className="top_section">

                    <h1 className="create_stage_heading">Pre-Screening </h1>

                    <div className="popup_close" onClick={() => setPopupOpen(false)}>
                        <X />
                    </div>

                    <p className="prescreening_description"> Feel free to add any requirements for auto-rejecting candidates. We try limiting these to <b>deterministic</b> metrics, such as location or work eligibility.</p>

                    {/* Add Screening Questions */}

                    <div className="prescreening_questions">

                    <div className="prescreening_heading">

                        <div className="left_side" style={{display:"flex"}}>

                            <h4 className="prescreening_heading_category"> Requirement </h4>
                        </div>

                        <div className="right_side">
                            <h4 className="prescreening_heading_category"> Required </h4>
                        </div>

                    </div>

                        <div className="prescreening_category prescreening_category_first">

                            <div className="left_side" style={{display:"flex", alignItems:"center"}}>
                                <FaIcons.FaMapMarkerAlt className="prescreening_icon"/>
                                <h4 className="prescreening_category_heading"> Can work at the job location </h4>


                            </div>

                            <div className="right_side">
                                <ToggleButton isOn={locationOn} setIsOn={changeLocationSetting} />
                                {/* Add some sort of button to signify that it's being used */}
                            </div>

                        </div>

                        <div className="prescreening_category">

                            <div className="left_side" style={{display:"flex"}}>
                                <FaIcons.FaFile className="prescreening_icon"/>
                                <h4 className="prescreening_category_heading"> Work Eligibility </h4>
                            </div>

                            <div className="right_side">
                                <ToggleButton isOn={workEligibilityOn} setIsOn={setWorkEligibilityOn} />
                                {/* Add some sort of button to signify that it's being used */}
                            </div>

                        </div>

                        <div className="prescreening_category">

                            <div className="left_side" style={{display:"flex"}}>
                                <FaIcons.FaBalanceScale className="prescreening_icon"/>
                                <h4 className="prescreening_category_heading"> Sponsorship Not Needed </h4>
                            </div>

                            <div className="right_side">
                                <ToggleButton isOn={sponsorshipOn} setIsOn={setSponsorshipOn} />
                                {/* Add some sort of button to signify that it's being used */}
                            </div>

                        </div>
                    </div>

                </div>



                <div className="stage_bottom_actions">
                    <div className="stage_delete" onClick={deleteNode}>
                        Delete
                    </div>

                    <div className="stage_save" onClick={(e) => setPopupOpen(false)}>
                        Save
                    </div>
                </div>
            </div>
        </div>

        </Popup>
    )
};
