import React from 'react';
import './styles/toggleButton.css'; // Import the CSS file

const ToggleButton = ({ isOn, setIsOn }) => {
    return (
      <div
        className={`toggle-button ${isOn ? 'on' : 'off'}`}
        onClick={() => setIsOn(!isOn)}
      >
        <div className="toggle-circle"></div>
      </div>
    );
  };

export default ToggleButton;