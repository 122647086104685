import { X } from "lucide-react";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "./styles/skillscore_category.css";
import {
  WeightageSlider,
  getBackgroundColor,
  interpolateColor,
} from "./components";
import * as FaIcons from "react-icons/fa";
import { JobInfo } from "./skillscore_info/jobInfo";
import { ProjectInfo } from "./skillscore_info/projectInfo";

export const WorkCategory = ({
  weightage,
  setWeightage,
  complete,
  setComplete,
  data,
  setData,
}) => {
  const [popupIsOpen, setPopupOpen] = useState(false);

  const handleWeightageChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setWeightage(event.target.value);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const [jobWeightage, setJobWeightage] = useState(data.jobsRating || 10);
  const [projectWeightage, setProjectWeightage] = useState(data.projectsRating || 10);

  // Handling completeness

  useEffect(() => {
    if (parseInt(weightage) > 0) {
      // if both gpa and university weightage are 0
      if (parseInt(jobWeightage) === 0 && parseInt(projectWeightage) === 0) {
        setComplete(false);
      } else {
        setComplete(true);
      }
    } else {
      setComplete(true);
    }
  }, [jobWeightage, projectWeightage, weightage]);


  // Handling saving the data

  useEffect(() => {
    const data = {
      rating: parseInt(weightage),
      jobsRating: parseInt(jobWeightage),
      projectsRating: parseInt(projectWeightage),
    };
    setData(data);
  }, [jobWeightage, projectWeightage, weightage]);


  return (
    <>
      <div
        className="skillscore_category"
        onClick={openPopup}
        style={{ backgroundColor: getBackgroundColor(weightage) }}
      >
        <p className="skillscore_category_heading">Work History</p>

        {!complete && (
          <div className="node_incomplete">
            !{/* Note that this will actually be an orange circle / symbol.  */}
          </div>
        )}

        <div className="skillscore_category_expand">
          <p style={{ margin: 0, marginRight: "1rem" }}>Click to customize</p>
          <FaIcons.FaExpandAlt />
        </div>

        <div className="weightage_section">
          <div className="weightage_top_row">
            <p>Weightage</p>
            <p>{weightage}</p>
          </div>

          <WeightageSlider
            value={weightage}
            onChange={handleWeightageChange}
            max={10}
          />
        </div>
      </div>

      <Popup
        open={popupIsOpen}
        onOpen={() => setPopupOpen(true)}
        modal
        nested
        position="center center"
      >
        <div className="popup_holder">
          <div className="popup" style={{ width: "50%", height: "50%" }}>
            <h1 className="popup_heading">Work History</h1>

            <div className="popup_body">
              <p
                style={{
                  margin: 0,
                  textAlign: "left",
                  width: "100%",
                  marginBottom: "1.5rem",
                }}
              >
                Please configure the weightage of the subcategories for Work
                History:
              </p>

              <div className="skillscore_subcategory_holder">
                <div
                  className="skillscore_subcategory"
                  style={{ backgroundColor: getBackgroundColor(jobWeightage) }}
                >
                  <p className="skillscore_subcategory_heading">Jobs</p>

                  <JobInfo />

                  <div className="skillscore_subcategory_body">
                    <p>
                      Scores jobs according to the <b>work done</b>,{" "}
                      <b>duration</b>, <b>position</b>, and{" "}
                      <b>company ranking</b>.
                    </p>
                  </div>

                  <div className="subcategory_weightage_section">
                    <div className="weightage_top_row">
                      <p>Weightage</p>
                      <p>{jobWeightage}</p>
                    </div>

                    <WeightageSlider
                      value={jobWeightage}
                      onChange={(e) => setJobWeightage(e.target.value)}
                      max={10}
                    />
                  </div>
                </div>

                <div
                  className="skillscore_subcategory"
                  style={{
                    backgroundColor: getBackgroundColor(projectWeightage),
                  }}
                >
                  <p className="skillscore_subcategory_heading">Projects</p>

                  <ProjectInfo />

                  <div className="skillscore_subcategory_body">
                    <p>
                      Scores projects based on the <b>technical complexity</b>,{" "}
                      <b>innovation</b>, <b>initiative</b>, and <b>impact</b> of
                      the project.
                    </p>
                  </div>

                  <div className="subcategory_weightage_section">
                    <div className="weightage_top_row">
                      <p>Weightage</p>
                      <p>{projectWeightage}</p>
                    </div>

                    <WeightageSlider
                      value={projectWeightage}
                      onChange={(e) => setProjectWeightage(e.target.value)}
                      max={10}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="popup_close" onClick={() => setPopupOpen(false)}>
              <X />
            </div>

          </div>
        </div>
      </Popup>
    </>
  );
};
