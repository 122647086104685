import ReactQuill from "react-quill";
import { Placeholder, placeholders } from "../../../components/candidateEmailHistory";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import Popup from "reactjs-popup";
import * as FaIcons from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import "./styles/customEmailTemplate.css"

export const CustomEmailTemplatePopup = ({employerID, employerData, index, setEmployerData}) => {

    const [popupIsOpen, setPopupOpen] = useState(false);


    const [currentSubject, setCurrentSubject] = useState(employerData?.settings.email_templates.custom[index].subject);
    const [currentBody, setCurrentBody] = useState(employerData?.settings.email_templates.custom[index].body);

    useEffect(() => {
        setCurrentSubject(employerData?.settings.email_templates.custom[index].subject);
        setCurrentBody(employerData?.settings.email_templates.custom[index].body);
    }, [employerData]) 

    const handleEmailBodyChange = (content, delta, source, editor) => {
        setCurrentBody(editor.getHTML()); // or content for just the HTML content
    };

    const cancelChanges = () => {
        setCurrentBody(employerData?.settings.email_templates.custom[index].body);
        setCurrentSubject(employerData?.settings.email_templates.custom[index].subject);
        setPopupOpen(false);
    }


    const saveChanges = () => {
        
        const newEmail = {
            name: employerData?.settings.email_templates.custom[index].name,
            subject: currentSubject,
            body: currentBody
        }

        employerData.settings.email_templates.custom[index] = newEmail;

        setEmployerData(employerData);

        const employerRef = doc(db, "teams", employerID);

        updateDoc(employerRef, {settings: employerData.settings});


        setPopupOpen(false);

    }


    const deleteTemplate = () => {
        
        setEmployerData(prevState => {
            const newCustomTemplates = prevState.settings.email_templates.custom.filter((_, i) => i !== index);
    
            // Update the employer's settings in Firestore
            const employerRef = doc(db, "teams", employerID);
            updateDoc(employerRef, {
                settings: {
                    ...prevState.settings,
                    email_templates: {
                        ...prevState.settings.email_templates,
                        custom: newCustomTemplates
                    }
                }
            });
    
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    email_templates: {
                        ...prevState.settings.email_templates,
                        custom: newCustomTemplates
                    }
                }
            };
        });

        setPopupOpen(false);
    }


    return (

        <Popup
            trigger={
                <div className="email_template_category">
                    <h3>{employerData?.settings.email_templates.custom[index].name}</h3>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                        <FaIcons.FaExpandAlt />
                    </div>
                </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup">
                    <h1 className="popup_heading">
                        {employerData?.settings.email_templates.custom[index].name}
                    </h1>

                    <div className="candidate_send_email_holder">
                        <div className="candidate_send_email_details">

                            <h3>Subject: </h3>
                            <input type="text" placeholder="Subject" value={currentSubject} onChange={(e) => setCurrentSubject(e.target.value)} className="send_email_subject" />

                            <h3>Body: </h3>
                            <ReactQuill theme="snow" value={currentBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="send_email_body"/>
                        </div>

                        <div className="candidate_send_email_placeholders">
                            <h3>Usable Placeholders</h3>
                            <div className="placeholders_list">
                                {placeholders.map((text, index) => (
                                    <Placeholder key={index} text={text} />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="popup_close" onClick={cancelChanges}>
                        <X />
                    </div>

                    <div className="popup_bottom_actions">
                        <div className="template_delete" onClick={deleteTemplate}>
                            Delete
                        </div>

                        <div className="popup_action" onClick={saveChanges}>
                            Save
                        </div>
                    </div>

                </div>
            </div>
        </Popup>
    )
}