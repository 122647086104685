import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "./candidateScreeningTranscript.css"
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { X } from "lucide-react";


export const CandidateScreeningInterviewTranscript = ({candidate, setActionsPopupOpen}) => {


    const [popupIsOpen, setPopupOpen] = useState(false);


    const closeTranscript = () => {
        setActionsPopupOpen(false);
        setPopupOpen(false);
    }




    return (
        <Popup
            trigger={
                <div className="candidate_action">
                    View Screening Interview
                </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup">
                    <h1 className="popup_heading">Screening Interview Transcript</h1>

                    <div className="popup_close" onClick={closeTranscript}>
                        <X />
                    </div>

                    <div className="popup_body">

                        <div className="interview_transcript_holder">
                            {candidate.screening_interview_transcript.map((message, index) => {

                                if ((index === 0) || (index === 1)) {
                                    // dont count it
                                    return null;
                                }


                                // "Interviewer" if message.role is assistant and {candidate.name} if message.role is user
                                const messageSpeaker = message.role === "assistant" ? "SkillPool" : candidate.name;

                                return (
                                    <div key={index} className="interview_transcript_message">
                                        <p className="interview_transcript_message_role">{messageSpeaker}: </p>
                                        {
                                            message.role === "assistant" ? (
                                              <p className="interview_transcript_message_content">{(message.content)}</p>
                                            ) : (
                                              <p className="interview_transcript_message_content">{JSON.parse(message.content).response}</p>
                                            )
                                        }

                                    </div>
                                )

                            })}
                        </div>

                    </div>



                </div>
            </div>
        </Popup>
    )
}
