// File will contain the routing logic for the app
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { NotFound } from "./pages/NotFound"; 

import PrivateRoute from "./components/privateRoute";
import { Login } from "./pages/auth_pages/LoginPage";
import { GetInTouch } from "./pages/auth_pages/getInTouch";
import { CompleteSignIn } from "./pages/auth_pages/completeSignIn";
import { Notification } from "./components/notification";

export const RoutingNoAuth = () => {
    return (
        <div className="website-container">
        <Router>
        
                <Routes>
                {/* Define routes with Sidebar */}
                <Route path="/get-in-touch" element={<GetInTouch />} />
                <Route path="/completeSignIn" element={<CompleteSignIn />} />
                <Route path="*" element={<Login />} />
                 

            {/* Define routes without Sidebar */}
            </Routes>
        </Router>
        <Notification />
        </div>
    )
}




