import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
// Initialize Firebase (if you haven't already)

export const isEmailValid = async (email) => {
    try {
        const q = query(collection(db, "members"), where("email", "==", email.toLowerCase()));
        const querySnapshot = await getDocs(q);

        console.log(querySnapshot);
    
        if (querySnapshot.empty) {
          console.log("here");
          return false;
        } else {
          console.log("or here");
          return true;
        }
      } catch (error) {
        return false;
      }
};
