import { useState } from "react";
import Popup from "reactjs-popup"
import * as FaIcons from "react-icons/fa"
import { X } from "lucide-react";
import { DonutChart } from "../../../donutChart";


export const SoftSkillsInfo = () => {

    const skill = {
        name: "Problem Solving",
        score: 82,
        sentences: [
            { sentence: "I debugged a critical payment processing issue by breaking it down into manageable parts.", score: 9 },
            { sentence: "I analyzed system logs to identify the root cause and proposed a solution.", score: 8.5 },
            { sentence: "I approached the problem from a user experience perspective to improve the interface.", score: 8 },
            { sentence: "I collaborated with the team to find a solution that worked for everyone.", score: 7.5 },
            { sentence: "I considered multiple perspectives to ensure a well-rounded solution.", score: 7 },
        ],
    };

    const [popupIsOpen, setPopupOpen] = useState(false);

    const sentences = skill.sentences || [];

    const sortedSentences = sentences.filter(sentence => sentence.sentence !== "None")
    .sort((a, b) => b.score - a.score)
    .slice(0, 5);

    const showcaseNames = sortedSentences.map(sentence => '"' + sentence.sentence + '"').join(', ');

    return (
        <Popup
            trigger={
            <div className="skillscore_info_icon">
                <FaIcons.FaInfoCircle />
            </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"

        >
        <div className="skill_popup_holder">
            <div className="skill_popup">

                <div className="skill_popup_header">
                    <div className="skill_popup_header_first">
                        <DonutChart percentage={skill.score} color="darkgreen" multiplier={0.075} fontSize={15} />
                        <h3>Soft Skill Example</h3>
                    </div>

                    <div className="skill_popup_close" onClick={() => setPopupOpen(false)}>
                        <X />
                    </div>
                </div>

                <p style={{marginLeft: "2rem"}}>The candidate displayed {skill.name} in their interview in these sentences: </p>

                <div className="skill_popup_showcases_holder skill_sentences_holder">
                    {sortedSentences.map((sentence, index) => (
                        <div key={index} className="skill_popup_sentence">

                            <p>"{sentence.sentence}"</p>

                        </div>
                    ))}
                </div>

            </div>
        </div>
        </Popup>
    )
}