import { useEffect, useState } from "react";
import "./styles/emails.css"
import * as FaIcons from "react-icons/fa"
import Popup from "reactjs-popup";
import { EmailTemplatePopup } from "./components/emailTemplatePopup";
import { auth, db } from "../../firebase";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../components/shadcn/collapsible";
import { CreateNewEmailTemplatePopup } from "./components/createNewEmailTemplatePopup";
import { CustomEmailTemplatePopup } from "./components/customEmailTemplatePopup";
import { EmailSection } from "./components/emailSection";


export const SettingsEmails = () => {


    const [employerData, setEmployerData] = useState(null);
    const [employerID, setEmployerID] = useState(null);
    const [canRun, setCanRun] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);



    useEffect(() => {
        
        

        const getTeam = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }
                
                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }
                
                const memberData = memberSnapshot.docs[0].data();
                const { team_id } = memberData;
                setEmployerID(team_id);
                // Step 2: Use team_id to find the team and retrieve the members array
                const teamSnapshot = await getDoc(doc(db, "teams", team_id));
                if (!teamSnapshot.exists()) {
                    throw new Error('Team not found');
                }
                
                const teamData = teamSnapshot.data();
                setEmployerData(teamData);
                
            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        };

        getTeam();
    }, [canRun]);


    // Handling all rules for subject and body for all pre-set templates

    const [interviewInviteSubject, setInterviewInviteSubject] = useState(null);
    const [interviewInviteBody, setInterviewInviteBody] = useState(null);

    const [noUpdateSubject, setNoUpdateSubject] = useState(null);
    const [noUpdateBody, setNoUpdateBody] = useState(null);

    const [rejectionDefaultSubject, setRejectionDefaultSubject] = useState(null);
    const [rejectionDefaultBody, setRejectionDefaultBody] = useState(null);

    const [rejectionAuthorizationSubject, setRejectionAuthorizationSubject] = useState(null);
    const [rejectionAuthorizationBody, setRejectionAuthorizationBody] = useState(null);

    const [rejectionLocationSubject, setRejectionLocationSubject] = useState(null);
    const [rejectionLocationBody, setRejectionLocationBody] = useState(null);

    const [rejectionSponsorshipSubject, setRejectionSponsorshipSubject] = useState(null);
    const [rejectionSponsorshipBody, setRejectionSponsorshipBody] = useState(null);

    const [soloApplicationThanksSubject, setSoloApplicationThanksSubject] = useState(null);
    const [soloApplicationThanksBody, setSoloApplicationThanksBody] = useState(null);

    const [applicationThanksSubject, setApplicationThanksSubject] = useState(null);
    const [applicationThanksBody, setApplicationThanksBody] = useState(null);

    const [reconsiderCandidateSubject, setReconsiderCandidateSubject] = useState(null);
    const [reconsiderCandidateBody, setReconsiderCandidateBody] = useState(null);

    // Simulating the employerData structure as a state hook for demonstration



    useEffect(() => {
        if (employerData) {
            setInterviewInviteSubject(employerData.settings.email_templates.interview_invite.subject);
            setInterviewInviteBody(employerData.settings.email_templates.interview_invite.body);

            setNoUpdateSubject(employerData.settings.email_templates.no_update.subject);
            setNoUpdateBody(employerData.settings.email_templates.no_update.body);

            setRejectionDefaultSubject(employerData.settings.email_templates.rejection.default.subject);
            setRejectionDefaultBody(employerData.settings.email_templates.rejection.default.body);

            setRejectionAuthorizationSubject(employerData.settings.email_templates.rejection.rejection_authorization.subject);
            setRejectionAuthorizationBody(employerData.settings.email_templates.rejection.rejection_authorization.body);

            setRejectionLocationSubject(employerData.settings.email_templates.rejection.rejection_location.subject);
            setRejectionLocationBody(employerData.settings.email_templates.rejection.rejection_location.body);

            setRejectionSponsorshipSubject(employerData.settings.email_templates.rejection.rejection_sponsorship.subject);
            setRejectionSponsorshipBody(employerData.settings.email_templates.rejection.rejection_sponsorship.body);

            setSoloApplicationThanksSubject(employerData.settings.email_templates.application_thanks_solo.subject);
            setSoloApplicationThanksBody(employerData.settings.email_templates.application_thanks_solo.body);

            setApplicationThanksSubject(employerData.settings.email_templates.application_thanks.subject);
            setApplicationThanksBody(employerData.settings.email_templates.application_thanks.body);

            setReconsiderCandidateSubject(employerData.settings.email_templates.reconsider_candidate.subject);
            setReconsiderCandidateBody(employerData.settings.email_templates.reconsider_candidate.body);

        }
    }, [employerData]);

    const saveEmail = () => {
        // Assuming employerData is already an object with settings including other possible settings


        const newEmailTemplates = {
            custom: employerData.settings.email_templates.custom,
            application_thanks_solo: {
                subject: soloApplicationThanksSubject,
                body: soloApplicationThanksBody,
            },
            application_thanks: {
                subject: applicationThanksSubject,
                body: applicationThanksBody,
            },
            reconsider_candidate: {
                subject: reconsiderCandidateSubject,
                body: reconsiderCandidateBody
            },
            interview_invite: {
                subject: interviewInviteSubject,
                body: interviewInviteBody
            },
            no_update: {
                subject: noUpdateSubject,
                body: noUpdateBody
            },
            rejection: {
                default: {
                    subject: rejectionDefaultSubject,
                    body: rejectionDefaultBody
                },
                rejection_authorization: {
                    subject: rejectionAuthorizationSubject,
                    body: rejectionAuthorizationBody
                },
                rejection_location: {
                    subject: rejectionLocationSubject,
                    body: rejectionLocationBody
                },
                rejection_sponsorship: {
                    subject: rejectionSponsorshipSubject,
                    body: rejectionSponsorshipBody
                }
            },
  
        };
    
        // Update employerData.settings with new email_templates without overwriting other settings
        const updatedSettings = { ...employerData.settings, email_templates: {
            ...employerData.settings.email_templates,
            ...newEmailTemplates,
        } };

        const docRef = doc(db, "teams", employerID);
        updateDoc(docRef, {settings: updatedSettings})

    };



    useEffect(() => {
        if (employerData) {
            saveEmail()
        }
    }, [interviewInviteSubject, interviewInviteBody, noUpdateSubject, noUpdateBody, rejectionDefaultSubject, rejectionDefaultBody, rejectionAuthorizationSubject, rejectionAuthorizationBody, rejectionLocationSubject, rejectionLocationBody, rejectionSponsorshipSubject, rejectionSponsorshipBody, applicationThanksSubject, applicationThanksBody, reconsiderCandidateSubject, reconsiderCandidateBody, soloApplicationThanksSubject, soloApplicationThanksBody, employerData, employerID])


    


    // Handling the email template popups

    const [soloApplicationThanksPopupOpen, setSoloApplicationThanksPopupOpen] = useState(false);
    const [manualInterviewPopupOpen, setManualInterviewPopupOpen] = useState(false);
    const [manualRejectionPopupOpen, setManualRejectionPopupOpen] = useState(false);
    const [locationUnavailabilityPopupOpen, setLocationUnavailabilityPopupOpen] = useState(false);
    const [noWorkAuthorizationPopupOpen, setNoWorkAuthorizationPopupOpen] = useState(false);
    const [sponsorshipNeededPopupOpen, setSponsorshipNeededPopupOpen] = useState(false);
    const [noUpdatePopupOpen, setNoUpdatePopupOpen] = useState(false);
    const [applicationThanksPopupOpen, setApplicationThanksPopupOpen] = useState(false);
    const [reconsiderCandidatePopupOpen, setReconsiderCandidatePopupOpen] = useState(false);

    // Handling the popup for creating new email templates

    const [createNewTemplatePopupOpen, setCreateNewTemplatePopupOpen] = useState(false);


    // Handling the switching sections for email templates

    const [activeSection, setActiveSection] = useState('regular');


    // handling the collapsible for the rejection email templates

    const [rejectionTemplatesOpen, setRejectionTemplatesOpen] = useState(false);



    // Note that I have to try to port the current templates that the users have to the new organization that I want to be doing. 
    
    // this is probably the best place to get all that setup. 
    


    return (
        <div className="settings_emails">

            {/* <div className="settings_emails_category settings_emails_preferences">

                <p className="settings_emails_category_heading">Preferences</p>

                <div className="settings_emails_category_body">
                    <input type="checkbox" /> <label>Send me an email when a candidate applies</label>
                </div>
                
            </div> */}


            <div className="settings_emails_category settings_emails_templates">
                <p className="settings_emails_category_heading">Templates</p>

                <div className="settings_emails_category_body">

                    {/* <EmailSection name={"Interview"} emails={employerData?.settings?.email_templates?.interviews} key={"interviews"} /> */}
                    
                    <div className="settings_emails_templates_section_switcher subnav">
                        <div className={`subnav_item ${activeSection === 'regular' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('regular')}>
                            <p>Regular</p>
                        </div>


                        <div className={`subnav_item ${activeSection === 'action' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('action')}>
                            <p>Action-Based</p>
                        </div>
                    </div>


                    {activeSection === 'action' && (
                        <div className="settings_emails_templates_section ">

                            <div className="email_templates_holder action_email_templates_holder">

                                {/* This should show all from applied, screening interview invite, interview invite, rejection */}


                                {/* Popup for ONLY application thanks */}

                                <div className="email_template_category_holder">
                                    <Popup
                                        trigger={
                                            <div className="email_template_category">
                                                <h3>Application Thanks</h3>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                    <FaIcons.FaExpandAlt />
                                                </div>
                                            </div>
                                        }
                                        open={soloApplicationThanksPopupOpen}
                                        onOpen={() => setSoloApplicationThanksPopupOpen(true)}
                                        modal
                                        nested
                                        position="center center"
                                    >
                                        <EmailTemplatePopup
                                            subject={soloApplicationThanksSubject}
                                            setSubject={setSoloApplicationThanksSubject}
                                            body={soloApplicationThanksBody}
                                            setBody={setSoloApplicationThanksBody}
                                            name={"Application Thanks"}
                                            // saveEmail={saveEmail}
                                            setPopupOpen={setSoloApplicationThanksPopupOpen}
                                        />
                                    </Popup>
                                </div>

                                {/* Popup for Application Thanks + Screening Interview */}
                                <div className="email_template_category_holder">
                                    <Popup
                                        trigger={
                                            <div className="email_template_category">
                                                <h3>Application Thanks + Screening Interview</h3>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                    <FaIcons.FaExpandAlt />
                                                </div>
                                            </div>
                                        }
                                        open={applicationThanksPopupOpen}
                                        onOpen={() => setApplicationThanksPopupOpen(true)}
                                        modal
                                        nested
                                        position="center center"
                                    >
                                        <EmailTemplatePopup
                                            subject={applicationThanksSubject}
                                            setSubject={setApplicationThanksSubject}
                                            body={applicationThanksBody}
                                            setBody={setApplicationThanksBody}
                                            name={"Application Thanks + Screening Interview"}
                                            // saveEmail={saveEmail}
                                            setPopupOpen={setApplicationThanksPopupOpen}
                                        />
                                    </Popup>
                                </div>


                                {/* Popup for Manual Interview Invite */}
                                <div className="email_template_category_holder">
                                    <Popup
                                        trigger={
                                            <div className="email_template_category">
                                                <h3>Manual Interview Invite</h3>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                    <FaIcons.FaExpandAlt />
                                                </div>
                                            </div>
                                        }
                                        open={manualInterviewPopupOpen}
                                        onOpen={() => setManualInterviewPopupOpen(true)}
                                        modal
                                        nested
                                        position="center center"
                                    >
                                        <EmailTemplatePopup
                                            subject={interviewInviteSubject}
                                            setSubject={setInterviewInviteSubject}
                                            body={interviewInviteBody}
                                            setBody={setInterviewInviteBody}
                                            name={"Manual Interview Invite"}
                                            // saveEmail={saveEmail}
                                            setPopupOpen={setManualInterviewPopupOpen}
                                        />
                                    </Popup>
                                </div>

                                <div className="email_template_category_holder email_template_rejections">
                                    <Collapsible
                                        open={rejectionTemplatesOpen}
                                        onOpenChange={setRejectionTemplatesOpen}
                                        className="email_template_category_collapsible"
                                        >
                                        
                                        <CollapsibleTrigger 
                                        className="email_template_category_collapsible_trigger_holder" 
                                        >
                                        <div className="email_template_category_trigger">


                                            <div className="email_template_category_collapsible_first">
                                                <h3>Rejection Emails</h3>
                                            </div>

                                            <div className='role_arrow_showcase_holder'>   
                                            {rejectionTemplatesOpen ? <FaIcons.FaChevronDown /> : <FaIcons.FaChevronRight />}
                                        
                                            </div>
                                        
                                        </div>
                                        
                                        </CollapsibleTrigger>

                                        <CollapsibleContent>

                                            <div className="email_template_subcategory_holder">
                                                <Popup
                                                    trigger={
                                                        <div className="email_template_subcategory">
                                                            <p>Manual Rejection</p>
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                                <FaIcons.FaExpandAlt />
                                                            </div>
                                                        </div>
                                                    }
                                                    open={manualRejectionPopupOpen}
                                                    onOpen={() => setManualRejectionPopupOpen(true)}
                                                    modal
                                                    nested
                                                    position="center center"
                                                >
                                                    <EmailTemplatePopup
                                                        subject={rejectionDefaultSubject}
                                                        setSubject={setRejectionDefaultSubject}
                                                        body={rejectionDefaultBody}
                                                        setBody={setRejectionDefaultBody}
                                                        name={"Manual Rejection"}
                                                        // saveEmail={saveEmail}
                                                        setPopupOpen={setManualRejectionPopupOpen}
                                                    />
                                                </Popup>
                                            </div>

                                            <div className="email_template_subcategory_holder">
                                                <Popup
                                                    trigger={
                                                        <div className="email_template_subcategory">
                                                            <p>Location Unavailability</p>
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                                <FaIcons.FaExpandAlt />
                                                            </div>
                                                        </div>
                                                    }
                                                    open={locationUnavailabilityPopupOpen}
                                                    onOpen={() => setLocationUnavailabilityPopupOpen(true)}
                                                    modal
                                                    nested
                                                    position="center center"
                                                >
                                                    <EmailTemplatePopup
                                                        subject={rejectionLocationSubject}
                                                        setSubject={setRejectionLocationSubject}
                                                        body={rejectionLocationBody}
                                                        setBody={setRejectionLocationBody}
                                                        name={"Location Unavailability"}
                                                        // saveEmail={saveEmail}
                                                        setPopupOpen={setLocationUnavailabilityPopupOpen}
                                                    />
                                                </Popup>
                                            </div>

                                            <div className="email_template_subcategory_holder">
                                                <Popup
                                                    trigger={
                                                        <div className="email_template_subcategory">
                                                            <p>No Work Authorization</p>
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                                <FaIcons.FaExpandAlt />
                                                            </div>
                                                        </div>
                                                    }
                                                    open={noWorkAuthorizationPopupOpen}
                                                    onOpen={() => setNoWorkAuthorizationPopupOpen(true)}
                                                    modal
                                                    nested
                                                    position="center center"
                                                >
                                                    <EmailTemplatePopup
                                                        subject={rejectionAuthorizationSubject}
                                                        setSubject={setRejectionAuthorizationSubject}
                                                        body={rejectionAuthorizationBody}
                                                        setBody={setRejectionAuthorizationBody}
                                                        name={"No Work Authorization"}
                                                        setPopupOpen={setNoWorkAuthorizationPopupOpen}
                                                    />
                                                </Popup>
                                            </div>

                                            <div className="email_template_subcategory_holder">
                                                <Popup
                                                    trigger={
                                                        <div className="email_template_subcategory">
                                                            <p>Sponsorship Needed</p>
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                                <FaIcons.FaExpandAlt />
                                                            </div>
                                                        </div>
                                                    }
                                                    open={sponsorshipNeededPopupOpen}
                                                    onOpen={() => setSponsorshipNeededPopupOpen(true)}
                                                    modal
                                                    nested
                                                    position="center center"
                                                >
                                                    <EmailTemplatePopup
                                                        subject={rejectionSponsorshipSubject}
                                                        setSubject={setRejectionSponsorshipSubject}
                                                        body={rejectionSponsorshipBody}
                                                        setBody={setRejectionSponsorshipBody}
                                                        name={"Sponsorship Needed"}
                                                        setPopupOpen={setSponsorshipNeededPopupOpen}
                                                    />
                                                </Popup>
                                            </div>
                                            
                                        </CollapsibleContent>
                                    </Collapsible>
                                </div>

                                {/* Popup for reconsidering candidate */}

                                <div className="email_template_category_holder">
                                    <Popup
                                        trigger={
                                            <div className="email_template_category">
                                                <h3>Reconsider Candidate</h3>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                    <FaIcons.FaExpandAlt />
                                                </div>
                                            </div>
                                        }
                                        open={reconsiderCandidatePopupOpen}
                                        onOpen={() => setReconsiderCandidatePopupOpen(true)}
                                        modal
                                        nested
                                        position="center center"
                                    >
                                        <EmailTemplatePopup
                                            subject={reconsiderCandidateSubject}
                                            setSubject={setReconsiderCandidateSubject}
                                            body={reconsiderCandidateBody}
                                            setBody={setReconsiderCandidateBody}
                                            name={"Reconsider Candidate"}
                                            // saveEmail={saveEmail}
                                            setPopupOpen={setReconsiderCandidatePopupOpen}
                                        />
                                    </Popup>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeSection === 'regular' && (
                        <div className="settings_emails_templates_section">
                            {/* This should hold the "no-update" and the option to add custom templates */}

                            <div className="email_templates_holder">
{/* Popup for No Update */}
                                <div className="email_template_category_holder">
                                    <Popup
                                        trigger={
                                            <div className="email_template_category">
                                                <h3>No Update</h3>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <p style={{ margin: 0, marginRight: "1rem" }}>View</p>
                                                    <FaIcons.FaExpandAlt />
                                                </div>
                                            </div>
                                        }
                                        open={noUpdatePopupOpen}
                                        onOpen={() => setNoUpdatePopupOpen(true)}
                                        modal
                                        nested
                                        position="center center"
                                    >
                                        <EmailTemplatePopup
                                            subject={noUpdateSubject}
                                            setSubject={setNoUpdateSubject}
                                            body={noUpdateBody}
                                            setBody={setNoUpdateBody}
                                            name={"No Update"}
                                            // saveEmail={saveEmail}
                                            setPopupOpen={setNoUpdatePopupOpen}
                                        />
                                    </Popup>
                                </div>


                                {employerData && employerData.settings.email_templates.custom && employerData.settings.email_templates.custom.map((template, index) => (

                                    <div className="email_template_category_holder">
                                        <CustomEmailTemplatePopup employerID={employerID} employerData={employerData} index={index} setEmployerData={setEmployerData} />
                                    </div>
                                ))}

                            </div>

                            

                            {/* This is to create new email templates */}
                            <div className="email_template_category_holder email_create_new_template_holder">

                                <CreateNewEmailTemplatePopup employerID={employerID} employerData={employerData} setEmployerData={setEmployerData} />
       
                                
                                
                            </div>
                            
                        </div>
                    )}

                </div>
            
            </div>
            
        </div>
    );
}