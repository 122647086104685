import { useEffect, useState } from "react";
import "./styles/interviewers.css"
import * as FaIcons from "react-icons/fa"
import Popup from "reactjs-popup";
import { sendNotification } from "../../notification";
import Autosuggest from "react-autosuggest";


export const Interviewers = ({
    interviewers, 
    setInterviewers, 
    teamMembers,
    setHasEmails,
    complete,
    setComplete
}) => {

    const [popupIsOpen, setPopupOpen] = useState(false);
    
    const [activeSection, setActiveSection] = useState("specific");


    // handling overall settings stuff for the section

    const handleDeleteMainInterviewer = (index) => {
        const updatedInterviewers = interviewers.filter((_, i) => i !== index);
        setInterviewers(updatedInterviewers);
    }


    // Handling the popup

    const cancelPopup = () => {
        setNumInterviewers(1);
        setInterviewersToAdd([]);
        setNewInterviewer('');
        setPopupOpen(false);
    }

    const saveChanges = () => {

        if (activeSection === "specific") {
            // one by one just add hte interviewes
            // or like concat or wtv lol

            setInterviewers([...interviewers, ...interviewersToAdd])
        } else {
            // you'll have to add them in a special format

            if (interviewersToAdd.length === 0) {
                sendNotification("Please select atleast one interviewer!");
                return;
            }

            const newObject = {
                interviewers: interviewersToAdd,
                type: "group",
                count: numInterviewers,
            }

            setInterviewers([...interviewers, newObject])
        }

        setNumInterviewers(1);
        setInterviewersToAdd([]);
        setNewInterviewer('');
        
        setPopupOpen(false);
    }

   

    const changeSection = (section) => {
        setInterviewersToAdd([]);
        setActiveSection(section)
    }

    const [numInterviewers, setNumInterviewers] = useState(1);

    const handleChangeNumInterviewers = (e) => {
        const value = e.target.value;

        if (value < 0) {
          sendNotification("Please enter a valid number of interviewers!");
          return;
        }

        if (value > 5) {
          sendNotification("Please enter a number of interviewers less than 5!");
          return;
        }

        if (value === "") {
          setNumInterviewers(0);
          return;
        }
        setNumInterviewers(parseInt(value));
    }


    // handlign adding a new interviewers

    const [interviewersToAdd, setInterviewersToAdd] = useState([]);
    const [newInterviewer, setNewInterviewer] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    // Handling the interviewer selection

    useEffect(() => {
        // check to see if any of the interviewers to add are emails

        const emailInterviewers = interviewers.filter(interviewer => interviewer.type === "email");

        console.log("emailInterviewers", emailInterviewers)

        if (emailInterviewers.length > 0) {
            setHasEmails(true);
        } else {
            setHasEmails(false);
        }

    }, [interviewers])

    const handleAddInterviewer = (interviewerName) => {

        let interviewerToAdd;
        
        if (interviewerName) {
            interviewerToAdd = interviewerName
        } else {
            interviewerToAdd = newInterviewer;
        }

        if (teamMembers.find(member => member.first_name === interviewerToAdd)) {
            const member = teamMembers.find(member => member.first_name === interviewerToAdd);
            if (!interviewersToAdd.some(interviewer => interviewer.name === interviewerToAdd)) {
                setInterviewersToAdd([...interviewersToAdd, { name: interviewerToAdd, id: member.id}]);
            }
            setNewInterviewer('');
        } else {
            // this is where i check to see if they entered an email

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailRegex.test(interviewerToAdd)) {
                if (!interviewersToAdd.some(interviewer => interviewer.name === interviewerToAdd)) {
                    setInterviewersToAdd([...interviewersToAdd, { name: interviewerToAdd, id: null, type: "email"}]);
                }
                setNewInterviewer('');

            } else {
                alert("Please select a valid team member");
            }
        }
    };

    const handleInterviewerKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleAddInterviewer();
        }
    };

    

    const handleDeleteInterviewer = (index) => {
        const updatedInterviewers = interviewersToAdd.filter((_, i) => i !== index);
        setInterviewersToAdd(updatedInterviewers);
    };

    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : teamMembers.filter(member =>
          member.first_name.toLowerCase().includes(inputValue)
        );
    };

    const getSuggestionValue = (suggestion) => suggestion.first_name;

    const renderSuggestion = (suggestion) => (
        <div>
          {suggestion.first_name}
        </div>
    );

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (event, { suggestionValue }) => {
        setNewInterviewer(suggestionValue);
        handleAddInterviewer(suggestionValue);
    };

    const inputProps = {
        className: "interviewers_input_area",
        placeholder: "Add a potential interviewer!",
        value: newInterviewer,
        onChange: (e, { newValue }) => {
          setNewInterviewer(newValue);
        },
        onKeyPress: handleInterviewerKeyPress
    };


    // handling complete

    useEffect(() => {
        if (interviewers.length > 0 ) {
            console.log("interviewers", interviewers)
            setComplete(true);
        } else {
            setComplete(false);
        }
    }, [interviewers])


    return (
        <div className="interview_section_holder">

            <div className="interviewers_heading">
                <p>
                    Add any interviewers for this interview!
                </p>

                <Popup trigger={
                    <button className="add_interviewers">
                        <FaIcons.FaPlus style={{marginRight:"0.5rem"}} />
                        Add
                    </button>
                }

                open={popupIsOpen}
                onOpen={() => setPopupOpen(true)}
                modal
                nested
                position="center center"
                >
                    <div className="popup_holder">
                        <div className="popup" style={{width: "60%", height: "70%"}}> 
                            <h1 className="popup_heading">Add Interviewers</h1>

                            <div className="add_interviewer_subnav">
                                <div className={`add_interviewer_section_option ${(activeSection === "specific") ? 'selected' : ''}`} onClick={() => changeSection('specific')}>
                                    Specific Interviewers
                                </div>

                                <div  className={`add_interviewer_section_option ${(activeSection === "group") ? 'selected' : ''}`} onClick={() => changeSection("group")}>
                                    Select from Group
                                </div>
                            </div>

                            {
                                (activeSection === "group") && (
                                    <div className="add_interviewer_section">
                                        <div className="add_interviewer_section_top_row">
                                            <div className="add_interviewer_section_text">
                                                <p>
                                                    Choose a group of potential interviewers to choose from!
                                                </p>
                                                <p>
                                                    We'll select the interviewer based on their availability.
                                                </p>
                                            </div>

                                            <div className="add_interviewer_subsection">
                                                <p style={{margin:0}}>Number of Interviewers</p>

                                                <div style={{display:"flex", alignItems:"center"}}>
                                                    <input
                                                    type="number"
                                                    className="interview_section_number_input"
                                                    value={numInterviewers}
                                                    onChange={handleChangeNumInterviewers}
                                                    style={{ width: "1.5rem" }}
                                                    />

                                                    {/* add an extra s if numInterviewers is more than 1 */}
                                                    <p>interviewer{numInterviewers !== 1 ? 's' : ''}</p>
                                                </div>
                                            </div>  
                                        </div>

                                        <div className="add_interviewer_selection_pool">
                                            <p className="interview_section_text">
                                                Selection Pool
                                            </p>

                                            <div className="interviewers_input_container">
                                                <Autosuggest
                                                    className="autosuggest_testing"
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    onSuggestionSelected={onSuggestionSelected}
                                                    inputProps={inputProps}
                                                    theme={{
                                                        container: 'autosuggest-container',
                                                        suggestionsContainer: 'suggestions-container',
                                                        suggestionsList: 'suggestions-list',
                                                        suggestion: 'suggestion-item'
                                                    }}
                                                />
                                            </div>

                                            <div className="interviewers_list">
                                                {interviewersToAdd.map((interviewer, index) => (
                                                    <div key={index} className="interviewer_item">
                                                        <span>{interviewer.name}</span>

                                                        <div style={{display:"flex", alignItems:"center"}}>
                                                            <FaIcons.FaTrashAlt className="delete_interviewer" onClick={() => handleDeleteInterviewer(index)} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                )
                            }

                            {
                                (activeSection === "specific") && (
                                    <div className="add_interviewer_section">
                                        <p>Choose specific interviewers to add!</p>

                                        <div className="add_interviewer_selected_interviewers">
                                            <div className="interviewers_input_container">
                                                <Autosuggest
                                                    className="autosuggest_testing"
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    onSuggestionSelected={onSuggestionSelected}
                                                    inputProps={inputProps}
                                                    theme={{
                                                        container: 'autosuggest-container',
                                                        suggestionsContainer: 'suggestions-container',
                                                        suggestionsList: 'suggestions-list',
                                                        suggestion: 'suggestion-item'
                                                    }}
                                                />
                                            </div>

                                            <div className="interviewers_list specific">
                                                {interviewersToAdd.map((interviewer, index) => (
                                                    <div key={index} className="interviewer_item">
                                                        <span>{interviewer.name}</span>

                                                        <div style={{display:"flex", alignItems:"center"}}>
                                                            <FaIcons.FaTrashAlt className="delete_interviewer" onClick={() => handleDeleteInterviewer(index)} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="popup_bottom_actions">
                                <div className="popup_cancel" onClick={cancelPopup}>
                                    Cancel
                                </div>

                                <div className="popup_action" onClick={saveChanges}>
                                    Add
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>

            <div className="interviewers_body">
            {interviewers.map((interviewer, index) => (
                
                (interviewer.type === "group") ? (
                    <div key={index} className="interviewer_group_item">
                        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <span>Select <strong>{interviewer.count}</strong> from: </span>

                            <div style={{display:"flex", alignItems:"center"}}>
                                <FaIcons.FaTrashAlt className="delete_interviewer" onClick={() => handleDeleteMainInterviewer(index)} />
                            </div>
                        </div>

                        <div className="interviewers_sublist">
                            {
                                interviewer.interviewers.map((specificInterviewer, index) => (
                                    <div key={index} className="interviewer_sub_item">
                                        <span>{specificInterviewer.name}</span>
                                    </div>
                                ))
                            }
                        </div>
                        
                    </div>
                ) : (
                    <div key={index} className="interviewer_item">
                        <span>{interviewer.name}</span>

                        <div style={{display:"flex", alignItems:"center"}}>
                            <FaIcons.FaTrashAlt className="delete_interviewer" onClick={() => handleDeleteMainInterviewer(index)} />
                        </div>
                    </div>
                )
                
                
            ))}   
            </div>
        
        </div>
        
    )
}