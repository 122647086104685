import { doc, updateDoc } from "firebase/firestore"
import { useState } from "react"
import Popup from "reactjs-popup"
import { db } from "../../firebase"
import { sendNotification } from "../notification"

export const EditMember = ({member, currentMember, teamMembers, setTeamMembers, setActionsPopupOpen}) => {


    const [popupIsOpen, setPopupOpen] = useState(false)

    const openEditPopup = () => {
        if (currentMember.role !== "Admin") {
            sendNotification("Only the admin can edit team members!");
            setActionsPopupOpen(null);
            return;
        }
        setPopupOpen(true);
    }

    const closePopup = () => {
        setPopupOpen(false);
        setActionsPopupOpen(null);
    }
    

    const editMember = () => {

        setDisplayEditMessage(true);

        setTimeout(() => {
            setDisplayEditMessage(false);
            closePopup();
        }, 1000)

        const memberRef = doc(db, "members", member.id);
        const memberData = {
            first_name: memberFirstName,
            last_name: memberLastName,
            email: memberEmail
        }

        updateDoc(memberRef, memberData);

        // update the actual teamMembers array - changing the info of the member with member.id

        const updatedTeamMembers = [...teamMembers];

        const memberIndex = updatedTeamMembers.findIndex((teamMember) => teamMember.id === member.id);

        updatedTeamMembers[memberIndex] = {
            id: member.id,
            ...memberData
        }

        setTeamMembers(updatedTeamMembers);
        
        // updateDoc()
    }


    const [memberFirstName, setMemberFirstName] = useState(member.first_name)
    const [memberLastName, setMemberLastName] = useState(member.last_name)
    const [memberEmail, setMemberEmail] = useState(member.email)


    const [displayEditMessage, setDisplayEditMessage] = useState(false)

    return (
        <>
        <div className='job_action' onClick={openEditPopup}>
            Edit Member
        </div>

        <Popup 
          open={popupIsOpen}
          modal
          nested
          position="center center"
        >   
        <div className="popup_holder">
            <div className='popup' style={{width: "40%", height:"45%"}}>
                <div className='popup_heading_area'>
                    <h1>Edit Team Member</h1>
                </div>


                <div className='popup_body'>

                    { displayEditMessage && 
                        <div className='edit_message' style={{marginTop:"1.5rem"}}>
                            Team member info has been edited!
                        </div>
                    }

                    { !displayEditMessage && 
                        <div className="team_member_info">
                        
                            <div className='member_name_input'>
                                <input value={memberFirstName} onChange={(e) => setMemberFirstName(e.target.value)}  type="text" id="add_member_first_name" placeholder="First Name Here*" className="first_name_enter member_enter_info" />
                                <input value={memberLastName} onChange={(e) => setMemberLastName(e.target.value)} type="text" id="add_member_last_name" placeholder="Last Name Here*" className="last_name_enter member_enter_info" />
                            </div>
                            <input value={memberEmail} onChange={(e) => setMemberEmail(e.target.value)} type="email" id="add_member_email" placeholder="Email Here*" className="email_enter member_enter_info" />
                            
                        </div>
                    }
                    
                </div>

                


                <div className='actions_bottom'>
                    <div className='close_popup' onClick={closePopup}>Close</div>

                    <div className='create_member' onClick={editMember}>
                        Add
                    </div>
                </div>
            </div>
        </div>

        </Popup>
        </>
    )
}