import { useEffect, useState } from "react";
import "./styles/jobs_page.css"
import * as FaIcons from "react-icons/fa"
import { auth, db } from "../../firebase";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";


export const SettingsJobsPage = () => {


    // get the employer id
    const [employerData, setEmployerData] = useState(null);
    const [employerID, setEmployerID] = useState(null);
    const [canRun, setCanRun] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);



    useEffect(() => {
        
        

        const getTeam = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }
                
                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }
                
                const memberData = memberSnapshot.docs[0].data();
                const { team_id } = memberData;
                setEmployerID(team_id);
                // Step 2: Use team_id to find the team and retrieve the members array
                const teamSnapshot = await getDoc(doc(db, "teams", team_id));
                if (!teamSnapshot.exists()) {
                    throw new Error('Team not found');
                }
                
                const teamData = teamSnapshot.data();
                setEmployerData(teamData);
                
            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        };

        getTeam();
    }, [canRun]);

    return (
        <div className="settings_jobs_page">
            <h1>Jobs Page</h1>


            <a href={"https://jobs.skillpool.tech/" + employerID} target="_blank" className="view_jobs_page_link">
                <div style={{display:"flex", alignItems:"center"}}>
                    View
                    <FaIcons.FaExternalLinkAlt style={{marginLeft: "0.5rem", textDecoration:"underline"}} />
                </div>
            </a>
            
        </div>
    );
}