import { deleteDoc, doc, updateDoc } from "firebase/firestore"
import { useState } from "react"
import Popup from "reactjs-popup"
import { db } from "../../firebase"
import { sendNotification } from "../notification"

export const DeleteMember = ({member, currentMember, teamMembers, setTeamMembers, setActionsPopupOpen}) => {


    const [popupIsOpen, setPopupOpen] = useState(false)

    const closePopup = () => {
        setPopupOpen(false);
        setActionsPopupOpen(null);
    }

    const openDeletePopup = () => {

        if (currentMember.role !== "Admin") {
            sendNotification("Only the admin can edit team members!");
            setActionsPopupOpen(null);
            return;
        }

        if (member.role === "Admin") {
            sendNotification("Admin cannot be deleted!");
            setActionsPopupOpen(null);
            return;
        }

        setPopupOpen(true);
    }

    const deleteMember = async () => {
        setDeleteMessage(true); // Show deleting message

        // Wait for 1500ms before proceeding with the delete operation
        setTimeout(async () => {
            const memberRef = doc(db, "members", member.id);

            try {
                await deleteDoc(memberRef); // Ensure deletion completes before proceeding
                const updatedTeamMembers = teamMembers.filter((teamMember) => teamMember.id !== member.id);
                setTeamMembers(updatedTeamMembers);
            } catch (error) {
                sendNotification("Failed to delete member."); // Handle potential errors
            }

            closePopup(); // Close the popup after all operations are complete
        }, 1000);
    };

    const [deleteMessage, setDeleteMessage] = useState(false);

    return (
        <>
            <div className='job_action' onClick={openDeletePopup}>
                Delete Member
            </div>

            <Popup 
            open={popupIsOpen}
            modal
            nested
            position="center center"
            >   
                <div className="popup_holder">
                    <div className='popup' style={{width: "30%", height:"35%"}}>
                        <h1 className="popup_heading">Delete Team Member</h1>

                        {
                            deleteMessage && 
                            <p>{member.first_name} {member.last_name} has been removed from your team!</p>
                        }


                        {
                            !deleteMessage && 
                            <>
                                <p>Are you sure you want to delete {member.first_name} {member.last_name} from your team?</p>

                                <div className='actions_bottom'>
                                    <div className='close_popup' onClick={closePopup}>Close</div>

                                    <div className='create_member delete_member' onClick={deleteMember}>
                                        Delete
                                    </div>
                                </div>
                            </>
                        }

                        
                    </div>
                </div>

            </Popup>
        </>
    )
}