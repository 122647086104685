import { useEffect, useState } from "react";
import "./styles/admin.css"
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { NotFound } from "./NotFound";
import { auth, db } from "../firebase";
import TimezoneSelect, { allTimezones, useTimezoneSelect } from "react-timezone-select";
import * as FaIcons from "react-icons/fa"

export const Admin = () => {

    const [canRun, setCanRun] = useState(false);
    const [god, setGod] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {



        const getMember = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }

                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }

                const memberData = memberSnapshot.docs[0].data();
                if (memberData.god == true) {
                    setGod(true);
                }

            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        };

        getMember();
    }, [canRun]);


    // handling info

    const [employerName, setEmployerName] = useState('');
    const [employerEmail, setEmployerEmail] = useState('');

    const [admins, setAdmins] = useState([{
        first_name: "",
        last_name: "",
        email: "",
        timezone: null,
    }]);

    const addNewAdmin = () => {
        const updatedAdmins = [...admins, {
            first_name: "",
            last_name: "",
            email: "",
            timezone: null,
        }]

        setAdmins(updatedAdmins);
    }

    const deleteAdmin = (index) => {
        const updatedAdmins = admins.filter((_, i) => i !== index);
        setAdmins(updatedAdmins);
    }

    const updateAdmin = (index, field, value) => {
        const updatedAdmins = admins.map((admin, i) => {
            if (i === index) {
                return { ...admin, [field]: value };
            }
            return admin;
        });
        setAdmins(updatedAdmins);
    }

    useEffect(() => {
        console.log(admins[0].timezone);
    }, [admins])

    const createNewEmployer = async () => {
        // This should create the team doucment.
        // you also have to setup the postmark email lmao

        const settings = {
            employerEmail: employerEmail,
            email_templates: {
                application_thanks: {
                    subject: "Thank You for Your Interest in {{employer_name}}",
                    body: `<p>Dear {{candidate_name}},</p><p>We wanted to take a moment to thank you for applying for the {{job_title}} position at {{employer_name}}. We appreciate your interest in our organization, and are happy to get to know you further!</p><p>We'd like to invite you to complete your application by participating in a brief, 10-minute interactive session conducted by our AI system.</p><p>Please use the following link to give the interview whenever you feel comfortable, within the next 2-3 days:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Take Interview</a></p><p>Thank you once again for your interest in joining our team. We are looking forward to getting to know you better over the next few steps in the recruitment process!</p><p>Warm regards,</p><p>The {{employer_name}} Recruitment Team</p>`,
                },
                application_thanks_solo: {
                    subject: "Thank You for Your Interest in {{employer_name}}",
                    body: "<p>Dear {{candidate_name}},</p><p>We wanted to take a moment to thank you for applying for the {{job_title}} position at {{employer_name}}. We appreciate your interest in our organization, and are happy to get to know you further!</p><p>We're going through your application, and will be in contact soon regarding the next steps in the application process.</p><p>Thank you once again for your interest in joining our team. We are looking forward to getting to know you better over the next few steps in the recruitment process!</p><p>Warm regards,</p><p>The {{employer_name}} Recruitment Team</p>",
                },
                custom: [],
                interview_invite: {
                    subject: `Congratulations, {{candidate_name}}! Next Steps in Your Application for {{job_title}}`,
                    body: `<p>Hi {{candidate_name}},</p><p>Congratulations on making it to the next round! We're excited to learn more about you and discuss how your background might be a fit for the {{job_title}} at {{employer_name}}.</p><p>Please schedule a time for our in-depth discussion at your earliest convenience by clicking the following link:</p><p><a href="{{link}}" rel="noopener noreferrer" target="_blank">Schedule Interview</a></p><p>We can't wait to meet you virtually and explore the possibilities of working together!</p><p>All the best,</p><p>The {{employer_name}} Recruitment Team</p>`,
                },
                no_update: {
                    subject: `The No Update Update: Your Application for {{job_title}} at {{employer_name}}`,
                    body: `<p>Dear {{candidate_name}},</p><p>We hope this message finds you well. We're writing to let you know that although the hiring process for the {{job_title}} at {{employer_name}} is proceeding naturally, we do not have any specific updates at this time. Your application is still under active consideration.</p><p>We appreciate your patience and understanding as we carefully review each candidate. We aim to ensure the best fit for both our team and the successful candidate. We will be in touch as soon as there are any developments regarding your application status.</p><p>Thank you for your continued interest in joining our team. Have a wonderful day!</p><p>Warm regards,</p><p>The {{employer_name}} Recruitment Team</p>`,
                },
                reconsider_candidate: {
                    subject: `Exciting Opportunity Reopened: Consideration for {{job_title}} at {{employer_name}}`,
                    body: `Dear {{candidate_name}},  We hope this message finds you well. We have some exciting news from {{employer_name}}! After further consideration and developments within our team, we are reopening the opportunity for the {{job_title}} position and would like to reconsider your application.  We were impressed by your initial interest and qualifications and believe it could be worth exploring potential opportunities with us again. If you are still interested in joining {{employer_name}} and available to discuss this further, please let us know your availability so we can arrange a follow-up discussion.  We are looking forward to possibly having you on our team and are eager to hear back from you soon.  Best regards,  The {{employer_name}} Recruitment Team`,
                },
                rejection: {
                    default: {
                        subject: `Update on Your Application for {{job_title}} at {{employer_name}}`,
                        body: `<p>Dear {{candidate_name}},</p><p>Thank you for your interest in the {{job_title}} position at {{employer_name}} and for the time you dedicated to your application. We wanted to reach out to let you know that after careful consideration, we will not be moving forward with your application.</p><p>The competition for opportunities at {{employer_name}} is always high, and this decision was not easy. We are grateful for the chance to learn about your skills and experiences and recommend you to keep an eye on our careers page as more opportunities arise that may align with your qualifications.</p><p>Thank you once again for considering a career with us. We wish you all the best in your job search and future professional endeavors.</p><p>Warm regards,</p><p>The {{employer_name}} Recruitment Team</p>`,
                    },
                    rejection_authorization: {
                        subject: `Update on Your Application for {{job_title}} at {{employer_name}}`,
                        body: `<p>Dear {{candidate_name}},</p><p>Thank you for applying for the {{job_title}} role and for the interest you have shown in {{employer_name}}. After careful consideration of your application and current hiring criteria, we must inform you that we cannot proceed with your application due to the work authorization requirement in this country, which you indicated you do not currently meet.</p><p>We value your time and effort in considering a position with us and encourage you to apply again should your situation change in the future.</p><p>Best wishes for your job search and future professional paths.</p><p>Kind regards,</p><p>The {{employer_name}} Recruitment Team</p>`,
                    },
                    rejection_location: {
                        subject: `Update on Your Application for {{job_title}} at {{employer_name}}`,
                        body: `<p>Dear {{candidate_name}},</p><p>We appreciate your application for the {{job_title}} at {{employer_name}} and the enthusiasm you've shown towards joining our team. After reviewing the requirements of the role, we regret to inform you that we are unable to proceed with your application as the position requires regular on-site presence, which you've indicated would not be feasible for you.</p><p>We understand that not every role is a perfect fit, and we thank you for your honesty and openness during this process. Please consider applying for future openings that may better accommodate your location preferences.</p><p>Thank you again for your interest in {{employer_name}}. We wish you success in your ongoing job search and future professional endeavors.</p><p>Best regards,</p><p>The {{employer_name}} Recruitment Team</p>`,
                    },
                    rejection_sponsorship: {
                        subject: `Update on Your Application for {{job_title}} at {{employer_name}}`,
                        body: `<p>Dear {{candidate_name}},</p><p>Thank you for your interest in the {{job_title}} at {{employer_name}} and for engaging with our application process. We regret to inform you that we are unable to offer sponsorship for work authorization, as you mentioned would be needed eventually. Therefore, we are unable to move forward with your application.</p><p>We appreciate your transparency and the opportunity to consider your application. Please do keep us in mind for future opportunities that may align better with your circumstances.</p><p>We wish you the best in your professional journey.</p><p>Sincerely,</p><p>The {{employer_name}} Recruitment Team</p>`,
                    },
                }

            }
        }

        const teamsCollection = collection(db, "teams");



        const employerRef = await addDoc(teamsCollection, {
            name: employerName,
            settings: settings,
            createdTime: Timestamp.now(),
        })

        const availability = {
            Monday: [{
                start: "09:00",
                end: "17:00",
            }],
            Tuesday: [{
                start: "09:00",
                end: "17:00",
            }],
            Wednesday: [{
                start: "09:00",
                end: "17:00",
            }],
            Thursday: [{
                start: "09:00",
                end: "17:00",
            }],
            Friday: [{
                start: "09:00",
                end: "17:00",
            }],
        }

        const membersRef = collection(db, "members");

        var memberIds = []

        for (const admin of admins) {
            const memberRef = await addDoc(membersRef, {
                first_name: admin.first_name,
                last_name: admin.last_name,
                team_id: employerRef.id,
                role: "Admin",
                email: admin.email.toLowerCase(),
                timezone: admin.timezone,
                availability: availability
            })

            memberIds.push(memberRef.id)
        }

        await updateDoc(employerRef, {members: memberIds})
        // then create the member document - with standard availability

        // then update the team document to have the member within members
        setPostmarkText(true);
    }

    const [postmarkText, setPostmarkText] = useState(false);

    const labelStyle = "original"
    const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
    }


    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })


    const [defaultTimezone, setDefaultTimezone] = useState(parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone));

    return (
    <>
        {
            god ? (
                <div className="admin_stuff">
                    <h1>Add new client</h1>

                    <input value={employerName} onChange={(e) => setEmployerName(e.target.value)} placeholder="Employer Name"/>
                    <input value={employerEmail} onChange={(e) => setEmployerEmail(e.target.value)} placeholder="hello@client.skillpool.tech" style={{marginBottom:"3rem"}} />

                    {
                        admins.map((admin, index) => (
                            <div key={index} className="team_admin_info">
                                <input value={admin.first_name} onChange={(e) => updateAdmin(index, 'first_name', e.target.value)} placeholder="First Name" />
                                <input value={admin.last_name} onChange={(e) => updateAdmin(index, 'last_name', e.target.value)} placeholder="Last Name" />
                                <input value={admin.email} onChange={(e) => updateAdmin(index, 'email', e.target.value)} placeholder="Email" />

                                <TimezoneSelect value={admin.timezone ? admin.timezone : defaultTimezone} onChange={(value) => updateAdmin(index, 'timezone', value)} />


                                <FaIcons.FaTrash onClick={() => deleteAdmin(index)} />
                            </div>
                        ))
                    }

                    <br />

                    <button onClick={addNewAdmin} style={{marginBottom:"2rem"}}> Add Admin </button>

                    {
                        postmarkText && (
                            <h1>Please setup the postmark email address at {employerEmail}</h1>
                        )
                    }
                    <br />
                    <button onClick={createNewEmployer} > Create </button>
                </div>
            ) : (
                <NotFound />
            )
        }
    </>
    )
}
