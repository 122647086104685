import { useEffect, useReducer, useRef, useState } from "react"
import "../styles/login.css"
import { Link, useNavigate } from "react-router-dom";
import { getAuth, sendSignInLinkToEmail, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { isEmailValid } from "../../functions/isEmailValid";
import { sendNotification } from "../../components/notification";

export const Login = () => {
    const inputRef = useRef(null);
    const passwordRef = useRef(null);
    const messageRef = useRef(null);


    let navigate = useNavigate();
    const [email, setEmail] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");

    useEffect(() => {
        if ((window.location.href.split('/')[3]) != "login") {
            navigate('../login');
        };
    })


    useEffect(() => {
        if (email === "ycombinator") {
            setShowPassword(true)
        } else {
            setShowPassword(false);
        }
    }, [email])


    const actionCodeSettings = {
        
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://app.skillpool.tech/completeSignIn',
        // This must be true.
        handleCodeInApp: true,
      };

    const manageLogin = async () => {

        if (email === "ycombinator") {
            const auth = getAuth();
            signInWithEmailAndPassword(
                auth,
                "testing@yc.com",
                password
            ).then((result) => {
                navigate('/');
            })
            return;
        }
        
        const emailValid = await isEmailValid(email);
        if(!emailValid){
            switchMsgs("<p>This email isn't registered :(</p> <p>Please <a href='https://skillpool.tech/beta'  target='_blank'>get in touch</a> with us to get your account setup!</p>");
            window.localStorage.setItem('useremail', email);
            return;
        }

        sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
            console.log("Email has been sent");
            switchMsgs("<p>We've sent you a login link! Please check your email!</p>");
            window.localStorage.setItem('emailForSignIn', email);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error);
            // ...
        });

        
    }


    const switchMsgs = (message) => {
        inputRef.current.style.display = "none";
        messageRef.current.style.display = "block";
        setMessageToShow(message);
    }

    const [messageToShow, setMessageToShow] = useState("")
    

    return (
        <div className="login_holder">


            <div className="login_header">

                <div className="logo"></div>
                
                <a href="https://skillpool.tech/beta" className="get_in_touch_button" target="_blank">
                    Get In Touch!
                </a>
            </div>

            <div className="login_area">
                <h1>Welcome Back!</h1>

                <div ref={inputRef} style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <input ref={inputRef} placeholder="Please enter your email here!" className="login_email_input" type="email" name="user_email" onChange={(e)=>setEmail(e.target.value)} onKeyPress={(e) => {if (e.key === 'Enter') {manageLogin()}}}/>
                    {
                        showPassword && (
                            <input ref={passwordRef} placeholder="Password (Hey YC! Please enter skillpoolf24 here)" style={{marginTop:"0rem"}} className="login_email_input" type="text" name="user_email" onChange={(e)=>setPassword(e.target.value)} onKeyPress={(e) => {if (e.key === 'Enter') {manageLogin()}}}/>
                        )
                    }
                    <div className="sign_in login_action_button" onClick={manageLogin}>
                        Log In!
                    </div>
                </div>

                <div ref={messageRef} style={{display:"none", marginTop:"2rem"}} dangerouslySetInnerHTML={{__html: messageToShow}}></div>

              
            </div>
            
            
        </div>
    )
}