import React, { useState, useEffect, useRef } from 'react';
import { registerBlockType } from '@quillforms/blocks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importing quill styles

const RichTextBlockDisplay = ({ setIsValid, setIsAnswered, setVal }) => {
  const [text, setText] = useState('');
  const quillRef = useRef(null); // Ref to access the ReactQuill instance

  const handleChange = (content) => {
    setText(content);
    setVal(content);
    if (content.trim()) {
      setIsValid(true);
      setIsAnswered(true);
    } else {
      setIsValid(false);
      setIsAnswered(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default action (form move to the next question)
        event.stopPropagation(); // Stop the event from propagating to parent elements
      }
    };

    const quillInstance = quillRef.current.getEditor(); // Get the Quill editor instance
    const editorRoot = quillInstance.root; // The editor's root DOM element
    editorRoot.addEventListener('keydown', handleKeyDown);

    return () => {
      editorRoot.removeEventListener('keydown', handleKeyDown); // Cleanup on component unmount
    };
  }, []);

  return (
      <ReactQuill ref={quillRef} value={text} onChange={handleChange} />
  );
};

registerBlockType('rich-text', {
  supports: {
    editable: true,
  },
  attributes: {
    // Define any specific attributes your block might need
  },
  display: RichTextBlockDisplay,
});