import { useState } from "react";
import Popup from "reactjs-popup"
import * as FaIcons from "react-icons/fa"
import { X } from "lucide-react";
import { DonutChart } from "../../../donutChart";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../../shadcn/collapsible";


export const SkillScoreInfo = () => {

    const [popupIsOpen, setPopupOpen] = useState(false);

    return (
        <Popup
            trigger={
            <div className="skillscore_info_icon" style={{top: "2rem", left:"2rem"}}>
                <FaIcons.FaInfoCircle />
            </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            

        >
            <div className="popup_holder">
                <div className="popup" style={{width: "55%", height: "65%"}}>

                    <h1 className="popup_heading" style={{marginBottom:"1rem"}}>Guide</h1>

                    <div className="skillscore_info_item">
                        <p className="skillscore_info_item_body">SkillScore enables you to define the ideal candidate profile by prioritizing the factors that matter most to you.</p>
                    </div>

                    <div className="skillscore_info_item">
                        <h3 className="skillscore_info_item_heading">Score Calculation</h3>
                        <p className="skillscore_info_item_body">The overall score is calculated based on key factors:
                        <ol>
                            <li>Education, Work Experience, and Technical Skills (assessed through resume screening) </li>
                            <li>Soft Skills and personality traits (evaluated via AI-driven interviews). </li>
                        </ol>
                        Each of these factors includes detailed sub-factors that contribute to the final score.
                        </p>
                    </div>

                    <div className="skillscore_info_item">
                        <h3 className="skillscore_info_item_heading">Explore Sub-Factors</h3>
                        <p className="skillscore_info_item_body">Click on any factor to explore its sub-factors and see examples of how that factor will be displayed on a candidate profile.</p>
                    </div>

                    <div className="skillscore_info_item">
                        <h3 className="skillscore_info_item_heading">Customize Weightage</h3>
                        <p className="skillscore_info_item_body">You can customize the weightage of each factor to align with your hiring priorities, ensuring the candidate score reflects what’s most important to your organization.</p>
                    </div>

                    <div className="popup_close" onClick={() => setPopupOpen(false)}> <X /> </div>
                </div>
            </div>
        </Popup>
    )
}